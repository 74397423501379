import qs from 'qs';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { addDays, differenceInDays, format, isDate, startOfDay } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import {
	Button,
	WhoForm,
	FormField,
	SearchFormButton,
} from '@/components/common';
import {
	WHO_OPTIONS,
	HOLIDAY_DURATIONS,
	HOLIDAY_DESTINATIONS_DEPARTING,
	HOLIDAY_DESTINATIONS_GOING_TO,
} from '@/lib/utils';
import { useSearchSave } from '@/hooks';
import { holidaySearchSchema } from '@/validationSchemas';

function HolidaySearchForm({ clear, params, hideClearBtn }) {
	const navigate = (url) => (window.location.href = url);

	const { saveSearch, getSearch } = useSearchSave();

	const [showDatePicker, setShowDatePicker] = useState(
		params?.startDate && params?.endDate
	);

	const savedSearch = getSearch('holidays');

	let defaultParams = null;

	if (savedSearch && !params) {
		defaultParams = savedSearch;
	} else {
		defaultParams = params;
	}

	const emptyParams = {
		type: null,
		location: null,
		from: null,
		who: WHO_OPTIONS.reduce((acc, option) => {
			acc[option.value] = null;
			return acc;
		}, {}),
		month: null,
		duration: null,
		when: {
			from: addDays(new Date(), 3),
			to: addDays(new Date(), 8),
		},
	};

	const defaultValues = useMemo(() => {
		if (!defaultParams) return emptyParams;

		const whoKeys = WHO_OPTIONS.map((option) => option.value);

		const type = defaultParams?.type ? defaultParams.type.split(':') : [];
		const location = defaultParams?.location
			? defaultParams.location.split(':')
			: [];
		const from = defaultParams?.from ? defaultParams.from.split(':') : [];
		const month = defaultParams?.month ? defaultParams.month.split(':') : [];
		const duration = defaultParams?.duration
			? defaultParams.duration.split(':')
			: [];
		const startDate =
			defaultParams?.startDate && new Date(defaultParams.startDate);
		const endDate = defaultParams?.endDate && new Date(defaultParams.endDate);

		const formattedWho = whoKeys.reduce((acc, key) => {
			const value = defaultParams?.who?.[key]
				? defaultParams.who[key].split(':')
				: [];
			return {
				...acc,
				[key]: value[0]
					? {
							value: value[0],
							label: value[1] || '',
					  }
					: null,
			};
		}, {});

		// only pre-fill if at least 3 days from today
		const fromDate = isDate(startDate) && differenceInDays(startDate, startOfDay(new Date())) >= 3 ?
			startDate :
			addDays(new Date(), 3);

		// only pre-fill if after the from date
		const toDate = isDate(endDate) && differenceInDays(endDate, fromDate) >= 0 ?
			endDate :
			addDays(fromDate, 8)

		return {
			type: type[0]
				? {
						value: type[0],
						label: type[1] || '',
				  }
				: null,
			location: location[0]
				? {
						value: location[0],
						label: location[1] || '',
				  }
				: null,
			from: from[0]
				? {
						value: from[0],
						label: from[1] || '',
				  }
				: null,
			who: formattedWho,
			month: month[0]
				? {
						value: month[0],
						label: month[1] || '',
				  }
				: null,
			duration: duration[0]
				? {
						value: duration[0],
						label: duration[1] || '',
				  }
				: null,
			when: {
				from: fromDate,
				to: toDate,
			},
		};
	}, [defaultParams, emptyParams]);

	const methods = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(holidaySearchSchema),
	});

	const {
		watch,
		control,
		setValue,
		handleSubmit,
		formState: { errors },
	} = methods;

	const monthOptions = useMemo(() => {
		const date = new Date();
		const options = [
			{
				value: format(date, 'yyyy-MM'),
				label: format(date, 'MMMM yyyy'),
			},
		];

		// show all months up to a year in advance
		for (let i = 0; i <= 11; i++) {
			date.setMonth(date.getMonth() + 1);
			options.push({
				value: format(date, 'yyyy-MM'),
				label: format(date, 'MMMM yyyy'),
			});
		}

		return options;
	});

	const onSubmit = async (data) => {
		const { type, location, from, who, month, duration, when } = data;

		const formattedWho = Object.keys(who).reduce((acc, type) => {
			acc[type] = who[type]?.value
				? `${who[type].value}:${who[type].label}`
				: '';
			return acc;
		}, {});

		const queryString = qs.stringify({
			type: `${type.value}:${type.label}`,
			location: `${location.value}:${location.label}`,
			from: `${from.value}:${from.label}`,
			who: formattedWho,
			month: month?.value ? `${month.value}:${month.label}` : '',
			duration: duration?.value ? `${duration.value}:${duration.label}` : '',
			startDate:
				!month?.value && when?.from ? format(when.from, 'yyyy-MM-dd') : '',
			endDate: !month?.value && when?.to ? format(when.to, 'yyyy-MM-dd') : '',
		});

		saveSearch(queryString, 'holidays');
		// console.log('submit', params.toString());
		navigate(`/search/holidays?${queryString}`);
	};

	const toggleDatePicker = (e) => {
		e.preventDefault();

		// clear month/duration if changing to date picker
		if (!showDatePicker) {
			setValue('month', {
				value: '',
				label: '',
			});
			setValue('duration', {
				value: '',
				label: '',
			});
		}

		setShowDatePicker(!showDatePicker);
	};

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="mx-auto flex flex-col 2xl:flex-row h-auto w-full items-center justify-center 2xl:items-start gap-2 md:gap-2.5 lg:gap-5"
			>
				<div className="flex flex-col md:flex-row w-full items-start justify-start gap-2 md:gap-2.5 lg:gap-5">
					<FormField
						name="type"
						label="Holiday Type"
						as="select"
						control={control}
						errors={errors}
						wrapperClassName="h-full w-full 2xl:min-w-[100px]"
						labelClassName="whitespace-nowrap"
						placeholder="Select"
						// endpoint="holiday/types"
						// queryKey="holiday-types"
						// isAsync
					/>

					<FormField
						name="from"
						label="Departing from"
						as="select"
						control={control}
						errors={errors}
						wrapperClassName="h-full w-full"
						labelClassName="whitespace-nowrap"
						placeholder="Select"
						options={HOLIDAY_DESTINATIONS_DEPARTING}
					/>

					<FormField
						name="location"
						label="Going to"
						as="select"
						control={control}
						errors={errors}
						wrapperClassName="h-full w-full 2xl:min-w-[100px]"
						labelClassName="whitespace-nowrap"
						placeholder="Select"
						options={HOLIDAY_DESTINATIONS_GOING_TO}
					/>
				</div>

				<div className="flex flex-col lg:flex-row w-full items-start justify-start gap-2 md:gap-2.5 lg:gap-5">
					<WhoForm
						name="who"
						control={control}
						triggerClassName="2xl:min-w-[330px]"
					/>

					{showDatePicker ? (
						<FormField
							name="when"
							label="When"
							as="date-range"
							control={control}
							errors={errors}
							wrapperClassName="h-full w-full lg:min-w-[330px]"
							footer={
								<div className="flex gap-1">
									Don’t know exact dates?{' '}
									<Button
										variant="button-field-footer"
										onClick={toggleDatePicker}
									>
										Search by month.
									</Button>
								</div>
							}
							hideTime
							disabledDays={{
								before: addDays(new Date(), 3),
							}}
						/>
					) : (
						<div className="flex flex-col w-full h-full">
							<div className="h-full w-full flex sm:flex-row gap-2 md:gap-2.5 lg:gap-5">
								<FormField
									name="month"
									label="When"
									as="select"
									control={control}
									errors={errors}
									wrapperClassName="w-full lg:min-w-[275px]"
									placeholder="Select"
									options={monthOptions}
									footer={
										<div className="hidden gap-1 sm:flex">
											Know exact dates?{' '}
											<Button
												variant="button-field-footer"
												onClick={toggleDatePicker}
											>
												Use date picker.
											</Button>
										</div>
									}
								/>

								<FormField
									name="duration"
									label="Duration"
									as="select"
									control={control}
									errors={errors}
									wrapperClassName="w-full lg:w-[180px]"
									placeholder="Select"
									options={HOLIDAY_DURATIONS}
								/>
							</div>

							<div className="text-sm font-normal leading-tight text-opacity-75 text-light-black text-body sm:hidden">
								Know exact dates?{' '}
								<Button
									variant="button-field-footer"
									onClick={toggleDatePicker}
								>
									Use date picker.
								</Button>
							</div>
						</div>
					)}
				</div>

				<SearchFormButton
					label="Find Holidays"
					onCancel={clear}
					hideCancel={hideClearBtn}
					className="lg:mt-0 2xl:mt-6"
					handleSubmit={handleSubmit(onSubmit)}
					hasYouth={watch('who.youth')?.value > 0}
				/>
			</form>
		</FormProvider>
	);
}

HolidaySearchForm.propTypes = {
	clear: PropTypes.func.isRequired,
	params: PropTypes.shape({
		type: PropTypes.string,
		location: PropTypes.string,
		from: PropTypes.string,
		adults: PropTypes.string,
		children: PropTypes.string,
		infants: PropTypes.string,
		month: PropTypes.string,
		duration: PropTypes.string,
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
};

HolidaySearchForm.defaultProps = {
	params: {},
};

export default HolidaySearchForm;
