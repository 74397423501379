import { useBookingStore } from '@/store';
import { DatePlaceHolder } from '@components/common';
import { useBreakpoint } from '@/hooks';

function QueryDetails({ params, QueryItem }) {
	const state = useBookingStore((store) => store.flights);
	const goingTo = params?.outboundArrive.split(':')[1] || 'Select Location';
	const departingFrom =
		params?.outboundDepart.split(':')[1] || 'Select Location';

	const isOneWay = state?.isOneWay ?? false;

	const people = Object.keys(params?.who ?? {}).reduce((acc, key) => {
		const value = params?.who?.[key];
		return acc + (value ? parseInt(value.split(':')[0]) : 0);
	}, 0);

	const isXxs = useBreakpoint('xxs');

	return (
		<div className="flex flex-wrap items-center justify-between w-full h-full gap-2 px-2 py-2 lg:py-0">
			<QueryItem label="Departing from" value={departingFrom} />
			<QueryItem label="Going to" value={goingTo} />
			{!isXxs && (
				<QueryItem
					icon="users"
					value={people.toString()}
					className="flex flex-row items-center gap-3 md:hidden"
				/>
			)}
			<QueryItem
				label={isOneWay ? 'Date' : 'Dates'}
				className="flex-grow"
				value={() => (
					<DatePlaceHolder
						hideTime
						hideToTime={isOneWay}
						selected={{
							from: new Date(params.startDate),
							to: new Date(params.endDate),
						}}
					/>
				)}
			/>
			<QueryItem
				icon="users"
				value={people}
				className="flex-row items-center hidden gap-3 md:flex"
			/>
		</div>
	);
}

export default QueryDetails;
