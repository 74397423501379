import qs from 'qs';
import { useMemo } from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { cva } from 'class-variance-authority';

import {
	cn,
	currencyFormatter,
	formatCarouselSlides,
	navigate,
} from '@/lib/utils';
import {
	Badge,
	Heading,
	Icon,
	MediaCarousel,
	Text,
	Button,
} from '@/components/common';
import { useUrlParams } from '@/hooks';

const hotelCardVariants = cva(
	'h-auto w-full grid grid-cols-1 gap-4 bg-white rounded-lg shadow-md overflow-hidden',
	{
		variants: {
			variant: {
				default: 'lg:grid-cols-3 lg:shadow-none lg:rounded-none border-b pb-5',
				popup: 'grid-cols-1 lg:gap-0 shadow-none rounded-none',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	}
);

function HotelCard({ result, className, variant }) {
	const { params } = useUrlParams('hotel_filters');
	const slides = useMemo(
		() => formatCarouselSlides(result?.gallery),
		[result?.gallery?.length]
	);

	const bookingUrl = useMemo(() => {
		if (!result?.url) return null;

		const { who, startDate, endDate, rooms, roomCount, location, category } =
			params.default || {};

		const bookingParams = {
			who,
			startDate,
			endDate,
			rooms,
			roomCount,
			location,
			tab: 'hotels',
			category: category === 'hotels' ? 'hotels' : undefined,
			priceFrom: result.pricePerPerson || result.price,
		};

		return `${result.url}?${qs.stringify(bookingParams)}`;
	}, [result?.url, params?.default]);

	return (
		<div className={cn(hotelCardVariants({ variant, className }))}>
			{slides?.length > 0 && (
				<div
					className={cn(
						'w-full col-span-1 h-full max-h-[30vh] md:max-h-[40vh]',
						variant === 'popup' ? 'lg:max-h-[60vh]' : 'lg:max-h-125'
					)}
				>
					<MediaCarousel slides={slides} className="h-full" />
				</div>
			)}
			<div
				className={cn(
					'w-full grid-cols-1 bg-white flex flex-col gap-2 justify-center items-start lg:col-span-2 ',
					!slides?.length && variant != 'popup' && 'pt-6 lg:pt-0',
					variant === 'popup' ? 'p-8 lg:p-12' : 'px-4 pb-8 lg:pb-0 lg:my-10'
				)}
			>
				<div className={cn('flex flex-col-reverse gap-2 lg:flex-col')}>
					{result?.rating && result.rating != 'unrated' && (
						<Badge
							text={`${result.rating} Star`}
							icon="star"
							variant="light-grey"
							textClassName="font-bold"
						/>
					)}
					{(result?.pagetitle || result?.address) && (
						<div className="flex flex-col gap-1">
							{result?.pagetitle && (
								<Heading
									as="h3"
									className="self-stretch font-bold text-core-blue "
								>
									{result.pagetitle}
								</Heading>
							)}
							{result?.address && (
								<Text className="font-semibold leading-tight text-core-light-blue">
									{result.address}
								</Text>
							)}
						</div>
					)}
				</div>
				{result?.highlights?.length > 0 && (
					<div className="flex flex-wrap items-center w-full gap-2 px-4 py-2 rounded-md lg:gap-x-4 bg-light-grey md:w-fit">
						{result.highlights.map((highlight) =>
							!highlight?.title ? null : (
								<div
									className="flex items-center justify-start w-full gap-1 lg:w-fit"
									key={highlight?.id}
								>
									{highlight?.image && (
										<img
											className="w-5"
											src={highlight.image}
											alt={`${highlight?.title} icon`}
										/>
									)}
									<Text className="text-sm leading-tight opacity-75 text-grey-dark">
										{highlight.title}
									</Text>
								</div>
							)
						)}
					</div>
				)}
				{result?.introtext && (
					<Text className="self-stretch text-base font-normal leading-tight opacity-75 text-dark-grey line-clamp-4">
						{result.introtext}
					</Text>
				)}
				{(result?.price > 0 ||
					result?.pricePerPerson > 0 ||
					result?.duration) && (
					<div className="self-stretch h-auto pb-2.5 flex-col justify-start items-start gap-2 flex my-2">
						<div className="flex flex-col items-start justify-start gap-1">
							{(result?.price > 0 || result?.pricePerPerson > 0) && (
								<Text variant="muted" className="font-semibold uppercase">
									From
								</Text>
							)}
							<div className="flex items-start justify-start gap-10">
								{(result?.price > 0 || result?.pricePerPerson > 0) && (
									<div className="flex items-center gap-1">
										<Text variant="bold" className="text-2xl">
											{currencyFormatter({
												amount: result.pricePerPerson || result.price,
											})}
										</Text>

										{result.pricePerPerson > 0 && (
											<div>
												<Text
													as="small"
													variant="muted"
													className="hidden text-base font-medium leading-none"
												>
													per person
												</Text>
												<Text
													as="small"
													variant="bold"
													className="text-base font-medium leading-none"
												>
													pp
												</Text>
											</div>
										)}
									</div>
								)}
								{result.duration > 0 && (
									<div className="flex items-center gap-1">
										<Icon name="moon" />
										<Text variant="bold" className="text-2xl">
											{result.duration} {pluralize('night', result.duration)}
										</Text>
									</div>
								)}
							</div>
						</div>
						{result.priceNotice && (
							<div className="flex flex-col">
								<Text
									as="small"
									variant="muted"
									className="text-lightest-grey opacity-60"
								>
									{result.priceNotice}
								</Text>
							</div>
						)}
					</div>
				)}
				{bookingUrl ? (
					<Button
						label="View & Book"
						variant="core-blue"
						tagName="a"
						href={bookingUrl}
						className="flex items-center gap-4"
					/>
				) : null}
			</div>
		</div>
	);
}

HotelCard.propTypes = {
	result: PropTypes.object.isRequired,
	className: PropTypes.string,
	variant: PropTypes.oneOf(['default', 'popup']),
};

HotelCard.defaultProps = {
	variant: 'default',
};

export default HotelCard;
