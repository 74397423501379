import { WHO_OPTIONS } from '@/lib/utils'

export const carhireParams = (state) => {
    const selected = state?.selected ?? null;

    if (!selected) return null;

    const selectedPackage = state?.carExtras?.selectedPackageExtra ?? null;
    const selectedItemExtras = state?.carExtras?.selectedItemExtras ?? [];

    selected.extras = []
    if (selectedPackage) {
        const extrasFormat = {
            type: 'item',
            code: selectedPackage?.code ?? '',
            set: selectedPackage?.set ?? '',
            startDate: selected?.startDate ?? '',
            endDate: selected?.endDate ?? '',
            quantity: 1,
            pickupTime: selected?.pickupTime ?? '',
            dropoffTime: selected?.dropoffTime ?? ''
        }
        selected.extras = [extrasFormat]
    }

    if (selectedItemExtras?.length > 0) {
        selectedItemExtras.forEach((extra) => {
            selected.extras.push({
                type: 'item',
                code: extra?.item?.code ?? '',
                set: extra?.item?.set ?? '',
                startDate: selected?.startDate ?? '',
                endDate: selected?.endDate ?? '',
                quantity: extra?.quantity ?? 1,
                pickupTime: selected?.pickupTime ?? '',
                dropoffTime: selected?.dropoffTime ?? ''
            })
        })
    }

    const people = WHO_OPTIONS.reduce((acc, key) => {
        acc[key.value] = key.value === 'adults' ? 1 : 0;
        return acc;
    }, {});


    let params = {
        items: [selected],
        rooms: [people]
    }

    // include booking details to the preview as well
    if (state?.bookingDetails) {
        params = {
            ...params,
            ...state?.bookingDetails,
            rooms: state?.bookingDetails?.pax ? [] : [people]
        }
    } 

    return params
}

export const flightsParams = (state) => {
    if (!state) return null;
    if (!state.selected) return null;

    // make sure selected outbound is available
    if (!state?.selected?.outbound) return null;

    // if it's a return flight make sure the inbound is available
    if (!state?.isOneWay && !state?.selected?.inbound) return null;

    const items = state?.selected?.items ?? [];

    if (!Array.isArray(items)) return null;
    if (items.length === 0) return null;

    // make sure items[0] outbound is available
    if (!items[0]?.outbound) return null;

    // if it's a return flight make sure the inbound is available
    if (!state?.isOneWay && !items[0]?.inbound) return null;


    // default params to be updated
    let initialParams = {
        items: state?.selected?.items,
        rooms: !state?.bookingDetails?.pax || state?.usePeople
            ? [state?.selected?.people]
            : [],
    };


    // get the pax details from the booking state
    if (state?.bookingDetails && !state?.usePeople) {
        initialParams = {
            ...initialParams,
            rooms: [],
            ...state?.bookingDetails,

        };
    }


    // if option plus is selected add it to the params
    if (state?.optionPlusOptions) {
        const optionsPlus = state?.optionPlusOptions;
        const newItems = initialParams?.items?.map((item) => {
            const newItem = { ...item };

            Object.keys(optionsPlus).forEach((key) => {
                if (key === 'roundTrip') {
                    newItem.outbound = {
                        ...newItem.outbound,
                        optionPlus: {
                            code: optionsPlus[key]?.code,
                            set: optionsPlus[key]?.set,
                            startDate: optionsPlus[key]?.startDate,
                        }
                    }
                } else {
                    if (!newItem[key]) return;
                    newItem[key] = {
                        ...newItem[key],
                        optionPlus: {
                            code: optionsPlus[key]?.code,
                            set: optionsPlus[key]?.set,
                            startDate: optionsPlus[key]?.startDate,
                        }
                    }
                }

            })
            return newItem;
        });

        initialParams = { ...initialParams, items: newItems };
    }


    // if baggage is added
    if (state?.baggage) {
        const newItems = initialParams?.items?.map((item) => {
            if (item?.type !== 'flight') return item;

            const newItem = { ...item };

            if (state?.baggage?.outbound) {
                newItem.outbound = {
                    ...newItem.outbound,
                    baggage: state?.baggage?.outbound,
                };
            }

            if (state?.baggage?.inbound) {
                newItem.inbound = {
                    ...newItem.inbound,
                    baggage: state?.baggage?.inbound,
                };
            }

            return newItem;
        });

        initialParams = {
            ...initialParams,
            items: newItems,
        };
    }

    // if seats are selected
    if (state?.selectedSeats) {
        // add them within the outbound and inbound sectors
        const newParams = initialParams?.items?.map((item) => {
            if (item?.type !== 'flight') return item;

            const newItem = { ...item };

            ['outbound', 'inbound'].forEach((flightType) => {
                const selection = state?.selectedSeats?.[flightType] ?? null;
                const sectors = newItem?.[flightType]?.sectors ?? [];
                const hasSeatsAssigned = sectors.some((sector) => sector?.seats?.length > 0);

                if (!selection) {
                    if (hasSeatsAssigned) {
                        newItem[flightType] = {
                            ...newItem[flightType],
                            sectors: sectors.map((sector) => {
                                sector.seats = undefined;
                                sector.gateway = undefined;
                                return sector;
                            }),
                        };
                    }
                } else {
                    newItem[flightType] = {
                        ...newItem[flightType],
                        sectors: sectors.map((sector) => {
                            const flightNumber = sector?.flightNumber;
                            const seats = selection?.[flightNumber]?.seats ?? [];
                            return {
                                ...sector,
                                gateway: selection?.gateway,
                                seats: seats.map((seat) => ({
                                    paxRef: seat?.paxRef,
                                    col: seat?.col,
                                    row: seat?.row,
                                })),
                            }
                        }),
                    };
                }
            })

            return newItem;
        });

        initialParams = {
            ...initialParams,
            items: newParams,
        };
    }

    return initialParams;
}

export const hotelsParams = (state) => {
    if (!state) return null;


    const selected = state?.selected ?? null;

    if (!selected) return null;

    const rooms = state?.selected?.rooms ?? [];

    if (!Array.isArray(rooms)) return null;
    if (rooms.length === 0) return null;

    let params = {
        items: [
            {
                type: 'accommodation',
                ...selected,
            }
        ],
    }

    // include booking details to the preview as well
    if (state?.bookingDetails) {
        params = {
            ...params,
            ...state?.bookingDetails,
        }
    }

    return params

}


/**
 * @name buildParams
 * @description Builds the params for the given category from the state values
 * @param {unknown} state 
 * @param {"flights" | "hotels" | "motorhome-hire" | "car-hire" | "holidays"} category 
 * @returns {Object | null}
 * @example
 * buildParams(state, 'car-hire');
 */
export const buildParams = (state, category) => {
    if (!state || !category) return null;

    switch (category) {
        case 'car-hire':
            return carhireParams(state)
        case 'flights':
            return flightsParams(state)
        case 'hotels':
            return hotelsParams(state)
        default:
            return null
    }
}
