import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorHandler, Heading, Text } from '@/components/common';
import { FlightDestinationSummary, OptionPlusCard } from '@/components/flights';
import { useBookingStore } from '@/store';

/**
 * @typedef {object} OptionsPlusListProps
 * @property {Array} inBoundOptions
 * @property {Array} outBoundOptions
 * @property {(param: opt)=> boolean} getSelected
 * @property {(param: opt)=> void} handleOptionSelection
 */

/**
 * @name OptionsPlusList
 * @description Renders the list of option plus cards
 * @param {OptionsPlusListProps} props
 * @returns {React.JSX.Element}
 * @example
 * import { OptionsPlusList } from '@components/flights';
 * ...
 * <OptionsPlusList options={[]} outBoundOptions={[]} getSelected={getSelected} />
 */

function OptionsPlusList({
	options,
	selectedFlights,
	handleOptionSelection,
	checkOptionExists,
	optionPlusErrorMessage,
}) {
	const state = useBookingStore((store) => store.flights);
	if (!(options?.outbound || options?.inbound || options?.roundTrip))
		return <div className="w-full p-8">No options available</div>;

	const singleOutboundSelected = checkOptionExists('outbound');
	const singleInboundSelected = checkOptionExists('inbound');
	const roundTripSelected = checkOptionExists('roundTrip');
	const noThanksSelected = checkOptionExists('nothanks');

	return (
		<ErrorBoundary FallbackComponent={ErrorHandler}>
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-4">
					{options?.roundTrip ? (
						<Heading as="h2" className="text-3xl font-bold font-body">
							One Way
						</Heading>
					) : null}

					<OptionPlusCard
						title="Outbound Flight"
						flight={selectedFlights?.outbound}
						option={options?.outbound}
						isDisabled={state?.previewLoading}
						onSelect={(option) => handleOptionSelection('outbound', option)}
						isSelected={singleOutboundSelected}
						isAvailable={
							!!options?.outbound && !roundTripSelected && !noThanksSelected
						}
						unavailableMessage={
							!options?.outbound
								? 'Not Available'
								: `Not Available${
										roundTripSelected ? ' with "Round Trip"' : ''
								  }`
						}
					/>

					{selectedFlights?.inbound ? (
						<OptionPlusCard
							title="Return Flight"
							flight={selectedFlights?.inbound}
							option={options?.inbound}
							isDisabled={state?.previewLoading}
							onSelect={(option) => handleOptionSelection('inbound', option)}
							isSelected={singleInboundSelected}
							isAvailable={
								!!options?.inbound && !roundTripSelected && !noThanksSelected
							}
							unavailableMessage={
								!options?.inbound
									? 'Not Available'
									: `Not Available${
											roundTripSelected ? ' with "Round Trip"' : ''
									  }`
							}
						/>
					) : null}
				</div>

				{options?.roundTrip ? (
					<div className="flex flex-col gap-4">
						<Heading as="h2" className="text-3xl font-bold font-body">
							Round Trip
						</Heading>

						<OptionPlusCard
							option={options?.roundTrip}
							isDisabled={state?.previewLoading}
							title="Outbound & Return Flights"
							onSelect={(option) => handleOptionSelection('roundTrip', option)}
							isSelected={roundTripSelected}
							isAvailable={
								!!options?.roundTrip &&
								!noThanksSelected &&
								!(singleOutboundSelected || singleInboundSelected)
							}
							unavailableMessage={`Not Available${
								singleOutboundSelected || singleInboundSelected
									? ' with "One Way"'
									: ''
							}`}
							renderSummary={() => (
								<>
									<FlightDestinationSummary
										flight={selectedFlights?.outbound}
									/>
									<FlightDestinationSummary flight={selectedFlights?.inbound} />
								</>
							)}
						/>
					</div>
				) : null}

				<div className="flex flex-col gap-4">
					<OptionPlusCard
						isDisabled={state?.previewLoading}
						title="No Thanks, I Do Not Wish To Purchase Option Plus"
						onSelect={() => handleOptionSelection('nothanks', true)}
						isSelected={noThanksSelected}
						isAvailable={
							!singleInboundSelected &&
							!singleOutboundSelected &&
							!roundTripSelected
						}
						hidePrice={true}
						checkboxText="Skip Option Plus"
						renderSummary={() => null}
					/>
				</div>

				{optionPlusErrorMessage.length > 0 && (
					<Text as="p" className="text-core-red">
						{optionPlusErrorMessage}
					</Text>
				)}
			</div>
		</ErrorBoundary>
	);
}

OptionsPlusList.propTypes = {
	options: PropTypes.shape({
		outbound: PropTypes.object,
		inbound: PropTypes.object,
		roundTrip: PropTypes.object,
	}),
	selectedFlights: PropTypes.shape({
		outbound: PropTypes.object,
		inbound: PropTypes.object,
	}),
	handleOptionSelection: PropTypes.func.isRequired,
	checkOptionExists: PropTypes.func.isRequired,
};

export default OptionsPlusList;
