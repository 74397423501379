import { cn } from '@/lib/utils';
import {
	Drawer as CnDrawer,
	DrawerContent,
	DrawerClose,
	DrawerTrigger,
	DrawerHeader,
} from './CnDrawer';
import { Icon, Heading } from '@/components/common';

function Drawer({
	asChild,
	renderTrigger,
	contentClassName,
	triggerClassName,
	children,
	className,
	title,
	noDrag,
	dismissable = true,
}) {
	return (
		<CnDrawer className={className} dismissible={dismissable}>
			<DrawerTrigger asChild={asChild} className={triggerClassName}>
				{renderTrigger()}
			</DrawerTrigger>

			<DrawerContent className="rounded-t-3xl z-[999]">
				<DrawerHeader
					className={cn(
						'bg-white flex items-center justify-between py-0 px-5',
						!title && 'justify-end'
					)}
				>
					{title ? (
						<Heading as="h3" className="text-2xl text-left font-body">
							{title}
						</Heading>
					) : null}
					<DrawerClose className="self-end">
						<Icon name="close" className="w-10 h-10" />
					</DrawerClose>
				</DrawerHeader>
				<hr className="my-2 border-lighter-grey" />
				<div data-vaul-no-drag={noDrag} className={cn('p-4', contentClassName)}>
					{children}
				</div>
			</DrawerContent>
		</CnDrawer>
	);
}
export default Drawer;
