import PropTypes from 'prop-types';

import { ToggleButtonGroup } from '@/components/common';
import { cn } from '@/lib/utils';

/**
 * @typedef {Object} MapListToggleProps
 * @property {string} view
 * @property {boolean} isDisabled
 * @property {(value: string) => void} onChange
 */

/**
 * @name MapListToggle
 * @description A toggle for switching between map and list view
 * @param {MapListToggleProps} props
 * @returns {React.JSX.Element}
 * @example
 * <MapListToggle view="map" onChange={(value) => console.log(value)} />
 */

function MapListToggle({ view, isDisabled, onChange, className }) {
	return (
		<ToggleButtonGroup
			value={view}
			instance="pages"
			onChange={onChange}
			disabled={isDisabled}
			options={[
				{ label: 'List View', value: 'default' },
				{ label: 'Map View', value: 'map' },
			]}
			className={cn("w-full md:w-auto md:ml-auto", className)}
			itemClassName="uppercase font-bold text-sm leading-snug"
		/>
	);
}

MapListToggle.propTypes = {
	isDisabled: PropTypes.bool,
	view: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

MapListToggle.defaultProps = {
	isDisabled: false,
};

export default MapListToggle;
