import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { Heading, Text } from '@/components/common';
import { BaggageOption } from '@components/flights';

function BaggageFlights({
	options,
	passenger,
	className,
	baggageParams,
	selectedOptions,
	handleSelection,
}) {
	const fullName = [
		passenger?.firstName,
		passenger?.middleName,
		passenger?.lastName,
	]
		.filter((name) => name)
		.join(' ');

	if (!options?.outbound && !options?.inbound) {
		return <div>No additional baggage available.</div>;
	}

	return (
		<div className={cn(className)}>
			<div>
				<Heading className="text-3xl font-bold font-body">
					Passenger {passenger?.ref || ''}
				</Heading>
				<Text className="mt-2 mb-4 capitalize">
					{fullName} {passenger?.type ? `(${passenger?.type})` : ''}
				</Text>
			</div>
			<div className="flex flex-col gap-2 lg:gap-4">
				{Object.keys(options).map((route, idx) => {
					const baggage = options[route];
					const flightDetails = baggageParams?.[route];

					// Don't show card if no baggage options available- e.g One way flight
					if (!baggage) return null;

					return (
						<BaggageOption
							route={route}
							baggage={baggage}
							flight={flightDetails}
							passenger={passenger}
							selectedOptions={selectedOptions}
							handleSelection={handleSelection}
							key={`${route}-pass-${fullName}-${idx}`}
						/>
					);
				})}
			</div>
		</div>
	);
}

BaggageFlights.propTypes = {
	className: PropTypes.string,
	passenger: PropTypes.object.isRequired,
	options: PropTypes.object.isRequired,
	baggageParams: PropTypes.object.isRequired,
	selectedOptions: PropTypes.object,
	handleSelection: PropTypes.func.isRequired,
};

BaggageFlights.defaultProps = {
	className: '',
};

export default BaggageFlights;
