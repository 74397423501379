import React from 'react';
import PropTypes from 'prop-types';
import { format, isSameDay, isValid } from 'date-fns';

import { useDisclosure } from '@/hooks';
import { cn, dateRangeFormatter, formatLocalDate } from '@/lib/utils';
import { Icon, Text, Heading, Button, Route } from '@/components/common';

/**
 * @typedef {Object} CarSummaryCardProps
 * @property {Object | null} car
 * @property {string} className
 * @property {boolean} justForShow
 */

/**
 * @name CarSummaryCard
 * @description A summary of the car details
 * @param {CarSummaryCardProps} props
 * @returns {React.JSX.Element}
 * @example
 * <CarSummaryCard car={car} />
 */

function CarSummaryCard({ car, className, justForShow }) {
	const { onToggle, isOpen } = useDisclosure(false);

	const formattedPickupDate = formatLocalDate(car?.startDate, 'dd MMMM yyyy');
	const formattedDropoffDate = formatLocalDate(car?.endDate, 'dd MMMM yyyy');

	const defaultText = car?.defaultInsuranceType;

	return (
		<div
			className={cn(
				'bg-white border border-lighter-grey w-full p-5 grid grid-cols-1 gap-10',
				car?.image && 'md:grid-cols-3',
				className
			)}
		>
			<div className="col-span-1 md:col-span-2">
				{/* Mobile car image */}
				{car?.image && (
					<div className="w-full md:hidden flex items-center mb-2">
						<img
							className="self-stretch h-full w-full object-contain"
							src={car.image}
							alt={car?.displayName || car?.name}
						/>
					</div>
				)}
				<Heading
					as="h3"
					className="text-core-blue font-bold font-body text-2xl"
				>
					{car?.displayName || car?.name}
				</Heading>
				{car?.features?.length > 0 && (
					<>
						<Button
							variant="unstyled"
							onClick={onToggle}
							className="my-2 gap-1 hover:opacity-75"
						>
							<Text as="span" className="leading-tight text-sm">
								Info
							</Text>
							<Icon
								className="w-3 h-3"
								name={isOpen ? 'chevron-up' : 'chevron-down'}
							/>
						</Button>
						{isOpen && (
							<div className="flex flex-col gap-1 p-3">
								{(car?.pax || car?.largeBaggage || car?.smallBaggage) && (
									<div className="flex flex-row gap-4 items-center justify-start md:justify-start">
										{car?.pax && (
											<Text className="flex gap-1 items-center">
												<Icon name="person" />
												{car.pax}
											</Text>
										)}
										{car?.largeBaggage && (
											<Text className="flex gap-1 items-center">
												<Icon name="luggage" />
												{car.largeBaggage}
											</Text>
										)}
										{car?.smallBaggage && (
											<Text className="flex gap-1 items-center">
												<Icon name="small-luggage" />
												{car.smallBaggage}
											</Text>
										)}
									</div>
								)}
								<ul>
									{car.features.map((feature) => (
										<li key={feature} className="list-none">
											<Text variant="muted" className="flex gap-2 items-center">
												<Icon name="maple-leaf" />
												<Text as="span">{feature}</Text>
											</Text>
										</li>
									))}
								</ul>
							</div>
						)}
					</>
				)}
				{(formattedPickupDate || formattedDropoffDate) ? (
					<Route
						className="my-3"
						iconName="car-right"
						renderFrom={() => (
							<Text as="span" className="flex flex-col">
								<Text as="span">Pick up</Text>
								<Text as="span" className="font-bold">
									{formattedPickupDate
										? formattedPickupDate
										: '-'}
								</Text>
							</Text>
						)}
						renderTo={() => (
							<Text as="span" className="flex flex-col">
								<Text as="span">Drop off</Text>
								<Text as="span" className="font-bold">
									{formattedDropoffDate
										? formattedDropoffDate
										: '-'}
								</Text>
							</Text>
						)}
					/>
				) : null}
				<div>
					<Text>
						Package:{' '}
						<span className="font-bold">
							{car?.packageExtra?.displayName ||
								car?.packageExtra?.name ||
								defaultText}
						</span>
					</Text>
					{car?.itemExtras?.length > 0 && (
						<>
							<Text>Extras (payable on collection):</Text>
							<ul className="list-disc ml-4">
								{car.itemExtras.map((extra) => (
									<li key={extra?.code}>
										<Text as="span" className="font-bold">
											{extra.quantity > 1 && <span>{extra.quantity}x </span>}

											{extra?.displayName || extra?.name}
										</Text>

										{/* Show extra rental period if different from pickup */}
										{!(
											extra?.startDate === car?.startDate &&
											extra?.endDate === car?.endDate
										) && (
												<Text as="span">
													{' '}
													{dateRangeFormatter({
														start: extra?.startDate,
														end: extra?.endDate,
													})}
												</Text>
											)}
									</li>
								))}
							</ul>
						</>
					)}
				</div>
			</div>
			{/* Desktop car image */}
			{car?.image && (
				<div className="hidden col-span-1 w-full md:block">
					<img
						className="self-stretch h-full w-full object-contain"
						src={car.image}
						alt={car?.displayName || car?.name}
					/>
				</div>
			)}
		</div>
	);
}

CarSummaryCard.propTypes = {
	car: PropTypes.object,
	className: PropTypes.string,
	justForShow: PropTypes.bool,
};

CarSummaryCard.defaultProps = {
	className: '',
	justForShow: false,
	car: null,
};

export default CarSummaryCard;
