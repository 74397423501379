import React, { useMemo } from 'react';
import { ErrorMessage } from '@hookform/error-message';
// import { useFormState, useWatch } from 'react-hook-form';

import { cn } from '@/lib/utils';
import { LoopWrapper, Text } from '@components/common';

function WhoTrigger({
	name,
	values,
	errors,
	options,
	renderExtraLabel,
	triggerClassName,
}) {
	const label = useMemo(() => {
		if (Object.entries(values).length === 0) return 'Select ...';

		const whoKeys = options.map((option) => option.value);
		return (
			Object.entries(values)
				.filter(([key, value]) => whoKeys.includes(key) && value?.value > 0)
				.map(([key, value]) => {
					if (key === 'children' && value?.value <= 1) {
						return `${value?.value} child`;
					}
					if (value?.value === 1) {
						return `${value?.value} ${key.replace(/s$/, '')}`;
					}
					return `${value?.value} ${key}`;
				})
				.join(', ') || 'Select ...'
		);
	}, [values, options]);

	const hasErrors = errors && errors[name];
	const fields = [name, ...options.map((option) => `${name}.${option.value}`)];

	return (
		<div className="w-full flex flex-col">
			<div className="w-full flex items-center justify-between gap-2">
				<Text className="text-light-black">Who</Text>
				{renderExtraLabel ? renderExtraLabel() : null}
			</div>
			<div
				data-invalid={hasErrors ? 'true' : 'false'}
				className={cn(
					'h-10.5 w-full border border-border-color flex items-center flex-wrap px-4 rounded  data-[invalid=true]:border-core-red data-[invalid=true]:text-core-red lg:h-13.25 lg:min-w-[200px]',
					triggerClassName
				)}
			>
				{label}
			</div>
			<LoopWrapper list={fields}>
				{(field) => (
					<ErrorMessage
						key={field}
						errors={errors}
						name={field}
						render={({ message }) => (
							<Text
								as="span"
								variant="error"
								data-hidden={message ? 'false' : 'true'}
								className="text-start group-data-[state=open]:hidden transition-all duration-300 ease-in-out data-[hidden=true]:hidden"
							>
								&uarr; {message}
							</Text>
						)}
					/>
				)}
			</LoopWrapper>
			{Object.keys(errors).length > 0 ? (
				<Text as="span" className="invisible">
					{Object.keys(errors).length}
				</Text>
			) : null}
		</div>
	);
}

export default WhoTrigger;
