import { makeQuote } from './makeQuote';
import { makeBooking } from './makeBooking';
import { buildParams } from './buildParams';
import { mergeArrayOfObjects } from '@/lib/utils';

/**
 * @name carhireBooking
 * @description Car hire booking endpoint call
 * @param {import('zustand').StoreApi<T>['getState']} get
 * @param {boolean} createQuote
 * @returns
 */

export const carhireBooking = async (get, createQuote = false) => {
    // construct the booking endpoint params from state
    const state = get()['car-hire'];
    const { setBookingState } = get();

    if (!state) return;
    const params = buildParams(state, 'car-hire');

    // quote booking
    if (createQuote) {
        params.quoteReceiver = state?.quoteReceiver || {};
        makeQuote(setBookingState, params, 'car-hire');
        return;
    }

    // standard booking
    makeBooking(setBookingState, params, 'car-hire');
};

/**
 * @name motorhomeHireBooking
 * @description Motorhome hire booking endpoint call
 * @param {import('zustand').StoreApi<T>['getState']} get
 * @param {boolean} createQuote
 * @returns
 */

export const motorhomeHireBooking = async (get, createQuote = false) => {
    // construct the booking endpoint params from state
    const { setBookingState } = get();
    const state = get()['motorhome-hire'];

    if (!state) return;

    let selected = state?.selected;
    let selectedPackage = state?.carExtras?.selectedPackageExtra;
    let bookingDetails = state?.bookingDetails;

    if (!selected) return;

    if (selectedPackage) {
        const extrasFormat = {
            type: 'item',
            code: selectedPackage?.code ?? '',
            set: selectedPackage?.set ?? '',
            startDate: selected?.startDate ?? '',
            endDate: selected?.endDate ?? '',
            quantity: 1,
            pickupTime: selected?.pickupTime ?? '',
            dropoffTime: selected?.dropoffTime ?? '',
        };

        // TODO: this needs to also add item extras (GPS, Booster Seats, etc)
        selected.extras = [extrasFormat];
    }

    const params = {
        items: [selected],
        ...bookingDetails,
    };

    // quote booking
    if (createQuote) {
        params.quoteReceiver = state?.quoteReceiver || {};
        makeQuote(setBookingState, params, 'motorhome-hire');
        return;
    }

    // standard booking
    makeBooking(setBookingState, params, 'motorhome-hire');
};

/**
 * @name flightsBooking
 * @description Flights booking endpoint call
 * @param {import('zustand').StoreApi<T>['getState']} get
 * @param {boolean} createQuote
 * @returns
 */

export const flightsBooking = async (get, createQuote = false) => {
    // construct the booking endpoint params from state
    const state = get()['flights'];
    const { setBookingState } = get();

    if (!state) return;

    if (!createQuote && !state?.bookingDetails) return;

    if (
        !state.selected?.items ||
        !state?.preview?.additionalParams
    )
        return;

    // construct the booking endpoint params from state
    // get everything in the preview
    const items = state?.selected?.items;
    const additionalParams = state?.preview?.additionalParams;

    // remove errata from the additional params
    const newAdditionalParams = additionalParams?.map((item) => {
        Object.keys(item).forEach((key) => {
            if (item[key]?.errata) {
                delete item[key]?.errata;
            }
        });

        return item;
    });

    // merge the additional params into the items before building the params
    const mergedState = {
        ...state,
        selected: {
            ...state.selected,
            items: mergeArrayOfObjects({
                toMerge: newAdditionalParams,
                toBeMergedInto: items,
            }),
        },
    };

    const params = buildParams(mergedState, 'flights');

    // quote booking
    if (createQuote) {
        params.quoteReceiver = state?.quoteReceiver || {};
        makeQuote(setBookingState, params, 'flights');
        return;
    }

    // standard booking
    makeBooking(setBookingState, params, 'flights');
};

/**
 * @name hotelsBooking
 * @description Hotels booking endpoint call
 * @param {import('zustand').StoreApi<T>['getState']} get
 * @param {boolean} createQuote
 * @returns
 */

export const hotelsBooking = async (get, createQuote = false) => {
    // construct the booking endpoint params from state
    const state = get()['hotels'];
    const { setBookingState } = get();
    if (!state) return;

    if (!state.selected) return;
    if (!createQuote && !state?.bookingDetails) return;

    const newState = {
        ...state,
        shouldBuildParams: true,
    }

    // // remove pageUrl from the selected object
    // if (newState?.selected?.pageUrl) {
    //     delete newState?.selected?.pageUrl;
    // }

    // merge errata into the selected object - for hotels we merge in the booking function as the function is called in the passenger details page.
    const additionalParams = state?.preview?.additionalParams ?? [];

    if (additionalParams?.length > 0) {
        const hotelErrata = additionalParams
            .filter((params) => params?.type === 'accommodation')
            .reduce(
                (all, additional) => additional?.errata?.length > 0 ? [...all, ...additional.errata] : all,
                []
            );

        newState.selected.errata = hotelErrata;
    }

    const params = buildParams(newState, 'hotels')

    // quote booking
    if (createQuote) {
        params.quoteReceiver = state?.quoteReceiver || {};
        makeQuote(setBookingState, params, 'hotels');
        return;
    }

    // standard booking
    makeBooking(setBookingState, params, 'hotels');
    return null;
};
