import { useQueryClient } from '@tanstack/react-query';

/**
 * @name useCancelQueryRequest
 * @description Cancels a query request
 * @param {string[]} keys
 * @returns {{
 * 	cancelRequest: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
 * }} cancelRequest
 * @example
 * const { cancelRequest } = useCancelQueryRequest(['flight-search']);
 */

function useCancelQueryRequest(keys) {
	const queryClient = useQueryClient();

	const cancelRequest = (e) => {
		e.preventDefault();
		queryClient.cancelQueries({ queryKey: keys });
	};

	return {
		cancelRequest,
	};
}
export default useCancelQueryRequest;
