import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import Icon from '../icon';
import { cn } from '@/lib/utils';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef(({ className, ...props }, ref) => (
	<AccordionPrimitive.Item
		ref={ref}
		className={cn('border-b pb-4', className)}
		{...props}
	/>
));

const AccordionTrigger = React.forwardRef(
	({ className, hideChevron, children, ...props }, ref) => (
		<AccordionPrimitive.Header className="flex">
			<AccordionPrimitive.Trigger
				ref={ref}
				className={cn(
					'flex flex-1 items-center justify-between font-medium transition-all appearance-none [&[data-state=open]>svg]:rotate-180',
					className
				)}
				{...props}
			>
				<span className="flex items-center transition-all">
					{children}
					{hideChevron ? null : (
						<Icon
							name="chevron-down"
							className="h-3 w-3 shrink-0 transition-transform duration-200"
						/>
					)}
				</span>
			</AccordionPrimitive.Trigger>
		</AccordionPrimitive.Header>
	)
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef(
	({ className, children, childClassName, ...props }, ref) => (
		<AccordionPrimitive.Content
			ref={ref}
			className={cn(
				'overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
				className
			)}
			{...props}
		>
			<div className={cn('pb-4 pt-0', childClassName)}>{children}</div>
		</AccordionPrimitive.Content>
	)
);
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
