import React from 'react';
import { cn } from '@/lib/utils';
import { Icon, DatePlaceHolder } from '@/components/common';

function DateRangeTrigger({
	value,
	isValid,
	hideTime,
	selectedTime,
	useCurrentTime,
	...props
}) {
	return (
		<div
			id="date"
			className={cn(
				'flex h-10.25 lg:h-13.25 w-full items-center  justify-between gap-2 self-stretch rounded border border-border-color bg-white px-3',
				!isValid ? 'text-core-red border-core-red focus:border-core-red' : ''
			)}
			{...props}
		>
			<DatePlaceHolder
				selected={value}
				hideTime={hideTime}
				isValid={isValid}
				selectedTime={selectedTime}
				useCurrentTime={useCurrentTime}
			/>
			<Icon name="calendar" className="hidden w-4 h-4 ml-2 md:block" />
		</div>
	);
}

export default DateRangeTrigger;
