import { useMemo } from 'react';
import PropTypes from 'prop-types';

import MobileView from './MobileView';
import { useBreakpoint } from '@/hooks';
import DesktopView from './DesktopView';
import { Button, Dialog, Heading, RteStyledContent, Text } from '@/components/common';

const features = [
	{
		label: 'Change fee',
		apiField: 'changes_details',
	},
	{
		label: 'Cancellation fee',
		apiField: 'cancellation_details',
	},
	{
		label: 'Hold baggage',
		apiField: 'hold_baggage_details',
	},
	{
		label: 'Hand baggage',
		apiField: 'hand_baggage_details',
	},
	{
		label: 'Seat selection',
		apiField: 'seat_selection_details',
	},
	{
		label: 'On-board meals',
		apiField: 'meal_details',
	},
];

function FareDetails({ results, extra }) {
	const isSmallDevice = useBreakpoint('lg');
	const cabins = useMemo(() => {
		if (!results?.length) return [];

		const cabinResults = [];
		const extractedCabinCodes = [];
		results.forEach((result) => {
			if (!result?.flights?.length) return;

			result.flights.forEach((flight) => {
				if (!flight?.pricingMatrix?.length) return;

				flight.pricingMatrix.forEach((pricing) => {
					if (!pricing?.cabin) return;
					if (extractedCabinCodes.includes(pricing.cabin.code)) return;

					cabinResults.push({
						...pricing.cabin,
						adultPrice: pricing?.pricing?.adult,
					});
					extractedCabinCodes.push(pricing.cabin.code);
				});
			});
		});

		return cabinResults.sort((a, b) => a?.adultPrice - b?.adultPrice);
	}, [results]);

	return (
		<Dialog
			as="modal"
			size="4xl"
			hideCloseBtn
			position="center"
			contentClassName="p-0"
			renderTrigger={({ DialogTrigger, onOpen }) => (
				<DialogTrigger className="mt-2 sm:mt-0 lg:mt-2" asChild>
					<Button
						onClick={onOpen}
						variant="link"
						label="Explain the different ticket types"
						iconName="question-without-outline"
						className="flex-row-reverse p-0 border-none underline-offset-4"
						iconClassName="fill-core-blue stroke-white w-6 h-6"
						disableAnimation
					/>
				</DialogTrigger>
			)}
		>
			{({ CloseButton }) => (
				<div className="min-h-48 max-h-[85vh] overflow-y-auto">
					<CloseButton
						className="top-0 right-0 w-12 h-12 text-white border-none bg-core-blue"
						variant="square"
					/>
					<div className="flex flex-col justify-center gap-4 p-6 py-12 lg:p-12">
						<Heading as="h2" className="text-3xl font-bold font-body">
							Compare Fare Types
						</Heading>
						{isSmallDevice ? (
							<MobileView cabins={cabins} features={features} />
						) : (
							<DesktopView cabins={cabins} features={features} />
						)}
						{extra?.fareNotice ? (
							<RteStyledContent
								variant="core-blue"
								className="flight-info"
								dangerouslySetInnerHTML={{
									__html: extra?.fareNotice,
								}}
							/>
						) : null}
					</div>
				</div>
			)}
		</Dialog>
	);
}

FareDetails.propTypes = {
	results: PropTypes.array,
	extra: PropTypes.object,
};

export default FareDetails;
