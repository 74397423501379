import React from 'react';
import { Text, Icon } from '@/components/common';

/**
 * @typedef {object} SeatLegendProps
 * @property {{
 * 	name: string;
 * 	items: {
 * 		label: string;
 * 		img: string;
 * 		category: string;
 * 	}[];
 * }} legend
 */

/**
 * @name SeatLegend
 * @description Renders a legend for the seat selection page
 * @param {SeatLegendProps} props
 * @returns {React.JSX.Element | null}
 * @example
 * <SeatLegend legend={{
 * 	name: 'Seat Legend',
 * 	items: []
 * }}/>
 */

function SeatLegend({ legend }) {
	const legendItems = legend?.items?.map((item) => {
		const icon = item?.img.split('/').pop().split('.')[0];
		return {
			...item,
			icon,
		};
	});

	return (
		<div>
			<Text className="font-bold">{legend?.name}</Text>

			{legendItems.map((item) => (
				<div key={item?.label} className="w-full flex items-start gap-3 my-2">
					<Text
						as="span"
						aria-label={item?.label}
						className="w-5 h-5 rounded border border-lighter-grey/20 overflow-hidden flex items-center justify-center"
					>
						{item?.icon ? (
							<Icon name={item?.icon} />
						) : (
							<img
								src={item?.img}
								alt={item?.label}
								className="w-full h-full object-contain"
							/>
						)}
					</Text>
					<Text title={item?.label} as="span">
						{item?.label}
					</Text>
				</div>
			))}
			<div className="flex gap-3 items-center">
				<Text aria-label="Wings" className=" flex items-center justify-center">
					<span className="w-5 h-5 [clip-path:polygon(100%_0,100%_38%,100%_100%,0_100%,0%_25%)] bg-lighter-grey"></span>
				</Text>
				<Text title="Wings" as="span">
					Wings
				</Text>
			</div>
		</div>
	);
}
export default SeatLegend;
