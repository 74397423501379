import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { DirectionalArrow, Icon, Text } from '@/components/common';

/**
 * @typedef {object} RouteProps
 * @property {string} to
 * @property {boolean} hideIcon
 * @property {import("@components/common/atoms/icon").iconNames} iconName
 * @property {string} from
 * @property {string} className
 * @property {string} className
 * @property {boolean} showArrow
 * @property {() => React.ReactNode} renderTo
 * @property {() => React.ReactNode} renderFrom
 * */

/**
 * @name Route
 * @description Renders a route with an arrival and departure location and time
 * @param {RouteProps} props - Route component props
 * @returns {JSX.Element | null}
 * @example
 * <Route
 *     from="London Gatwick (LGW)"
 *     to="Toronto (YYZ)"
 * />
 * */

function Route({
	to,
	from,
	hideIcon,
	renderTo,
	iconName,
	className,
	renderFrom,
	showArrow = false,
	truncateText = false,
}) {
	return (
		<div className={cn('flex flex-col gap-5 w-full', className)}>
			<div
				className={cn(
					'flex flex-1 grow shrink basis-0 h-px opacity-50 justify-start items-center gap-1 lg:hidden',
					{ hidden: showArrow }
				)}
			>
				<div className="w-3 h-3 rounded-3xl border border-neutral-800" />
				<div className="grow shrink basis-0 h-px border border-neutral-800 border-dashed" />
				{hideIcon ? null : <Icon className="w-5 h-5" name={iconName} />}
				<div className="grow shrink basis-0 h-px border border-neutral-800 border-dashed" />
				<div className="w-3 h-3 rounded-3xl border border-neutral-800" />
			</div>

			<div className="flex items-center justify-between gap-5 w-full">
				<Text
					className={cn('w-fit leading-snug', truncateText ? 'truncate' : '')}
				>
					{renderFrom ? renderFrom() : from}
				</Text>

				{showArrow ? (
					<div className="flex flex-1 grow shrink basis-0 h-px opacity-50 justify-start items-center gap-1">
						<DirectionalArrow />
					</div>
				) : (
					<div className="hidden flex-1 grow shrink basis-0 h-px opacity-50 justify-start items-center gap-1 lg:flex ">
						<div className="w-3 h-3 rounded-3xl border border-neutral-800" />
						<div className="grow shrink basis-0 h-px border border-neutral-800 border-dashed" />
						{hideIcon ? null : <Icon className="w-5 h-5" name={iconName} />}
						<div className="grow shrink basis-0 h-px border border-neutral-800 border-dashed" />
						<div className="w-3 h-3 rounded-3xl border border-neutral-800" />
					</div>
				)}
				<Text
					className={cn('w-fit leading-snug', truncateText ? 'truncate' : '')}
				>
					{renderTo ? renderTo() : to}
				</Text>
			</div>
		</div>
	);
}

Route.propTypes = {
	from: PropTypes.string,
	renderFrom: PropTypes.func,
	to: PropTypes.string,
	renderTo: PropTypes.func,
	hideIcon: PropTypes.bool,
	truncateText: PropTypes.bool,
	iconName: PropTypes.string,
	className: PropTypes.string,
};

Route.defaultProps = {
	renderFrom: null,
	renderTo: null,
	hideIcon: false,
	truncateText: false,
	iconName: 'flight-fill',
	className: '',
};

export default Route;
