import PropTypes from 'prop-types';

import { cn, dateRangeFormatter } from '@/lib/utils';
import { Text } from '@/components/common';
import { FlightCard } from '@/components/flights';

function BookingSummary({ item, className, detailsClassName }) {
	return (
		<div>
			<Text className={cn('flex gap-2 items-center justify-between w-full mb-2', className)}>
				<Text as="span" className="font-bold text-2xl leading-tight capitalize">
					{item?.direction?.toLowerCase() === 'outbound' ? 'Outbound' : 'Return'}
				</Text>
				{item?.departureTime ? (
					<Text as="span" className="text-right text-lg tracking-tighter">
						{dateRangeFormatter({
							start: item?.departureTime,
							end: null,
						})}
					</Text>
				) : null}
			</Text>
			<FlightCard
				defaultOpen
				flight={item}
				hideMoreInfoToggle
				hideFlightLogo
				showFullAirportName
				className="gap-0 lg:gap-1.5"
				overviewClassName="px-5 py-4 md:px-0 lg:p-0 gap-0.5 lg:gap-1.5"
				detailsClassName={cn('bg-white gap-1 p-5', detailsClassName)}
				detailsDivideClassName="bg-white px-5"
				timeClassName="text-2xl tracking-extra-tight"
				detailsTimeClassName="text-base font-bold leading-extra-tight tracking-extra-tight"
				detailsFlightNumberClassName="text-dark-grey text-opacity-65"
				detailsNameWrapperClassName="mb-1.5"
				detailsAirportCodeClassName="text-lg font-medium"
				detailsAirportClassName="text-sm lg:max-w-[160px] leading-loose-snug tracking-less-tight text-opacity-65"
				detailsLayoverClassName="text-sm"
				detailsDaysForwardClassName="text-sm"
				hideDetailsPlaneIcon={false}
				hideDetailsDivide={false}
				renderExtras={({ flight, parentFlight }) => (
					<div className="flex flex-col gap-0.5">
						<Text className="text-sm text-dark-grey text-opacity-65 capitalize">
							<span className="font-bold">Fare:{' '}</span>
							<span>{flight?.cabin?.displayName || flight?.cabin?.name || flight?.cabin?.code || 'Unknown'}</span>
						</Text>
						<Text className="text-sm text-dark-grey text-opacity-65">
							<span className="font-bold">Option Plus:{' '}</span>
							<span>{parentFlight?.hasOptionPlus ? 'Yes' : 'No'}</span>
						</Text>
						<Text className="text-sm text-dark-grey text-opacity-65">
							<span className="font-bold">Seats:{' '}</span>
							<span>{flight?.seats?.length ? flight.seats.join(', ') : 'N/A'}</span>
						</Text>
						<Text className="text-sm text-dark-grey text-opacity-65">
							<span className="font-bold">Baggage:{' '}</span>
							<span>
								{!parentFlight?.baggage?.length ?
									'N/A' :
									parentFlight.baggage
										.filter(bag => typeof bag?.total !== 'undefined' && typeof bag?.weight !== 'undefined')
										.map((bag) => `${bag?.total}x ${bag?.weight}kg`)
										.join(', ')
								}
							</span>
						</Text>
					</div>
				)}
			/>
		</div>
	);
}

BookingSummary.propTypes = {
	item: PropTypes.object.isRequired,
	className: PropTypes.string,
	detailsClassName: PropTypes.string,
};

BookingSummary.defaultProps = {
	detailsClassName: '',
};

export default BookingSummary;
