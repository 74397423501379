/**
 * Validator utilities.
 * - Each utility must return true if the value is valid.
 * - Otherwise, false or a string containing the validation message should be returned.
 *
 * @see https://www.react-hook-form.com/api/useform/register/#options
 */

export const Required = (value) => {
    if (Array.isArray(value) && value.length === 0) {
        return "Required";
    }

    if (typeof value === 'object' && value !== null) {
        const valueKeys = Object.keys(value);
        // empty fields
        if (valueKeys.length === 0) return "Required";

        // empty selects
        if (typeof value?.value !== 'undefined' && !value?.value) return "Required";

        // date ranges missing part of it's range
        const isDateRange = valueKeys.includes('from') && valueKeys.includes('to');
        if (isDateRange && !(value?.from && value?.to)) return "Required";
    }

    return value ? true : 'Required';
};

export const Email = (value) => {
    if (!value) return true;

    const regex = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(value) || "Please enter a valid email address";
}

export const Min = (min) => (value) => parseFloat(value) >= min || `This must be at least ${min}`;

export const Max = (max) => (value) => parseFloat(value) <= max || `This must be not be greater than ${max}`;

export const MinChars = (min) => (value) => value?.length >= min || `Minimum length ${min} characters`;

export const IsNumeric = (value) => {
    if (!value) return true;

    const regex = /^[0-9 .]+$/;
    return regex.test(value) || "Numeric characters only";
}

export const RequiredNumeric = (value) => {
    const required = Required(value);
    if (typeof required === 'string') return required;

    return IsNumeric(value);
};

export const ValidPostcodeUK = (value) => {
    if (!value) return true;

    const regex =
        /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/; // eslint-disable-line

    return regex.test(value) || "Invalid postcode";
};

export const ValidPhoneNumber = (value) => {
    if (!value) return true;

    const regex = /^[+]?[(]?([0-9]{2})?[)]?[-\s.]?[0-9]{4,5}[-\s.]?[0-9]{4,6}$/;
    return regex.test(value) || "Please enter a valid phone number";
};

export const ValidTime = (value) => {
    if (!value) return true;

    const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
    return regex.test(value) || "Invalid time format";
};

export function composeValidators(...validators) {
    return (value, formValues) =>
        // eslint-disable-next-line
        validators.reduce(
            (error, validator) =>
                error === false || typeof error === 'string'
                    ? error
                    : validator(value, formValues),
            true
        )
}
