import PropTypes from 'prop-types';

import { cn, formatLocalTime } from '@/lib/utils';
import { useDisclosure } from '@/hooks';
import { FlightCardDetails } from '@components/flights';
import {
	Button,
	Text,
	Route,
	LoopWrapper,
	Accordion,
	AccordionItem,
	AccordionTrigger,
	AccordionContent,
} from '@/components/common';

function FlightCard({
	flight,
	className,
	renderExtras,
	overviewClassName,
	detailsClassName,
	timeClassName,
	detailsWrapperClassName,
	detailsTimeClassName,
	detailsFlightNumberClassName,
	detailsNameWrapperClassName,
	detailsAirportCodeClassName,
	detailsAirportClassName,
	hideDetailsPlaneIcon,
	detailsDivideClassName,
	detailsLayoverClassName,
	detailsDaysForwardClassName,
	hideMoreInfoToggle,
	defaultOpen = false,
	hideFlightLogo = false,
	hideDetailsDivide = true,
}) {
	const { onToggle, isOpen } = useDisclosure(defaultOpen);

	const stops = Array.from(
		{ length: parseInt(flight?.sectors?.length - 1) ?? 0 },
		(_, i) => i + 1
	);

	const departureTime = formatLocalTime(flight?.departureTime);
	const arrivalTime = formatLocalTime(flight?.arrivalTime);

	const accordionValue = flight?.key || flight?.flightNumber;

	return (
		<div
			className={cn(
				'w-full h-auto flex-col justify-center items-start gap-1.5 inline-flex',
				className
			)}
		>
			<Accordion
				className="w-full"
				defaultValue={defaultOpen ? accordionValue : undefined}
				collapsible
			>
				<AccordionItem value={accordionValue} className="border-none pb-0">
					<div
						className={cn(
							'w-full h-auto flex-col justify-center items-start gap-1.5 inline-flex',
							overviewClassName
						)}
					>
						{hideFlightLogo || !flight?.carrier?.logo ? null : (
							<img
								className="object-contain w-12 h-fit"
								src={flight.carrier.logo}
								alt={flight?.carrier?.name || flight?.carrier?.code}
							/>
						)}
						<Route
							iconName="flight-fill"
							className="self-stretch"
							renderFrom={() => (
								<Text
									as="time"
									className={cn(
										'text-xl font-bold leading-snug w-fit',
										timeClassName
									)}
								>
									{departureTime ? departureTime : 'N/A'}
								</Text>
							)}
							renderTo={() => (
								<Text
									as="span"
									className="flex items-center justify-center gap-1"
								>
									<Text
										as="time"
										className={cn(
											'text-xl font-bold leading-snug w-fit',
											timeClassName
										)}
									>
										{arrivalTime ? arrivalTime : 'N/A'}
									</Text>
									{flight?.daysForward > 0 && (
										<Text
											as="span"
											className="px-1 text-lg font-bold leading-6 tracking-tighter rounded-lg w-fit bg-light-grey"
										>
											{' '}
											+{flight?.daysForward}
										</Text>
									)}
								</Text>
							)}
						/>
						<div className="inline-flex items-center self-stretch justify-between gap-2 mt-1.5">
							<Text className="text-base font-medium leading-tight text-center text-neutral-800">
								{flight?.departure}
							</Text>

							<Text className="text-base font-medium leading-tight text-center text-neutral-800">
								{flight?.arrival}
							</Text>
						</div>
						<div className="relative inline-flex items-center self-stretch justify-between gap-1 mt-1.5">
							<Text className="text-sm font-normal leading-none text-center text-neutral-800">
								{flight?.duration
									?.replace('day', ' day')
									?.replace('days', ' days')}
							</Text>
							<Text className="absolute text-sm font-normal leading-none text-center -translate-x-1/2 -translate-y-1/2 text-neutral-800 left-1/2 top-1/2">
								{stops.length === 0
									? 'Direct'
									: stops.length === 1
									? '1 stop'
									: `${stops.length} stops`}
							</Text>

							{hideMoreInfoToggle ? null : (
								<div className="flex items-center justify-center gap-1">
									<AccordionTrigger asChild className="gap-2">
										<Button
											hideIcon
											variant="unstyled"
											label="More"
											// onClick={onToggle}
											labelClassName="text-core-blue font-normal text-sm leading-none"
											// iconName={isOpen ? 'chevron-up' : 'chevron-down'}
											iconClassName="w-3 h-3 text-core-blue"
											disableAnimation
										/>
									</AccordionTrigger>
								</div>
							)}
						</div>
					</div>
					<AccordionContent value={accordionValue} childClassName="pb-0">
						<div
							className={cn(
								'w-full mt-0 lg:mt-4 border bg-lighter-grey border-lighter-grey',
								detailsWrapperClassName
							)}
						>
							<LoopWrapper list={flight?.sectors}>
								{(item, index) => (
									<>
										<FlightCardDetails
											flight={item}
											parentFlight={flight}
											index={index + 1}
											flights={flight?.sectors.length}
											renderExtras={renderExtras}
											className={cn('p-4', detailsClassName)}
											timeClassName={detailsTimeClassName}
											flightNumberClassName={detailsFlightNumberClassName}
											nameWrapperClassName={detailsNameWrapperClassName}
											airportCodeClassName={detailsAirportCodeClassName}
											airportClassName={detailsAirportClassName}
											layoverClassName={detailsLayoverClassName}
											daysForwardClassName={detailsDaysForwardClassName}
											hidePlaneIcon={hideDetailsPlaneIcon}
											isFinalSector={index === flight?.sectors?.length - 1}
										/>

										{!hideDetailsDivide &&
											index !== flight?.sectors?.length - 1 && (
												<div
													className={cn(
														'flex flex-col px-4',
														detailsDivideClassName
													)}
												>
													<hr className="border-lighter-grey" />
												</div>
											)}
									</>
								)}
							</LoopWrapper>
						</div>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</div>
	);
}

FlightCard.propTypes = {
	flight: PropTypes.object.isRequired,
	className: PropTypes.string,
	defaultOpen: PropTypes.bool,
	hideMoreInfoToggle: PropTypes.bool,
	overviewClassName: PropTypes.string,
	detailsClassName: PropTypes.string,
	hideFlightLogo: PropTypes.bool,
	hideDetailsDivide: PropTypes.bool,
	hideDetailsPlaneIcon: PropTypes.bool,
	timeClassName: PropTypes.string,
	detailsTimeClassName: PropTypes.string,
	detailsWrapperClassName: PropTypes.string,
	detailsFlightNumberClassName: PropTypes.string,
	detailsNameWrapperClassName: PropTypes.string,
	detailsAirportCodeClassName: PropTypes.string,
	detailsAirportClassName: PropTypes.string,
	detailsDivideClassName: PropTypes.string,
	detailsLayoverClassName: PropTypes.string,
	detailsDaysForwardClassName: PropTypes.string,
};

FlightCard.defaultProps = {
	className: '',
	defaultOpen: false,
	hideMoreInfoToggle: false,
	detailsClassName: '',
	hideFlightLogo: false,
	hideDetailsDivide: true,
};

export default FlightCard;
