import { useEffect } from 'react';

/**
 * @name useMetaDescription
 * @description A hook that calls and passes a value to meta descrition
 * @param {unknown} value
 * @returns {null}
 */

function useMetaDescription(value) {
	useEffect(() => {
		if (value) {
			const valueArray = value.length > 0 ? value.join(' | ') : value;
			document.title = valueArray;

			let metaKeywords = document.getElementsByTagName('meta')['keywords'];
			if (!metaKeywords) {
				metaKeywords = document.createElement('meta');
				metaKeywords.name = 'keywords';
				document.head.append(metaKeywords);
			}
			metaKeywords.content = valueArray;

			let metaDescription =
				document.getElementsByTagName('meta')['description'];
			if (!metaDescription) {
				metaDescription = document.createElement('meta');
				metaDescription.name = 'description';
				document.head.append(metaDescription);
			}
			metaDescription.content = valueArray;
		}
	}, []);
}
export default useMetaDescription;
