import { useMemo, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useBookingStore } from '@/store';
import { useValueCallback } from '@/hooks';
import { ErrorHandler } from '@/components/common';
import { CarExtrasDetails } from '@components/carhire';

/**
 * @name TravelPlanDetails
 * @description A component that shows the travel plan details for car hire booking process
 * @returns {React.JSX.Element}
 */

function TravelPlanDetails({
	isLoading,
	handleTotalPrice,
	handleShowSaveForLater,
}) {
	const state = useBookingStore((state) => state['car-hire']);

	const packageExtraCost = useMemo(() => {
		const selectedPackagePrice = state?.carExtras?.selectedPackageExtra?.price;

		if (!selectedPackagePrice) return 0;
		return parseFloat(selectedPackagePrice);
	}, [state?.carExtras?.selectedPackageExtra]);

	const itemExtrasCost = useMemo(() => {
		const localTotal = state?.preview?.overview?.localTotal;
		if (localTotal) return localTotal;
		return 0;
	}, [state?.preview?.overview?.localTotal]);

	const totalCost = useMemo(() => {
		if (state?.preview?.overview?.total) return state?.preview?.overview?.total;

		const car = state?.carExtras?.car;
		if (!car?.price) return packageExtraCost;

		return parseFloat(car?.price) + packageExtraCost;
	}, [state?.carExtras, packageExtraCost, state?.preview?.overview?.total]);

	// useValueCallback(handleTotalPrice, totalCost);
	useEffect(() => {
		if (handleTotalPrice) handleTotalPrice(totalCost);
	}, [totalCost, handleTotalPrice]);

	// determine whether to show save for later
	useValueCallback(
		typeof handleShowSaveForLater === 'function'
			? (newPreview) => {
					if (typeof newPreview?.quotable !== 'undefined') {
						handleShowSaveForLater(newPreview.quotable === true);
					}
			  }
			: null,
		state?.preview
	);

	return (
		<ErrorBoundary FallbackComponent={ErrorHandler}>
			<CarExtrasDetails
				totalCost={totalCost}
				car={state?.carExtras?.car}
				isLoading={isLoading || state?.previewLoading}
				itemExtrasCost={itemExtrasCost}
				pickup={state?.carExtras?.pickup}
				dropoff={state?.carExtras?.dropoff}
				itemExtras={state?.carExtras?.selectedItemExtras}
				packageExtra={state?.carExtras?.selectedPackageExtra}
				error={state?.error}
			/>
		</ErrorBoundary>
	);
}
export default TravelPlanDetails;
