import { useEffect } from 'react';

/**
 * @name useScrollToTop
 * @description Scroll to top of page on route change
 * @param {string} [pathname]
 * @returns null
 * @example useScrollToTop();
 */

function useScrollToTop(pathname = window.location.pathname) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

export default useScrollToTop;
