import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';

function NullTerniaryWrapper({
	children,
	className,
	animate = false,
	condition = true,
}) {
	const variants = {
		visible: {
			opacity: 1,
			height: 'auto',
		},
		hidden: {
			opacity: 0,
			height: 0,
		},
	};

	return (
		<AnimatePresence mode="wait" initial={false}>
			{condition && animate && (
				<motion.div
					initial="hidden"
					animate="visible"
					exit="hidden"
					variants={variants}
					className={className}
				>
					<motion.div
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0, transition: { delay: 0.1 } }}
						exit={{ opacity: 0, y: -10 }}
					>
						{children}
					</motion.div>
				</motion.div>
			)}
			{condition && !animate && <div className={className}>{children}</div>}
		</AnimatePresence>
	);
}

NullTerniaryWrapper.propTypes = {
	condition: PropTypes.bool,
	className: PropTypes.string,
	animate: PropTypes.bool,
	children: PropTypes.node,
};

NullTerniaryWrapper.defaultProps = {
	condition: true,
	className: '',
	animate: false,
	children: null,
};

export default NullTerniaryWrapper;
