import { cn } from '@/lib/utils';
import PropTypes from 'prop-types';
import { cva } from 'class-variance-authority';
import { Text, Icon } from '@components/common';

export const badgeVariants = cva(
	'p-2 bg-light-grey rounded justify-start items-center gap-2 flex w-fit uppercase',
	{
		variants: {
			variant: {
				'core-blue': 'bg-core-blue text-white',
				'supporting-yellow': 'bg-supporting-yellow text-core-blue',
				'supporting-coral': 'bg-supporting-coral text-core-blue',
				'light-grey': 'bg-light-grey text-core-blue',
				'opaque-blue': 'bg-core-blue bg-opacity-50 text-white',
				outline: 'bg-transparent text-core-blue border border-core-blue',
				ghost: 'bg-transparent text-core-blue',
				black: 'bg-black text-white',
				red: 'bg-core-red text-white',
				success: 'bg-check-green text-white',
			},
		},
	}
);

/**
 * @typedef {object} BadgeProps
 * @property {import("@components/common/atoms/icon").iconNames} icon
 * @property {string} className
 * @property {string} textClassName
 * @property {string} text
 * @property {React.ReactNode} children
 * @property {"core-blue" | "supporting-yellow" | "supporting-coral" | "light-grey" | "opaque-blue" | "outline" | "ghost" | "black" | "red" | "success"} variant
 * */

/**
 * @name Badge
 * @description Renders a badge with text and an optional icon
 * @param {BadgeProps} props
 * @returns {JSX.Element | null}
 * @example
 * <Badge
 *    text="Best Price"
 *    variant="supporting-yellow"
 * />
 * */

function Badge({
	icon,
	className,
	textClassName,
	iconClassName,
	children,
	text,
	variant,
}) {
	return (
		<span className={cn(badgeVariants({ variant, className }))}>
			{icon && (
				<Icon name={icon} className={cn('text-grey-dark', iconClassName)} />
			)}
			{text ? (
				<Text
					as="span"
					className={cn('text-xs font-medium leading-3', textClassName)}
				>
					{text}
				</Text>
			) : (
				children
			)}
		</span>
	);
}

Badge.propTypes = {
	icon: PropTypes.string,
	className: PropTypes.string,
	textClassName: PropTypes.string,
	text: PropTypes.string,
	children: PropTypes.node,
	variant: PropTypes.oneOf([
		'core-blue',
		'supporting-yellow',
		'supporting-coral',
		'light-grey',
		'opaque-blue',
		'outline',
		'ghost',
		'black',
		'red',
		'success',
	]),
};

Badge.defaultProps = {
	icon: '',
	className: '',
	children: null,
	textClassName: '',
	text: 'Badge text',
	variant: 'core-blue',
};

export default Badge;
