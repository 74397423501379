import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { cn } from '@/lib/utils';
import { useBreadcrumbs } from '@/hooks';
import { Icon, Text } from '@components/common';

/**
 * @typedef {Object} BreadCrumbsProps
 * @property {string} className
 **/

/**
 * @name BreadCrumbs Component
 * @description This component is used to create breadcrumbs that show the current page and future pages.
 * @param {BreadCrumbsProps} props
 * @returns {React.JSX.Element}
 * @example
 * <BreadCrumbs />
 * */

function BreadCrumbs({ className = '' }) {
	const { crumbs, activeCrumbIndex } = useBreadcrumbs();
	return (
		<div
			className={cn('flex items-center gap-2 text-lightest-grey', className)}
		>
			<Link to="/">
				<Text as="span">
					<Icon name="home" />
				</Text>
			</Link>
			<Icon name="arrow-right" className="w-5" />
			{crumbs.map((link, index) => (
				<Text
					as="span"
					key={link?.name}
					className={cn('flex items-center gap-2 capitalize font-medium', {
						'opacity-40 pointer-events-none': index > activeCrumbIndex,
					})}
				>
					<Link
						key={link?.name}
						to={link?.search ? `${link.to}${link.search}` : link?.to}
						className="text-sm"
					>
						{link?.name}
					</Link>
					<Icon
						name="arrow-right"
						className={cn('w-5', index === crumbs.length - 1 && 'hidden')}
					/>
				</Text>
			))}
		</div>
	);
}

BreadCrumbs.propTypes = {
	className: PropTypes.string,
};
export default BreadCrumbs;
