import { format } from 'date-fns';
import { createContext, useContext, useMemo } from 'react';

import { useUrlParams } from '@/hooks';

const FlightsSelectionContext = createContext({
	outbound: {
		results: [],
		selectedDate: new Date(),
		handleDateChange: () => {},
	},
	inbound: {
		results: [],
		selectedDate: new Date(),
		handleDateChange: () => {},
	},
});

const sortResults = (results) => {
	if (!results?.length) return [];

	// sort flights by shortest duration first
	return results
		.reduce(
			(allFlights, res) =>
				res?.flights?.length ? [...allFlights, ...res?.flights] : allFlights,
			[]
		)
		.sort((a, b) => a?.durationInSeconds - b?.durationInSeconds);
};

const formatResults = (results) => {
	if (!results) return null;

	if (Object.keys(results).length === 0) return null;

	const newResults = Object.keys(results).map((key) => {
		const result = results[key];
		const flights = Object.keys(results[key]).map((flightKey) => {
			const flight = result[flightKey];
			return {
				...flight,
			};
		});
		return {
			departureTime: key,
			flights,
		};
	});

	return newResults;
};

function FlightSelectionProvider({ children, flights }) {
	const { updateParams } = useUrlParams();
	const { outbound, inbound } = useMemo(() => {
		if (flights) {
			return {
				outbound: sortResults(formatResults(flights?.outbound)) ?? [],
				inbound: sortResults(formatResults(flights?.inbound)) ?? [],
			};
		}
		return {
			outbound: [],
			inbound: [],
		};
	}, [flights, formatResults, sortResults]);

	return (
		<FlightsSelectionContext.Provider
			value={{
				outbound: {
					results: outbound,
					selectedDate: new Date(),
					handleDateChange: (date) => {
						updateParams({ startDate: format(date, 'yyyy-MM-dd') });
					},
				},
				inbound: {
					results: inbound,
					selectedDate: new Date(),
					handleDateChange: (date) => {
						updateParams({ endDate: format(date, 'yyyy-MM-dd') });
					},
				},
			}}
		>
			{children}
		</FlightsSelectionContext.Provider>
	);
}

/**
 *
 * @name useFlightSelection
 * @description Custom hook to access the flight selection context
 * @param {"outbound"|"inbound"} instance
 * @returns {{
 * 	results: Array,
 * 	selectedDate: Date,
 * handleDateChange: (param: string | Date) => void
 * }}
 * @example
 * const { results, selectedDate, handleDateChange } = useFlightSelection('outbound');
 */

export const useFlightSelection = (instance) => {
	const context = useContext(FlightsSelectionContext);
	if (!context) {
		throw new Error(
			'useFlightSelection must be used within a FlightsSelectionProvider'
		);
	}
	return context[instance];
};

export default FlightSelectionProvider;
