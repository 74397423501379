import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { BookingCalendar, DatePlaceHolder, Text } from '@/components/common';
import FormField from '@/components/common/molecules/formfield';
import { Required, ONE_TO_NINE, ZERO_TO_NINE } from '@/lib/utils';
import { useDisclosure } from '@/hooks';
import { addDays } from 'date-fns';

function BookingForm({ control, errors, watch, handleSubmit, setValue }) {
	const { from, to } = watch('when');

	const { isOpen, onToggle } = useDisclosure(() => {
		if (from && to) return true;
		return false;
	});
	const validateTotalPeople = useCallback((value, formValues) => {
		// only allow up to 9 people per booking
		const adults = formValues.adults?.value
			? parseInt(formValues.adults.value)
			: 0;
		const children = formValues.children?.value
			? parseInt(formValues.children.value)
			: 0;
		const infants = formValues.infants?.value
			? parseInt(formValues.infants.value)
			: 0;

		// show custom message to prevent 3 fields with the same message
		const isValid = adults + children + infants <= 9;
		setShowMaxPeopleError(!isValid);

		return isValid;
	});

	return (
		<form onSubmit={handleSubmit}>
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-3 items-center justify-center">
					<Text as="label" className="font-bold text-gray-700">
						Select a departure date
					</Text>
					<Controller
						name="when"
						control={control}
						render={({ field }) => {
							return isOpen ? (
								<DatePlaceHolder
									hideTime
									showIcon
									type="inputdate"
									handleClick={onToggle}
									className="cursor-pointer"
									selected={{ from: new Date(from), to: new Date(to) }}
								/>
							) : (
								<BookingCalendar
									className="w-full"
									{...field}
									onChange={(value) => {
										field.onChange(value.range);
										setValue('price', value.totalPrice);
										onToggle();
									}}
									minDate={addDays(new Date(), 3)}
								/>
							);
						}}
					/>
				</div>

				<div className="w-full grid grid-cols-3 gap-3">
					<FormField
						name="adults"
						label="Adults"
						as="select"
						options={ONE_TO_NINE}
						control={control}
						errors={errors}
						validation={[Required, validateTotalPeople]}
						wrapperClassName="w-full lg:min-w-[124px]"
						placeholder="Adults"
						footer="Aged 15 +"
					/>

					<FormField
						name="children"
						label="Children"
						as="select"
						options={ZERO_TO_NINE}
						control={control}
						errors={errors}
						validation={[validateTotalPeople]}
						wrapperClassName="w-full lg:min-w-[124px]"
						placeholder="Children"
						footer="Ages 3 - 15"
					/>

					<FormField
						name="infants"
						label="Infants"
						as="select"
						options={ZERO_TO_NINE}
						control={control}
						errors={errors}
						validation={[validateTotalPeople]}
						wrapperClassName="w-full lg:min-w-[124px]"
						placeholder="Infants"
						footer="Ages 0 - 3"
					/>
				</div>
			</div>
		</form>
	);
}

BookingForm.propTypes = {
	params: PropTypes.shape({
		adults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		infants: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
};

BookingForm.defaultProps = {
	params: {},
	hideClearBtn: false,
};

export default BookingForm;
