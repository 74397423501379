import { currencyFormatter } from '@/lib/utils';
import {
	SortFilter,
	DatesFilter,
	DurationFilter,
	CapacityFilter,
	LocationFilter,
	StarRatingFilter,
	HolidayTypeFilter,
	AttractionsFilter,
	ExperiencesActivitiesFilter,
	FacetFilter,
	FacetRangeFilter,
} from '@components/common/organisms/filters';
import pluralize from 'pluralize';

function FilterList({ filters, filterState, handleFilterChange, disabled }) {
	if (!filters?.length) return;

	return (
		<div className="flex flex-col gap-2">
			{filters.map((filter) => {
				switch (filter.type) {
					case 'star_rating':
						return (
							<StarRatingFilter
								key={filter.name}
								name={filter.name}
								value={filterState[filter.name] || []}
								onChange={handleFilterChange}
								disabled={disabled}
							/>
						);
					case 'duration':
						return (
							<DurationFilter
								key={filter.name}
								name={filter.name}
								title={filter.title}
								value={filterState[filter.name]}
								onChange={handleFilterChange}
								disabled={disabled}
							/>
						);
					case 'budget':
						return (
							<FacetRangeFilter
								key={filter.name}
								name={filter.name}
								title={filter.title}
								value={
									filterState[filter.name]?.length
										? filterState[filter.name]
										: [filter.min, filter.max]
								}
								onChange={handleFilterChange}
								min={filter.min}
								max={filter.max}
								disabled={disabled}
								renderValue={(value) => currencyFormatter({ amount: value })}
							/>
						);
					case 'flightDuration':
						return (
							<FacetRangeFilter
								key={filter.name}
								name={filter.name}
								title={filter.title}
								value={
									filterState[filter.name]?.length
										? filterState[filter.name]
										: [filter.min, filter.max]
								}
								onChange={handleFilterChange}
								min={filter.min}
								max={filter.max}
								disabled={disabled}
								renderValue={(value) =>
									`${value} ${pluralize('hour', parseFloat(value))}`
								}
							/>
						);
					case 'location':
						return (
							<LocationFilter
								key={filter.name}
								name={filter.name}
								value={filterState[filter.name]}
								onChange={handleFilterChange}
								disabled={disabled}
							/>
						);
					case 'dates':
						return (
							<DatesFilter
								key={filter.name}
								name={filter.name}
								value={filterState[filter.name]}
								onChange={handleFilterChange}
								disabled={disabled}
							/>
						);
					case 'attractions':
						return (
							<AttractionsFilter
								key={filter.name}
								name={filter.name}
								value={filterState[filter.name]}
								onChange={handleFilterChange}
								disabled={disabled}
							/>
						);
					case 'holiday_type':
						return (
							<HolidayTypeFilter
								key={filter.name}
								name={filter.name}
								value={filterState[filter.name]}
								onChange={handleFilterChange}
								disabled={disabled}
							/>
						);
					case 'sort':
						return (
							<SortFilter
								key={filter.name}
								name={filter.name}
								value={filterState[filter.name]}
								onChange={handleFilterChange}
								disabled={disabled}
							/>
						);
					case 'capacity':
						return (
							<CapacityFilter
								key={filter.name}
								name={filter.name}
								value={filterState[filter.name]}
								onChange={handleFilterChange}
								disabled={disabled}
							/>
						);
					case 'experiences_activities':
						return (
							<ExperiencesActivitiesFilter
								key={filter.name}
								name={filter.name}
								value={filterState[filter.name]}
								onChange={handleFilterChange}
								disabled={disabled}
							/>
						);
					case 'facet':
						return (
							<FacetFilter
								key={filter.name}
								name={filter.name}
								title={filter.title}
								options={filter.options}
								value={filterState[filter.name]}
								onChange={handleFilterChange}
								disabled={disabled}
							/>
						);
					default:
						return null;
				}
			})}
		</div>
	);
}

export default FilterList;
