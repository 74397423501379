import React from 'react';
import PropTypes from 'prop-types';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from '@/lib/utils';

/**
 * @typedef {Object} TooltipContentProps
 * @property {string} className
 * @property {number} sideOffset
 * @property {boolean} hideTooltip
 * @property {React.ReactNode} children
 */

/**
 * @name TooltipContent
 * @description TooltipContent component
 * @param {TooltipContentProps & TooltipPrimitive.TooltipContentProps} props
 * @returns {JSX.Element}
 * @example
 * <TooltipContent className="text-red-500" sideOffset={4}>
 *     Hello World
 * </TooltipContent>
 * */

function Tooltip({
	className,
	children,
	renderContent,
	sideOffset = 5,
	hideTooltip,
	...props
}) {
	return (
		<TooltipPrimitive.Provider>
			<TooltipPrimitive.Root>
				<TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
				<TooltipPrimitive.Portal>
					{hideTooltip ? null : (
						<TooltipPrimitive.Content
							sideOffset={sideOffset}
							side="top"
							align="center"
							className={cn(
								'z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
								className
							)}
							{...props}
						>
							{renderContent()}
							<TooltipPrimitive.Arrow className="fill-white" width={10} />
						</TooltipPrimitive.Content>
					)}
				</TooltipPrimitive.Portal>
			</TooltipPrimitive.Root>
		</TooltipPrimitive.Provider>
	);
}

Tooltip.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	renderContent: PropTypes.func,
	sideOffset: PropTypes.number,
	hideTooltip: PropTypes.bool,
};

Tooltip.defaultProps = {
	className: '',
	children: null,
	renderContent: () => null,
	sideOffset: 4,
	hideTooltip: false,
};

export default Tooltip;
