/**
 * @module string
 * @name randomString
 * @description Generate a random string
 * @categeory Utils
 * @param {string} prefix
 * @param {number} length
 * @returns {string}
 * @example
 * import { randomString } from '@/lib/utils';
 * randomString('PREFIX', 8); // PREFIX1234
 */

export function randomString(prefix, length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const prefixLength = prefix.length;
    let randomString = '';

    for (let i = 0; i < length - prefixLength; i++) {
        const randomChar = characters.charAt(Math.floor(Math.random() * characters.length));
        randomString += randomChar;
    }

    return prefix + randomString;
}

/**
 * @module string
 * @name capitalizeString
 * @description Capitalize the first letter of a string
 * @categeory Utils
 * @param {string} str
 * @returns {string}
 * @example
 * import { capitalizeString } from '@/lib/utils';
 * capitalizeString('passenger'); // Passenger
 */

export function capitalizeString(str) {
    if (typeof str !== 'string') return '';

    return str.charAt(0).toUpperCase() + str.slice(1);
}
