import React, { useState, useMemo } from 'react';

import {
	Dialog,
	Text,
	Button,
	Heading,
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
	LoopWrapper,
} from '@/components/common';
import { useBookingStore } from '@/store';
import { FlightMobileFareCard } from '@components/flights';
import { currencyFormatter, CABIN_OPTIONS, cn } from '@/lib/utils';

function MobileListItem({
	cabin,
	instance,
	fromPrice,
	flight,
	flightIndex,
	handleChange,
}) {
	const state = useBookingStore((store) => store.flights);
	const selectedCabinFare =
		state?.selected?.selectedFare?.[instance]?.fare ?? '';
	const [activeTab, setActiveTab] = useState(null);

	const handleTabChange = (value) => {
		if (value === activeTab) return;
		setActiveTab(value);
	};

	const tabCarbinOptions = useMemo(() => {
		if (!activeTab) return [];
		const selectedTabCarbin =
			activeTab.split('-')[0] + '-' + activeTab.split('-')[1];

		if (!selectedTabCarbin) return [];

		const { pricingMatrix } = flight;
		if (!pricingMatrix) return [];
		const cabinSelections = pricingMatrix.filter((item) =>
			item?.cabin?.code.toLowerCase().includes(selectedTabCarbin.split('-')[0])
		);

		return cabinSelections;
	}, [activeTab, flight]);

	const selectedFareCabin = selectedCabinFare
		? `${
				selectedCabinFare?.split('-')[1].toLowerCase().includes('eco')
					? 'eco'
					: 'club'
		  }-${selectedCabinFare?.split('-')[0]}`
		: null;

	return (
		<Dialog
			as="drawer"
			hideCloseBtn
			position="bottom"
			contentClassName="w-full min-h-[100dvh] max-h-[100dvh] overflow-y-auto p-0"
			renderTrigger={({ DialogTrigger, onOpen }) => {
				const isTriggerSelected =
					selectedFareCabin === `${cabin?.value}-${flightIndex}`;

				return fromPrice[cabin?.value] ? (
					<DialogTrigger asChild className="p-4">
						<Text className="flex flex-col gap-2">
							<Text as="span">
								<strong className="font-bold font-body">{cabin?.label}</strong>
								<br />{' '}
								<span className="italic leading-3 font-display">from </span>
								<strong className="font-bold font-body">
									{fromPrice
										? currencyFormatter({
												amount: fromPrice[cabin?.value],
										  })
										: '-'}
								</strong>
							</Text>
							<Button
								hideIcon={!isTriggerSelected}
								disableAnimation={true}
								disabled={state?.isLoading || state?.previewLoading}
								variant={isTriggerSelected ? 'hollow-expand' : 'outline'}
								label={isTriggerSelected ? 'Selected' : 'Select fare'}
								onClick={() => {
									const tabValue = `${cabin?.value}-${flightIndex}-tab`;
									handleTabChange(tabValue);
									// handleCabinChange(`${cabin?.value}-${flightIndex}`);
									onOpen();
								}}
								{...(isTriggerSelected && {
									iconName: 'check',
									iconClassName:
										'flex justify-center w-6 h-6 p-1 text-center rounded-full bg-check-green shrink-0',
								})}
							/>
						</Text>
					</DialogTrigger>
				) : (
					<Text>-</Text>
				);
			}}
		>
			{({ CloseButton, onClose }) => (
				<div>
					<div className="sticky top-0 left-0 z-10 flex items-center justify-between p-5 py-3 bg-white">
						<Heading as="h3" className="text-3xl font-body">
							Select fare
						</Heading>
						<CloseButton className="relative top-0 right-0 w-12 h-12 border-none" />
					</div>
					<Tabs value={activeTab}>
						<TabsList className="sticky left-0 z-10 w-full p-0 bg-white rounded-none shadow-none h-15 top-18">
							{CABIN_OPTIONS.map((option) => (
								<TabsTrigger
									key={option.label}
									value={`${option?.value}-${flightIndex}-tab`}
									className={cn(
										'w-full h-full flex py-3 px-6 border-t-3 border-lightest-grey/2 text-dark-grey shadow-none bg-lightest-grey/30 data-[state=active]:bg-white data-[state=active]:border-core-red rounded-none border-b-0'
									)}
									disabled={!(fromPrice && fromPrice[option?.value])}
									onClick={() => {
										const tabValue = `${option?.value}-${flightIndex}-tab`;
										handleTabChange(tabValue);
									}}
								>
									<Text className="flex flex-col w-full gap-1 text-left">
										<strong className="block font-bold leading-4 font-body">
											{option?.label}
										</strong>
										<span className="block leading-4">
											{fromPrice && fromPrice[option?.value] > 0 ? (
												<>
													<span className="italic font-display">from </span>
													<strong className="font-bold font-body">
														{currencyFormatter({
															amount: fromPrice[option?.value],
														})}
													</strong>
												</>
											) : (
												<span className="italic font-display">unavailable</span>
											)}
										</span>
									</Text>
								</TabsTrigger>
							))}
						</TabsList>
						{CABIN_OPTIONS.map((option) => (
							<TabsContent
								className="w-full"
								key={option.id}
								value={`${option?.value}-${flightIndex}-tab`}
							>
								<LoopWrapper
									itemKey="cabin.code"
									// list={selectedOptions}
									list={tabCarbinOptions}
									renderFallback={() => (
										<div className="flex items-center justify-center p-10">
											<Text as="p" className="text-center">
												{option?.label ? `${option?.label} is not` : 'Not'}{' '}
												available for this flight.
											</Text>
										</div>
									)}
								>
									{(option) => (
										<FlightMobileFareCard
											key={option?.cabin?.code}
											option={option}
											flight={flight}
											selected={
												state?.selected?.selectedFare?.[instance]?.fare ?? null
											}
											onSelect={(selected) => {
												const selectedOption = `${flight?.key}-${selected}`;
												handleChange(selectedOption, flight);
												onClose();
											}}
										/>
									)}
								</LoopWrapper>
							</TabsContent>
						))}
					</Tabs>
				</div>
			)}
		</Dialog>
	);
}

export default MobileListItem;
