import React from 'react';
import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';

/**
 * @typedef {"h1" | "h2" | "h3" | "h4" | "h5" | "h6"} headingTags
 **/

/**
 * @typedef {Object} HeadingProps
 * @property {headingTags} as
 * @property {string} className
 **/

/**
 * @name Heading
 * @description Renders a heading element
 * @param {HeadingProps} props
 * @returns {React.JSX.Element}
 * @example
 * <Heading as="h1" className="text-2xl">Hello World</Heading>
 * */

function Heading({ as = 'h1', className, ...props }) {
	return React.createElement(as, {
		className: cn('', className),
		...props,
	});
}

Heading.propTypes = {
	as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
	className: PropTypes.string,
};

Heading.defaultProps = {
	as: 'h1',
	className: '',
};

export default Heading;
