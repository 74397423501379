import { forwardRef } from 'react';
import * as Primitives from '@radix-ui/react-dialog';

import { cn } from '@/lib/utils';

/**
 * @typedef {Object} DialogTriggerProps
 * @property {boolean} asChild
 * @property {string} className
 * @property {React.HTMLAttributes<HTMLButtonElement>} buttonProps
 */

/**
 * @name DialogTrigger
 * @description Renders a dialog trigger
 * @param {DialogTriggerProps } props
 * @param {React.ForwardedRef<HTMLButtonElement>} ref
 * @returns {JSX.Element | null}
 * @example
 * <DialogTrigger asChild>
 *      <button>Open Dialog</button>
 * </DialogTrigger>
 */

const DialogTrigger = forwardRef(
	({ className, children, asChild, ...props }, ref) => {
		return (
			<Primitives.Trigger
				ref={ref}
				asChild={asChild}
				className={cn('cursor-pointer', className)}
				{...props}
			>
				{children}
			</Primitives.Trigger>
		);
	}
);
export default DialogTrigger;
