import { useScrollToTop } from '@/hooks';
import { useLocation } from 'react-router-dom';

/**
 * @name ScrollToTop
 * @description Scroll to top of page on route change
 * @returns null
 */

function ScrollToTop({ children }) {
	const { pathname } = useLocation();
	useScrollToTop(pathname);
	return <>{children}</>;
}

export default ScrollToTop;
