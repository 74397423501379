import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { AnimatePresence, motion } from 'framer-motion';

import { cn } from '@/lib/utils';
import { Loader, ErrorHandler } from '@components/common';

const PageWrapper = forwardRef(
	(
		{
			error,
			loading,
			children,
			className,
			loaderClassName,
			errorClassName,
			loadingTitle,
			loadingText,
			onCancelRequest,
			alwaysRenderChildren,
			hideSearchAgainOnError,
			errorHandlerDescription,
		},
		ref
	) => {
		const motionVariants = {
			initial: { opacity: 0 },
			animate: { opacity: 1 },
			exit: { opacity: 0 },
		};
		return (
			<ErrorBoundary
				fallbackRender={(props) => (
					<ErrorHandler
						{...props}
						className={errorClassName}
						description={errorHandlerDescription}
						hideSearchAgain={hideSearchAgainOnError}
					/>
				)}
			>
				<section ref={ref} className="h-auto">
					<AnimatePresence initial={false} mode="wait">
						{error && !loading ? (
							<motion.div
								initial="initial"
								animate="animate"
								exit="exit"
								variants={motionVariants}
								className={className}
							>
								<ErrorHandler
									error={error}
									className={errorClassName}
									description={errorHandlerDescription}
									hideSearchAgain={hideSearchAgainOnError}
								/>
							</motion.div>
						) : null}
						{loading && !error ? (
							<motion.div
								initial="initial"
								animate="animate"
								exit="exit"
								variants={motionVariants}
								className={className}
							>
								<Loader
									onCancel={onCancelRequest}
									className={loaderClassName}
									title={loadingTitle}
									loadingSubText={loadingText}
								/>
							</motion.div>
						) : null}

						{alwaysRenderChildren || (!error && !loading) ? (
							<motion.div
								initial="initial"
								animate="animate"
								exit="exit"
								variants={motionVariants}
								className={className}
							>
								{children}
							</motion.div>
						) : null}
					</AnimatePresence>
				</section>
			</ErrorBoundary>
		);
	}
);

PageWrapper.propTypes = {
	error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	loading: PropTypes.bool,
	children: PropTypes.node.isRequired,
	loadingTitle: PropTypes.string,
	loadingText: PropTypes.string,
	loaderClassName: PropTypes.string,
	errorClassName: PropTypes.string,
	alwaysRenderChildren: PropTypes.bool,
	hideSearchAgainOnError: PropTypes.bool,
	errorHandlerDescription: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
	]),
};

PageWrapper.defaultProps = {
	error: false,
	loading: false,
	loadingText: 'This may take a few seconds...',
	alwaysRenderChildren: false,
};

export default PageWrapper;
