import * as yup from 'yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { DetailsForm } from '@components/mycaf';

const schema = yup.object().shape({
	title: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.string(),
		})
		.required('Title is required'),
	firstName: yup.string().required('First name is required'),
	middleName: yup.string(),
	lastName: yup.string().required('Last name is required'),
	gender: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.string(),
		})
		.required('Gender is required'),
});

function DetailsTab() {
	const defaultValues = useMemo(() => {
		// get user details from auth state and return details else return default values
		return {
			title: null,
			firstName: 'John',
			middleName: '',
			lastName: 'Doe',
			gender: null,
		};
	}, []);
	const { control, handleSubmit } = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const loading = false; // get loading from somewhere

	const onDetailsSubmit = (details) => {
		console.log(details);
	};
	return (
		<form onSubmit={handleSubmit(onDetailsSubmit)}>
			<DetailsForm control={control} isLoading={loading} />
		</form>
	);
}
export default DetailsTab;
