import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
	Button,
	FormField,
	Heading,
	Text,
	BookingPageWrapper,
} from '@/components/common';
import { currencyFormatter } from '@/lib/utils';
import { MotorHomeInfoCard } from '@components/motorhomes';

const packages = [
	{
		name: 'Fully Inclusive',
		label: 'compact',
		price: null,
		value: 'full',
		features: ['1x Driver', 'No GPS'],
	},
	{
		name: 'Super Fully Inclusive',
		label: 'compact',
		price: 36.57,
		value: 'super',
		features: ['2x Driver', 'No GPS'],
	},
	{
		name: 'Super Fully Inclusive Plus',
		label: 'compact',
		price: 99.48,
		value: 'super-plus',
		features: ['4x Driver', 'GPS Included'],
	},
];

function HireExtras({ steps }) {
	const navigate = useNavigate();
	const { control, handleSubmit } = useForm({
		defaultValues: {
			package: 'full',
			gps: 'no',
			'car-seat': {
				infant: {
					label: '0',
					value: 0,
				},
				child: {
					label: '1',
					value: 1,
				},
				toddler: null,
			},
		},
	});

	const onSubmit = (data) => {
		navigate(`/booking/motorhome-hire/driver-details`);
	};

	return (
		<BookingPageWrapper
			error={null}
			title="Motorhome Hire Extras"
			steps={steps ?? []}
			category="motorhome"
			onContinue={handleSubmit(onSubmit)}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<MotorHomeInfoCard
					motorhome={{}}
					className="p-10 border border-lighter-grey"
				/>
				<div className="my-8">
					<div className="px-5 lg:px-0">
						<Heading as="h2" className="text-2xl font-bold font-body">
							Package enhancement
						</Heading>
						<Text className="mt-3 mb-8">
							Our fully inclusive package has got you covered but if you want to
							add more options choose from the below.
						</Text>
					</div>

					<div className="p-10 bg-white border border-lighter-grey">
						<FormField
							name="package"
							as="radio-group"
							control={control}
							options={packages}
							className="items-start gap-8 md:grid-cols-1 lg:flex lg:items-center"
							fieldWrapperClassName="items-start lg:flex-col lg:items-center"
							renderOptionChildren={(option) => (
								<div className="flex flex-col items-start gap-2 lg:items-center">
									<Text
										variant="bold"
										className="text-xl text-left lg:text-center"
									>
										{option.name}
									</Text>

									<Text variant="black" className="text-3xl">
										{option?.price
											? `+ ${currencyFormatter({
													amount: option.price,
											  })}`
											: 'INCLUDED'}
									</Text>

									{option?.features?.length && (
										<div>
											{option.features.map((feature) => (
												<Text
													variant="bold"
													key={`${option.value}-feature-${feature}`}
													className="text-center"
												>
													{feature}
												</Text>
											))}
										</div>
									)}
								</div>
							)}
						/>
					</div>
				</div>
				<div className="mb-8">
					<div className="px-5 lg:px-0">
						<Heading as="h2" className="text-2xl font-bold font-body">
							Paid for on pick-up
						</Heading>
						<Text className="mt-2 mb-8">
							Add a request for an infant, toddler or child seat, or a GPS. You
							will pay for these extras directly at the car rental supplier
							service desk upon pick-up.{' '}
							<strong>
								Please note that availability of these extras is not always
								guaranteed.
							</strong>
						</Text>
					</div>
					<div className="flex flex-col items-start justify-between gap-4 p-5 bg-white border border-lighter-grey lg:flex-row lg:items-center">
						<div className="flex-1">
							<Text className="font-bold">Garmin GPS</Text>
							<Text>Navigate Canada with confidence</Text>
						</div>
						<FormField
							name="gps"
							as="toggle-group"
							control={control}
							options={[
								{
									label: 'No thanks',
									value: 'no',
								},
								{
									label: 'Yes please',
									value: 'yes',
								},
							]}
							wrapperClassName="w-auto"
						/>
					</div>
					{['infant', 'child', 'toddler'].map((seat) => (
						<div
							key={seat}
							className="flex flex-col items-start justify-between gap-4 p-5 my-3 bg-white border border-lighter-grey lg:flex-row lg:items-center"
						>
							<div className="flex-1">
								<Text className="font-bold">Car Seat ({seat})</Text>
								<Text>2 - 20lbs / 2.27- 9.07kgs</Text>
							</div>
							<div className="flex flex-col items-start justify-center gap-4 shrink grow basis-0 lg:flex-row lg:items-center">
								<div className="flex flex-col items-start w-full lg:items-end">
									<Text className="text-xl font-bold">
										+
										{currencyFormatter({
											amount: 13.0,
											currency: 'USD',
										})}{' '}
										per day
									</Text>
									<Text>
										maximum costs of{' '}
										{currencyFormatter({
											amount: 195,
											currency: 'USD',
											params: {
												hideDecimal: true,
											},
										})}
									</Text>
								</div>
								<FormField
									as="select"
									name={`car-seat.${seat}`}
									control={control}
									options={[
										{
											label: '0',
											value: '0',
										},
										{
											label: '1',
											value: '1',
										},
										{
											label: '2',
											value: '2',
										},
										{
											label: '3',
											value: '3',
										},
									]}
									wrapperClassName="shrink grow"
								/>
							</div>
						</div>
					))}
				</div>
				<div className="hidden w-full px-5 md:flex lg:px-0">
					<Button label="Continue" variant="supporting-yellow" />
				</div>
			</form>
		</BookingPageWrapper>
	);
}
export default HireExtras;
