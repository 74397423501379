export const navigate = (url, state) => {
    if (state) { window.history.pushState(state, '', url) };
    return window.location.href = url;
};

/**
 * Strip leading and trailing slashes from a url.
 *
 * @param {string} url
 * @returns {string}
 */
export const trimSlashes = (url) => url.replace(/^\/|\/$/gm, '');

/**
 * Get the index of the current step from an array of steps.
 *
 * @param {Array.<{to: string, name: string}>} steps
 * @param {string} currentPath
 * @returns {number}
 */
export const getCurrentStepIndex = (steps, currentPath) => {
    if (!Array.isArray(steps) || steps?.length < 0) return -1;

    const cleanPath = trimSlashes(currentPath);
    return steps.findIndex((step) => {
        if (!step?.to) return;

        const cleanTo = trimSlashes(step.to);
        return cleanTo === cleanPath;
    });
};


/**
 * Get the current step from an array of steps.
 *
 * @param {Array.<{to: string, name: string}>} steps
 * @param {string} currentPath
 * @returns {number}
 */
export const getCurrentStep = (steps, currentPath) => {
    const index = getCurrentStepIndex(steps, currentPath);
    return index >= 0 && steps[index]?.to ? steps[index] : null;
};

/**
 * Get the next or previous step from an array of steps.
 *
 * @param {Array.<{to: string, name: string}>} steps
 * @param {string} currentPath
 * @param {boolean} next
 * @returns {{to: string, name: string}}
 */
const getStep = (steps, currentPath, next = true) => {
    const fallbackStep = { to: '/' };

    const currentIndex = getCurrentStepIndex(steps, currentPath);
    if (currentIndex < 0) return fallbackStep;

    const nextIndex = next ? (currentIndex + 1) : (currentIndex - 1);
    if (!steps[nextIndex]?.to) return fallbackStep;

    return steps[nextIndex];
}

export const getNextStep = (steps, currentPath) => getStep(steps, currentPath, true);

export const getPreviousStep = (steps, currentPath) => getStep(steps, currentPath, false);
