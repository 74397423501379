import PropTypes from 'prop-types';
import { SearchResultsList } from '@/components/common';
import { SpecialOfferResultCard } from '@/components/flights';

function SpecialOfferResultsList({ pages }) {
	return (
		<SearchResultsList
			pages={pages}
			ResultCardComponent={SpecialOfferResultCard}
			className="md:grid-cols-2 lg:gap-5"
		/>
	);
}

SpecialOfferResultsList.propTypes = {
	pages: PropTypes.array,
};

SpecialOfferResultsList.defaultProps = {
	pages: [],
};

export default SpecialOfferResultsList;
