import * as yup from 'yup';

export const MotorCarSchema = yup.object().shape({
    pickup: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required('Required'),
            label: yup.string(),
        })
        .required('Required'),
    dropoff: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required('Required'),
            label: yup.string(),
        })
        .required('Required'),
    when: yup.object().shape({
        from: yup.date().required('Required'),
        to: yup.date().required('Required'),
    }),
});
