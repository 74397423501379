import * as yup from 'yup';
import { HOTEL_WHO_OPTIONS } from '@/lib/utils';

export const HotelSearchSchema = yup.object().shape({
    location: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required('Location is required'),
            label: yup.string(),
        })
        .required('Required'),
    roomCount: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required('At least one room is required'),
            label: yup.string(),
        })
        .required('At least one room is required'),
    rooms: yup.array().when('roomCount', {
        is: (roomCount) => roomCount && roomCount.value >= 1,
        then: () => yup.array().of(
            yup.object().shape(
                HOTEL_WHO_OPTIONS.reduce((acc, option) => {
                    if (option.value === 'adults') {
                        acc[option.value] = yup
                            .object()
                            .shape({
                                value: yup.string().required('At least one adult is required'),
                                label: yup.string(),
                            })
                            .required('At least one adult is required');

                        return acc;
                    }
                    acc[option.value] = yup.object().nullable().shape({
                        value: yup.string(),
                        label: yup.string(),
                    });
                    return acc;
                }, {})
            )
        ),
    }),

    when: yup.object().shape({
        from: yup.date().required('Start date is required'),
        to: yup.date().required('End date is required'),
    }),
});
