import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';

/**
 * @name SearchResultsList Generic search results listing component.
 * @returns {React.JSX.Element} SearchResultsList
 */
function SearchResultsList({ pages, className, ResultCardComponent }) {
	return (
		<div
			className={cn('w-full grid grid-cols-1 gap-4 px-5 md:px-0', className)}
		>
			{pages.map((page, pageIdx) => {
				if (!page?.data?.hits) return;

				return page.data.hits.map((item, i) => (
					<ResultCardComponent
						key={`results-page-${pageIdx}-${item?.id || i}`}
						result={item}
					/>
				));
			})}
		</div>
	);
}

SearchResultsList.propTypes = {
	pages: PropTypes.array,
	ResultCardComponent: PropTypes.func.isRequired,
};

SearchResultsList.defaultProps = {
	pages: [],
};

export default SearchResultsList;
