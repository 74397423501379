import PropTypes from 'prop-types';
import {
	add,
	differenceInDays,
	format,
	isValid,
	isWithinInterval,
	startOfDay,
} from 'date-fns';
import { useState, useMemo, useEffect } from 'react';

import MonthItem from './MonthItem';
import { useBreakpoint } from '@/hooks';
import { FLIGHT_ONLY_MAX_DATE, cn, currencyFormatter } from '@/lib/utils';
import { Button, Calendar, Text } from '@/components/common';

/**
 * @typedef {Object} FlightsCalendarProps
 * @property {Date} activeDay
 * @property {string} className
 * @property {string} captionClassName
 * @property {number} numberOfDays
 * @property {(day:Date)=>void} onChange
 * */

/**
 * @name FlightsCalendar
 * @description A component that renders a calendar for flights
 * @param {FlightsCalendarProps} props
 * @returns {React.JSX.Element}
 * @example
 * <FlightsCalendar
 * 	 activeDay={activeDay}
 * 	 onChange={handleChange}
 * 	 renderPrice={renderPrice}
 * />
 * */

function FlightsCalendar({
	prices,
	onChange,
	className,
	captionClassName,
	activeDay,
	numberOfDays,
	scrollToContainer,
}) {
	const isLg = useBreakpoint('lg');
	const isMd = useBreakpoint('md');
	const isSm = useBreakpoint('sm');
	const isXl = useBreakpoint('xl');

	const [selectedDay, setSelectedDay] = useState(() => {
		if (activeDay) {
			return activeDay;
		}
		return add(new Date(), { days: -1 });
	});

	useEffect(() => {
		if (activeDay) setSelectedDay(activeDay);
	}, [activeDay]);

	const handleChange = (day) => {
		setSelectedDay(day);

		if (onChange && typeof onChange === 'function') {
			onChange(day);
		}
	};

	const daysToShow = useMemo(() => {
		let len = numberOfDays;
		switch (true) {
			case isSm:
				len = 1;
				break;
			case isMd:
				len = 1;
				break;
			case isLg:
				len = 1;
				break;
			case isXl:
				len = 2;
				break;
			default:
				len = numberOfDays;
				break;
		}
		return len;
	}, [isSm, isMd, isLg, isXl, numberOfDays]);

	const nextDays = useMemo(() => {
		return Array.from({ length: daysToShow }, (_, i) =>
			add(selectedDay, { days: i + 1 })
		);
	}, [selectedDay, daysToShow]);

	const previousDay = useMemo(() => {
		return Array.from({ length: daysToShow }, (_, i) =>
			add(selectedDay, { days: -(i + 1) })
		).reverse();
	}, [selectedDay, daysToShow]);

	const getPrice = (day) => {
		const key = format(day, 'yyyy-MM-dd');
		const price = prices ? prices[key]?.price?.min : null;
		return price;
	};

	// determine the largest and smallest dates we have pricing for
	const { minPricingDate, maxPricingDate } = useMemo(() => {
		if (!prices) return {};

		const dates = Object.keys(prices).sort((a, b) => {
			const dateA = new Date(a);
			const dateB = new Date(b);
			if (!(isValid(dateA) && isValid(dateB))) return 0;

			return dateA - dateB;
		});
		if (!dates?.length) return {};

		const minDay = dates[0];
		const maxDay = dates.length > 1 ? dates[dates.length - 1] : minDay;
		return {
			minPricingDate: new Date(minDay),
			maxPricingDate: new Date(maxDay),
		};
	}, [prices]);

	const checkIfDayIsDisabled = (day, type) => {
		// cannot sell flights within 24 hours of departure
		if (differenceInDays(startOfDay(day), startOfDay(new Date())) <= 0)
			return true;

		// disable if past the maximum day restriction
		const maxDate = new Date(FLIGHT_ONLY_MAX_DATE);
		if (
			isValid(maxDate) &&
			differenceInDays(startOfDay(day), startOfDay(maxDate)) > 0
		)
			return true;

		return false;
	};

	return (
		<div
			className={cn(
				'w-full h-auto justify-center items-center inline-flex relative',
				className
			)}
		>
			<Calendar
				numberOfMonths={1}
				selected={selectedDay}
				defaultMonth={selectedDay}
				onMonthChange={handleChange}
				components={{
					Day: () => null,
					Head: () => null,
					Caption: () => {
						return (
							<div
								className={cn(
									'flex items-center justify-center w-full mx-auto gap-2 h-full',
									captionClassName
								)}
							>
								{previousDay.map((day) => (
									<MonthItem
										key={day}
										day={day}
										onClick={handleChange}
										disabled={checkIfDayIsDisabled(day, 'previous')}
										currentDay={selectedDay}
										renderPrice={(day, disabled) => (
											<PriceComponent
												day={day}
												minPricingDate={minPricingDate}
												maxPricingDate={maxPricingDate}
												price={getPrice(day)}
												disabled={disabled}
											/>
										)}
									/>
								))}

								<MonthItem
									day={selectedDay}
									currentDay={selectedDay}
									renderPrice={(day, disabled) => (
										<PriceComponent
											day={day}
											minPricingDate={minPricingDate}
											maxPricingDate={maxPricingDate}
											price={getPrice(day)}
											disabled={disabled}
											isSelectedDay
										/>
									)}
								/>

								{nextDays.map((day) => (
									<MonthItem
										key={day}
										day={day}
										currentDay={selectedDay}
										disabled={checkIfDayIsDisabled(day, 'next')}
										renderPrice={(day, disabled) => (
											<PriceComponent
												day={day}
												minPricingDate={minPricingDate}
												maxPricingDate={maxPricingDate}
												price={getPrice(day)}
												disabled={disabled}
											/>
										)}
										onClick={handleChange}
									/>
								))}

								<div className="absolute top-0 left-0 z-0 flex items-end justify-between w-full h-full px-2 py-4 md:items-center lg:px-0">
									<DateChangeArrowComponent
										type="previous"
										selectedDay={selectedDay}
										handleChange={(val) => {
											handleChange(val);
											scrollToContainer && scrollToContainer();
										}}
										checkIfDayIsDisabled={checkIfDayIsDisabled}
									/>
									<DateChangeArrowComponent
										type="next"
										selectedDay={selectedDay}
										handleChange={(val) => {
											handleChange(val);
											scrollToContainer && scrollToContainer();
										}}
										checkIfDayIsDisabled={checkIfDayIsDisabled}
									/>
								</div>
							</div>
						);
					},
				}}
				classNames={{
					row: 'border-none',
					months:
						'w-full mx-auto flex items-center justify-center mb-12 md:mb-0 md:w-[90%]',
					month: 'h-full w-full mx-auto',
					caption: 'w-full mx-auto pointer-events-none',
				}}
				className="flex items-center justify-center w-full p-0 border-b-4 border-core-blue"
			/>
		</div>
	);
}

function DateChangeArrowComponent({
	type,
	selectedDay,
	handleChange,
	checkIfDayIsDisabled,
}) {
	const isPrevious = type === 'previous';

	const newDay = add(selectedDay, { days: isPrevious ? -1 : 1 });
	const isDisabled = checkIfDayIsDisabled(
		newDay,
		isPrevious ? 'previous' : 'next'
	);

	return (
		<Button
			variant="unstyled"
			iconName={`arrow-${isPrevious ? 'left' : 'right'}`}
			className="flex-row-reverse"
			label={type === 'previous' ? 'Earlier' : 'Later'}
			labelClassName="md:hidden"
			disabled={isDisabled}
			onClick={() => {
				handleChange(newDay);
			}}
		/>
	);
}

const formatPriceLabel = ({
	price,
	day,
	isSelectedDay,
	minPricingDate,
	maxPricingDate,
}) => {
	// pricing available - show price
	if (price) return currencyFormatter({ amount: price });

	// no pricing available & is selected day - show unavailable
	if (isSelectedDay) return '-';

	// no pricing available, but we have pricing for other days either side of the day - show unavailable
	const hasPricingEitherSideOfDay =
		isValid(minPricingDate) &&
		isValid(maxPricingDate) &&
		isWithinInterval(startOfDay(day), {
			start: startOfDay(minPricingDate),
			end: startOfDay(maxPricingDate),
		});
	if (hasPricingEitherSideOfDay) return '-';

	// otherwise we don't know the availability - encourage user to search
	return 'Search';
};

function PriceComponent({
	price,
	day,
	minPricingDate,
	maxPricingDate,
	isSelectedDay,
	disabled,
}) {
	const label = !disabled
		? formatPriceLabel({
				price,
				day,
				isSelectedDay,
				minPricingDate,
				maxPricingDate,
		  })
		: '-';

	return (
		<Text as="span" className="text-xl font-bold">
			{label}
		</Text>
	);
}

FlightsCalendar.propTypes = {
	activeMonth: PropTypes.instanceOf(Date),
	onChange: PropTypes.func,
	className: PropTypes.string,
	captionClassName: PropTypes.string,
	renderPrice: PropTypes.func,
	numberOfDays: PropTypes.number,
};

FlightsCalendar.defaultProps = {
	activeMonth: new Date(),
	onChange: () => {},
	className: '',
	renderPrice: () => null,
	numberOfDays: 3,
};

export default FlightsCalendar;
