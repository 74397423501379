import { useCallback } from 'react';

/**
 * @name useStorage Hook
 * @description This hook is used to save data in the local storage or session storage
 * @param {string} key
 * @param {any} initialValue
 * @param {"localStorage" | "sessionStorage"} storageType
 * @returns {{remove: (key: string)=> void, save: (key: string, value: any)=> void, getValue: (key: string)=> any}}
 * @example
 * const { getValue, remove, save } = useStorage('localStorage');
 */
function useStorage(storageType = 'localStorage') {
	const storage = {
		localStorage: window.localStorage,
		sessionStorage: window.sessionStorage,
	}[storageType];

	const remove = useCallback((key) => {
		const storedValue = getValue(key);
		if (!storedValue) return;
		storage.removeItem(key);
	}, []);

	const getValue = useCallback((key) => {
		const storedValue = storage?.getItem(key);
		if (!storedValue) return null;
		return JSON.parse(storedValue);
	}, []);

	const save = useCallback((key, value) => {
		const existingValue = getValue(key);
		if (existingValue) {
			remove(key);
		}

		storage.setItem(key, JSON.stringify(value));
	}, []);

	return {
		save,
		remove,
		getValue,
	};
}
export default useStorage;
