import React from 'react';
import useDimensions from 'react-cool-dimensions';
import { trackWindowScroll } from 'react-lazy-load-image-component';

import {
	Image,
	Carousel,
	CarouselDots,
	CarouselNext,
	CarouselItem,
	CarouselContent,
	CarouselPrevious,
	Text,
} from '@components/common';
import { cn } from '@/lib/utils';

/**
 * @typedef {Object} MediaCarouselProps
 * @property {Array} slides - An array of objects containing the image / video assets
 * @property {bool} showSlideTitles - Display slide alt text over the image
 * @property {string} className - A class name to be applied to the carousel wrapper
 * @property {"light" | "dark"} theme
 */

/**
 * @name MediaCarousel
 * @description A carousel component that can be used to display images or videos
 * @param {MediaCarouselProps} props
 * @returns {React.JSX.Element}
 * @todo TODO: Add autoplay, create a lazy loaded image/ video component
 * @example
 * <MediaCarousel slides={images} />
 */

function MediaCarousel({ slides, showSlideTitles, className, theme }) {
	const { observe, height, width } = useDimensions({
		debounce: 100,
	});

	return (
		<Carousel opts={{ loop: true }} className={cn('h-full', className)}>
			<CarouselContent className="h-full">
				{slides.map((slide, index) => (
					<CarouselItem ref={observe} key={index} className="relative h-full">
						<Image
							height={height || 500}
							src={slide.src}
							alt={slide.alt}
							width={width || 500}
							className="object-cover w-full h-full"
						/>
						{showSlideTitles && slide.alt && (
							<Text className="absolute bottom-0 right-0 p-1.5 text-xs font-normal text-white/75 z-2 font-body leading-extra-tight tracking-less-tight bg-black/40 max-w-[95%] text-right">
								{slide.alt}
							</Text>
						)}
					</CarouselItem>
				))}
			</CarouselContent>
			<CarouselDots className="absolute bg-opacity-50 bottom-8" />
			<div className="absolute z-10 flex items-center justify-between w-full h-auto -translate-y-1/2 top-1/2">
				<CarouselPrevious
					variant="unstyled"
					className={cn(
						'relative left-0 top-0 -translate-y-0 h-14 w-16 p-4 bg-white rounded-none rounded-r-full ',
						{ 'bg-core-blue text-white': theme === 'dark' }
					)}
				/>
				<CarouselNext
					variant="unstyled"
					className={cn(
						'relative right-0 top-0 -translate-y-0 h-14 w-16 p-4 bg-white rounded-none rounded-l-full ',
						{ 'bg-core-blue text-white': theme === 'dark' }
					)}
				/>
			</div>
		</Carousel>
	);
}

MediaCarousel.defaultProps = {
	theme: 'light',
	slides: [
		{
			src: 'https://assets.architecturaldigest.in/photos/60084dd6cce5700439e12bf7/16:9/w_2560%2Cc_limit/modern-living-room-decor-1366x768.jpg',
			alt: 'room1',
		},
		{
			src: 'https://images.pexels.com/photos/775219/pexels-photo-775219.jpeg?auto=compress&cs=tinysrgb&w=800',
			alt: 'room2',
		},
		{
			src: 'https://images.pexels.com/photos/2507010/pexels-photo-2507010.jpeg?auto=compress&cs=tinysrgb&w=800',
			alt: 'room3',
		},
	],
};

export default trackWindowScroll(MediaCarousel);
