import * as yup from 'yup';

export const EnquirySchema = yup.object().shape({
    from: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required('Required'),
            label: yup.string(),
        })
        .required('Required'),
    when: yup.string().required('This field is required'),
    flexibleTravel: yup.mixed().oneOf(['no', 'withinMonth', 'withinWeek']).required('This field is required'),
    rooms: yup.array().of(yup.object().shape({
        adults: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup
                    .number()
                    .typeError('Adults should be a number')
                    .min(1, 'At least 1 adult is required.')
                    .max(9, 'Only 9 adults allowed'),
            })
            .required('Please select the number of adults'),
        children: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup
                    .number()
                    .typeError('Children should be a number')
                    .min(0, 'At least 0 children are required.')
                    .max(9, 'Only 9 children allowed'),
            })
            .nullable(),
        infants: yup
            .object()
            .shape({
                label: yup.string(),
                value: yup
                    .number()
                    .typeError('Infants should be a number')
                    .min(0, 'At least 0 infants are required.')
                    .max(9, 'Only 9 infants allowed'),
            })
            .nullable(),

    })),
    firstName: yup.string().required('First name is required'),
    surname: yup.string().required('Surname is required'),
    phone: yup
        .string()
        .matches(/^[0-9]{10,11}$/, 'Please enter a valid UK telephone number')
        .required('Telephone number is required'),
    email: yup
        .string()
        .email('Please enter a valid email address')
        .required('Email address is required'),
    preferredContactMethod: yup.mixed().oneOf(['phone', 'email']).required('The field is required'),
    contactDaysTimes: yup.string().when('preferredContactMethod', {
        is: (preferredContactMethod) => preferredContactMethod === 'phone',
        then: () => yup.string().required('Contact time is required'),
    }),
});
