import PropTypes from 'prop-types';
import { SearchResultsPage } from '@/components/common';
import {
	SearchResultsList,
	SuggestionsList,
} from '@/components/find-my-holiday';
import { withPortal } from '@/HOCs/withPortal';
import { cn, buildFacetFilters } from '@/lib/utils';
import { useAppliedFilters } from '@/hooks';

const sortOptions = [
	{
		label: 'Price - Low to High',
		value: 'price:asc',
	},
	{
		label: 'Price - High to Low',
		value: 'price:desc',
	},
];

const pageOptions = [
	{
		label: '9',
		value: '9',
	},
];

/**
 * @name FindMyHolidaySearch Page
 * @description Page to display find my holiday search results
 * @returns {React.JSX.Element} FindMyHolidaySearch
 */
function FindMyHolidaySearch({ portalData }) {
	const { totalAppliedFilters } = useAppliedFilters('find_my_holiday_filters');

	return (
		<SearchResultsPage
			paramsKey="find_my_holiday_filters"
			queryKey="find-my-holiday-search"
			sortOptions={sortOptions}
			pageOptions={pageOptions}
			hidePerPageField
			hideSortField={totalAppliedFilters === 0}
			filterLabel="Filter Holidays By"
			buildSearchParams={(params) => {
				return {
					pageId: portalData?.pageId,
				};
			}}
			buildFilters={(distribution, icons, stats) => {
				const customFilters = {
					price: {
						// represent price as a budget range filter
						type: 'budget',
						min: 500,
						max: 25000,
						name: 'budget',
						title: 'Budget',
						defaultToZero: true,
					},
					nights: {
						// represent nights as a duration range filter
						type: 'duration',
						name: 'duration',
						title: 'Duration',
					},
				};

				const filterOrder = [
					'types',
					'locations',
					'dates',
					'duration',
					'budget',
					'attractions',
					'themes',
					'experiences',
				];

				return buildFacetFilters({
					distribution: distribution,
					icons: icons,
					customFilters: customFilters,
					customSortOrder: filterOrder,
				});
			}}
			ListResultsComponent={SearchResultsList}
			NoFiltersAppliedComponent={SuggestionsList}
			className={portalData?.withSiteHeader && 'lg:pt-18'}
			resultsContainerClassName="lg:flex lg:items-start lg:relative grow"
			filterClassName={cn(
				'lg:sticky lg:w-1/4 lg:top-10',
				portalData?.withSiteHeader && 'lg:top-50.5'
			)}
		/>
	);
}

FindMyHolidaySearch.propTypes = {
	portalData: PropTypes.object,
};

// portaled to find-my-holiday-search-page
export default withPortal(FindMyHolidaySearch, 'find-my-holiday-search-page');
