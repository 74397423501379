import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

import {
	Required,
	calculateAge,
	TITLE_OPTIONS,
	GENDER_OPTIONS,
	capitalizeString,
} from '@/lib/utils';
import { FormField, FieldGroup } from '@/components/common';

function PassengerForm({ index, ageGroup, type, assistanceOptions }) {
	const { control, watch, setError, clearErrors } = useFormContext();
	const { errors } = useFormState({ control });

	useEffect(() => {
		const sub = watch((data) => {
			const isLeadPax = index === 0;
			if (!isLeadPax && !ageGroup) return;

			const passenger = data?.passengers ? data.passengers[index] : null;
			if (!passenger) return;

			const dob = passenger?.dob;
			if (!dob?.month || !dob?.day || !dob?.year) return;
			if (dob?.year?.toString()?.length !== 4) return;

			let errorMsg = null;
			const age = calculateAge(dob);
			switch (true) {
				case isLeadPax:
				case ageGroup === 'adult':
					if (age >= 18) break;
					errorMsg = `${isLeadPax ? `Lead ${type}` : `${capitalizeString(type)} ${index + 1}`} must be at least 18 years old`;
					break;

				// custom age for hotel "child" category
				case ageGroup === 'hotel-child':
					if (age >= 2 && age < 18) break;
					errorMsg = `${capitalizeString(type)} ${index + 1} must be between the age of 2 and 17`;
					break;

				case ageGroup === 'infant':
					if (age < 2) break;
					errorMsg = `${capitalizeString(type)} ${index + 1} must be under the age of 2`;
					break;
			}

			if (!errorMsg) {
				clearErrors(`passengers[${index}].dob`);
				return;
			}

			setError(`passengers[${index}].dob`, {
				message: errorMsg,
			});
		});
		return () => sub.unsubscribe();
	}, [watch, index, ageGroup, type]);

	return (
		<div className="flex flex-col space-y-4">
			<FormField
				as="select"
				name={`passengers[${index}].title`}
				label="Title"
				control={control}
				validation={[Required]}
				wrapperClassName="w-1/2"
				errors={errors}
				showRequired
				options={TITLE_OPTIONS}
			/>
			<FormField
				as="input"
				name={`passengers[${index}].firstName`}
				label="First name"
				validation={[Required]}
				errors={errors}
				control={control}
				showRequired
			/>
			<FormField
				as="input"
				name={`passengers[${index}].middleName`}
				label="Middle name"
				control={control}
				errors={errors}
			/>
			<FormField
				as="input"
				name={`passengers[${index}].lastName`}
				label="Last name"
				validation={[Required]}
				control={control}
				errors={errors}
				showRequired
			/>
			<FormField
				as="select"
				name={`passengers[${index}].gender`}
				label="Gender"
				showRequired
				options={GENDER_OPTIONS}
				control={control}
				validation={[Required]}
				wrapperClassName="w-1/2"
				errors={errors}
			/>

			<FieldGroup
				name={`passengers[${index}].dob`}
				className="grid grid-cols-3 gap-3"
				label="Date of birth"
				htmlFor={`passengers[${index}].dob.day`}
				showRequired
				errors={errors}
			>
				<Controller
					control={control}
					name={`passengers[${index}].dob.day`}
					render={({ field }) => (
						<FormField
							{...field}
							as="input"
							hideLabel
							label="Day"
							type="number"
							placeholder="DD"
							variant="default"
							onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
							onChange={(e) => {
								const val = e.target.value;

								const dayRegex = /^(0?[1-9]|[12]\d|3[01])$/;
								if (val.length > 2 && !dayRegex.test(val)) {
									e.preventDefault();
									return;
								}

								field.onChange(e);
							}}
							min={1}
							max={31}
						/>
					)}
				/>
				<Controller
					control={control}
					name={`passengers[${index}].dob.month`}
					render={({ field }) => (
						<FormField
							hideLabel
							as="input"
							type="number"
							label="Month"
							placeholder="MM"
							variant="default"
							onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
							onChange={(field, e) => {
								const val = e.target.value;

								const monthRegex = /^(0?[1-9]|1[012])$/;
								if (val.length > 2 && !monthRegex.test(val)) {
									e.preventDefault();
									return;
								}

								field.onChange(e);
							}}
							min={1}
							max={12}
							{...field}
						/>
					)}
				/>
				<FormField
					as="input"
					type="number"
					name={`passengers[${index}].dob.year`}
					label="Year"
					hideLabel
					placeholder="YYYY"
					control={control}
				/>
			</FieldGroup>

			{assistanceOptions?.length > 0 && (
				<FormField
					as="select"
					name={`passengers.${index}.assistance`}
					label="Special assistance required?"
					options={assistanceOptions}
					control={control}
					errors={errors}
				/>
			)}
		</div>
	);
}

PassengerForm.propTypes = {
	index: PropTypes.number.isRequired,
	assistanceOptions: PropTypes.array,
};

export default PassengerForm;
