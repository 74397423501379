import * as React from 'react';
import useEmblaCarousel from 'embla-carousel-react';

import { cn } from '@/lib/utils';
import { Button, Icon } from '@components/common';

/**
 * @typedef {import("embla-carousel-react").EmblaOptionsType} CarouselOptions
 * @typedef {import("embla-carousel-react").EmblaPluginType} CarouselPlugin
 * @typedef {import("embla-carousel-react").EmblaCarouselType} CarouselApi
 */

/**
 * @typedef {object} CarouselProps
 * @property {CarouselOptions} [opts] - Options for the carousel.
 * @property {CarouselPlugin[]} [plugins] - An array of carousel plugins.
 * @property {"horizontal" | "vertical"} [orientation] - The orientation of the carousel.
 * @property {(api: CarouselApi) => void} [setApi] - A function to set the carousel API.
 */

/**
 * @typedef {object} CarouselContextProps
 * @property {ReturnType<typeof useEmblaCarousel>[0]} carouselRef - Reference to the carousel.
 * @property {ReturnType<typeof useEmblaCarousel>[1]} api - The carousel API.
 * @property {() => void} scrollPrev - Function to scroll to the previous item.
 * @property {() => void} scrollNext - Function to scroll to the next item.
 * @property {boolean} canScrollPrev - Indicates if it's possible to scroll to the previous item.
 * @property {boolean} canScrollNext - Indicates if it's possible to scroll to the next item.
 * @property {CarouselProps} props - Additional props from CarouselProps.
 */

/**
 * @type {React.Context<CarouselContextProps>}
 * */
const CarouselContext = React.createContext(null);

function useCarousel() {
	const context = React.useContext(CarouselContext);

	if (!context) {
		throw new Error('useCarousel must be used within a <Carousel />');
	}

	return context;
}

/**
 * @name Carousel - A carousel component.
 * @param {CarouselProps} props
 * @param {React.Ref<HTMLDivElement>} ref
 * */

const Carousel = React.forwardRef(
	(
		{
			orientation = 'horizontal',
			opts,
			setApi,
			plugins,
			className,
			children,
			...props
		},
		ref
	) => {
		const [carouselRef, api] = useEmblaCarousel(
			{
				...opts,
				axis: orientation === 'horizontal' ? 'x' : 'y',
			},
			plugins
		);
		const [canScrollPrev, setCanScrollPrev] = React.useState(false);
		const [canScrollNext, setCanScrollNext] = React.useState(false);

		const onSelect = React.useCallback((api) => {
			if (!api) {
				return;
			}

			setCanScrollPrev(api.canScrollPrev());
			setCanScrollNext(api.canScrollNext());
		}, []);

		const scrollPrev = React.useCallback(() => {
			api?.scrollPrev();
		}, [api]);

		const scrollNext = React.useCallback(() => {
			api?.scrollNext();
		}, [api]);

		const handleKeyDown = React.useCallback(
			(event) => {
				if (event.key === 'ArrowLeft') {
					event.preventDefault();
					scrollPrev();
				} else if (event.key === 'ArrowRight') {
					event.preventDefault();
					scrollNext();
				}
			},
			[scrollPrev, scrollNext]
		);

		React.useEffect(() => {
			if (!api || !setApi) {
				return;
			}

			setApi(api);
		}, [api, setApi]);

		React.useEffect(() => {
			if (!api) {
				return;
			}

			onSelect(api);
			api.on('reInit', onSelect);
			api.on('select', onSelect);

			return () => {
				api?.off('select', onSelect);
			};
		}, [api, onSelect]);

		return (
			<CarouselContext.Provider
				value={{
					carouselRef,
					api: api,
					opts,
					orientation:
						orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
					scrollPrev,
					scrollNext,
					canScrollPrev,
					canScrollNext,
				}}
			>
				<div
					ref={ref}
					onKeyDownCapture={handleKeyDown}
					className={cn('relative', className)}
					role="region"
					aria-roledescription="carousel"
					{...props}
				>
					{children}
				</div>
			</CarouselContext.Provider>
		);
	}
);
Carousel.displayName = 'Carousel';

/**
 * @name CarouselContent - A carousel content component.
 * @param {React.HTMLProps<HTMLDivElement>} props
 * @param {React.Ref<HTMLDivElement>} ref
 * @returns {React.JSX.Element}
 * */

const CarouselContent = React.forwardRef(({ className, ...props }, ref) => {
	const { carouselRef, orientation } = useCarousel();

	return (
		<div ref={carouselRef} className="overflow-hidden h-full">
			<div
				ref={ref}
				className={cn(
					'flex',
					orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col',
					className
				)}
				{...props}
			/>
		</div>
	);
});
CarouselContent.displayName = 'CarouselContent';

/**
 * @name CarouselItem - A carousel item component.
 * @param {React.HTMLProps<HTMLDivElement>} props
 * @param {React.Ref<HTMLDivElement>} ref
 * @returns {React.JSX.Element}
 * */

const CarouselItem = React.forwardRef(({ className, ...props }, ref) => {
	const { orientation } = useCarousel();

	return (
		<div
			ref={ref}
			role="group"
			aria-roledescription="slide"
			className={cn(
				'min-w-0 shrink-0 grow-0 basis-full',
				orientation === 'horizontal' ? 'pl-4' : 'pt-4',
				className
			)}
			{...props}
		/>
	);
});
CarouselItem.displayName = 'CarouselItem';

/**
 * @name CarouselPrevious - A carousel previous button component.
 * @param {ReturnType<typeof import("@components/common/atoms/button").ButtonProps} props
 * @param {React.Ref<HTMLButtonElement>} ref
 * @returns {React.JSX.Element}
 */

const CarouselPrevious = React.forwardRef(
	({ className, variant = 'unstyled', size = 'icon', ...props }, ref) => {
		const { orientation, scrollPrev, canScrollPrev } = useCarousel();

		return (
			<Button
				ref={ref}
				variant={variant}
				size={size}
				className={cn(
					'absolute h-8 w-8 rounded-full text-black',
					orientation === 'horizontal'
						? '-left-12 top-1/2 -translate-y-1/2'
						: '-top-12 left-1/2 -translate-x-1/2 rotate-90',
					className
				)}
				disabled={!canScrollPrev}
				onClick={scrollPrev}
				{...props}
			>
				<Icon name="arrow-left" className="h-full w-full" />
				<span className="sr-only">Previous slide</span>
			</Button>
		);
	}
);
CarouselPrevious.displayName = 'CarouselPrevious';

const CarouselNext = React.forwardRef(
	({ className, variant = 'unstyled', size = 'icon', ...props }, ref) => {
		const { orientation, scrollNext, canScrollNext } = useCarousel();

		return (
			<Button
				ref={ref}
				variant={variant}
				size={size}
				className={cn(
					'absolute h-8 w-8 rounded-full',
					orientation === 'horizontal'
						? '-right-12 top-1/2 -translate-y-1/2'
						: '-bottom-12 left-1/2 -translate-x-1/2 rotate-90',
					className
				)}
				disabled={!canScrollNext}
				onClick={scrollNext}
				{...props}
			>
				<Icon name="arrow-right" className="h-full w-full" />
				<span className="sr-only">Next slide</span>
			</Button>
		);
	}
);

CarouselNext.displayName = 'CarouselNext';

/**
 * @name CarouselDots - A carousel dots component.
 * @param {React.HTMLProps<HTMLDivElement>} props
 * @param {React.Ref<HTMLDivElement>} ref
 * @returns {React.JSX.Element}
 * */

const CarouselDots = React.forwardRef(({ className, ...props }, ref) => {
	const { api, orientation } = useCarousel();
	const slides = Array.from({ length: api?.scrollSnapList().length });

	const currentSlide = api?.selectedScrollSnap();

	if (!slides) return null;
	return (
		<div
			ref={ref}
			className={cn(
				'absolute',
				orientation === 'horizontal'
					? 'bottom-4 left-1/2 -translate-x-1/2'
					: 'top-4',
				className
			)}
			{...props}
		>
			<div className="flex gap-2">
				{slides.map((_, index) => (
					<button
						key={index}
						type="button"
						className={cn(
							'rounded-full w-2 h-2 border border-white bg-white ',
							currentSlide === index && 'bg-opacity-30'
						)}
						onClick={() => {
							api.scrollTo(index);
						}}
					/>
				))}
			</div>
		</div>
	);
});

export {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselPrevious,
	CarouselNext,
	CarouselDots,
};
