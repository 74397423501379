import React from 'react';
import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { Tag } from '@components/common';

/**
 * @typedef {{
 * 	 label: string,
 * 	 value: string
 * }} option
 */

/**
 * @typedef {Object} TagGroupProps
 * @property {option[]} options
 * @property {boolean} cancellable
 * @property {string} className
 * @property {(option: option, index: number) => void} onCancel
 **/

/**
 * @name TagGroup
 * @description A reusable tag group component.
 * @param {TagGroupProps} props
 * @returns {React.JSX.Element}
 * @example
 * <TagGroup options={options} onCancel={onCancel} />
 */
function TagGroup({ options, onCancel, cancellable, className, disabled }) {
	if (!options) return null;
	return (
		<div className={cn('flex items-center gap-2 flex-wrap', className)}>
			{options && options.length > 0
				? options.map((option, index) => (
						<Tag
							key={option.value}
							label={option.label}
							isCancellable={cancellable}
							onCancel={() => onCancel(option, index)}
							disabled={disabled}
						/>
				  ))
				: null}
		</div>
	);
}

TagGroup.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
	onCancel: PropTypes.func.isRequired,
	cancellable: PropTypes.bool,
	disabled: PropTypes.bool,
};

TagGroup.defaultProps = {
	cancellable: true,
	disabled: false,
};

export default TagGroup;
