import PropTypes from 'prop-types';

import { cn, formatLocalDate } from '@/lib/utils';
import { Icon, Text } from '@/components/common';

/**
 * @typedef {Object} BlockLayoutProps
 * @property {string} date
 * @property {string} title
 * @property {import("@components/common/atoms/icon").IconProps["name"]} iconName
 * @property {React.ReactNode} children
 * @property {string} className
 * @property {() => React.ReactNode} renderStep
 *
 */

/**
 * @name BlockLayout
 * @description Renders a block layout with a date, title, icon, and children
 * @param {BlockLayoutProps} props
 * @returns {JSX.Element | null}
 * @example
 * <BlockLayout
 *  date="2024-06-14T00:00:00+00:00"
 *  title="Outbound Flight"
 *  iconName="plane"
 * >
 * 	<div className="flex flex-col p-5">
 * 		<Route iconName="plane-right" to={flight.to} from={flight.from} />
 * 	</div>
 * </BlockLayout>
 */
function BlockLayout({
	date,
	title,
	iconName,
	children,
	className,
	renderStep,
}) {
	return (
		<section className={cn('w-full relative flex items-start', className)}>
			{date ? (
				<div className="hidden bg-lightest-grey text-white rounded-l-xl py-3 px-5 h-auto items-center -space-y-1 w-auto lg:flex flex-col 2xl:-left-16 2xl:absolute">
					<Text as="span" className="font-normal text-sm">
						{formatLocalDate(date, 'EE')}
					</Text>
					<Text as="span" className="font-bold text-3xl">
						{formatLocalDate(date, 'dd')}
					</Text>
					<Text as="span" className="font-normal text-sm">
						{formatLocalDate(date, 'MMM')}
					</Text>
				</div>
			) : null}
			<div className="shadow-md bg-white border border-lighter-grey flex-1 relative">
				<div className="bg-core-blue text-white w-full p-4 flex items-end justify-between lg:items-center">
					<div>
						{date ? (
							<Text className="lg:hidden flex items-center gap-2 text-light-blue mb-1">
								<Icon name="calendar" className="w-4 h-4" />
								<Text as="span" className="text-sm font-bold uppercase">
									{formatLocalDate(date, 'EE dd MMM')}
								</Text>
							</Text>
						) : null}
						<Text className="flex items-center gap-3">
							<Icon name={iconName} className="w-5 h-5" />
							<Text as="span" className="font-bold">
								{title}
							</Text>
						</Text>
					</div>

					{renderStep()}
				</div>
				{children}
			</div>
		</section>
	);
}

BlockLayout.propTypes = {
	date: PropTypes.string,
	title: PropTypes.string,
	iconName: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	totalSteps: PropTypes.number,
	currentStep: PropTypes.number,
};

BlockLayout.defaultProps = {
	date: '2024-06-14T00:00:00+00:00',
	title: 'Outbound Flight',
	iconName: 'plane',
	children: null,
	className: '',
	renderStep: () => <p>Step 1 of 2</p>,
};

export default BlockLayout;
