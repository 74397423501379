import {
	useMemo,
	useRef,
	useState,
	useEffect,
	forwardRef,
	useCallback,
	useImperativeHandle,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryClient } from '@tanstack/react-query';

import {
	Button,
	SortBar,
	NotFound,
	PageHeader,
	PageWrapper,
	FilterWidget,
	ErrorHandler,
	SearchCancelledButton,
	MapListToggle,
} from '@/components/common';
import {
	useSortBar,
	useUrlParams,
	useMapToggle,
	useInfiniteFetch,
	useAppliedFilters,
} from '@/hooks';
import { cn } from '@/lib/utils';

/**
 * @name SearchResultsPage Generic search results page component with filter & sort functionality.
 * @returns {React.JSX.Element} SearchResultsPage
 */
const SearchResultsPage = (
	{
		paramsKey,
		queryKey,
		externalView,
		buildSearchParams,
		buildDefaultFilterParams,
		buildFilters,
		sortOptions = [],
		hideSortField = false,
		pageOptions = [],
		hidePerPageField = false,
		hideSaveAndShare = false,
		pageTitle,
		filterLabel = 'Filter Results By ...',
		className,
		resultsContainerClassName,
		filterClassName,
		ListResultsComponent,
		MapResultsComponent,
		NoFiltersAppliedComponent,
		hideMapToggle = false,
	},
	ref
) => {
	const queryClient = useQueryClient();
	const containerRef = useRef(null);
	const [readyToFetch, setReadyToFetch] = useState(false);
	const [perPageOverride, setPerPageOverride] = useState(null);
	const [filters, setFilters] = useState([]);
	const [facetStatRanges, setFacetStatRanges] = useState({});
	const { params, updateParams, parsedInitialParams } = useUrlParams(paramsKey);
	const { totalAppliedFilters } = useAppliedFilters(paramsKey);

	const newSearchParams = useMemo(() => {
		if (!params?.default) return;

		// get the required params from params object
		const newParams =
			typeof buildSearchParams === 'function' ? buildSearchParams(params) : {};

		// apply filter values to the search params
		if (params && params[paramsKey]) {
			const filters =
				typeof buildDefaultFilterParams === 'function'
					? buildDefaultFilterParams()
					: {};
			Object.keys(params[paramsKey]).forEach((key) => {
				const value = params[paramsKey][key];
				if (!value) return;

				switch (key) {
					case 'sort':
					case 'perPage':
						break;

					case 'budget':
						if (value[0] !== '') filters['minPrice'] = value[0];
						if (parseFloat(value[1]) > 0) filters['maxPrice'] = value[1];
						break;
					case 'flightDuration':
						if (value[0] !== '') filters['minDuration'] = value[0];
						if (parseFloat(value[1]) > 0) filters['maxDuration'] = value[1];
						break;
					case 'duration':
						const min = parseInt(value?.minNights?.value);
						if (!isNaN(min)) filters['minNights'] = value?.minNights?.value;

						const max = parseInt(value?.maxNights?.value);
						if (!isNaN(max)) filters['maxNights'] = value?.maxNights?.value;
						break;
					default:
						filters[key] = value;
						break;
				}
			});
			newParams.filters = filters;
		}

		return newParams;
	}, [paramsKey, params]);

	const { sort, perPage } = useSortBar(
		paramsKey,
		sortOptions,
		pageOptions,
		() => {
			// start fetching results after sort and perPage have been parsed
			if (!readyToFetch) setReadyToFetch(true);
		}
	);

	const parsedSort = useMemo(() => {
		const defaultSort = sortOptions && sortOptions[0] ? sortOptions[0] : null;
		const value =
			typeof sort?.value === 'string' ? sort.value : defaultSort?.value;
		if (!value) return {};

		const parts = value.split(':');
		return {
			field: parts[0],
			direction: parts[1] === 'asc' ? 'asc' : 'desc',
		};
	}, [sort?.value]);

	const {
		data,
		error,
		refetch,
		isLoading,
		isCancelled,
		fetchNextPage,
		isInitialFetch,
		isFetchingNextPage,
	} = useInfiniteFetch({
		key: queryKey,
		params: newSearchParams,
		sort: parsedSort,
		limit: perPageOverride ? perPageOverride : perPage?.value,
		config: {
			enabled: (readyToFetch && !!JSON.stringify(newSearchParams)) || false,
		},
	});

	const cancelRequest = (e) => {
		e.preventDefault();
		queryClient.cancelQueries({ queryKey: [queryKey] });
	};

	const lastPage = useMemo(() => {
		if (!data?.pages?.length) return null;

		return data?.pages?.slice(-1).shift();
	}, [data?.pages]);

	const paginationSummary = useMemo(() => {
		if (!lastPage?.data) return null;

		const totalResults = lastPage.data?.estimatedTotalHits;
		const totalDisplayed = data.pages.reduce(
			(total, page) => total + (page?.data?.hits?.length || 0),
			0
		);

		return totalResults >= 1
			? `Showing 1 - ${totalDisplayed} of ${totalResults}`
			: null;
	}, [data?.pages?.length, lastPage]);

	// refetch query results when filtering or sorting changes
	useEffect(() => {
		if (data?.pages?.length) {
			queryClient.removeQueries({ queryKey: [queryKey], exact: true });
			refetch();
		}
	}, [perPage?.value, perPageOverride, sort?.value, params[paramsKey]]);

	// set the overall min and max values for all facet stats
	useEffect(() => {
		if (!lastPage?.data?.facetStats) return;

		const newRanges = { ...facetStatRanges };
		Object.keys(lastPage.data.facetStats).forEach((facet) => {
			const facetStats = lastPage.data.facetStats[facet];
			if (!facetStats) return;

			// default to Infinity
			if (!newRanges[facet]) {
				newRanges[facet] = {
					min: Infinity,
					max: -Infinity,
				};
			}

			// determine if current min is smaller
			if (facetStats.min < newRanges[facet].min)
				newRanges[facet].min = facetStats.min;

			// determine if current max is larger
			if (facetStats.max > newRanges[facet].max)
				newRanges[facet].max = facetStats.max;
		});

		setFacetStatRanges(newRanges);
	}, [lastPage?.data?.facetStats]);

	// set the filters
	useEffect(() => {
		if (typeof buildFilters !== 'function') return;

		const distribution = lastPage?.data?.facetDistribution;
		if (!distribution) return;
		if (paramsKey === 'flight_offer_filters') {
			delete distribution['departureTimePeriod'];
		}
		const newFilters = buildFilters(
			distribution,
			lastPage?.extra?.icons,
			facetStatRanges
		);
		if (newFilters?.length) setFilters(newFilters);
	}, [
		buildFilters,
		lastPage?.data?.page,
		lastPage?.data?.facetDistribution,
		lastPage?.extra?.icons,
		facetStatRanges,
	]);

	// set default sort & per page params if they've not been provided
	useEffect(() => {
		// don't change params until they've been parsed
		if (!parsedInitialParams) return;

		const currentParams = params && params[paramsKey] ? params[paramsKey] : {};
		const newParams = { ...currentParams };
		if (!newParams?.sort) newParams.sort = sortOptions[0]?.value;
		if (!newParams?.perPage) newParams.perPage = pageOptions[0]?.value;

		updateParams(newParams);
	}, [params, parsedInitialParams]);

	const shouldHideMapToggle = useMemo(() => {
		if (typeof MapResultsComponent === 'undefined' || isCancelled) return true;
		if (hideMapToggle) return true;
		return false;
	}, [hideMapToggle, isCancelled, MapResultsComponent]);

	const isDisabled = useMemo(
		() => isLoading || isFetchingNextPage,
		[isLoading, isFetchingNextPage]
	);

	const { view, toggle } = useMapToggle('default', (value) =>
		setPerPageOverride(value === 'map' ? 1000 : null)
	);

	const resetPageSize = useCallback(() => {
		const isMap = view === 'map' || externalView === 'map';
		setPerPageOverride(isMap ? 1000 : null);
	}, [view, externalView]);

	useImperativeHandle(ref, () => ({
		isDisabled,
		resetPageSize,
	}));

	const pageView = useMemo(() => {
		if (externalView) return externalView;
		return view;
	}, [view, externalView]);

	const scrollToContainer = () => {
		window.scrollTo({
			top: containerRef.current.offsetTop - 140,
			behavior: 'smooth',
		});
	};

	return (
		<PageWrapper
			ref={containerRef}
			error={error}
			onCancelRequest={cancelRequest}
			loading={isLoading && isInitialFetch} // show on first fetch
			className="min-h-screen bg-light-grey lg:bg-white"
			loaderClassName="min-h-[calc(100dvh-4rem)] md:min-h-[calc(100vh-4.5rem)]"
		>
			<div
				className={cn(
					'min-h-screen mx-auto w-full flex flex-col gap-8 bg-light-grey lg:bg-white py-5 md:py-5 lg:py-10',
					className
				)}
			>
				<PageHeader
					title={pageTitle}
					hideBreadcrumb={!pageTitle}
					titleClassName="lg:text-9xl"
					className="px-5 mx-auto my-4 container-fluid max-w-screen-2xl 2xl:px-0"
				>
					{shouldHideMapToggle ? null : (
						<MapListToggle
							view={pageView}
							onChange={toggle}
							isDisabled={isDisabled}
						/>
					)}
				</PageHeader>

				{isCancelled ? (
					<SearchCancelledButton paramsKey={paramsKey} />
				) : (
					<div
						className={cn(
							'grid grid-cols-1 gap-5 mx-auto container-fluid max-w-screen-2xl md:px-6 lg:gap-12 lg:grid-cols-4 2xl:px-0',
							resultsContainerClassName && resultsContainerClassName
						)}
					>
						<ErrorBoundary FallbackComponent={ErrorHandler}>
							<FilterWidget
								paramsKey={paramsKey}
								widgetLabel={filterLabel}
								filters={filters}
								disabled={isLoading || isFetchingNextPage}
								sortOptions={sortOptions}
								defaultSort={sort}
								hideSortField={hideSortField}
								filterClassName={filterClassName}
								scrollToContainer={scrollToContainer}
							/>
						</ErrorBoundary>

						<div className="flex-1 w-full col-span-1 mx-auto lg:col-span-3">
							<PageWrapper
								error={error}
								loading={isLoading}
								onCancelRequest={cancelRequest}
								loaderClassName="relative py-12"
							>
								{/* map view */}
								{pageView === 'map' &&
									typeof MapResultsComponent !== 'undefined' && (
										<MapResultsComponent
											results={data?.pages[0]?.data?.hits || []}
										/>
									)}

								{/* default/list view */}
								{pageView === 'default' && (
									<div>
										{typeof NoFiltersAppliedComponent !== 'undefined' &&
										totalAppliedFilters === 0 ? (
											<NoFiltersAppliedComponent extra={lastPage?.extra} />
										) : (
											<div className="flex flex-col gap-4 md:gap-5">
												<SortBar
													sortOptions={sortOptions}
													hideSortField={hideSortField}
													pageOptions={pageOptions}
													hidePerPageField={hidePerPageField}
													hideSaveAndShare={
														hideSaveAndShare ||
														lastPage?.data?.hits?.length <= 0
													}
													paramsKey={paramsKey}
													summary={paginationSummary}
													defaultSort={sort}
													defaultPerPage={perPage}
													disabled={isFetchingNextPage}
												/>

												{lastPage?.data?.hits?.length > 0 ? (
													<ListResultsComponent pages={data?.pages} />
												) : (
													<NotFound
														titleClassName="text-2xl"
														message={
															totalAppliedFilters > 0
																? 'Please change your filter criteria.'
																: null
														}
													/>
												)}

												{lastPage?.data?.nextPage >= 1 && (
													<div className="flex items-center justify-center">
														<Button
															type="button"
															variant="outline"
															onClick={fetchNextPage}
															disabled={isFetchingNextPage}
															className="font-semibold border-lightest-grey text-lightest-grey hover:scale-x-105"
														>
															{isFetchingNextPage ? 'Loading...' : 'Load More'}
														</Button>
													</div>
												)}
											</div>
										)}
									</div>
								)}
							</PageWrapper>
						</div>
					</div>
				)}
			</div>
		</PageWrapper>
	);
};

export default forwardRef(SearchResultsPage);
