import { useState } from 'react';
import { useDebounce } from 'use-debounce';

import {
	Icon,
	Text,
	Input,
	Button,
	Dialog,
	Heading,
	SiteSearchSkeleton,
} from '@components/common';
import { useFetch } from '@/hooks';
import { POPULAR_SEARCHES } from '@/lib/utils';

function SiteSearchWidget() {
	const [searchTerm, setSearchTerm] = useState('');
	const [value] = useDebounce(searchTerm, 100);

	const { data, isFetching } = useFetch({
		key: 'site-search',
		params: { term: value },
		config: {
			enabled: value.length > 3,
		},
		format: (res) => res?.data,
	});

	const isDev = import.meta.env.DEV;

	return (
		<Dialog
			size="xl"
			as="drawer"
			hideCloseBtn
			position="top"
			contentClassName="rounded-t-none rounded-b-[36px] shadow-none overflow-hidden"
			renderTrigger={({ onOpen, DialogTrigger }) => (
				<DialogTrigger asChild>
					<button
						onClick={onOpen}
						className="text-core-blue bg-lighter-grey hover:text-core-blue border-3 border-transparent hover:border-border-color/40  w-[44px] h-[44px] transition-all rounded-full flex items-center justify-center md:w-[56px] md:h-[56px]"
					>
						<Icon name="search" className="w-4 h-4 md:h-4 md:w-4" />
					</button>
				</DialogTrigger>
			)}
		>
			{({ CloseButton }) => (
				<section className="p-2 flex flex-col gap-3 md:p-8 max-h-[70vh] overflow-y-auto">
					<div className="flex flex-col gap-2">
						<div className="relative flex items-center justify-between">
							<Heading
								as="h2"
								className="text-3xl font-bold font-body -tracking-2 text-core-blue"
							>
								Search
							</Heading>

							<CloseButton
								asChild
								variant="unstyled"
								className="right-0 -top-1"
							>
								<Button
									variant="unstyled"
									className="flex items-center gap-2 text-darker-grey hover:text-core-blue"
								>
									<Text
										as="span"
										className="hidden text-base font-medium md:block"
									>
										Close
									</Text>
									<Icon name="close" className="w-full h-12 md:h-10" />
								</Button>
							</CloseButton>
						</div>
						<div className="relative w-full">
							<Input
								value={searchTerm}
								name="search"
								onChange={(e) => setSearchTerm(e.target.value)}
								suffix={
									value ? (
										<Button
											variant="unstyled"
											className="w-4 h-4"
											onClick={() => setSearchTerm('')}
										>
											<Icon name="close" />
										</Button>
									) : (
										<Icon name="search" className="w-4 h-4" />
									)
								}
								placeholder="Search for location, experience, attraction"
								className="w-full text-base font-medium placeholder-gray-400 truncate rounded-sm"
								autoComplete="off"
								autoFocus
							/>
						</div>
					</div>
					{searchTerm ? (
						<div className="relative h-full overflow-y-auto hide-scrollbar">
							<Text className="sticky top-0 left-0 w-full font-bold tracking-tight bg-white text-core-blue">
								Showing results for {`"${searchTerm}"`}
							</Text>

							{!data && isFetching && <SiteSearchSkeleton />}
							<div className="flex flex-col gap-2 mt-4">
								{data &&
									data.hits &&
									data.hits.map((hit) => (
										<Text
											as="a"
											key={hit?.id}
											href={hit?.url}
											className="flex items-center justify-start gap-2 text-dark-grey/80"
										>
											<Icon
												name="maple-leaf"
												className="w-8 h-8 !text-core-blue shrink-0"
											/>

											<Text as="span" className="font-normal tracking-tight">
												{hit.pagetitle}
											</Text>
										</Text>
									))}
							</div>
						</div>
					) : (
						<div>
							<Text className="font-bold tracking-tight text-core-blue">
								Popular searches ...
							</Text>
							<div className="flex flex-col gap-2 mt-4">
								{POPULAR_SEARCHES.map((item) => (
									<Text
										role="button"
										key={item.label}
										onClick={() => setSearchTerm(item.label)}
										className="flex items-center gap-2 text-dark-grey hover:dark-grey"
									>
										<Icon name={item.icon} className="w-8 h-8" />
										<Text as="span" className="tracking-tight">
											{item.label}
										</Text>
									</Text>
								))}
							</div>
						</div>
					)}
				</section>
			)}
		</Dialog>
	);
}
export default SiteSearchWidget;
