import { useQueryClient } from '@tanstack/react-query';

/**
 * @name useRemoveQueries
 * @description - Removes the queries from the cache
 * @returns {{
 * queryClient: import('@tanstack/react-query').QueryClient,
 * removeQueries: (queries: Array<import('../lib/api').endpointKey>) => void
 * }}
 * @example
 * import { useRemoveQueries } from '@/hooks';
 * const { removeQueries } = useRemoveQueries();
 *
 * removeQueries(['flight-search', 'hotel-search']);
 */

function useRemoveQueries() {
	const queryClient = useQueryClient();

	/**
	 * @name removeQueries
	 * @description - Removes the queries from the cache
	 * @param {Array<import('../lib/api').endpointKey>} queries
	 * @returns {void}
	 */
	const removeQueries = (queries = []) => {
		// check that queries is an array
		if (!Array.isArray(queries)) return;

		// remove the queries from the cache
		queries.forEach((query) => {
			queryClient.removeQueries({ queryKey: [query] });
		});
	};
	return {
		queryClient,
		removeQueries,
	};
}
export default useRemoveQueries;
