import * as React from 'react';
import axios from 'axios';

axios.defaults.withCredentials = true;

const sanctumConfig = {
	apiUrl: 'https://localapi.canadianaffair.adido.dev/',
	// apiUrl: 'http://127.0.0.1:8000',
	csrfCookieRoute: 'sanctum/csrf-cookie',
	signInRoute: 'login',
	signOutRoute: 'logout',
	userObjectRoute: 'user',
};

// axios // get csrf token
// 	.get(sanctumConfig.apiUrl + sanctumConfig.csrfCookieRoute)
// 	.then((response) => {
// 		console.log('token', response, response.cookie);

// 		// login user
// 		axios
// 			.post(sanctumConfig.apiUrl + sanctumConfig.signInRoute, {
// 				email: 'test@test.com',
// 				password: 'password',
// 			})
// 			.then((response) => {
// 				console.log('login', response);
// 			})
// 			.catch((error) => {
// 				console.log('login error', error);
// 			});
// 	});

function Login() {
	return (
		<main className="text-gray h-auto min-h-screen w-full">
			<p>Login Area</p>
			<LoginButton />
		</main>
	);
}

const LoginButton = () => {
	const authenticated = false;
	const user = { name: 'John Doe' };
	const { handleLogin } = () => {};

	if (authenticated === true) {
		return <h1>Welcome, {user.name}</h1>;
	} else {
		return <button onClick={handleLogin}>Sign in</button>;
	}
};

export default Login;
