import { useLocation } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';

import { useStorage } from '@/hooks';
import { getCurrentStep } from '@/lib/utils';

/**
 * @typedef {import('@/lib/steps').step} Crumb
 **/

/**
 * @typedef {Object} useBreadcrumbsParams
 * @property {Crumb[]} pages
 * @property {boolean} showNextPages
 * @property {"sessionStorage" | "localStorage"} storageType
 */

/**
 * @name useBreadcrumbs Hook
 * @description This hook is used to create breadcrumbs for the user to navigate through the app
 * @param {useBreadcrumbsParams} params { pages, showNextPages, storageType }
 * @returns {{
 *     crumbs: Crumb[]
 *     activeCrumb: Crumb
 *     showNextPages: boolean
 *     activeCrumbIndex: number
 * }} breadcrumbs
 * @example
 * const { crumbs, activeCrumbIndex, activeCrumb, showNextStep } = useBreadcrumbs({ nextSteps, showNextStep });
 */
function useBreadcrumbs(params) {
	const { pathname, search } = useLocation();
	const [activeCrumb, setActiveCrumb] = useState(null);

	const storageKey = 'breadcrumbs';

	// handle breadcrumbs if page is refreshed
	const { save, remove, getValue } = useStorage(
		params?.storageType ?? 'sessionStorage'
	);

	const crumbs = getValue(storageKey) ?? [];

	const generateCrumbs = useCallback(() => {
		let crumbsList = crumbs && crumbs.length > 0 ? crumbs : [];
		// if session storage is not empty, set the crumbsList to the session storage but also check if the nextSteps exists in the crumbsList

		if (params?.showNextPages && params?.pages.length > 0) {
			// check if the new pags are the same as the current crumbsList
			const isSame = params?.pages.every((page) => {
				const crumb = crumbsList.find((crumb) => crumb?.to === page?.to);
				return crumb;
			});

			// if the new pages are not the same as the current crumbsList, reset the crumbsList
			if (!isSame) {
				remove(storageKey);
				crumbsList = [];
			}
			// check if the nextSteps already exists in the crumbsList
			params?.pages?.forEach((page) => {
				const stepExists = crumbsList.find(
					(crumb) => crumb?.name.toLowerCase() === page.name.toLowerCase()
				);
				if (!stepExists) crumbsList.push(page);
			});

			// set the active crumb to the items that is equal to the current pathname
			let activeCrumb = getCurrentStep(crumbsList, pathname);

			if (search && activeCrumb && Array.isArray(crumbsList)) {
				// set the search params to the active crumb
				const activeCrumbIdx = crumbsList.findIndex(
					(crumb) => crumb.to === activeCrumb?.to
				);

				if (activeCrumbIdx !== -1) {
					crumbsList[activeCrumbIdx] = {
						...crumbsList[activeCrumbIdx],
						search,
					};
				}
			}

			if (activeCrumb) {
				setActiveCrumb(activeCrumb);
			}
		} else {
			const segments = pathname.split('/').filter((segment) => segment !== '');
			crumbsList = segments
				.map((segment, index) => {
					// check if segment is /booking or /search
					if (segment === 'booking' || segment === 'search') return null;

					const segmentExists =
						crumbs && crumbs.find((crumb) => crumb?.name === segment);

					if (segmentExists)
						return {
							...segmentExists,
						};

					const to = `/${segments.slice(0, index + 1).join('/')}`;
					return {
						name: segment.replace(/-/g, ' '), // replace all hyphens with spaces
						to,
					};
				})
				.filter((crumb) => crumb?.to);

			// This is true if the current page has search params
			if (search) {
				crumbsList[crumbsList.length - 1].search = search;
			}

			setActiveCrumb(crumbsList[crumbsList.length - 1]);
		}

		// save the crumbsList to the session storage
		save(storageKey, crumbsList);
	}, [pathname, search, crumbs?.length]);

	useEffect(() => {
		generateCrumbs();
	}, [generateCrumbs]);

	const activeCrumbIndex = crumbs.findIndex(
		(crumb) => crumb?.to === activeCrumb?.to
	);


	return {
		crumbs,
		activeCrumb,
		activeCrumbIndex,
		showNextPages: params?.showNextPages,
	};
}
export default useBreadcrumbs;
