import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text } from '@/components/common';
import { cn } from '@/lib/utils';

function BulletList({ items, icon, className, iconClassName }) {
	return (
		<ul className={cn('flex flex-col gap-2', className)}>
			{items.map((item, idx) => (
				<li className="flex gap-3" key={idx}>
					<Icon name={icon} className={cn('mt-0.5', iconClassName)} />
					<Text className="text-neutral-800 opacity-75 text-base leading-tight font-body tracking-tighter font-normal">
						{item}
					</Text>
				</li>
			))}
		</ul>
	);
}

BulletList.propTypes = {
	items: PropTypes.arrayOf(PropTypes.string),
	icon: PropTypes.string,
	className: PropTypes.string,
	iconClassName: PropTypes.string,
};

export default BulletList;
