import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Text } from '@/components/common';
import { FlightCard } from '@/components/flights';
import { HotelSummaryCard } from '@/components/hotels';
import { HOTEL_BOOKING_CONFIRMED_DATA } from '@/lib/mockData';

function BookingSummary({ outbound, returns }) {
	return (
		<section>
			<div>
				<Text className="flex items-center justify-between w-full mb-4">
					<Text as="span" className="font-bold text-2xl capitalize">
						Outbound
					</Text>
					<Text as="span" className="text-right text-lg tracking-tighter">
						{formatLocalDate('2023-08-01T00:00:00+00:00', 'dd/MM/yyyy')}
					</Text>
				</Text>

				{outbound.map((flight) => (
					<FlightCard
						defaultOpen
						flight={flight}
						hideMoreInfoToggle
						key={flight?.flightNo}
						detailsClassName="bg-white gap-4"
					/>
				))}
			</div>
			<hr className="border border-lighter-grey my-5" />
			<div>
				<Text className="flex items-center justify-between w-full mb-4">
					<Text as="span" className="font-bold text-2xl capitalize">
						Hotel
					</Text>
					<Text as="span" className="text-right text-lg tracking-tighter">
						{formatLocalDate('2023-08-01T00:00:00+00:00', 'dd/MM/yyyy')} -{' '}
						{formatLocalDate('2023-08-12T00:00:00+00:00', 'dd/MM/yyyy')}
					</Text>
				</Text>
				<HotelSummaryCard hotel={HOTEL_BOOKING_CONFIRMED_DATA} hideChange className="border border-lighter-grey" />
			</div>
			<hr className="border border-lighter-grey my-5" />
			<div>
				<Text className="flex items-center justify-between w-full mb-4">
					<Text as="span" className="font-bold text-2xl capitalize">
						Return
					</Text>
					<Text as="span" className="text-right text-lg tracking-tighter">
						{formatLocalDate('2023-08-08T00:00:00+00:00', 'dd/MM/yyyy')}
					</Text>
				</Text>

				{returns.map((flight) => (
					<FlightCard
						key={flight?.flightNo}
						flight={flight}
						hideMoreInfoToggle
						defaultOpen
						detailsClassName="bg-white"
					/>
				))}
			</div>
		</section>
	);
}

BookingSummary.propTypes = {
	outbound: PropTypes.arrayOf(PropTypes.object).isRequired,
	returns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BookingSummary;
