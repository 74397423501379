import React from 'react';
import PropTypes from 'prop-types';

import { useBreakpoint } from '@/hooks';
import { currencyFormatter } from '@/lib/utils';
import { Button, CheckButton, Heading, Icon, Text } from '@/components/common';

/**
 * @typedef {Object} RailUpgradeProps
 * @property {(param: unknown)=>void} onSelect
 * @property {Object} selected
 * @property {boolean} isMobile
 */

/**
 * @name RailUpgrade
 * @description Renders rail journey upgrade options
 * @param {RailUpgradeProps} props
 * @returns {React.JSX.Element}
 * @example
 * <RailUpgrade
 * 	onSelect={(param) => console.log(param)}
 * 	selected={{}}
 * 	isMobile={false}
 * />
 */

function RailUpgrade({ onSelect, selected, isMobile }) {
	const isSm = useBreakpoint('sm');
	const isLg = useBreakpoint('lg');

	const showMobile = isSm && isMobile;
	return (
		<section role="table">
			{isLg || showMobile ? (
				<div className="grid grid-cols-1 gap-4">
					{Array.from({ length: 2 }).map((_, i) => (
						<div className="even:bg-lightish-grey p-5">
							<div className="flex items-start justify-between">
								<Heading as="h3" className="text-xl font-body ">
									Silver Leaf Double
								</Heading>
								<Text as="span" className="text-xl font-bold">
									{currencyFormatter({
										amount: 0,
										params: { hideDecimal: true },
									})}
								</Text>
							</div>
							<Text>Lorem ipsum dolor sit amet consectetur odit.</Text>
							<ul>
								{Array.from({ length: 2 }).map((_, i) => (
									<li className="flex items-start gap-2 my-1">
										<Icon name="maple-leaf" className="w-5 h-5" />
										<Text as="span" variant="caption">
											Lorem ipsum dolor sit amet.
										</Text>
									</li>
								))}
							</ul>
							<Button
								hideIcon
								label="More Info"
								variant="unstyled"
								className="underline underline-offset-4"
								labelClassName="font-normal"
							/>

							<div className="flex items-center justify-between my-4">
								<Button variant="supporting-yellow" label="Select" />
								{selected && (
									<Text className="flex items-center gap-2">
										<Text as="span">Selected</Text>
										<Text
											as="span"
											className="w-6 h-6 rounded-full bg-check-green p-1 flex items-center justify-center text-white"
										>
											<Icon name="check" className="w-full h-full" />
										</Text>
									</Text>
								)}
							</div>
						</div>
					))}
				</div>
			) : (
				<div role="table">
					<div
						className="grid grid-cols-3 text-supporting-lightblue xl:grid-cols-4"
						role="rowheader"
					>
						<Text role="th" className="xl:col-span-2"></Text>
						<Text role="th" className="font-bold col-span-1">
							Silver Leaf Double
						</Text>
						<Text role="th" className="font-bold col-span-1">
							Gold Leaf Double
						</Text>
					</div>
					{Array.from({ length: 2 }).map((_, i) => (
						<div
							key={i}
							className="grid grid-cols-3 gap-y-4 border-b py-4 xl:grid-cols-4"
							role="row"
						>
							<div role="td" className="flex items-center gap-2 xl:col-span-2">
								<Icon name="baggage" className="w-5 h-5" />
								<Text as="span" variant="caption">
									Something
								</Text>
							</div>
							<div role="td" className="flex flex-col gap-2 col-span-1">
								<Text className="font-bold">Information</Text>
								<Text as="span" variant="caption">
									Lorem ipsum dolor, sit amet consectetur adipisicing elit.
									Dignissimos, nemo.
								</Text>
							</div>
							<div role="td" className="flex flex-col gap-2 col-span-1">
								<Text className="font-bold">Information</Text>
								<Text as="span" variant="caption">
									Lorem ipsum dolor, sit amet consectetur adipisicing elit.
									Dignissimos, nemo.
								</Text>
							</div>
						</div>
					))}
					<div
						className="grid grid-cols-3 gap-y-4 mt-4 xl:grid-cols-4"
						role="row"
					>
						<div
							role="td"
							className="flex items-center gap-2 xl:col-span-2"
						></div>
						<div role="td" className="flex flex-col gap-2 col-span-1">
							<Text className="font-bold text-xl">
								{currencyFormatter({
									amount: 0,
									params: { hideDecimal: true },
								})}
							</Text>
							<CheckButton
								className="w-fit"
								renderText={(isSelected) => (
									<Text as="span">{isSelected ? 'Selected' : 'Select'}</Text>
								)}
							/>
						</div>
						<div role="td" className="flex flex-col gap-2 col-span-1">
							<Text className="font-bold text-xl">
								{currencyFormatter({
									amount: 0,
									params: { hideDecimal: true },
								})}
							</Text>
							<CheckButton
								className="w-fit"
								renderText={(isSelected) => (
									<Text as="span">{isSelected ? 'Selected' : 'Select'}</Text>
								)}
							/>
						</div>
					</div>
					<div className="w-full flex items-center justify-center mt-5">
						<Text className="flex items-center gap-1">
							<Icon name="chevron-down" className="w-3 h-3" />
							<Text as="span" className="text-sm">
								More Info
							</Text>
						</Text>
					</div>
				</div>
			)}
		</section>
	);
}

RailUpgrade.propTypes = {
	onSelect: PropTypes.func,
	selected: PropTypes.object,
	isMobile: PropTypes.bool,
};

RailUpgrade.defaultProps = {
	isMobile: false,
	selected: null,
	onSelect: () => {},
};

export default RailUpgrade;
