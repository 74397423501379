import { Button, Drawer } from '@/components/common';
import { CarTypeFilter } from '@/components/carhire';
import { DrawerClose } from '@/components/common/atoms/drawer/CnDrawer';

function FilterList({ filters, values, onChange }) {
	const options = filters.map((key) => {
		const value = Object.keys(key)[0];
		return {
			value,
			...key[value],
		};
	});
	return (
		<div className="flex flex-col over-flow-x-scroll">
			{/* Mobile car type filter */}
			<Drawer
				asChild
				title="Select Car Type"
				contentClassName="max-h-[calc(100vh-10rem)] overflow-y-scroll relative pb-0 lg:hidden"
				renderTrigger={() => (
					<Button
						className="w-full text-left flex-row-reverse justify-center items-center gap-2 rounded-none md:hidden"
						variant="core-blue"
						label="Select a car type"
						iconName="car"
					/>
				)}
			>
				<div className="w-full h-1/2 pb-4 flex flex-col overflow-y-scroll relative">
					<div className="flex justify-center w-full">
						<CarTypeFilter
							values={values}
							options={options}
							onChange={onChange}
							wrapperClassName="flex flex-row items-center justify-start gap-4 mt-4"
							itemClassName="shrink grow w-full flex-row items-center justify-start md:justify-center gap-2"
						/>
					</div>
				</div>
				<div className="w-full sticky bg-white py-4 left-0 bottom-0 z-10 border-t">
					<DrawerClose className="w-full">
						<Button
							label="View results"
							variant="supporting-yellow"
							className=" w-full justify-between items-center gap-2"
						/>
					</DrawerClose>
				</div>
			</Drawer>

			{/* Desktop car type filter */}
			<CarTypeFilter
				values={values}
				options={options}
				onChange={onChange}
				className="hidden flex-row flex-nowrap w-full overflow-hidden overflow-x-scroll  mx-auto hide-scrollbar md:flex"
				wrapperClassName="flex flex-col items-center justify-center gap-2"
				itemClassName="shrink grow min-w-[150px]"
			/>
		</div>
	);
}
export default FilterList;
