import PropTypes from 'prop-types';

import { RteStyledContent } from '@/components/common';

function DesktopView({ cabins = [], features = [] }) {
	return (
		<div className="w-full overflow-auto">
			<table className="w-full overflow-scroll border border-lighter-grey">
				<thead className="bg-core-blue text-white">
					<tr>
						<th className="p-2 opacity-0 text-left">Empty Cell</th>
						{cabins.map((cabin) => (
							<th className="p-4 text-left" key={cabin.code}>
								{cabin?.displayName || cabin?.name}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{features.map((feature) => (
						<tr key={feature.apiField} className="even:bg-light-grey/70">
							<td className="p-4 min-w-60">{feature.label}</td>
							{cabins.map((cabin) => (
								<td className="p-4 min-w-60" key={cabin.code}>
									<RteStyledContent
										variant="dark"
										className="flight-info"
										dangerouslySetInnerHTML={{
											__html: cabin[feature.apiField],
										}}
									/>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

DesktopView.propTypes = {
	cabins: PropTypes.arrayOf(
		PropTypes.shape({
			code: PropTypes.string,
			name: PropTypes.string,
		})
	).isRequired,
	features: PropTypes.arrayOf(
		PropTypes.shape({
			apiField: PropTypes.string,
			label: PropTypes.string,
		})
	).isRequired,
};

export default DesktopView;
