import { Skeleton } from '@components/common';

function SiteSearchSkeleton() {
	return (
		<div className="flex flex-col gap-2 mt-2">
			<div className="flex items-center gap-2">
				<Skeleton className="w-4 h-4 rounded-none" />
				<Skeleton className="w-3/4 h-4 rounded-none" />
			</div>
			<div className="flex items-center gap-2">
				<Skeleton className="w-4 h-4 rounded-none" />
				<Skeleton className="w-[60%] h-4 rounded-none" />
			</div>
			<div className="flex items-center gap-2">
				<Skeleton className="w-4 h-4 rounded-none" />
				<Skeleton className="w-1/2 h-4 rounded-none" />
			</div>
			<div className="flex items-center gap-2">
				<Skeleton className="w-4 h-4 rounded-none" />
				<Skeleton className="w-1/4 h-4 rounded-none" />
			</div>
		</div>
	);
}
export default SiteSearchSkeleton;
