import PropTypes from 'prop-types';

import { Route } from '@/components/common';
import { BlockLayout } from '@components/holidays';

/**
 * @typedef {Object} TransferBlockProps
 * @property {string} className
 * @property {{
 * 		from: string;
 * 		to: string;
 * 		date: Date;
 * }} transfer
 * */

/**
 * @name TransferBlock
 * @description Renders a travel plan block with the transfer details
 * @param {TransferBlockProps} props
 * @returns {JSX.Element | null}
 * @example
 * <TransferBlock
 *    transfer={{
 * 			from: 'Calgary Airport',
 * 			to: 'Calgary Downtown',
 * 			date: new Date(),
 * 		}}
 * />
 * */

function TransferBlock({ className, transfer, renderStep }) {
	return (
		<BlockLayout
			iconName="car"
			title="Transfer"
			date={transfer.date}
			className={className}
			renderStep={renderStep}
		>
			<div className="flex flex-col p-5">
				<Route iconName="car-right" to={transfer.to} from={transfer.from} />
			</div>
		</BlockLayout>
	);
}

TransferBlock.propTypes = {
	className: PropTypes.string,
	transfer: PropTypes.shape({
		from: PropTypes.string,
		to: PropTypes.string,
		date: PropTypes.instanceOf(Date),
	}),
};

TransferBlock.defaultProps = {
	className: '',
	transfer: {
		from: '',
		to: '',
		date: new Date(),
	},
};

export default TransferBlock;
