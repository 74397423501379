import {
	Heading,
	Icon,
	Text,
	Badge,
	buttonVariants,
} from '@/components/common';
import { cn } from '@/lib/utils';
import { Link } from 'react-router-dom';

function MotorHomeCard({ motorHome, className, onSelect }) {
	return (
		<div
			className={cn(
				'h-auto w-full grid grid-cols-1 gap-4 bg-white rounded-lg shadow-md overflow-hidden lg:grid-cols-3 lg:shadow-none lg:rounded-none',
				className
			)}
		>
			<div className="w-full col-span-1 h-full max-h-[30vh] md:max-h-[40vh] lg:max-h-full lg:h-full">
				<img
					className="self-stretch h-full w-full object-contain"
					src="https://www.carthago.com/fileadmin/_processed_/6/2/csm_2022_Carthago_c-tourer_T143LE-Fiat_vV-dyn-BFS_fc087a5008.png"
				/>
			</div>
			<div
				className={cn(
					'w-full grid-cols-1 px-6 pb-8 bg-white flex flex-col gap-2 justify-center items-start lg:col-span-2 lg:pb-0 lg:my-10'
				)}
			>
				<div className={cn('flex flex-col-reverse gap-2 lg:flex-col')}>
					<Badge text="5 Birth" />
					<Heading as="h3" className="self-stretch text-core-blue font-bold ">
						C25 Standard Motorhome Toronto
					</Heading>
				</div>
				<div className="flex p-4 w-full flex-wrap items-center gap-x-4 bg-light-grey rounded-md md:w-fit lg:py-2 ">
					<div className="justify-start items-center gap-1 flex">
						<Icon name="accommodation" className="w-5" />
						<Text className="text-sm opacity-75 text-grey-dark leading-tight">
							1 night pre accommodation in Toronto
						</Text>
					</div>
					<div className="justify-start items-center gap-1 flex">
						<Icon name="plane" className="w-5" />
						<Text className="text-sm opacity-75 text-grey-dark leading-tight">
							International flights with Air Transit
						</Text>
					</div>
				</div>
				<Text className="self-stretch  text-dark-grey opacity-75 leading-tight font-normal text-base line-clamp-4">
					Our most popular sized motorhome to hire. The C25 is a medium sized
					vehicle with a rear corner double bed as well as a double bed over the
					cab. Ideal for two couples or a family of up to five - the dining
					table easily drops to make into another bed suitable for a child.
				</Text>
				<div className="self-stretch h-auto pb-2.5 flex-col justify-start items-start gap-2 flex my-2">
					<div className="flex flex-col justify-start items-start gap-1">
						<Text variant="muted" className="uppercase font-semibold">
							From
						</Text>
						<div className="flex gap-10 justify-start items-start">
							<div className="flex items-center gap-1">
								<Text variant="bold" className="text-2xl">
									£1,625
								</Text>

								<Text
									as="small"
									variant="muted"
									className="hidden text-base font-medium leading-none"
								>
									per person
								</Text>
								<Text
									as="small"
									variant="bold"
									className="text-base font-medium leading-none"
								>
									pp
								</Text>
							</div>
							<div className="flex items-center gap-1">
								<Icon name="moon" />
								<Text variant="bold" className="text-2xl">
									10 Days
								</Text>
							</div>
						</div>
					</div>
					<div className="flex flex-col">
						<Text
							as="small"
							variant="muted"
							className="text-lightest-grey opacity-60"
						>
							Sleeping Summary: 5 People <br />
							Bed Summary: Cab Over, Dinette & Rear bed
						</Text>
					</div>
				</div>
				{/* TODO: change button variant to outline when in mobile */}
				<Link
					to="/hotels/1"
					className={cn(
						buttonVariants({
							className:
								' bg-transparent border- flex items-center gap-4 lg:text-white',
						})
					)}
					onClick={() => onSelect(motorHome)}
				>
					<Text variant="bold">View & Book</Text>
					<Icon name="arrow-right" className="text-white" />
				</Link>
			</div>
		</div>
	);
}
export default MotorHomeCard;
