import React from 'react';
import PropTypes from 'prop-types';
import { cva } from 'class-variance-authority';
import * as ToggleGroup from '@radix-ui/react-toggle-group';

import { cn } from '@/lib/utils';
import { Icon, Text } from '@/components/common';

const toggleGroupVariants = cva(
	'group px-4 py-2 min-h-[40px] flex justify-between items-center first:rounded-l-full last:rounded-r-full focus:outline-none text-base leading-4 border border-lighter-grey lg:px-8 lg:pl-7.5 lg:pr-4 disabled:opacity-75 disabled:cursor-not-allowed',
	{
		variants: {
			instance: {
				formField:
					'bg-white hover:enabled:bg-lighter-grey data-[state=on]:bg-core-light-blue data-[state=on]:text-white data-[state=on]:border-core-light-blue hover:data-[state=on]:bg-core-light-blue/90 hover:data-[state=on]:text-white',
				pages:
					'bg-light-grey text-core-blue hover:enabled:bg-lighter-grey data-[state=on]:bg-white data-[state=on]:text-core-red data-[state=on]:border-lighter-grey',
			},
			states: {
				valid: 'border-lighter-grey',
				invalid: 'text-core-red border-core-red',
			},
		},
		defaultVariants: {
			instance: 'formField',
			states: 'valid',
		},
	}
);

/**
 * @typedef {Object} option
 * @property {string} value
 * @property {string} label
 **/

/**
 * @typedef {Object} ToggleButtonGroupProps
 * @property {string} className
 * @property {option[]} options
 * @property {string} value
 * @property {(param)=> void} onChange
 * @property {boolean} isValid
 * @property {"formfield" | "pages"} instance
 * @property {string} itemClassName
 **/

/**
 * @name ToggleButtonGroup
 * @description A component that renders a group of toggle buttons
 * @param {ToggleButtonGroupProps} props
 * @param {React.ForwardedRef<HTMLDivElement>} ref
 * @returns {React.JSX.Element}
 * @example
 * <ToggleButtonGroup
 * 		className="w-full"
 * 		options={options}
 * 		value={value}
 * 		onChange={onChange}
 * 		isValid={isValid}
 * 		instance="formField"
 * 		itemClassName="w-full"
 * />
 **/

const ToggleButtonGroup = React.forwardRef((props, ref) => {
	let {
		value,
		options,
		onChange,
		className,
		itemClassName,
		itemLabelClassName,
		isValid = true,
		instance = 'formField',
		...rest
	} = props;

	// default value to the first option value if no value is provided
	if (!value) {
		value = options[0]?.value;
	}

	return (
		<ToggleGroup.Root
			ref={ref}
			className={cn(
				'flex items-stretch',
				{ 'grid grid-cols-2': options && options.length > 2 },
				className
			)}
			type="single"
			value={value}
			onValueChange={onChange}
			{...rest}
		>
			{options.map((option) => (
				<ToggleGroup.Item
					key={option.value}
					className={cn(
						toggleGroupVariants({
							instance,
							states: isValid ? 'valid' : 'invalid',
							className: itemClassName,
						})
					)}
					value={option.value}
					aria-label={option.label}
				>
					<Text
						as="span"
						className={cn(
							'w-full text-sm text-left md:text-base',
							itemLabelClassName && itemLabelClassName
						)}
					>
						{option.label}
					</Text>

					<Text
						as="span"
						className="h-full opacity-0 flex items-center justify-end pl-4 group-data-[state=on]:flex group-data-[state=on]:opacity-100 group-hover:invisible group-hover:group-data-[state=on]:visible"
					>
						<Icon name="check" className="w-4 h-4" />
					</Text>
				</ToggleGroup.Item>
			))}
		</ToggleGroup.Root>
	);
});

ToggleButtonGroup.propTypes = {
	className: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			label: PropTypes.string,
		})
	).isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func,
	isValid: PropTypes.bool,
	instance: PropTypes.oneOf(['formField', 'pages']),
};

export default ToggleButtonGroup;
