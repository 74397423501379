import { useMemo, lazy, Suspense, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import { useBackNavigate, useFetch, useMetaDescription } from '@/hooks';
import { useBookingStore } from '@/store';
import { flightSteps as steps } from '@/lib/steps';
import { getNextStep } from '@/lib/utils';
import {
	Button,
	BookingPageWrapper,
	PageWrapper,
	Skeleton,
} from '@/components/common';

const BaggageFlights = lazy(() => import('../../molecules/baggage-flights'));

function BaggagePage() {
	useMetaDescription(['Baggage', 'Flights', 'Canadian Affair']);
	const params = useParams();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { setBookingState, mergeFlightsParams } = useBookingStore();
	const [hasParsedIncludedBags, setHasParsedIncludedBags] = useState(false);
	const category = params?.category ?? 'flights';
	const state = useBookingStore((store) => store[category]);

	const pageSteps = useMemo(() => {
		if (!steps) return null;
		const stepsIsArray = Array.isArray(steps);
		return !stepsIsArray ? steps[category] : steps;
	}, [steps]);

	// digest the preview object to get the pax type
	const paxInfoWithType = useMemo(() => {
		if (!state?.bookingDetails?.pax?.length) return [];
		if (!state?.preview?.pax?.length) return [];

		return state?.bookingDetails?.pax.map((person) => {
			const paxType = state.preview.pax.find(
				(previewPax) => previewPax?.ref === person?.ref
			)?.type;

			return {
				...person,
				type: paxType ? paxType?.toString()?.toLowerCase() : null,
			};
		});
	}, [state?.bookingDetails?.pax, state?.preview?.pax]);

	const baggageParams = useMemo(() => {
		if (!state?.selected?.items) return null;
		if (!state?.bookingDetails?.pax?.length) return null;

		let outboundParams = null;
		let inboundParams = null;

		const getParams = (flight) => {
			if (!flight) return null;

			return {
				departure: flight?.departure || '',
				departureName: flight?.departureName || '',
				departureTime: flight?.departureTime || '',
				departureTimezone: flight?.departureTimezone || '',
				arrival: flight?.arrival || '',
				arrivalName: flight?.arrivalName || '',
				arrivalTime: flight?.arrivalTime || '',
				arrivalTimezone: flight?.arrivalTimezone || '',
				flightNumber: flight?.flightNumber || '',
				carrierCode: flight?.carrierCode || '',
				bookingClass: flight?.bookingClass || '',
				cabin: flight?.cabin || '',
				fareId: flight?.fareId || '',
				fareCode: flight?.fareCode || '',
				fareMethod: flight?.fareMethod || '',
				fareBasis: flight?.fareBasis || '',
				inventory: flight?.inventory || '',
				extRefCode: flight?.extRefCode || '',
				extRefSystem: flight?.extRefSystem || '',
				system: flight?.system || '',
				booking: flight?.booking || '',
				hasOptionPlus: flight?.hasOptionPlus || false,
			};
		};

		state?.selected?.items.forEach((item) => {
			if (item?.type !== 'flight') return;
			const outFlight = item?.outbound;
			const inFlight = item?.inbound;

			outboundParams = getParams(outFlight);
			inboundParams = getParams(inFlight);
		});

		const paxParams = state.bookingDetails.pax.map((pax) => ({
			ref: pax?.ref || null,
			firstName: pax?.firstName || null,
			middleName: pax?.middleName || null,
			lastName: pax?.lastName || null,
			dob: pax?.dob || null,
			title: pax?.title || null,
			gender: pax?.gender || null,
		}));

		return {
			pax: paxParams,
			outbound: outboundParams ?? [],
			inbound: inboundParams ?? [],
		};
	}, [state?.selected, state?.bookingDetails?.pax]);

	// fetch baggage options
	const { data, error, isLoading } = useFetch({
		key: 'baggage',
		method: 'POST',
		useBody: true,
		params: baggageParams,
		config: {
			enabled: !!baggageParams,
		},
		format: (res) => res?.data,
		onData: () => {
			// reset loading state
			if (state?.isLoading) {
				setBookingState(category, {
					...state,
					isLoading: false,
				});
			}
		},
	});

	const buildDefaultBaggage = (baggage, pax) => {
		if (!pax?.length) return [];
		if (!baggage?.totalIncludedWithFlight) return [];

		const bags = [];

		// build infant defaults
		const infantRefs = pax
			.filter((pax) => pax?.ref && pax?.type?.toLowerCase() === 'infant')
			.map((pax) => pax?.ref);
		if (infantRefs?.length && baggage?.infantAllowedBags) {
			const weightForInfants = !isNaN(parseFloat(baggage?.infantWeight))
				? baggage?.infantWeight
				: baggage?.weight;
			bags.push({
				weight: weightForInfants,
				pax: infantRefs.map((ref) => ({
					ref: ref,
					quantity: baggage.totalIncludedWithFlight,
				})),
			});
		}

		// build adult/youth/child defaults
		const otherRefs = pax
			.filter((pax) => !infantRefs.includes(pax?.ref))
			.map((pax) => pax?.ref);
		if (otherRefs?.length) {
			const existingItemIdx = bags.findIndex(
				(opt) => opt?.weight === baggage?.weight
			);
			switch (existingItemIdx) {
				case -1: // bag hasn't already been added
					bags.push({
						weight: baggage?.weight,
						pax: otherRefs.map((ref) => ({
							ref: ref,
							quantity: baggage.totalIncludedWithFlight,
						})),
					});
					break;

				default: // bag was already added for infants - update pax ids
					bags[existingItemIdx] = {
						weight: baggage?.weight,
						pax: [
							...bags[existingItemIdx].pax,
							...otherRefs.map((ref) => ({
								ref: ref,
								quantity: baggage.totalIncludedWithFlight,
							})),
						],
					};
					break;
			}
		}

		return bags;
	};

	// add inclusive baggage to the baggage booking state
	useEffect(() => {
		if (!paxInfoWithType?.length) return;
		if (typeof data === 'undefined') return;

		// prevent overwriting existing selection
		if (state?.baggage?.outbound?.length || state?.baggage?.inbound?.length) {
			setHasParsedIncludedBags(true);
			return;
		}

		// only set default baggage if some are included with the flight
		if (
			!(
				data?.outbound?.totalIncludedWithFlight ||
				data?.inbound?.totalIncludedWithFlight
			)
		) {
			setHasParsedIncludedBags(true);
			return;
		}

		const defaultBaggage = {};
		if (data?.outbound) {
			defaultBaggage.outbound = buildDefaultBaggage(
				data.outbound,
				paxInfoWithType
			);
		}

		if (data?.inbound) {
			defaultBaggage.inbound = buildDefaultBaggage(
				data.inbound,
				paxInfoWithType
			);
		}

		const newState = {
			...state,
			shouldBuildParams: true,
			baggage: defaultBaggage,
		};
		setBookingState(category, newState, 'SET_INCLUDED_BAGGAGE');

		setTimeout(() => {
			setHasParsedIncludedBags(true);
		}, 1000);
	}, [data, paxInfoWithType, state?.baggage]);

	const onContinue = () => {
		const nextStep = getNextStep(steps, pathname);

		// merge the flight params here
		navigate(nextStep.to);
		mergeFlightsParams();
	};

	const handleSelection = (selected, instance, passengerRef) => {
		if (!selected || !instance || !passengerRef) return;

		const otherItems = state?.baggage?.[instance]?.filter(
			(opt) => opt?.weight !== selected?.weight
		);

		// other passengers with the selected bag weight
		const existingItem = state?.baggage?.[instance]?.find(
			(opt) => opt?.weight === selected?.weight
		);
		const otherPaxWithWeight = existingItem?.pax?.filter(
			(pax) => pax?.ref && pax.ref !== passengerRef
		);

		if (!selected?.pax?.quantity) {
			// remove passenger from baggage list
			const newBaggage = [
				...(otherItems?.length > 0 ? otherItems : []),
				otherPaxWithWeight?.length > 0
					? {
							weight: selected?.weight,
							pax: otherPaxWithWeight,
					  }
					: null,
			].filter((item) => item);

			const newState = {
				...state,
				shouldBuildParams: true,
				baggage: {
					...state?.baggage,
					[instance]: newBaggage,
				},
			};

			setBookingState(category, newState, 'SET_BAGGAGE');
			return;
		}

		let updatedPax = [
			{
				ref: passengerRef,
				quantity: selected?.pax?.quantity,
			},
		];
		// add other passenger selection details
		if (otherPaxWithWeight?.length) {
			updatedPax = [
				...(otherPaxWithWeight?.length ? otherPaxWithWeight : []),
				...updatedPax,
			];
		}

		const newBaggage = [
			...(otherItems?.length > 0 ? otherItems : []),
			{
				weight: selected?.weight,
				pax: updatedPax,
			},
		];

		const newState = {
			...state,
			shouldBuildParams: true,
			baggage: {
				...state?.baggage,
				[instance]: newBaggage,
			},
		};

		setBookingState(category, newState, 'SET_BAGGAGE');
	};

	const { handleBack, previousStep } = useBackNavigate(pageSteps);

	// disable continue if loading, or failed to fetch baggage
	const continueIsDisabled =
		state?.isLoading || state?.previewLoading || isLoading || !!error;

	return (
		<BookingPageWrapper
			title="Baggage"
			steps={pageSteps}
			category={category}
			onContinue={onContinue}
			previousPage={previousStep}
			loadingText="Loading baggage options..."
			disablePreviewFetch={!hasParsedIncludedBags}
			continueIsDisabled={continueIsDisabled}
		>
			<PageWrapper
				error={error}
				loading={isLoading}
				loaderClassName="h-96 border border-lighter-grey"
				errorClassName="border border-lighter-grey"
				loadingText="Fetching baggage options..."
			>
				{paxInfoWithType?.length > 0 && (
					<div className="flex flex-col gap-8">
						{paxInfoWithType.map((passenger) => (
							<Suspense
								fallback={<Skeleton className="w-full h-40" />}
								key={`${passenger?.firstName}-${passenger?.ref}`}
							>
								<BaggageFlights
									passenger={passenger}
									options={data || null}
									baggageParams={baggageParams}
									handleSelection={handleSelection}
									selectedOptions={state?.baggage || null}
								/>
							</Suspense>
						))}
					</div>
				)}
				<div className="flex flex-col items-start justify-between gap-2 mt-5 md:px-0 lg:flex-row lg:items-center">
					<Button
						label="Continue"
						onClick={onContinue}
						variant="supporting-yellow"
						className="hidden md:flex"
						disabled={continueIsDisabled}
					/>
					<Button
						type="button"
						disableAnimation
						variant="unstyled"
						onClick={handleBack}
						iconName="arrow-left"
						className="flex-row-reverse"
						label={`Back to ${previousStep?.name}`}
						labelClassName="font-normal"
						disabled={state?.isLoading || state?.previewLoading || isLoading}
					/>
				</div>
			</PageWrapper>
		</BookingPageWrapper>
	);
}
export default BaggagePage;
