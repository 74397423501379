import React from 'react';
import PropTypes from 'prop-types';

import { Text, Button } from '@/components/common';

function AssignedSeatsPreview({
	assignedSeats = [],
	changeHandler,
	keepSectorOpen,
}) {
	if (!assignedSeats.length) return null;
	return (
		<div className="w-full  flex flex-col gap-1 items-start justify-start xl:items-end">
			<Text className="tracking-normal">Selected Seats</Text>
			<Text className="flex gap-1 items-center justify-end font-bold">
				<Text as="span" className="font-bold">
					{assignedSeats.map((seat) => seat?.seat).join(', ')}
				</Text>
				<Button
					disableAnimation
					variant="unstyled"
					onClick={changeHandler}
					disabled={keepSectorOpen}
					className="font-bold underline underline-offset-4 tracking-normal cursor-pointer"
				>
					(Change)
				</Button>
			</Text>
		</div>
	);
}

AssignedSeatsPreview.propTypes = {
	assignedSeats: PropTypes.array,
	changeHandler: PropTypes.func,
	keepSectorOpen: PropTypes.bool,
};

export default AssignedSeatsPreview;
