import { useState, useEffect } from 'react';
import { useEventListener } from '@/hooks';

function useMediaQuery({ query, defaultMatches = false }) {
	const [isMatch, setIsMatch] = useState(defaultMatches);
	const [mediaQueryList, setMediaQueryList] = useState(null);

	useEffect(() => {
		const list = window.matchMedia(query);
		setMediaQueryList(list);
		setIsMatch(list.matches);
	}, [query]);

	useEventListener(
		'change',
		(event) => setIsMatch(event.matches),
		mediaQueryList
	);

	return isMatch;
}

export default useMediaQuery;
