import qs from 'qs';
import { format, add } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';

import { useBookingStore } from '@/store';
import { useRemoveQueries, useUrlParams, useSearchSave } from '@/hooks';

/**
 * @typedef {Object} SearchParams
 * @property {string} startDate - The start date
 * @property {string} endDate - The end date
 * @property {number} adults - The number of adults
 * @property {number} children - The number of children
 * @property {number} infants - The number of infants
 * @property {number} youths - The outbound departure
 * @property {string} outboundDepart - The outbound departure
 * @property {string} outboundArrive - The outbound arrival
 * @property {string} inboundDepart - The inbound departure
 * @property {string} inboundArrive - The inbound arrival
 * @property {number} plusMinusDays - The plus minus days
 * */

/**
 * @name useManageFlightState
 * @description Manages the flight state and updates the state when the search, date or number of passengers change
 * @returns {SearchParams} searchParams - The search params
 * @example
 * import { useManageFlightState } from '@/hooks';
 * const { searchParams } = useManageFlightState();
 * */

function useManageFlightState() {
	const { params } = useUrlParams();
	const { removeQueries } = useRemoveQueries();
	const { setBookingState } = useBookingStore();
	const state = useBookingStore((store) => store.flights);

	const { getSearch, saveSearch } = useSearchSave();

	const savedSearch = getSearch('flights') ?? null;

	const queryString = qs.stringify(params?.default);

	const isOneWay = useMemo(() => {
		const values = params?.default;

		if (!values) return false;

		const outbound = values?.outboundDepart && values?.outboundArrive;
		const inbound = values?.inboundDepart && values?.inboundArrive;

		return outbound && !inbound ? true : false;
	}, [params?.default]);

	// search changed
	const searchChanged = useMemo(() => {
		if (!queryString) return false;
		if (!savedSearch && queryString) return true;

		return queryString !== savedSearch;
	}, [savedSearch, queryString]);

	const pax = state?.bookingDetails?.pax ?? [];

	const newState = {
		...state,
		error: null,
		isLoading: false,
		shouldBuildParams: false,
		previewLoading: false,
		questions: undefined,
		usePeople: true,
		preview: undefined,
		baggage: undefined,
		isOneWay: isOneWay,
		optionPlusOptions: undefined,
		selectedSeats: undefined,
	};

	// handle state update when the number of passengers change
	const handlePeopleChange = useCallback(
		(currState) => {
			if (!pax?.length > 0) return currState;

			const who = params.default?.who ?? {};

			const formatedWho = Object.keys(who).reduce((acc, type) => {
				const value = type === 'adults' ? 1 : 0;
				acc[type] = who[type] ? parseInt(who[type].split(':')[0]) : value;
				return acc;
			}, {});

			// check if the who values have reduced and remove the extra pax
			const totalWho = Object.keys(formatedWho).reduce((acc, type) => {
				const value = formatedWho[type];
				const paxCount = parseInt(value);
				acc += paxCount;
				return acc;
			}, 0);

			let newState = {
				...currState,
				usePeople: true,
				selected: {
					...currState.selected,
					people: formatedWho,
				},
			};

			// if the new total who is less than the total pax, we have decreased the number of passengers
			if (totalWho < pax?.length) {
				// remove the last pax
				const newPax = pax.slice(0, totalWho);

				// remove the last passengers based on the total who
				const newPassengers = newState?.passengerDetails?.passengers?.slice(
					0,
					totalWho
				);

				newState = {
					...newState,
					usePeople: true,
					bookingDetails: {
						...newState.bookingDetails,
						pax: newPax,
					},
					passengerDetails: {
						...newState.passengerDetails,
						passengers: newPassengers,
					},
				};

				return newState;
			}

			return newState;
		},
		[pax, params.default?.who]
	);

	// update the state when the search changes
	const handleUpdateState = useCallback(() => {
		// if the search has not changed, do nothing
		if (!searchChanged) return;

		// copy current state
		let state = newState;

		// if only the date changed, clear everything except the selected and passenger details
		if (state?.selected?.isDateChange) {
			const hasOneWayFare = state?.selected?.selectedFare?.outbound; // check if oneway fare is selected
			const hasReturnFare = state?.selected?.selectedFare?.inbound; // check if return fare is selected

			const hasAllFares = hasOneWayFare && hasReturnFare;

			let newSelected = {
				...state?.selected,
				isDateChange: false,
			};

			// if the search is one way and has a fare, trigger update fares
			if (state?.isOneWay && hasOneWayFare) {
				newSelected = {
					...newSelected,
					updateFares: true,
				};
			}

			// if the search is return and has all fares, trigger update fares
			if (!state?.isOneWay && hasAllFares) {
				newSelected = {
					...newSelected,
					updateFares: true,
				};
			}

			state = {
				...state,
				selected: newSelected,
			};

			
		} else {
			// if the search changed and isDateChange is false, clear everything except the passenger details
			state = {
				...state,
				selected: undefined,
			};
		}

		// handle the people change
		state = handlePeopleChange(state);

		// update the state
		setBookingState('flights', state, 'FLIGHTS_SEARCH');

		// remove the queries
		removeQueries([
			'flight-search',
			'booking-preview',
			'option-plus',
			'baggage',
			'seating',
		]);

		// save the search
		saveSearch(queryString, 'flights');
	}, [searchChanged, queryString, newState, handlePeopleChange]);

	useEffect(() => {
		handleUpdateState();
	}, [handleUpdateState]);

	const searchParams = useMemo(() => {
		const { default: values } = params;

		const formatWho = Object.keys(values?.who ?? {}).reduce((acc, key) => {
			const value = values?.who?.[key];
			acc[key] = value ? parseInt(value.split(':')[0]) : 0;
			return acc;
		}, {});

		return {
			startDate: values?.startDate ?? format(new Date(), 'yyyy-MM-dd'),
			endDate:
				isOneWay && values?.startDate
					? values.startDate
					: values?.endDate ??
					  format(add(new Date(), { days: 7 }), 'yyyy-MM-dd'),
			...formatWho,
			outboundDepart: values?.outboundDepart
				? values.outboundDepart.split(':')[0]
				: '',
			outboundArrive: values?.outboundArrive
				? values.outboundArrive.split(':')[0]
				: '',
			inboundDepart: values?.inboundDepart
				? values.inboundDepart.split(':')[0]
				: '',
			inboundArrive: values?.inboundArrive
				? values.inboundArrive.split(':')[0]
				: '',
			plusMinusDays: 0,
		};
	}, [params, isOneWay]);

	return { searchParams };
}

export default useManageFlightState;
