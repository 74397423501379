import { useMediaQuery } from '@/hooks';

/**
 * @typedef {"sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl"} Breakpoints
 */

const breakpoints = {
	xxs: '375px',
	sm: '639px',
	md: '767px',
	lg: '1023px',
	xl: '1279px',
	'2xl': '1599px',
	'3xl': '1679px',
	'4xl': '1719px',
};

/**
 * @name useBreakPoint
 * @description Returns a boolean if the breakpoint is matched
 * @param {Breakpoints} breakpoint
 * @returns {boolean}
 * @example
 * const isSm = useBreakPoint('sm');
 */

function useBreakpoint(breakpoint) {
	const isMatch = useMediaQuery({
		query: `(max-width: ${breakpoints[breakpoint]})`,
	});

	return isMatch;
}

export default useBreakpoint;
