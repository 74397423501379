import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import invoke from '@/lib/invoke';
import { ENDPOINTS } from '@/lib/api';
import useValueCallback from './useValueCallback';

/**
 * @typedef {Object} UseFetchParams
 * @property {import("@/lib/api").endpointKey} key
 * @property {Object} params
 * @property {boolean} [useBody] - A boolean that determines if the params should be used as a body
 * @property {Object} config
 * @property {string} endpoint
 * @property {import("@/lib/invoke").InvokeOptions["method"]} [method]
 * @property {(res: unknown)=> void} format
 * @property {string} customEndpoint - custom endpoint
 * @property {(param: string)=> string} formatEndpoint - A function that formats the endpoint before it is used for example adding a path param
 * @property {(res: unknown)=> void} onData - A callback function that is called when the data is fetched
 * */

/**
 * @name useFetch
 * @description A custom hook that uses react-query to fetch data from the API
 * @param {UseFetchParams} params
 * @returns {import('@tanstack/react-query').UseQueryResult}
 * */

function useFetch({
	key,
	params,
	useBody = false,
	format,
	method = 'GET',
	config = {},
	endpoint,
	formatEndpoint,
	customEndpoint,
	headers,
	onData,
}) {
	const isDev = import.meta.env.DEV;
	const [isCancelled, setIsCancelled] = useState(false);

	const defaultEndpoint = useMemo(() => {
		if (endpoint) return endpoint;

		const keyExists = ENDPOINTS.find((e) => e.key === key);

		if (!keyExists) return null;

		return keyExists.endpoint;
	}, [ENDPOINTS, endpoint, key]);

	const query = useQuery({
		queryKey: [key, JSON.stringify(params)],
		queryFn: async ({ signal }) => {
			if (!customEndpoint && !defaultEndpoint) {
				throw new Error(`The key ${key} does not exist in the ENDPOINTS array`);
			}

			if (defaultEndpoint && customEndpoint) {
				throw new Error('You cannot have both default and custom endpoint');
			}

			// listen for query cancellation
			setIsCancelled(signal?.aborted === true);
			if (signal)
				signal.addEventListener('abort', () => {
					setIsCancelled(signal?.aborted === true);
				});

			// fetch data
			const { res, error } = await invoke({
				signal,
				method,
				params: useBody ? null : params,
				data: useBody ? params : null,
				endpoint:
					typeof formatEndpoint === 'function'
						? formatEndpoint(defaultEndpoint)
						: defaultEndpoint,
				customUrl: customEndpoint,
				headers: headers,
			});

			if (error) {
				if (isDev) console.error('error', error);
				throw new Error(error?.message);
			}

			if (res === undefined) return null;

			if (format && typeof format === 'function') {
				return format(res);
			}

			return res;
		},
		...config,
	});

	useValueCallback(onData, query.data);

	return {
		...query,
		isCancelled: isCancelled,
	};
}

export default useFetch;
