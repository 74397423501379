import {
	Dialog,
	Button,
	Heading,
	Accordion,
	AccordionItem,
	AccordionTrigger,
	AccordionContent,
	Icon,
	Text,
} from '@/components/common';
import PropTypes from 'prop-types';
import { useState } from 'react';

function FilterFields({ onClose, onReset }) {
	return (
		<div className="w-full mx-auto max-w-[1200px]">
			<div className="flex justify-between items-center">
				<Button
					hideIcon
					variant="unstyled"
					label="Reset filters"
					onClick={() => {
						onReset();
						onClose();
					}}
					className="underline underline-offset-4"
				/>

				<Button
					variant="outline"
					className="border-core-blue"
					onClick={onClose}
				>
					See results
				</Button>
			</div>
		</div>
	);
}

FilterFields.propTypes = {
	onReset: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

function CarFilters({ resetFilters }) {
	const [showOptions, setShowOptions] = useState(false);

	return (
		<div className="flex over-flow-x-scroll lg:mx-2">
			<Dialog
				as="drawer"
				size="screen"
				hideCloseBtn
				position="bottom"
				className="w-full lg:hidden"
				contentClassName="h-auto overflow-y-scroll hide-scrollbar rounded-t-2xl"
				renderTrigger={({ DialogTrigger, onOpen }) => (
					<DialogTrigger
						onClick={onOpen}
						className="w-full py-4 text-left flex justify-center items-center gap-2 bg-lighter-grey rounded-none md:hidden border-none"
					>
						<Icon name="filter" className="mr-2" />
						<Text className="text-base font-normal leading-tight">
							More filter options
						</Text>
					</DialogTrigger>
				)}
			>
				{({ CloseButton, onClose }) => (
					<div className="h-full hide-scrollbar overflow-y-scroll flex flex-col gap-4 justify-end relative">
						<div className="sticky flex items-center justify-between top-0 left-0">
							<Heading as="h3" className="text-2xl text-left font-body">
								More filter options
							</Heading>
							<CloseButton className="relative border-none top-0 right-0" />
						</div>

						<FilterFields
							onClose={onClose}
							onReset={() => {
								resetFilters();
								onClose();
							}}
						/>
					</div>
				)}
			</Dialog>

			<Accordion
				className="w-full hidden md:block my-8"
				collapsible
				value={showOptions ? 'car-filters' : null}
				onValueChange={() => setShowOptions(!showOptions)}
			>
				<AccordionItem className="border-none" value="car-filters">
					<AccordionTrigger
						hideChevron
						className="w-full py-4 flex items-center justify-center bg-lighter-grey rounded-sm"
					>
						<Icon name="filter" className="mr-2" />
						<Text className="text-base font-normal leading-tight">
							More filter options
						</Text>
					</AccordionTrigger>
					<AccordionContent
						value="car-filters"
						className="bg-lighter-grey p-12"
					>
						<FilterFields
							onClose={() => setShowOptions(false)}
							onReset={() => {
								resetFilters();
								setShowOptions(false);
							}}
						/>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</div>
	);
}

CarFilters.propTypes = {
	resetFilters: PropTypes.func.isRequired,
};

export default CarFilters;
