import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import {
	Accordion,
	AccordionItem,
	AccordionContent,
	AccordionTrigger,
	Text,
	Slider,
	Input,
	Button,
} from '@components/common';
import { useDebounce } from 'use-debounce';

function RangeFilter({
	min,
	max,
	name,
	title,
	value,
	className,
	inputPrefix,
	onFilterChange,
	renderSelectedValues,
	disabled,
}) {
	const [localValue, setLocalValue] = useState(value || []);
	const [accordionActiveValue, setAccordionActiveValue] = useState('');

	// watch for input value changes
	useEffect(() => {
		if (value[0] != localValue[0] || value[1] != localValue[1]) {
			setLocalValue(value); // handle reset
		}
	}, [value[0], value[1]]);

	const [debouncedValue] = useDebounce(localValue, 500);

	// fire filter change on debounce
	useEffect(() => {
		// ignore change due to value reset
		if (debouncedValue[0] == value[0] && debouncedValue[1] == value[1]) return;

		onFilterChange({ name, value: debouncedValue });
	}, [debouncedValue]);

	const handleInputChange = (e) => {
		let newMin = value[0];
		let newMax = value[1];
		if (e.name === 'minimum') {
			newMin = e.value;
		}
		if (e.name === 'maximum') {
			newMax = e.value;
		}

		setLocalValue([newMin, newMax]);
	};

	const isAllZero = localValue.every((val) => parseInt(val) === 0);
	const isAllDefault = localValue[0] == min && localValue[1] == max;
	const isAllEmpty = localValue[0] === '' && localValue[1] === '';

	return (
		<div className={cn('w-full', className)}>
			<Accordion
				value={accordionActiveValue}
				onValueChange={setAccordionActiveValue}
				collapsible
			>
				<AccordionItem value={name} className="group py-2">
					<AccordionTrigger asChild>
						<Text as="span" variant="bold" className="p-0 m-0 tracking-normal">
							{title}
						</Text>
					</AccordionTrigger>
					<div>
						{localValue.length > 0 &&
						!(isAllDefault || isAllZero || isAllEmpty) ? (
							<Text
								variant="muted"
								className="text-sm flex gap-1 group-data-[state=open]:hidden group-data-[state=closed]:flex transition-all ease-in-out"
							>
								{renderSelectedValues(localValue)}
								<span
									role="button"
									aria-label="change"
									onClick={() => setAccordionActiveValue(name)}
									className="underline cursor-pointer underline-offset-4"
								>
									(change)
								</span>
							</Text>
						) : (
							<Text
								as="span"
								variant="muted"
								className="text-sm flex gap-1 group-data-[state=open]:hidden group-data-[state=closed]:flex transition-all ease-in-out"
							>
								Any
								<span
									role="button"
									aria-label="change"
									onClick={() => setAccordionActiveValue(name)}
									className="underline cursor-pointer underline-offset-4"
								>
									(change)
								</span>
							</Text>
						)}
					</div>
					<AccordionContent
						value={name}
						className="group-data-[state=open]:p-2 flex flex-col gap-8"
						childClassName="pb-0"
					>
						<Slider
							value={localValue}
							min={min}
							max={max}
							type="range"
							onValueChange={setLocalValue}
							className="mt-4"
							disabled={disabled}
						/>

						<div className="grid grid-cols-1 gap-2 lg:grid-cols-2 mt-8">
							<RangeInput
								label="Minimum"
								name="minimum"
								value={localValue[0]}
								defaultValue={min}
								min={min}
								max={max}
								onChange={handleInputChange}
								prefix={inputPrefix}
								placeholder="Min"
								wrapperClassName="w-full"
								disabled={disabled}
							/>
							<RangeInput
								label="Maximum"
								name="maximum"
								value={localValue[1]}
								defaultValue={max}
								min={min}
								max={max}
								onChange={handleInputChange}
								prefix={inputPrefix}
								placeholder="Max"
								wrapperClassName="w-full"
								disabled={disabled}
							/>
						</div>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</div>
	);
}

function RangeInput({
	label,
	name,
	value,
	min,
	max,
	defaultValue,
	onChange,
	placeholder,
	disabled,
	...props
}) {
	return (
		<div className="flex flex-col gap-1">
			<Text variant="muted" className="text-sm">
				{label}
			</Text>
			<div className={cn('flex flex-col items-start gap-1')}>
				<Input
					name={name}
					type="number"
					placeholder={placeholder}
					value={value}
					min={min}
					max={max}
					onChange={(e) => onChange({ name, value: e.target.value })}
					disabled={disabled}
					{...props}
				/>
				<div className={disabled ? 'cursor-not-allowed opacity-75' : null}>
					<Button
						type="button"
						variant="unstyled"
						className="underline underline-offset-3"
						onClick={() => onChange({ name, value: defaultValue || 0 })} // clear the value
						disabled={disabled}
					>
						Any
					</Button>
				</div>
			</div>
		</div>
	);
}

RangeFilter.propTypes = {
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	value: PropTypes.array.isRequired,
	className: PropTypes.string,
	onFilterChange: PropTypes.func.isRequired,
	renderSelectedValues: PropTypes.func.isRequired,
};

export default RangeFilter;
