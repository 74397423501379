import PropTypes from 'prop-types';
import { Badge, Button, Heading, Text } from '@/components/common';
import { cn } from '@/lib/utils';

function ResultCard({ result }) {
	return (
		<div className="relative flex flex-col justify-between w-full h-auto gap-2 px-6 bg-white rounded-lg shadow xs:flex-row">
			<div className="flex flex-col justify-between gap-2">
				{/* Card content */}
				<div
					className={cn(
						'flex flex-col gap-2 pb-0 pt-6',
						result.thumbnail && 'pt-0 xs:pt-6'
					)}
				>
					{(result?.assetTypes?.length > 0 ||
						result?.categories?.length > 0) && (
						<div className="flex flex-row flex-wrap gap-2 w-fit z-4 top-7.5 inset-x-5 md:top-10 md:left-10">
							{result?.assetTypes?.length > 0
								? result.assetTypes.map((type, idx) => (
										<Badge
											key={`asset-type-${idx}`}
											text={type}
											variant="black"
											textClassName="font-body font-bold text-xs uppercase leading-extra-snug"
										/>
								  ))
								: null}

							{result?.categories?.length > 0
								? result.categories.map((category, idx) => (
										<Badge
											key={`category-${idx}`}
											text={category}
											variant="supporting-coral"
											textClassName="font-body font-bold text-xs uppercase leading-extra-snug"
										/>
								  ))
								: null}
						</div>
					)}

					{result?.pagetitle && (
						<Heading
							as="h3"
							className="self-stretch text-3xl font-bold text-core-blue leading-extra-tight tracking-extra-tight font-body"
						>
							{result.pagetitle}
						</Heading>
					)}

					{result?.longtitle && (
						<Heading
							as="h3"
							className="self-stretch text-lg font-bold tracking-tighter text-core-blue font-body leading-loose-snug"
						>
							{result.longtitle}
						</Heading>
					)}

					{result?.introtext && (
						<Text className="text-base font-normal tracking-tighter opacity-75 text-dark-grey leading-snuger font-body">
							{result.introtext}
						</Text>
					)}
				</div>

				{/* Card footer */}
				<div className={cn(result?.file ? 'pb-7.5' : 'pb-6')}>
					{result?.file && (
						<Button
							label="View & Download"
							variant="unstyled"
							tagName="a"
							href={result.file}
							target="_blank"
							rel="noopener noreferrer"
							download
							className="p-0 underline w-fit hover:opacity-60"
							labelClassName="text-base leading-snug tracking-tighter"
							hideIcon
						/>
					)}
				</div>
			</div>

			{result.thumbnail && (
				<div class="w-full xs:h-full xs:max-w-1/2 xs:w-50 shrink-0 -order-1 xs:order-none pt-6 xs:pt-0">
					<div class="relative h-full overflow-hidden aspect-w-16 aspect-h-9 xs:aspect-w-1 xs:aspect-h-1">
						<img
							class="absolute inset-0 block object-contain w-full h-full"
							src={result.thumbnail}
							alt={result.pagetitle}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

ResultCard.propTypes = {
	result: PropTypes.object.isRequired,
};

export default ResultCard;
