import { useFormContext, useFormState } from 'react-hook-form';

import { Required } from '@/lib/utils';
import { AddressForm, FormField } from '@/components/common';

function ContactForm() {
	const { control, watch, setValue } = useFormContext();
	const { errors } = useFormState({ control });
	return (
		<div className="flex flex-col space-y-4">
			<FormField
				as="input"
				name="contact.phone"
				label="Telephone"
				type="tel"
				placeholder="Telephone"
				autoComplete="tel"
				control={control}
				validation={[Required]}
				wrapperClassName="w-1/2"
				errors={errors}
				showRequired
			/>

			<FormField
				as="input"
				name="contact.email"
				type="email"
				label="Email address"
				placeholder="Email address"
				autoComplete="email"
				control={control}
				validation={[Required]}
				errors={errors}
				showRequired
			/>
			{/* <FormField as="address" name="contact.address" control={control} /> */}
			<AddressForm
				watch={watch}
				errors={errors}
				control={control}
				setValue={setValue}
				nameObj="contact.address"
			/>
		</div>
	);
}

export default ContactForm;
