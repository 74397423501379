import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	FlightBlock,
	HotelBlock,
	RailBlock,
	TransferBlock,
} from '@/components/holidays';
import {
	Button,
	Heading,
	Icon,
	Text,
	BookingPageWrapper,
} from '@/components/common';

const flightData = [
	{
		type: 'outbound',
		flightNo: '283',
		departure: 'LGW',
		arrival: 'YVR',
		departureTime: '2023-09-01T09:50:00+00:00',
		arrivalTime: '2023-09-01T19:26:00+00:00',
		duration: '13hrs 1mins',
		stops: '0',
		flightNumber: '283',
		carrier: 'TS',
		aircraft: '',
		cabin: {
			code: 'CLUB',
			name: 'Club ',
		},
		pricing: {
			adult: '1300.63',
			child: '0.00',
			infant: '0.00',
		},
		sectors: [
			{
				departure: 'LGW',
				arrival: 'YYZ',
				departureTime: '2023-09-01T09:50:00+00:00',
				arrivalTime: '2023-09-01T12:45:00+00:00',
				duration: null,
				stops: '',
				flightNumber: '283',
				carrier: 'TS',
				aircraft: '32Q',
				cabin: {
					code: 'CLUB',
					name: 'Club ',
				},
				pricing: {
					adult: '',
					child: '',
					infant: '',
				},
				isSeatResPossible: false,
			},
			{
				departure: 'YYZ',
				arrival: 'YVR',
				departureTime: '2023-09-01T17:20:00+00:00',
				arrivalTime: '2023-09-01T19:26:00+00:00',
				duration: null,
				stops: '',
				flightNumber: '7909',
				carrier: 'TS',
				aircraft: '295',
				cabin: {
					code: 'CLUB',
					name: 'Club ',
				},
				pricing: {
					adult: '',
					child: '',
					infant: '',
				},
				isSeatResPossible: false,
			},
		],
	},
	{
		type: 'outbound',
		flightNo: '438',
		departure: 'LGW',
		arrival: 'YVR',
		departureTime: '2023-09-01T09:50:00+00:00',
		arrivalTime: '2023-09-01T19:26:00+00:00',
		duration: '13hrs 1mins',
		stops: '0',
		flightNumber: '283',
		carrier: 'TS',
		aircraft: '',
		cabin: {
			code: 'CLUB',
			name: 'Club ',
		},
		pricing: {
			adult: '1300.63',
			child: '0.00',
			infant: '0.00',
		},
		sectors: [
			{
				departure: 'LGW',
				arrival: 'YYZ',
				departureTime: '2023-09-01T09:50:00+00:00',
				arrivalTime: '2023-09-01T12:45:00+00:00',
				duration: null,
				stops: '',
				flightNumber: '283',
				carrier: 'TS',
				aircraft: '32Q',
				cabin: {
					code: 'CLUB',
					name: 'Club ',
				},
				pricing: {
					adult: '',
					child: '',
					infant: '',
				},
				isSeatResPossible: false,
			},
			{
				departure: 'YYZ',
				arrival: 'YVR',
				departureTime: '2023-09-01T17:20:00+00:00',
				arrivalTime: '2023-09-01T19:26:00+00:00',
				duration: null,
				stops: '',
				flightNumber: '7909',
				carrier: 'TS',
				aircraft: '295',
				cabin: {
					code: 'CLUB',
					name: 'Club ',
				},
				pricing: {
					adult: '',
					child: '',
					infant: '',
				},
				isSeatResPossible: false,
			},
		],
	},

	{
		type: 'return',
		flightNo: '282',
		departure: 'YYZ',
		arrival: 'LGW',
		departureTime: '2023-09-06T19:50:00+00:00',
		arrivalTime: '2023-09-07T07:55:00+00:00',
		duration: '7hrs 5mins',
		stops: '0',
		flightNumber: '282',
		carrier: 'TS',
		aircraft: '',
		cabin: {
			code: 'CLUB',
			name: 'Club ',
		},
		pricing: {
			adult: '1038.72',
			child: '0.00',
			infant: '0.00',
		},
		sectors: [
			{
				departure: 'YYZ',
				arrival: 'LGW',
				departureTime: '2023-09-06T19:50:00+00:00',
				arrivalTime: '2023-09-07T07:55:00+00:00',
				duration: null,
				stops: '',
				flightNumber: '282',
				carrier: 'TS',
				aircraft: '32Q',
				cabin: {
					code: 'CLUB',
					name: 'Club ',
				},
				pricing: {
					adult: '',
					child: '',
					infant: '',
				},
				isSeatResPossible: false,
			},
		],
	},
];

function MultiStepTravelPlan({ steps = [] }) {
	const navigate = useNavigate();

	const { outbound, returns } = useMemo(() => {
		const outbound = flightData.filter((flight) => flight.type === 'outbound');
		const returns = flightData.filter((flight) => flight.type === 'return');
		return { outbound, returns };
	}, [flightData]);

	const handleContinue = () => {
		navigate('/booking/holidays/passenger-details');
	};

	return (
		<BookingPageWrapper
			error={null}
			steps={steps}
			title="My Travel Plan"
			onContinue={handleContinue}
		>
			<div className="px-5 md:px-0">
				<Heading as="h3" className="font-body text-3xl mb-2">
					Toronto City Break
				</Heading>
				<Text className="flex items-center gap-3">
					<Icon name="moon" className="w-5 h-5" />
					<Text as="span" className="font-bold">
						7 Nights
					</Text>
				</Text>
			</div>
			<div className="bg-core-light-blue text-white px-4 py-6 rounded-lg flex flex-col items-start gap-5 justify-between mx-5 lg:flex-row lg:items-center md:mx-0">
				<Text className="font-bold text-lg">
					Need help with changes to this holiday?
				</Text>
				<Text className="flex flex-col items-start gap-3 lg:flex-row lg:items-center">
					<Button
						hideIcon
						variant="unstyled"
						label="Talk to an expert"
						onClick={() => console.log('Talk to an expert')}
						className="underline underline-offset-4 font-bold w-auto text-white"
					/>
					<Text
						as="a"
						href="tel:02076169184"
						className="flex items-center gap-2"
					>
						<Icon name="phone" className="w-5 h-5" />
						<Text as="span" className="font-bold">
							Call 0207 616 9184
						</Text>
					</Text>
				</Text>
			</div>

			{/* blocks */}
			<div className="flex flex-col gap-5">
				{outbound.map((flight, index) => (
					<FlightBlock
						flight={flight}
						key={flight?.flightNumber}
						title={`Outbound Flights (${index + 1} of ${outbound.length})`}
						renderStep={() => <Text>Step {1 + index} of 10</Text>}
					/>
				))}

				<TransferBlock
					transfer={{
						date: '2023-09-01T09:50:00+00:00',
						to: 'Calgary Downtown',
						from: 'Calgary Airport',
					}}
					renderStep={() => <Text>Step 3 of 10</Text>}
				/>

				<HotelBlock
					title="Accommodation"
					renderStep={() => <Text>Step 4 of 10</Text>}
				/>

				<RailBlock
					rail={{
						to: 'Vancouver',
						from: 'West Lake Louise',
						date: '2023-09-02T09:50:00+00:00',
					}}
					title="Rail Journey"
					renderStep={() => <Text>Step 5 of 10</Text>}
				/>

				<HotelBlock
					title="Accommodation"
					renderStep={() => <Text>Step 6 of 10</Text>}
				/>

				<TransferBlock
					transfer={{
						date: '2023-09-01T09:50:00+00:00',
						to: 'Calgary Downtown',
						from: 'Calgary Airport',
					}}
					renderStep={() => <Text>Step 7 of 10</Text>}
				/>

				<HotelBlock
					title="Accommodation"
					renderStep={() => <Text>Step 8 of 10</Text>}
				/>

				<TransferBlock
					transfer={{
						date: '2023-09-01T09:50:00+00:00',
						to: 'Calgary Airport',
						from: 'Calgary Downtown',
					}}
					renderStep={() => <Text>Step 9 of 10</Text>}
				/>

				{returns.map((flight, index) => (
					<FlightBlock
						flight={flight}
						title="Return Flight"
						key={flight?.flightNumber}
						renderStep={() => <Text>Step {10 + index} of 10</Text>}
					/>
				))}
			</div>
		</BookingPageWrapper>
	);
}
export default MultiStepTravelPlan;
