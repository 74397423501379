import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Map, Icon, Text, Dialog, MapMarker } from '@/components/common';
import { HotelCard } from '@/components/hotels';

function HotelsMap({ results }) {
	// exclude hotels without lat and lng
	const mappableHotels = useMemo(() => {
		if (!results?.length) return [];

		return results.filter(
			(hotel) =>
				typeof hotel?.lat === 'number' && typeof hotel?.lng === 'number'
		);
	}, [results]);

	return (
		<div className="h-[375px] md:h-[500px] lg:h-[800px] w-full z-0 relative">
			<Map
				instance="hotels"
				defaultCenter={
					mappableHotels?.length
						? [mappableHotels[0]?.lat, mappableHotels[0]?.lng]
						: [45.5019, -73.5674]
				}
				zoomLevel={14}
				className="z-[0]"
			>
				{mappableHotels.map((hotel, index) => (
					<Dialog
						size="xl"
						key={index}
						as="drawer"
						hideCloseBtn
						position="right"
						contentClassName="p-0 overflow-x-auto"
						renderTrigger={({ onOpen }) => (
							<MapMarker
								position={[hotel.lat, hotel.lng]}
								onClick={() => {
									onOpen();
								}}
								icon="hotel-marker"
							/>
						)}
					>
						{({ CloseButton }) => (
							<div className="relative">
								<CloseButton className="top-0 right-0 border-0 rounded-none text-white bg-dark-grey hover:bg-darker-grey" />
								<HotelCard className="gap-0" variant="popup" result={hotel} />
							</div>
						)}
					</Dialog>
				))}
			</Map>
			<div className="w-fit px-4 rounded-full shadow-md bg-white flex items-center gap-1 absolute bottom-4 left-4 z-[100]">
				<Icon name="hotel-marker" className="w-5" />
				<Text className="text-sm">Hotel</Text>
			</div>
		</div>
	);
}

HotelsMap.propTypes = {
	results: PropTypes.array.isRequired,
};

export default HotelsMap;
