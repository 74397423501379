import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { randomString, getObjectValue } from '@/lib/utils';

/**
 * @typedef {Object} LoopWrapperProps
 * @property {Array} list
 * @property {string} itemKey
 * @property {(item: any, idx:number)=> React.ReactNode} children
 * @property {()=> React.ReactNode} renderFallback
 */

/**
 * @name LoopWrapper
 * @description Loop over a list and render children
 * @param {LoopWrapperProps} props
 * @returns {React.JSX.Element | null}
 * @example
 * <LoopWrapper list={list} itemKey="id">
 *     {(item, idx) => <div>{item.name}</div>}
 * </LoopWrapper>
 */

function LoopWrapper({ list = [], itemKey, children, renderFallback }) {
	// validate list
	if (!Array.isArray(list) || !list.length > 0) {
		if (typeof renderFallback === 'function') {
			return renderFallback();
		}

		return null;
	}

	return (
		<Fragment>
			{list.map((item, idx) => {
				let key = randomString('LOOP', 14);

				if (itemKey && getObjectValue(item, itemKey)) {
					key = getObjectValue(item, itemKey);
				}

				if (itemKey && !getObjectValue(item, itemKey)) {
					key = randomString(itemKey, 12);
				}

				return <Fragment key={key}>{children(item, idx)}</Fragment>;
			})}
		</Fragment>
	);
}

LoopWrapper.propTypes = {
	list: PropTypes.array.isRequired,
	itemKey: PropTypes.string,
	children: PropTypes.func,
};

LoopWrapper.defaultProps = {
	itemKey: null,
	children: () => null,
};

export default LoopWrapper;
