import PropTypes from 'prop-types';
import { Suspense, lazy } from 'react';

import { LoopWrapper } from '../../../common';
import CardSkeleton from '../../molecules/cardSkeleton';

const Card = lazy(() => import('../../molecules/card'));

function CarList({ pickup, dropoff, cars, handleCarSelect }) {
	if (!(pickup?.code && dropoff?.code && cars?.length)) return;

	return (
		<div className="w-full h-fit space-y-2.5 md:space-y-5">
			<LoopWrapper list={cars} itemKey="name">
				{(car) => (
					<Suspense fallback={<CardSkeleton />}>
						<Card
							car={car}
							className="border border-lighter-grey px-5 md:px-6"
							onSelect={() => {
								handleCarSelect({ car, pickup, dropoff });
							}}
						/>
					</Suspense>
				)}
			</LoopWrapper>
		</div>
	);
}

CarList.propTypes = {
	pickup: PropTypes.object.isRequired,
	dropoff: PropTypes.object.isRequired,
	cars: PropTypes.array.isRequired,
	handleCarSelect: PropTypes.func.isRequired,
};

export default CarList;
