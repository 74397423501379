import { cn } from '@/lib/utils';
import { SeatCell } from '@components/flights';
import { LoopWrapper } from '@/components/common';

function SeatRow({ row, hView, onChange, selectedSeats, availability }) {
	const isWingStart = row?.exteriorType === 'wing_start';

	if (row?.type === 'cockpit') return null;
	return (
		<div
			className={cn(
				'group/row flex relative px-4',
				{ 'flex-col-reverse px-0 py-4': hView },
				isWingStart && !hView
					? 'before:[content=""] before:absolute before:w-6 before:h-[32rem] before:translate-y-1/2 before:bg-lighter-grey before:-left-2 before:[clip-path:polygon(100%_0,100%_38%,100%_100%,0_100%,0%_2%)] after:[content=""] after:absolute after:w-6 after:h-[32rem] after:translate-y-1/2 after:bg-lighter-grey after:-right-2 after:[clip-path:polygon(100%_2%,100%_38%,100%_100%,0_100%,0%_0%)]'
					: '',
				isWingStart && hView
					? 'before:[content=""] before:absolute before:h-6 before:w-[32rem] before:translate-x-1/2 before:bg-lighter-grey before:-top-2 before:[clip-path:polygon(100%_0,100%_38%,100%_100%,0_100%,2%_0%)] after:[content=""] after:absolute after:h-6 after:w-[32rem] after:translate-x-1/2 after:bg-lighter-grey after:-bottom-2 after:[clip-path:polygon(100%_0%,100%_38%,100%_100%,2%_100%,0%_0%)]'
					: ''
			)}
		>
			<LoopWrapper list={row?.cells} itemKey="row">
				{(cell) => {
					const cellsWithAvailability = row?.cells?.map((cell) => ({
						...cell,
						available: availability[cell?.seatNumber]?.status === 'AVAILABLE',
					}));

					const cellWithAvailability = {
						...cell,
						available: availability[cell?.seatNumber]?.status === 'AVAILABLE',
					};
					return (
						<SeatCell
							hView={hView}
							onChange={onChange}
							cell={cellWithAvailability}
							cells={cellsWithAvailability}
							selectedSeats={selectedSeats}
							availability={availability[cell?.seatNumber] ?? null}
						/>
					);
				}}
			</LoopWrapper>
		</div>
	);
}
export default SeatRow;
