import { useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ENDPOINTS } from '@/lib/api';
import invoke from '@/lib/invoke';

function useInfiniteFetch({
	key,
	params,
	limit = 10,
	sort,
	endpoint,
	config,
	format,
}) {
	const [isInitialFetch, setIsInitialFetch] = useState(true);
	const [isCancelled, setIsCancelled] = useState(false);
	const defaultEndpoint = useMemo(() => {
		if (endpoint) return endpoint;
		return ENDPOINTS.find((e) => e.key === key).endpoint;
	}, [ENDPOINTS, endpoint, key]);

	const query = useInfiniteQuery({
		queryKey: [key],
		queryFn: async ({ signal, pageParam = 1 }) => {
			// listen for query cancellation
			setIsCancelled(signal?.aborted === true);
			if (signal)
				signal.addEventListener('abort', () => {
					setIsCancelled(signal?.aborted === true);
				});

			// fetch data
			const { res, error } = await invoke({
				signal,
				params: {
					...params,
					sort: sort,
					pagination: {
						page: pageParam,
						limit: limit || 10,
					},
				},
				method: 'GET',
				endpoint: defaultEndpoint,
			});
			if (error) {
				return Promise.reject(error);
			}

			setIsInitialFetch(false);

			if (format && typeof format === 'function') {
				return format(res);
			}
			return res;
		},
		refetchOnWindowFocus: false,
		...config,
		getNextPageParam: (lastPage, allPages) => lastPage?.data?.nextPage,
	});

	return {
		...query,
		isCancelled: isCancelled,
		isInitialFetch: isInitialFetch,
	};
}

export default useInfiniteFetch;
