import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { format, isAfter, isBefore, isValid } from 'date-fns';

import {
	useFetch,
	useUrlParams,
	useCancelQueryRequest,
	useManageFlightState,
	useMetaDescription,
} from '@/hooks';
import {
	Text,
	Button,
	PageWrapper,
	BookingPageWrapper,
} from '@/components/common';
import { useBookingStore } from '@/store';
import { withPortal } from '@/HOCs/withPortal';
import { FlightList } from '@/components/flights';
import { flightSteps as steps } from '@/lib/steps';
import { cn, getNextStep, navigate } from '@/lib/utils';
import { FlightSelectionProvider } from '@/context';

const formatResults = (results) => {
	if (!results) return null;

	if (Object.keys(results).length === 0) return null;

	const newResults = Object.keys(results).map((key) => {
		const result = results[key];
		const flights = Object.keys(results[key]).map((flightKey) => {
			const flight = result[flightKey];
			return {
				...flight,
			};
		});
		return {
			departureTime: key,
			flights,
		};
	});

	return newResults;
};

/**
 * @typedef {Object} FlightSearchResultsProps
 * @property {{
 * 	"flights": import("@/routes/routes").step[],
 * } | import("@/routes/routes").step[]} steps
 *
 */

/**
 * @name FlightSearchResults
 * @description Renders the flight search results page
 * @param {FlightSearchResultsProps} props
 * @returns { React.JSX.Element}
 * @example
 * <FlightSearchResults steps={steps} />
 * */

function FlightSearchResults({ portalData }) {
	useMetaDescription(['Search Results', 'Flights', 'Canadian Affair']);

	const { pathname, search } = useLocation();
	const { defaultParamSize, updateParams } = useUrlParams();

	const category = 'flights';

	// get the flights booking state
	const { setBookingState } = useBookingStore();
	const state = useBookingStore((store) => store[category]);

	const pageSteps = useMemo(() => {
		if (!steps) return null;
		const stepsIsArray = Array.isArray(steps);
		return !stepsIsArray ? steps[category] : steps;
	}, [steps]);

	const { searchParams } = useManageFlightState();

	const {
		error,
		isLoading,
		data: results,
	} = useFetch({
		key: 'flight-search',
		params: searchParams,
		requestName: 'flight-search',
		config: {
			enabled: defaultParamSize > 0 && !!searchParams,
		},
		onData: () => {
			if (state?.error) {
				setBookingState('flights', {
					error: null,
					isLoading: false,
					preview: undefined,
				});
			}

			if (
				state?.isOneWay &&
				state?.selected?.outbound &&
				!state?.selected?.updateFares
			) {
				const newState = {
					selected: {
						...state?.selected,
						updateFares: true,
					},
				};

				setBookingState('flights', newState, 'UPDATE_FARES_ONDATA');
			}

			if (
				!state?.isOneWay &&
				state?.selected?.outbound &&
				state?.selected?.inbound &&
				!state?.selected?.updateFares
			) {
				const newState = {
					selected: {
						...state?.selected,
						updateFares: true,
					},
				};

				setBookingState('flights', newState, 'UPDATE_FARES_ONDATA');
			}
		},
	});

	// second request with plusMinusDays = 3

	const { data } = useFetch({
		key: 'flight-search',
		params: {
			...searchParams,
			plusMinusDays: 3,
		},
		config: {
			enabled: defaultParamSize > 0 && !!searchParams,
		},
		format: (res) => res?.data?.facetStats,
	});

	const { cancelRequest } = useCancelQueryRequest(['flight-search']);

	const { outbound, inbound } = useMemo(() => {
		const items = results?.data;
		if (items) {
			return {
				outbound: formatResults(items?.outbound) ?? [],
				inbound: formatResults(items?.inbound) ?? [],
			};
		}
		return {
			outbound: [],
			inbound: [],
		};
	}, [results, formatResults]);

	const handleDateChange = (date, key) => {
		const currentStartDate = new Date(searchParams?.startDate);
		const currentEndDate = new Date(searchParams?.endDate);

		const newParams = {
			[key]: format(date, 'yyyy-MM-dd'),
		};

		// also update start/end date if date change moves outside of range
		switch (key) {
			case 'startDate':
				if (state?.isOneWay) break;

				const isAfterEnd =
					isValid(currentEndDate) && isAfter(date, currentEndDate);
				if (isAfterEnd) {
					newParams.endDate = format(date, 'yyyy-MM-dd');
				}
				break;
			case 'endDate':
				const isBeforeStart =
					isValid(currentStartDate) && isBefore(date, currentStartDate);
				if (isBeforeStart) {
					newParams.startDate = format(date, 'yyyy-MM-dd');
				}
				break;
		}

		updateParams(newParams);
	};

	let customError = null;

	setTimeout(() => {
		if (!defaultParamSize > 0) {
			customError = new Error('Please add your search parameters.');
		}
	}, 1000);

	const handleContinue = () => {
		if (!state?.selected) return;
		let nextStep = getNextStep(pageSteps, pathname).to;

		if (search) {
			nextStep = `${nextStep}${search}`;
		}
		navigate(nextStep);
	};

	const hasBothOutboundAndInbound = state?.isOneWay
		? state?.selected?.outbound
		: state?.selected?.outbound && state?.selected?.inbound;

	const getFromPrices = (direction, selectedDate) => {
		// use pricing from plusMinusDays=3 response
		const pricing =
			typeof data?.[direction] === 'object' ? { ...data[direction] } : {};

		// show pricing for selected day price from plusMinusDays=0 to prevent inconsistency
		if (typeof selectedDate === 'string') {
			pricing[selectedDate] =
				results?.data?.facetStats?.[direction]?.[selectedDate];
		}

		return pricing;
	};

	// calculate from pricing
	const { outbound: outboundFromPrices, inbound: inboundFromPrices } =
		useMemo(() => {
			const fromPricing = { outbound: {}, inbound: {} };

			if (searchParams?.startDate) {
				fromPricing.outbound = getFromPrices(
					'outbound',
					searchParams?.startDate
				);
			}

			if (!state?.isOneWay && searchParams?.endDate) {
				fromPricing.inbound = getFromPrices('inbound', searchParams?.endDate);
			}

			return fromPricing;
		}, [
			data,
			results?.data?.facetStats?.outbound,
			searchParams?.startDate,
			searchParams?.endDate,
			state?.isOneWay,
		]);

	const continueIsDisabled =
		!hasBothOutboundAndInbound ||
		state?.previewLoading ||
		state?.isLoading ||
		isLoading ||
		state?.error;

	return (
		<BookingPageWrapper
			steps={steps}
			category={category}
			title="Select your flights"
			onContinue={handleContinue}
			contentClassName="px-0 sm:px-5"
			titleWrapperClassName="px-5 sm:px-0"
			childrenClassName="gap-4 md:gap-6 lg:gap-8"
			pageHeaderClassName="lg:text-9xl"
			handleCancelRequest={cancelRequest}
			continueIsDisabled={continueIsDisabled}
			error={!defaultParamSize > 0 && !error ? customError : error}
			portalData={portalData}
			disablePreviewFetch={isLoading}
		>
			<PageWrapper
				loading={isLoading}
				loadingText="fetching your flights..."
				loaderClassName="h-[60dvh]"
				className="space-y-6"
			>
				<FlightSelectionProvider flights={results?.data}>
					<div
						className={cn(
							'flex flex-col gap-2 border-b-4 border-core-blue',
							!state?.isOneWay ? 'sm:border-b-0' : ''
						)}
					>
						<FlightList
							results={outbound}
							extra={results?.extra}
							instance="outbound"
							dayPrices={outboundFromPrices}
							date={searchParams?.startDate}
							handleDateChange={(date) => handleDateChange(date, 'startDate')}
						/>
					</div>
					{state?.isOneWay ? null : (
						<>
							<hr className="hidden border-b-4 sm:block border-core-blue" />
							<div className="flex flex-col gap-2 border-b-4 border-core-blue sm:border-b-0">
								<FlightList
									title="Return"
									results={inbound}
									extra={results?.extra}
									instance="inbound"
									dayPrices={inboundFromPrices}
									date={searchParams?.endDate}
									handleDateChange={(date) => handleDateChange(date, 'endDate')}
								/>
							</div>
						</>
					)}
				</FlightSelectionProvider>
			</PageWrapper>
			<div className="px-5 sm:px-0">
				<Button
					type="button"
					label="Continue"
					onClick={handleContinue}
					variant="supporting-yellow"
					className="justify-between hidden md:flex w-fit"
					disabled={continueIsDisabled}
				/>

				<Text className="text-dark-grey/65 w-full text-sm leading-5 lg:w-[600px] mt-5">
					All flight times displayed are local. Prices shown INCLUDE ALL taxes
					and fees. Name changes are not permitted.
				</Text>
				<Text className="text-dark-grey/65 w-full text-sm leading-5 lg:w-[600px] mt-5">
					Flight changes fees where applicable are per person, per direction,
					plus difference in fare (if applicable).
				</Text>
				<Text className="text-dark-grey/65 w-full text-sm leading-5 lg:w-[600px] mt-5">
					Cancellation fees where applicable are per person, per ticket (whether
					one-way or roundtrip). No refunds within 24 hours of departure or when
					a portion of the ticket has already been used. Changes and
					cancellations are not permitted within 24 hours of departure.
				</Text>
			</div>
		</BookingPageWrapper>
	);
}

// portaled to booking-flights-search-page
export default withPortal(FlightSearchResults, 'booking-flights-search-page');
