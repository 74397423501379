import { format } from 'date-fns';
import { cn, currencyFormatter, formatLocalDate } from '@/lib/utils';
import { FlightBlockListItemDetails } from '@components/flights';
import { Text, Button, Icon, Route } from '@components/common';
import { useState } from 'react';
import { useDisclosure } from '@/hooks';
// import { updateBookingState } from '@/store/slices/booking';

/**
 * this component is a flight item for the flight block
 * It will also have the outbound & return flights
 * return flights only show if the outbound is selected
 */

function FlightBlockListItem({ flight }) {
	const { onToggle, isOpen } = useDisclosure(false);
	const [selectedFlights, setSelectedFlights] = useState({
		outbound: null,
		return: null,
	});

	const handleSelectFlight = (key, flight) => {
		// key should be outbound or return
		setSelectedFlights((prev) => ({
			...prev,
			[key]: flight,
		}));

		// update the booking state with the selected flights
		// dispatch(updateBookingState({ key: "holidays", value:{flights: selectedFlights} }));
	};
	return (
		<section
			className={cn(
				'w-full h-auto border-b border-lighter-grey last:border-b-0 py-5',
				{ 'border-b-0': isOpen }
			)}
		>
			<div className="grid grid-cols-1 gap-6 px-3 lg:grid-cols-5 lg:px-5">
				<div className=" col-span-1 w-full flex flex-col items-center gap-5 lg:col-span-4 lg:flex-row lg:items-end">
					<div className="col-span-1 w-24 h-24 border border-lighter-grey-color flex items-center justify-center">
						<Icon name="air-transat" className="w-20 h-12" />
					</div>
					<div className="flex-1 px-0 w-full">
						<Text className="font-semibold mb-5">Flights</Text>
						<div className="w-full">
							<div className="flex flex-col items-start gap-2 w-full lg:flex-row lg:items-center">
								{flight?.departureTime ? (
									<Text className="font-bold">
										{formatLocalDate(flight?.departureTime, 'dd MMM')}
									</Text>
								) : null}
								<Route
									className="flex-1"
									from="London (LGW"
									to="Toronto (YYZ)"
								/>
							</div>
							<div className="flex flex-col items-start gap-2 w-full lg:flex-row lg:items-center">
								{flight?.departureTime ? (
									<Text className="font-bold">
										{formatLocalDate(flight?.departureTime, 'dd MMM')}
									</Text>
								) : null}
								<Route
									className="flex-1"
									from="London (LGW"
									to="Toronto (YYZ)"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="col-span-1 flex flex-col items-start gap-2 lg:items-end w-full">
					<Text className="text-right text-lg font-bold">
						{currencyFormatter({
							amount: 120,
							params: {
								hideDecimal: true,
							},
						})}
					</Text>
					<Button
						hideIcon
						disableAnimation
						variant={isOpen ? 'outline' : 'core-blue'}
						label={isOpen ? 'Cancel' : 'Select flights'}
						onClick={onToggle}
						className={cn('w-fit', {
							'hover:border-2 hover:border-lighter-grey': isOpen,
						})}
					/>
				</div>
			</div>
			{isOpen && (
				<>
					{selectedFlights.outbound ? (
						<FlightBlockListItemDetails
							flightType="return"
							sectors={flight?.sections}
							selectedFlights={selectedFlights}
							handleSelectFlight={handleSelectFlight}
						/>
					) : (
						<FlightBlockListItemDetails
							flightType="outbound"
							sectors={flight?.sectors}
							selectedFlights={selectedFlights}
							handleSelectFlight={handleSelectFlight}
						/>
					)}
				</>
			)}
		</section>
	);
}
export default FlightBlockListItem;
