import { useEffect, useMemo, useState } from 'react';

import { ErrorHandler, Navbar, PageWrapper } from '@/components/common';
import { useFetch, useUrlParams } from '@/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBookingStore } from '@/store';
import qs from 'qs';

function ConfirmBooking() {
	const { params: urlParams } = useUrlParams();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const category = pathname ? pathname.split('/')[2] : null;
	const [paymentError, setPaymentError] = useState(null);
	const PAYMENT_ERROR_STATUS_CODE = 1;

	const bookingState = useBookingStore((state) => state[category]);

	const confirmParams = useMemo(() => {
		if (!category) return null;
		if (!urlParams?.default?.ref) return null;
		if (!bookingState?.paymentDetails?.bookingId) return null;
		if (typeof bookingState?.paymentDetails?.overview?.deposit === 'undefined')
			return null;

		// check for payment failure status code - do not send
		const status = urlParams?.default?.Status
			? parseInt(urlParams?.default?.Status)
			: null;
		if (status === PAYMENT_ERROR_STATUS_CODE) return null;

		return {
			// general booking details
			pax: bookingState?.bookingDetails?.pax,
			contactDetails: bookingState?.bookingDetails?.contactDetails,
			marketing: bookingState?.bookingDetails?.marketing,

			// book 3 details
			sessionId: urlParams?.default?.ref,
			bookingId: bookingState?.paymentDetails?.bookingId,
			viewKey: bookingState?.paymentDetails?.viewKey,
			deposit: bookingState?.paymentDetails?.overview?.deposit,
			fullPrice: bookingState?.paymentDetails?.overview?.total,
			transactionRef: urlParams?.default?.PaymentTransactionReference,
			transactionNumber: urlParams?.default?.Id,
		};
	}, [bookingState, urlParams?.default, category]);

	// set custom error message on payment error Status redirect
	useEffect(() => {
		if (typeof urlParams?.default?.Status === 'undefined') return;

		const status = urlParams?.default?.Status
			? parseInt(urlParams?.default?.Status)
			: null;
		if (status !== PAYMENT_ERROR_STATUS_CODE) {
			setPaymentError(null);
			return;
		}

		setPaymentError({
			message: 'Something went wrong while processing your payment.',
		});
	}, [urlParams?.default?.Status]);

	const { data, error, isLoading } = useFetch({
		method: 'POST',
		key: 'booking-confirm',
		params: confirmParams,
		useBody: true,
		config: {
			enabled: !!confirmParams,
			retryOnWindowFocus: false,
			retry: false,
		},
	});

	// redirect to booking complete page on success
	useEffect(() => {
		if (!data?.success) return;

		navigate(
			`/booking/${category}/booking-complete?${qs.stringify({
				id: bookingState?.paymentDetails?.bookingId,
			})}`
		);
	}, [data?.success]);

	// redirect to homepage if landing on page without booking info
	useEffect(() => {
		if (!urlParams?.default) return;

		// wait until bookingState has loaded - error prop should always be defined once initialized
		if (typeof bookingState?.error === 'undefined') return;

		// do not redirect if we have a payment error code
		const status = urlParams?.default?.Status
			? parseInt(urlParams?.default?.Status)
			: null;
		if (status === PAYMENT_ERROR_STATUS_CODE) return;

		if (!bookingState?.paymentDetails?.bookingId) {
			window.top.location.href = '/';
		}
	}, [bookingState, urlParams?.default]);

	// show loading if we don't have an error, or request is loading/hasn't been sent yet
	const showLoading = !(error || paymentError) && (isLoading || !confirmParams);

	const customErrorMsg = useMemo(() => {
		const type = paymentError?.message ? 'Payment' : 'Booking';
		const context = paymentError?.message
			? 'processing your payment'
			: 'confirming your booking';

		// add booking id to email link
		const mailToQs = qs.stringify({
			subject: `Website ${type} Error Query${
				bookingState?.paymentDetails?.bookingId
					? ` - Booking #${bookingState?.paymentDetails?.bookingId}`
					: ''
			}`,
		});

		return (
			<>
				Apologies, we have encountered an error while {context}.{' '}
				{bookingState?.paymentDetails?.bookingId ? (
					<>
						Your booking ID is #{bookingState?.paymentDetails?.bookingId}.{' '}
						<br />
					</>
				) : null}
				Please{' '}
				<a
					href={`mailto:enquiries@canadianaffair.com?${mailToQs}`}
					target="_top"
					className="underline underline-offset-2"
				>
					email us
				</a>{' '}
				for assistance.
			</>
		);
	}, [paymentError, bookingState?.paymentDetails?.bookingId]);

	return (
		<>
			<Navbar />
			<PageWrapper
				error={error || paymentError}
				className="relative min-h-screen bg-light-grey"
				loading={showLoading}
				loadingTitle="Confirming your booking"
				loadingText="Please do not leave this page."
				loaderClassName="h-[calc(100vh-4.5rem)]"
				hideSearchAgainOnError
				errorHandlerDescription={customErrorMsg}
			>
				{/* Show error message if loading finished, but 'success' missing from response */}
				<ErrorHandler description={customErrorMsg} hideSearchAgain />
			</PageWrapper>
		</>
	);
}

export default ConfirmBooking;
