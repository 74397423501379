import { useCallback, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { Text } from '@components/common';
import { cn, ONE_TO_NINE, Required, ZERO_TO_NINE } from '@/lib/utils';

const SimpleSelect = ({
	name,
	label = null,
	wrapperClassName = null,
	options = [],
}) => {
	const isAdults = name.includes('adults');
	const defaultValue = isAdults
		? { label: '1', value: 1 }
		: { label: '0', value: 0 };
	const { field } = useController({
		name,
		defaultValue,
	});

	return (
		<div className={wrapperClassName}>
			{label}
			<select
				// name={name}
				{...field}
				value={field?.value?.value}
				onChange={(e) => {
					field.onChange({
						label: e.target.value.toString(),
						value: e.target.value,
					});
				}}
				className="border rounded border-border-color col-span-1"
			>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
};

function WhoFormContent({
	name,
	options,
	className,
	wrapperClassName,
	formFieldsContainerClassName,
	showFormFieldFooter = false,
	combinedLabel,
}) {
	const {
		watch,
		setError,
		clearErrors,
		formState: { errors },
	} = useFormContext();

	const validateTotalPeople = useCallback(
		(_, formValues) => {
			const keys = options.map((option) => option.value);

			let totalPeople = 0;

			Object.keys(formValues[name]).forEach((key) => {
				if (!keys.includes(key)) return;

				const value = formValues?.[name]?.[key]?.value;
				totalPeople += value ? parseInt(value) : 0;
			});

			const isValid = totalPeople <= 9;
			if (!isValid) {
				setError('who', {
					message: 'You cannot have more than 9 people per booking',
				});
			}

			if (isValid && errors?.who) {
				clearErrors('who');
			}

			return isValid;
		},
		[options, errors?.who, name]
	);

	useEffect(() => {
		const sub = watch((values, { name }) => {
			if (name.includes('adults')) {
				Required(values[name]);
			}

			validateTotalPeople(null, values);
		});
		return () => sub.unsubscribe();
	}, [watch, validateTotalPeople, errors, clearErrors]);

	// const getValidations = (option) => {
	// 	if (option?.value === 'adults') {
	// 		return [Required, validateTotalPeople];
	// 	}
	// 	return [validateTotalPeople];
	// };

	return (
		<div
			className={cn(
				'flex flex-col items-start justify-start w-full h-full gap-5 p-5',
				className && className
			)}
		>
			{combinedLabel && combinedLabel}

			<div
				className={cn(
					'flex flex-col items-start justify-start gap-5',
					formFieldsContainerClassName && formFieldsContainerClassName
				)}
			>
				{options.map((option) => (
					<div key={option?.label} className="flex flex-col w-full gap-2">
						<SimpleSelect
							name={`${name}.${option?.value}`}
							options={option?.value === 'adults' ? ONE_TO_NINE : ZERO_TO_NINE}
							wrapperClassName={cn(
								'flex justify-between w-full gap-6',
								wrapperClassName && wrapperClassName
							)}
							label={
								showFormFieldFooter ? null : (
									<div className="flex items-center col-span-2">
										<label className="w-full grow shrink">
											{option?.label}
										</label>
									</div>
								)
							}
						/>

						{/* <FormField
							as="select"
							control={control}
							name={`${name}.${option?.value}`}
							validation={getValidations(option)}
							options={option?.value === 'adults' ? ONE_TO_NINE : ZERO_TO_NINE}
							isSearchable={isSearchable}
							wrapperClassName={cn(
								'grid grid-cols-3 w-full gap-3',
								wrapperClassName && wrapperClassName
							)}
							{...(!showFormFieldFooter && {
								label: (
									<div className="flex items-center col-span-2">
										<label className="w-full col-span-2 grow shrink">
											{option?.label}
										</label>
									</div>
								),
							})}
							{...(showFormFieldFooter && {
								placeholder:
									option?.value[0].toUpperCase() + option?.value.slice(1),
								footer: option?.label.split('(').pop().split(')')[0],
							})}
							{...(typeof captureMenuScroll === 'boolean' && {
								captureMenuScroll: captureMenuScroll,
							})}
						/> */}

						<ErrorMessage
							errors={errors}
							name={`${name}.${option?.value}`}
							render={({ message }) =>
								message ? (
									<Text as="span" variant="error">
										&uarr; {message}
									</Text>
								) : null
							}
						/>
					</div>
				))}
			</div>
			<ErrorMessage
				errors={errors}
				name="who"
				render={({ message }) =>
					message ? (
						<Text as="span" variant="error">
							&uarr; {message}
						</Text>
					) : null
				}
			/>
		</div>
	);
}

export default WhoFormContent;
