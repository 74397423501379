import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { addDays, format, isValid } from 'date-fns';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
	Text,
	Button,
	DatePlaceHolder,
	BookingCalendar,
} from '@/components/common';
import { useDisclosure, useFetch } from '@/hooks';
import FormField from '@/components/common/molecules/formfield';
import {
	cn,
	Required,
	ONE_TO_NINE,
	ZERO_TO_NINE,
	HOTEL_ONLY_MAX_DATE,
} from '@/lib/utils';

/**
 * @typedef {Object} BookingFormProps
 * @property {Object} params
 * @property {import("react-hook-form").UseWatchProps} watch
 * @property {import("react-hook-form").FieldErrors} errors
 * @property {import("react-hook-form").Control} control
 * @property {import("react-hook-form").SetFieldValue} setValue
 * @property {import("react-hook-form").UseFormClearErrors} clearErrors
 * @property {(params: unknown)=>void} handleSubmit
 * */

/**
 * @name BookingForm
 * @description A form to book a hotel
 * @param {BookingFormProps} props
 * @returns {React.JSX.Element}
 * @example
 * <BookingForm watch={watch} errors={errors} params={params}  />
 * */

function BookingForm({
	watch,
	errors,
	params,
	control,
	setError,
	setValue,
	clearErrors,
}) {
	const [roomsParams, setRoomParams] = useState(null);

	const { from, to } = watch('when');
	const roomPax = watch('rooms');

	const { isOpen, onToggle } = useDisclosure(() => {
		// show field summary
		if (from && to) {
			return true;
		}

		// show empty field
		if (!from && !to) {
			return true;
		}

		return false;
	});

	// const roomsList = Array.from({ length: rooms });

	useEffect(() => {
		const validStartDate = isValid(from) ? format(from, 'yyyy-MM-dd') : null;
		const validEndDate = isValid(to) ? format(to, 'yyyy-MM-dd') : null;

		if (!(params?.code && validStartDate && validEndDate)) {
			setRoomParams(null);
			return;
		}

		setRoomParams({
			code: params?.code,
			startDate: format(from, 'yyyy-MM-dd'),
			endDate: format(to, 'yyyy-MM-dd'),
			// rooms: roomPaxInfo,
		});
		// setRooms(roomPaxInfo.length);
	}, [params, to, from, JSON.stringify(roomPax)]);

	const roomPaxInfo = roomPax
		.map((room, idx) => {
			return {
				adults: room?.adults?.value,
				children: room?.children?.value,
				infants: room?.infants?.value,
			};
		})
		.filter(Boolean);

	const { data, isLoading } = useFetch({
		key: 'hotel-rooms',
		params: {
			...(roomsParams || {}),
			rooms: roomPaxInfo,
		},
		config: {
			enabled: !!roomsParams,
			retry: false,
		},
		format: (res) => {
			return res.data;
		},
	});

	const formatRoom = (room, key) => {
		return {
			label:
				room.name +
				(room.boardBasis
					? ` - ${room.boardBasisDisplayName || room.boardBasis}`
					: ''),
			value: room.code + (room.boardBasis ? `-${room.boardBasis}` : ''),
			type: room.type,
			key,
		};
	};

	const getAvailableRooms = (level) => {
		if (!data) return [];

		const clone = (obj) => JSON.parse(JSON.stringify(obj));

		const rooms = [];
		if (level === 0) {
			Object.keys(data).forEach((kk) => {
				rooms.push(formatRoom(data[kk], kk));
			});
		} else {
			// get all previous selected room keys in order
			const selectedRooms = roomPax
				.map((room) => room.room?.key)
				.filter(Boolean);

			// recursively get the next level rooms

			let parent = clone(data);

			selectedRooms.forEach((roomKey, _idx) => {
				let room = null;
				if (level === 0 || _idx === 0) {
					room = parent[roomKey];
				} else if (level === 1) {
					if (parent[roomKey]) {
						room = parent[roomKey];
					}
				} else {
					if (
						parent &&
						parent.rooms &&
						typeof parent.rooms[roomKey] !== 'undefined'
					) {
						room = parent.rooms[roomKey];
					}
				}

				if (room && room?.rooms) {
					parent = room;
				}
			});

			if (parent && parent.rooms && Object.keys(parent.rooms).length) {
				Object.keys(parent.rooms).forEach((kk) => {
					rooms.push(formatRoom(parent.rooms[kk], kk));
				});
			}
		}

		return rooms;
	};

	return (
		<div className="flex flex-col gap-4">
			{errors?.code ? (
				<Text as="p" className="my-2 text-red-500">
					{errors?.code?.message}
				</Text>
			) : null}
			<div className="flex flex-col items-center justify-center gap-3">
				<Text as="label" className="font-bold text-gray-700">
					Select a departure date
				</Text>
				<Controller
					name="when"
					control={control}
					render={({ field }) => {
						return isOpen ? (
							<DatePlaceHolder
								hideTime
								showIcon
								type="inputdate"
								handleClick={onToggle}
								className="cursor-pointer"
								selected={{ from: new Date(from), to: new Date(to) }}
							/>
						) : (
							<BookingCalendar
								className="w-full"
								code={params?.code}
								{...field}
								onChange={(range) => {
									field.onChange(range);

									if (range.to && range.from)
										setTimeout(() => {
											onToggle();
										}, 500);
								}}
								minDate={addDays(new Date(), 3)}
								maxDate={new Date(HOTEL_ONLY_MAX_DATE)}
							/>
						);
					}}
				/>
				<div className="flex flex-col w-full">
					<ErrorMessage
						errors={errors}
						name="when.from"
						render={({ message }) => (
							<Text as="span" variant="error" className="mt-2">
								&uarr; {message}
							</Text>
						)}
					/>
					<ErrorMessage
						errors={errors}
						name="when.to"
						render={({ message }) => (
							<Text as="span" variant="error" className="mt-2">
								&uarr; {message}
							</Text>
						)}
					/>
				</div>
			</div>

			{roomPaxInfo.map((_, idx) => (
				<div
					key={`booking-hotels-rooms-${idx}`}
					className="flex flex-col gap-4"
				>
					<div className="grid w-full grid-cols-2 gap-3 md:grid-cols-3">
						<FormField
							name={`rooms.${idx.toString()}.adults`}
							label={idx === 0 ? 'Who' : `Who room ${idx + 1}?`}
							as="select"
							options={ONE_TO_NINE}
							control={control}
							errors={errors}
							validation={[Required]}
							wrapperClassName="w-full lg:min-w-[124px]"
							placeholder="Adults"
							footer="Age 18+"
						/>

						<FormField
							name={`rooms.${idx.toString()}.children`}
							as="select"
							options={ZERO_TO_NINE}
							control={control}
							errors={errors}
							wrapperClassName="w-full lg:min-w-[124px] mt-6.25"
							placeholder="Children"
							footer="Age 2-17"
						/>

						<FormField
							name={`rooms.${idx.toString()}.infants`}
							as="select"
							options={ZERO_TO_NINE}
							control={control}
							errors={errors}
							className={cn('md:mt-6.25')}
							wrapperClassName="w-full col-span-2 md:col-span-1 lg:min-w-[124px]"
							placeholder="Infants"
							footer="Age < 2"
						/>
					</div>

					<FormField
						name={`rooms.${idx.toString()}.room`}
						as="select"
						control={control}
						errors={errors}
						validation={[Required]}
						wrapperClassName="h-full w-full col-span-2"
						labelClassName="whitespace-nowrap"
						placeholder="Select Room"
						options={getAvailableRooms(idx)}
						isLoading={isLoading}
						disabled={!roomsParams}
					/>
					{roomPaxInfo.length > 1 && (
						<Button
							hideIcon
							type="button"
							disableAnimation
							variant="unstyled"
							label="Remove room"
							className="h-auto underline border rounded-md w-fit border-border-color text-core-blue/70 underline-offset-4"
							labelClassName="text-sm font-normal hover:text-core-blue"
							onClick={() => {
								// get the current value of the rooms field
								const currentRooms = watch('rooms');

								// remove the room at the current index
								currentRooms.splice(idx, 1);

								// update the rooms field
								setValue('rooms', currentRooms);

								// clear errors
								clearErrors();
							}}
						/>
					)}
				</div>
			))}

			{roomPaxInfo.length < 3 && (
				<Button
					type="button"
					variant="unstyled"
					label="Add a room"
					onClick={() => {
						// setRooms((prev) => prev + 1);

						// update the rooms field
						const currentRooms = watch('rooms');
						const newRooms = [...currentRooms, {}];

						setValue('rooms', newRooms);

						// clear errors
						clearErrors();
					}}
					className="underline underline-offset-2 w-fit"
					hideIcon
				/>
			)}
		</div>
	);
}

BookingForm.propTypes = {
	params: PropTypes.shape({
		adults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		infants: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
};

BookingForm.defaultProps = {
	params: {},
	hideClearBtn: false,
};

export default BookingForm;
