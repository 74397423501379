import { useEffect, useRef } from 'react';

function useListener(eventName, handler, element = window) {
	const callbackRef = useRef(handler);

	useEffect(() => {
		callbackRef.current = handler;
	}, [handler]);

	useEffect(() => {
		const isSupported = element && element.addEventListener;
		if (!isSupported) return;

		const eventListener = (event) => callbackRef.current(event);
		element.addEventListener(eventName, eventListener);

		return () => {
			element.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element]);
}

export default useListener;
