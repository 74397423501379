import WhoTrigger from './WhoTrigger';
import WhoContent from './WhoContent';
import { useBreakpoint } from '@/hooks';
import { WHO_OPTIONS } from '@/lib/utils';
import { Button, Dialog, Drawer, buttonVariants } from '@components/common';
import { DrawerClose } from '@/components/common/atoms/drawer/CnDrawer';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { staticFlightSearchSchemaConfig } from '@/validationSchemas';

function WhoForm({ name, control, renderExtraLabel, triggerClassName }) {
	const isSm = useBreakpoint('sm');
	const watch = useWatch({ control, name });
	const { errors } = useFormState({ control });

	const { setError, clearErrors } = useFormContext();

	const validateSchema = async (values) => {
		try {
			clearErrors('who');

			await staticFlightSearchSchemaConfig.who.validate(values, {
				abortEarly: false,
			});
		} catch (error) {
			setError('who', error);
		}
	};

	return isSm ? (
		<Drawer
			dismissable={false}
			triggerClassName="group w-full"
			contentClassName="px-0 overflow-y-auto"
			renderTrigger={() => (
				<WhoTrigger
					name={name}
					values={watch}
					errors={errors}
					options={WHO_OPTIONS}
					triggerClassName={triggerClassName}
					renderExtraLabel={renderExtraLabel}
				/>
			)}
		>
			<WhoContent
				name={name}
				control={control}
				options={WHO_OPTIONS}
				isSearchable={false}
				captureMenuScroll={false}
				formFieldsContainerClassName="w-full"
			/>
			<hr className="my-2 border-lighter-grey" />
			<div className="px-5">
				<DrawerClose
					type="button"
					disabled={!!errors?.who}
					onClick={() => validateSchema(watch)}
					className={buttonVariants({
						className: 'w-full',
						variant: 'supporting-yellow',
					})}
				>
					Confirm
				</DrawerClose>
			</div>
		</Drawer>
	) : (
		<Dialog
			as="modal"
			size="sm"
			hideCloseBtn
			contentClassName="w-full h-auto bg-white p-8 relative"
			renderTrigger={({ DialogTrigger, onOpen }) => (
				<DialogTrigger onClick={onOpen}>
					<WhoTrigger
						name={name}
						values={watch}
						errors={errors}
						options={WHO_OPTIONS}
						renderExtraLabel={renderExtraLabel}
						triggerClassName={triggerClassName}
					/>
				</DialogTrigger>
			)}
		>
			{({ CloseButton, onClose }) => (
				<div className="space-y-3 h-fit">
					<CloseButton
						variant="square"
						className="absolute top-0 right-0 text-white bg-core-blue"
					/>

					<WhoContent
						name={name}
						control={control}
						options={WHO_OPTIONS}
						formFieldsContainerClassName="w-full"
					/>
					<hr className="my-2 border-lighter-grey" />

					<Button
						type="button"
						disabled={!!errors?.who}
						onClick={() => {
							validateSchema(watch);
							onClose();
						}}
						variant="supporting-yellow"
						className="text-base font-bold w-fit"
					>
						Confirm
					</Button>
				</div>
			)}
		</Dialog>
	);
}

export default WhoForm;
