import React from 'react';
import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { Text, CheckboxGroup } from '@components/common';
import { FilterCard } from '@/components/carhire';

/**
 * @typedef {Object} CarTypeFilterProps
 * @property {string} className
 * @property {string} itemClassName
 * @property {string} wrapperClassName
 * @property {Array} values
 * @property {Array<{label: string, value: string, disabled: boolean}>} options
 * @property {boolean} isValid
 * @property {boolean} hideLabel
 * @property {()=>void} onChange
 * */

/**
 * @name CarTypeFilter
 * @description A car type filter component
 * @param {CarTypeFilterProps} props
 * @returns {React.JSX.Element}
 * @example
 * <CarTypeFilter />
 * */

function CarTypeFilter({
	values,
	options,
	className,
	itemClassName,
	isValid,
	wrapperClassName,
	hideLabel,
	onChange,
	...props
}) {
	return (
		<CheckboxGroup
			options={options}
			onChange={onChange}
			values={values}
			className={cn(
				'grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3',
				className
			)}
			inputClassName="aspect-square border border-gray-400 h-8 rounded-full w-8"
			iconClassName="h-4 w-4"
			renderOptionChildren={({ option, checkboxEl }) => (
				<div
					key={option.value}
					className={cn('flex items-center gap-4 w-full', wrapperClassName, {
						'text-core-red': !isValid,
					})}
				>
					{checkboxEl}

					<Text
						as="label"
						htmlFor={option.value}
						className="flex flex-col gap-2 cursor-pointer items-start flex-1 grow shrink basis-0 md:items-center "
					>
						{option.label && !hideLabel && <Text>{option.label}</Text>}
						<FilterCard className={itemClassName} car={option} />
					</Text>
				</div>
			)}
			{...props}
		/>
	);
}

CarTypeFilter.propTypes = {
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	itemClassName: PropTypes.string,
	wrapperClassName: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
			disabled: PropTypes.bool,
		})
	),
	isValid: PropTypes.bool,
	hideLabel: PropTypes.bool,
};

CarTypeFilter.defaultProps = {
	options: [],
	isValid: true,
	hideLabel: false,
};

CarTypeFilter.displayName = 'CarTypeFilter';

export default CarTypeFilter;
