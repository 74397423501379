import PropTypes from 'prop-types';
import { Marker, Popup, useMapEvents } from 'react-leaflet';

import { useMapMarker } from '@/hooks';

function MapMarker({
	icon,
	iconUrl,
	onClick,
	position,
	isActive,
	children,
	iconWidth,
	iconHeight,
	iconAnchorPosX,
	iconAnchorPosY,
}) {
	const markerInfo = !iconUrl
		? { marker: icon }
		: {
				marker: 'custom',
				iconUrl: iconUrl,
				iconWidth: iconWidth,
				iconHeight: iconHeight,
				iconAnchorPosX: iconAnchorPosX,
				iconAnchorPosY: iconAnchorPosY,
		  };

	const map = useMapEvents({});
	const { activeIcon, defaultIcon } = useMapMarker(markerInfo);

	return (
		<Marker
			position={position}
			eventHandlers={{
				click: () => onClick(map),
			}}
			icon={isActive ? activeIcon : defaultIcon}
		>
			{children && <Popup className="request-popup">{children}</Popup>}
		</Marker>
	);
}

MapMarker.propTypes = {
	position: PropTypes.array.isRequired,
	icon: PropTypes.string,
	iconUrl: PropTypes.string,
	popup: PropTypes.string,
	onClick: PropTypes.func,
};

MapMarker.defaultProps = {
	position: [50.74555, -1.84818],
	icon: 'default',
	iconUrl: '',
	popup: 'Hello Popup',
	onClick: () => {},
};

export default MapMarker;
