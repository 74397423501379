import PropTypes from 'prop-types';
import { Icon, Text } from '@/components/common';

function FlightDestinationSummary({ flight }) {
    return (
			<div className="grid grid-cols-5 gap-x-6 group/sfc-disabled:opacity-50 md:gap-2 xl:flex xl:items-center xl:justify-start xl:gap-5">
				<Text
					title={flight?.departureName}
					as="span"
					className="col-span-2 line-clamp-2"
					name={flight?.departureName}
				>
					{flight?.departureName || flight?.departure || 'Departure'}
				</Text>
				<Text as="span" className="w-full xl:w-auto">
					<Icon name="arrow-right" className="w-10 h-8" />
				</Text>
				<Text
					title={flight?.arrivalName}
					as="span"
					className="col-span-2 line-clamp-2"
				>
					{flight?.arrivalName || flight?.arrival || 'Arrival'}
				</Text>
			</div>
		);
}

FlightDestinationSummary.propTypes = {
    flight: PropTypes.object,
}

export default FlightDestinationSummary;
