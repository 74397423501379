import { currencyFormatter } from '@/lib/utils';
import { Text, Skeleton } from '@components/common';

function TravelPlanPrice({ isLoading, total }) {
	if (!isLoading && total === 0) return null;
	return (
		<div className="flex items-center justify-between my-4">
			<Text as="p" className="font-bold text-xl">
				Total
			</Text>
			{isLoading ? (
				<Skeleton className="w-1/4 h-5" />
			) : total > 0 ? (
				<Text as="p" className="font-bold text-xl">
					{currencyFormatter({
						amount: total,
					})}
				</Text>
			) : null}
		</div>
	);
}
export default TravelPlanPrice;
