import { useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useUrlParams } from '@/hooks';
import { withPortal } from '@/HOCs/withPortal';
import { CarHireSearch } from '@/components/carhire';
import { ErrorHandler, SearchWidget } from '@components/common';

function CarHireResults({ portalData }) {
	const { params } = useUrlParams('carhire-filters');

	const buildSearchParams = useCallback(() => {
		if (!params?.default) return null;
		return {
			...params.default,
			dropoffLocation: params.default?.dropoff
				? params.default?.dropoff.split(':')[0]
				: null,
			pickupLocation: params.default?.pickup
				? params.default?.pickup.split(':')[0]
				: null,
		};
	}, [params?.default]);

	return (
		<ErrorBoundary FallbackComponent={ErrorHandler}>
			<SearchWidget
				category="car-hire"
				className={
					portalData?.withSiteHeader
						? 'fixed z-50 w-full top-15 md:top-25 drop-shadow-3xl'
						: ''
				}
			/>
			<CarHireSearch
				filterKey="carhire-filters"
				filterTitle="Filter by car type - this will update your results below"
				pageTitle="Car Hire Search Results"
				buildSearchParams={buildSearchParams}
				withSiteHeader={portalData?.withSiteHeader}
			/>
		</ErrorBoundary>
	);
}

export default withPortal(CarHireResults, 'booking-car-hire-page');
