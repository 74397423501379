import PropTypes from 'prop-types';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { Text, Icon, Button } from '@components/common';

/**
 * @typedef {"supporting-coral" | "supporting-yellow" | "core-blue" | "blurred"} tagVariants
 **/

const tagVariants = cva(
	'flex items-center justify-between w-fit rounded-full px-3 py-0.5 gap-2 transition-all duration-200',
	{
		variants: {
			variant: {
				'supporting-coral': 'bg-supporting-coral text-dark-grey',
				'supporting-yellow': 'bg-supporting-yellow text-core-blue',
				'core-blue': 'bg-core-blue text-white',
				blurred: 'backdrop-blur-sm bg-light-black/60 text-white',
			},
			disabled: {
				true: 'opacity-75 cursor-not-allowed',
			},
		},
		defaultVariants: {
			variant: 'supporting-coral',
		},
	}
);

/**
 * @typedef {object} TagProps
 * @property {boolean} isCancellable
 * @property {function} onCancel
 * @property {string} label
 * @property {string} className
 * @property {tagVariants} variant
 * */

/**
 * @name Tag
 * @description A tag component
 * @param {TagProps & React.ReactHTMLElement<HTMLParagraphElement>} props
 * @returns {React.JSX.Element}
 * @example
 * <Tag label="Tag" />
 * */

function Tag({
	isCancellable,
	onCancel,
	label,
	className,
	variant,
	disabled,
	...props
}) {
	return (
		<Text
			aria-label={label}
			className={cn(tagVariants({ variant, disabled, className }))}
			{...props}
		>
			<Text as="span" className="text-sm font-semibold capitalize">
				{label}
			</Text>
			{isCancellable && (
				<Button
					variant="unstyled"
					type="button"
					className="w-3 h-3 disabled:opacity-100"
					onClick={onCancel}
					disabled={disabled}
				>
					<Icon name="close" />
				</Button>
			)}
		</Text>
	);
}

Tag.propTypes = {
	isCancellable: PropTypes.bool,
	onCancel: PropTypes.func,
	label: PropTypes.string.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool,
};

Tag.defaultProps = {
	isCancellable: false,
	onCancel: () => {},
	className: '',
	disabled: false,
};

export default Tag;
