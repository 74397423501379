import L from 'leaflet';
import { renderToString } from 'react-dom/server';

import { Icon } from '@/components/common';

/**
 * @typedef {object} MarkerIconProps
 * @property {import("@components/common/atoms/icon").iconNames} [icon]
 * @property {string} [iconUrl]
 * @property {number} [width]
 * @property {number} [height]
 * @property {number} [anchorPosX]
 * @property {number} [anchorPosY]
 */

/**
 * @typedef {object} MapMarkerProps
 * @property {string} [marker]
 * @property {string} [iconUrl]
 * @property {number} [iconWidth]
 * @property {number} [iconHeight]
 * @property {number} [iconAnchorPosX]
 * @property {number} [iconAnchorPosY]
 */

/**
 * @name createMarkerIcon
 * @param {MarkerIconProps} props
 * @returns {{defaultIcon: L.DivIcon, activeIcon: L.DivIcon}}
 * @example
 * const { defaultIcon, activeIcon } = createMarkerIcon({ icon: 'hotel-marker' });
 * */

const createMarkerIcon = ({
	icon,
	iconUrl,
	width = 60.6,
	height = 60.6,
	anchorPosX = 30.3,
	anchorPosY = 50.64,
}) => {
	const shouldUseUrl = !icon && iconUrl;

	const htmlString = shouldUseUrl
		? renderToString(<img src={iconUrl} alt="marker" />)
		: renderToString(<Icon name={icon} className="w-full h-full" />);

	const iconConfig = {
		html: htmlString,
		className: '!flex justify-center bg-transparent border-none',
		iconSize: [width, height],
		iconAnchor: [anchorPosX, anchorPosY],
		popupAnchor: [0, -40],
		shadowUrl: null,
		shadowSize: [0, 0],
		shadowAnchor: [0, 0],
	};

	const defaultIcon = new L.divIcon(iconConfig);

	const activeIcon = new L.divIcon({
		...iconConfig,
		iconSize: [width * 1.4, height * 1.4],
		iconAnchor: [anchorPosX * 1.4, anchorPosY * 1.4],
	});

	return {
		defaultIcon,
		activeIcon,
	};
};

/**
 * @name useMapMarker
 * @param {MarkerIconProps} props
 * @returns {{defaultIcon: L.DivIcon, activeIcon: L.DivIcon}}
 * @example
 * const { defaultIcon, activeIcon } = useMapMarker({ icon: 'hotel-marker' });
 * */
function useMapMarker({
	marker,
	iconUrl,
	iconWidth,
	iconHeight,
	iconAnchorPosX,
	iconAnchorPosY,
}) {
	switch (marker) {
		case 'hotel-marker':
			return createMarkerIcon({ icon: 'hotel-marker' });
		case 'avis-marker':
		case 'AVI':
			return createMarkerIcon({ icon: 'avis-marker' });
		case 'enterprise-marker':
		case 'ENT':
			return createMarkerIcon({ icon: 'enterprise-marker' });
		case 'airport-marker':
			return createMarkerIcon({ icon: 'default-fill-marker' });
		case 'custom':
			// show default marker if missing required info
			if (
				!(
					iconUrl &&
					iconWidth &&
					iconHeight &&
					iconAnchorPosX &&
					iconAnchorPosY
				)
			) {
				return createMarkerIcon({ icon: 'default-marker' });
			}

			return createMarkerIcon({
				iconUrl,
				width: iconWidth,
				height: iconHeight,
				anchorPosX: iconAnchorPosX,
				anchorPosY: iconAnchorPosY,
			});
		case 'default':
		default:
			return createMarkerIcon({ icon: 'default-marker' });
	}
}
export default useMapMarker;
