import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { cn } from '@/lib/utils';
import { useValueCallback } from '@/hooks';
import { LoopWrapper, Text } from '@/components/common';
import { SeatLegend, SeatRow } from '@components/flights';

function SeatSelection({
	template,
	className,
	isMobile,
	handleSelect,
	availability,
	selectedSeats,
	disabled = false,
}) {
	const [enableHorizontalView, setEnableHorizontalView] = useState(false);
	const aircraftData = useMemo(() => {
		if (!template) return null;

		return template;
	}, [template]);

	const hView = enableHorizontalView && isMobile;

	return (
		<section
			className={cn(
				'w-fit min-w-full mx-auto flex-col justify-center h-full lg:min-w-0',
				className,
				{
					'w-full': hView,
				}
			)}
		>
			<Text className="hidden text-xl font-bold my-3 md:block">
				Select your seat(s) ...
			</Text>

			<div
				className={cn(
					'rounded-lg w-full py-4 max-h-[70vh] h-full lg:max-h-[70vh] overflow-x-hidden overflow-auto border border-lighter-grey bg-white ',
					{
						'w-full py-0 overflow-x-auto': hView,
					}
				)}
			>
				<div
					className={cn(
						'rounded-lg relative flex items-center justify-between w-full h-auto overflow-x-hidden overflow-y-auto align-bottom',
						{ 'flex-col overflow-x-auto overflow-y-hidden': hView }
					)}
				>
					<div
						className={cn('w-fit mx-auto h-auto align-top flex flex-col', {
							'flex-row items-center h-fit gap-3': hView,
						})}
					>
						<Text
							className={cn('text-center w-full uppercase text-dark-grey/30', {
								'-rotate-90': hView,
							})}
						>
							Front
						</Text>
						<LoopWrapper list={aircraftData?.planeSegment}>
							{(segment) => (
								<Segment
									hView={hView}
									segment={segment}
									disabled={disabled}
									handleSelect={handleSelect}
									availability={availability}
									selectedSeats={selectedSeats}
									setEnableHorizontalView={setEnableHorizontalView}
								/>
							)}
						</LoopWrapper>
						<Text
							className={cn(
								'text-center w-full uppercase text-sm text-dark-grey/30',
								{ '-rotate-90': hView }
							)}
						>
							Back
						</Text>
					</div>
				</div>
			</div>

			<div className="my-5 w-full">
				<Text variant="bold">Key</Text>
				<div className="grid grid-cols-1 my-4 gap-10 md:grid-cols-2">
					{aircraftData?.legends?.map((legend, idx) => (
						<SeatLegend key={`${legend?.label}-${idx}`} legend={legend} />
					))}
				</div>
			</div>
		</section>
	);
}

function Segment({
	hView,
	segment,
	disabled,
	handleSelect,
	availability,
	selectedSeats,
	setEnableHorizontalView,
}) {
	const maxRowCells = useMemo(() => {
		if (!segment?.rows) return 0;

		return segment?.rows?.reduce((acc, row) => {
			if (row?.cells?.length > acc) {
				return row?.cells?.length;
			}
			return acc;
		}, 0);
	}, [segment?.rows]);

	useValueCallback((value) => {
		if (value && value >= 9) {
			setEnableHorizontalView(true);
		}
	}, maxRowCells);

	return (
		<fieldset
			disabled={disabled}
			className={cn(
				'flex flex-col w-auto disabled:opacity-70 disabled:cursor-not-allowed disabled:pointer-events-none',
				{ 'flex-row h-full': hView }
			)}
		>
			<LoopWrapper list={segment?.rows}>
				{(row) => (
					<SeatRow
						row={row}
						hView={hView}
						rows={segment?.rows}
						onChange={handleSelect}
						availability={availability}
						selectedSeats={selectedSeats}
					/>
				)}
			</LoopWrapper>
		</fieldset>
	);
}

SeatSelection.propTypes = {
	className: PropTypes.string,
	selectedSeats: PropTypes.array,
	template: PropTypes.object.isRequired,
	handleSelect: PropTypes.func.isRequired,
};

SeatSelection.defaultProps = {
	className: '',
};

export default SeatSelection;
