import React from 'react';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { forwardRef } from 'react';

const textVariants = cva('leading-tight w-fit font-body', {
	variants: {
		as: {
			p: 'text-base',
			span: 'text-base',
			label: 'text-base',
			small: 'text-xs',
			caption: 'text-sm',
		},
		variant: {
			body: 'font-normal',
			bold: 'font-semibold',
			black: 'font-bold text-black',
			muted: 'font-normal text-dark-grey/75',
			italic: 'italic',
			error: ' font-normal text-core-red',
		},
	},

	defaultVariants: {
		as: 'p',
		variant: 'body',
	},
});

/**
 * @typedef {"p" | "span" | "label" | "small" | "caption" | "a" | "time" | "pre"} textTags
 **/

/**
 * @typedef {"body"  | "bold" | "black" | "muted" | "italic" | "error"} variants

/**
 * @typedef {Object} TextProps
 * @property {textTags} as
 * @property {variants} variant
 * @property {string} className
 * */

/**
 * @name Text
 * @description Renders a text element
 * @param {TextProps & React.BaseHTMLAttributes} props
 * @returns {React.JSX.Element | null}
 * @example
 * <Text as="p" variant="body">Hello World</Text>
 */

function Text({ as = 'p', variant = 'body', className, ...props }, ref) {
	return React.createElement(as, {
		ref,
		className: cn(textVariants({ as, variant, className })),
		...props,
	});
}

Text.displayName = 'Text';

export default forwardRef(Text);
