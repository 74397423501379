import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/lib/utils';
import { Text, Button, Heading } from '@/components/common';

function AssignedSeats({
	pax,
	unassignSeat,
	assignedSeats,
	selectionRequired = false,
	availability,
	disableClear = false,
}) {
	const fullName = [pax?.firstName, pax?.middleName, pax?.lastName]
		.filter((name) => name)
		.join(' ');
	const seat = assignedSeats.find((seat) => seat?.paxRef === pax?.ref);

	const price = useMemo(() => {
		if (!seat?.seat) return undefined;

		// show pricing from availability if available
		if (availability && typeof availability[seat?.seat] === 'object') {
			return availability[seat?.seat]?.price;
		}

		// otherwise show price from selected state
		return seat?.price;
	}, [availability, seat?.seat]);

	const shouldDisplayPrice =
		typeof price !== 'undefined' && parseFloat(price) > 0;

	return (
		<div className="flex flex-col mb-3">
			<div className="flex items-center justify-between w-full">
				<Heading as="h3" className="text-xl bold font-body">
					Passenger {pax?.ref}
				</Heading>
				{selectionRequired && !seat ? (
					<Text variant="muted" className="text-sm">
						*Required
					</Text>
				) : null}
			</div>

			{seat ? (
				<div className="flex items-center justify-start w-full gap-1 mt-1">
					<Text as="span">{fullName},</Text>
					<Text as="span">{`${seat?.row}${seat?.col}${
						shouldDisplayPrice ? ',' : ''
					}`}</Text>
					{shouldDisplayPrice && (
						<Text as="span" className="font-bold">
							{currencyFormatter({ amount: price })}
						</Text>
					)}
					<Button
						hideIcon
						variant="unstyled"
						label="clear"
						disableAnimation
						disabled={disableClear}
						className="underline underline-offset-4"
						labelClassName="font-normal"
						onClick={() => {
							unassignSeat(seat);
						}}
					/>
				</div>
			) : (
				<Text>{fullName}, No selection</Text>
			)}
		</div>
	);
}

AssignedSeats.propTypes = {
	assignedSeats: PropTypes.array.isRequired,
	pax: PropTypes.object.isRequired,
	unassignSeat: PropTypes.func,
	availability: PropTypes.object,
};

export default AssignedSeats;
