import { SortBar } from '@/components/common';
import { MotorHomeCard } from '@/components/motorhomes';
import { useSortBar } from '@/hooks';

function MotorHomeList({ results }) {
	const list = new Array(10).fill(0);
	const sortOptions = [
		{
			label: 'Most Popular',
			value: 'popular',
		},
	];

	const pageOptions = [
		{
			label: '10',
			value: '10',
		},
		{
			label: '20',
			value: '20',
		},
	];

	const { sort, perPage } = useSortBar(
		'motorhomehire_filters',
		sortOptions,
		pageOptions
	);

	return (
		<div className="flex flex-col gap-6 px-7 md:px-0">
			{sort && perPage && (
				<SortBar
					sortOptions={sortOptions}
					pageOptions={pageOptions}
					paramsKey="motorhomehire_filters"
					defaultSort={sort}
					defaultPerPage={perPage}
				/>
			)}
			<div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-1 lg:gap-12">
				{list.map((_, i) => (
					<MotorHomeCard key={i} />
				))}
			</div>
		</div>
	);
}
export default MotorHomeList;
