import PropTypes from 'prop-types';
import { Text } from '@/components/common';
import { ErrorMessage } from '@hookform/error-message';
import { getObjectValue } from '@/lib/utils';

function FieldGroup({
	label,
	className,
	htmlFor,
	showRequired,
	children,
	errors,
	name,
}) {
	const errorsArray = getObjectValue(errors, name)
		? Object.keys(getObjectValue(errors, name)).filter(
				(key) => ['message', 'ref'].includes(key) === false
		  )
		: [];

	const isParentError = !!getObjectValue(errors, `${name}.message`);
	return (
		<div title={name} className="space-y-1">
			<div className="flex items-center justify-between">
				{label && (
					<Text
						as="label"
						htmlFor={htmlFor}
						variant="muted"
						className="text-base leading-tight text-light-black"
					>
						{label}
					</Text>
				)}
				{showRequired && (
					<Text as="span" variant="muted">
						required
					</Text>
				)}
			</div>
			{children && <div className={className}>{children}</div>}

			<div className="mt-4 flex flex-col">
				{errorsArray.length > 0 &&
					errorsArray.map((key) => (
						<ErrorMessage
							key={key}
							errors={errors}
							name={`${name}.${key}`}
							render={({ message }) => (
								<Text as="span" variant="error" className="mt-2">
									&uarr; {message}
								</Text>
							)}
						/>
					))}

				{isParentError && (
					<ErrorMessage
						name={name}
						errors={errors}
						render={({ message }) => (
							<Text as="span" variant="error" className="mt-2">
								&uarr; {message}
							</Text>
						)}
					/>
				)}
			</div>
		</div>
	);
}

FieldGroup.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    htmlFor: PropTypes.string,
    showRequired: PropTypes.bool,
    children: PropTypes.node,
}

export default FieldGroup;
