/**
 * @module merge
 * @description A collection of functions to merge objects and arrays
 * @category Utils
 */

/**
 * @typedef {Object} mergeParams
 * @property {any[]} toMerge - The object to be merged into the other
 * @property {any[]} toBeMergedInto - The object to be merged into
 */

/**
 * @name mergeArrayOfObjects
 * @description Merges two arrays of objects based on the index
 * @param {mergeParams} params - The parameters for the function
 * @returns {Array} - The merged array
 * @example
 * import { mergeArrayOfObjects } from '@/lib/utils';
 * mergeArrayOfObjects({ toMerge: [{ a: 1 }, { b: 2 }], toBeMergedInto: [{ c: 3 }, { d: 4 }] }); 
 */

export const mergeArrayOfObjects = ({ toMerge, toBeMergedInto }) => {
    // check that both are arrays and not empty
    if (!Array.isArray(toMerge) || !Array.isArray(toBeMergedInto) || !toMerge.length || !toBeMergedInto.length) return []

    return toBeMergedInto.map((item, index) => {
        Object.keys(item).forEach(key => {
            // if item[key] is string or number return the value of toMerge if it exists
            if (typeof item[key] === 'string' || typeof item[key] === 'number') {
                if (toMerge[index][key]) {
                    item[key] = toMerge[index][key]
                } else {
                    item[key] = item[key]
                }
                return
            }

            if (toMerge[index][key]) {
                // TODO: to be optimized to handle nested objects
                item[key] = { ...item[key], ...toMerge[index][key] }
            }
        })
        return item
    })

}


