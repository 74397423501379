import DropDownFilter from './dropdown-filter';
import ListFilter from './list-filter';
import {
	MONTHS,
	LOCATIONS,
	STAR_RATINGS,
	ATTRACTIONS,
	HOLIDAY_TYPES,
	EXPERIENCES_ACTIVITIES,
	CAPACITY,
	SORT_OPTIONS,
	FIND_MY_HOLIDAY_DURATIONS,
	formatActiveDurationLabel,
} from '@/lib/utils';
import RangeFilter from './range-filter';
import { Text, Icon } from '@components/common';
import { useMemo } from 'react';

export const DatesFilter = ({ onChange, value, name, disabled }) => {
	return (
		<ListFilter
			name={name}
			title="Dates"
			options={MONTHS}
			values={value}
			onFilterChange={onChange}
			disabled={disabled}
		/>
	);
};

export const StarRatingFilter = ({ onChange, value, name, disabled }) => {
	return (
		<ListFilter
			name={name}
			title="Star Rating"
			options={STAR_RATINGS}
			onFilterChange={onChange}
			values={value}
			disabled={disabled}
		/>
	);
};

export const LocationFilter = ({ onChange, value, name, disabled }) => {
	return (
		<ListFilter
			name={name}
			title="Location"
			options={LOCATIONS}
			onFilterChange={onChange}
			values={value}
			disabled={disabled}
		/>
	);
};

export const DurationFilter = ({ onChange, value, name, title, disabled }) => {
	const getOptions = (value, type) => {
		if (!value) return FIND_MY_HOLIDAY_DURATIONS;

		// do not offer options outside the selected range
		const selectedValue = parseInt(value);
		return FIND_MY_HOLIDAY_DURATIONS.filter((option) => {
			const value = parseInt(option?.value);

			// min options
			if (type === 'min') return value <= selectedValue;

			// max options
			return value >= selectedValue;
		});
	};

	const minNightOpts = useMemo(
		() => getOptions(value?.maxNights?.value, 'min'),
		[value?.maxNights?.value]
	);
	const maxNightOpts = useMemo(
		() => getOptions(value?.minNights?.value, 'max'),
		[value?.minNights?.value]
	);

	return (
		<DropDownFilter
			name={name}
			title={title}
			inputOptions={[
				{
					label: 'Minimum Nights',
					name: 'minNights',
					value: value?.minNights?.value,
					options: minNightOpts,
				},
				{
					label: 'Maximum Nights',
					name: 'maxNights',
					value: value?.maxNights?.value,
					options: maxNightOpts,
				},
			]}
			value={value}
			renderSelectedValues={(selected) => {
				const min = selected?.minNights?.value;
				const max = selected?.maxNights?.value;

				return (
					<Text
						as="span"
						variant="muted"
						className="text-sm flex gap-1 group-data-[state=open]:hidden group-data-[state=closed]:flex transition-all ease-in-out"
					>
						{formatActiveDurationLabel(min, max)}
					</Text>
				);
			}}
			onFilterChange={onChange}
			disabled={disabled}
		/>
	);
};

export const FacetRangeFilter = ({
	onChange,
	value,
	min,
	max,
	name,
	title,
	disabled,
	renderValue,
}) => {
	return (
		<RangeFilter
			min={min}
			max={max}
			name={name}
			title={title}
			value={value || [min, max]}
			onFilterChange={onChange}
			renderSelectedValues={([min, max]) => (
				<Text
					as="span"
					variant="muted"
					className="text-sm flex gap-1 group-data-[state=open]:hidden group-data-[state=closed]:flex transition-all ease-in-out"
				>
					<span className="flex justify-between items-center gap-2">
						{min !== '' && <span>{renderValue(min)}</span>}
						{min !== '' && max !== '' && <Icon name="arrow-right" />}
						{max !== '' && <span>{renderValue(max)}</span>}
					</span>
				</Text>
			)}
			disabled={disabled}
		/>
	);
};

export const AttractionsFilter = ({ onChange, value, name, disabled }) => {
	// fetch options if they are dynamic
	return (
		<ListFilter
			name={name}
			title="Attractions"
			options={ATTRACTIONS}
			onFilterChange={onChange}
			values={value}
			disabled={disabled}
		/>
	);
};

export const HolidayTypeFilter = ({ onChange, value, name, disabled }) => {
	return (
		<ListFilter
			name={name}
			title="Holiday Type"
			options={HOLIDAY_TYPES}
			onFilterChange={onChange}
			values={value}
			disabled={disabled}
		/>
	);
};

export const ExperiencesActivitiesFilter = ({
	onChange,
	value,
	name,
	disabled,
}) => {
	return (
		<ListFilter
			name={name}
			title="Experiences & Activities"
			options={EXPERIENCES_ACTIVITIES}
			onFilterChange={onChange}
			values={value}
			disabled={disabled}
		/>
	);
};

export const CapacityFilter = ({ onChange, value, name, disabled }) => {
	return (
		<ListFilter
			name={name}
			title="Capacity"
			options={CAPACITY}
			onFilterChange={onChange}
			values={value}
			disabled={disabled}
		/>
	);
};

export const SortFilter = ({
	onChange,
	value,
	name,
	options,
	disabled,
	className,
}) => {
	return (
		<DropDownFilter
			name={name}
			title="Sort By"
			inputOptions={[
				{
					name: 'sort',
					value: value?.value,
					options: options,
					hideReset: true,
				},
			]}
			value={{ sort: value }}
			renderSelectedValues={(selected) => (
				<Text
					as="span"
					variant="muted"
					className="text-sm flex gap-1 group-data-[state=open]:hidden group-data-[state=closed]:flex transition-all ease-in-out"
				>
					{selected.sort?.label}
				</Text>
			)}
			onFilterChange={onChange}
			disabled={disabled}
			className={className}
		/>
	);
};

export const FacetFilter = ({
	onChange,
	value,
	name,
	options,
	title,
	disabled,
}) => {
	return (
		<ListFilter
			name={name}
			title={title}
			options={options}
			onFilterChange={onChange}
			values={value}
			disabled={disabled}
		/>
	);
};
