import PropTypes from 'prop-types';
import { Badge, Button, Heading, Text } from '@/components/common';

function SuggestionCard({
	title,
	description,
	image,
	imagealt,
	imageColorRgb,
	link,
	tags,
}) {
	const gradientRgb = imageColorRgb || '0, 0, 0';
	const cardBg = `linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(90deg, rgba(${gradientRgb}, 0.6) 0%, rgba(${gradientRgb}, 0) 100%)`;

	return (
		<a
			href={link}
			aria-label={title}
			title={title}
			className="relative col-span-1 flex flex-col h-full group grow"
		>
			<div className="absolute inset-0 z-0 w-full overflow-hidden rounded-2.5xl">
				<img
					className="block object-cover w-full h-full transition-all ease-out duration-180 group-hover:scale-105"
					src={image}
					alt={imagealt || title}
				/>
				<div
					className="absolute inset-0 z-1 bg-center bg-no-repeat bg-cover"
					style={{ background: cardBg }}
				></div>
			</div>

			<div className="relative flex flex-col items-start justify-end h-full min-h-96.25 z-3 grow gap-2 px-5 pb-7.5 pt-32.5 md:p-10 md:pt-30 card-content">
				{title && (
					<Heading as="h3" className="text-white text-4xl">
						{title}
					</Heading>
				)}
				{description && (
					<Text className="text-white opacity-75 leading-tight tracking-tighter">
						{description}
					</Text>
				)}
				<Button
					tagName="span"
					label="See Holidays"
					variant="hollow-arrow"
					className="py-4 px-8"
					labelClassName="text-lg font-bold leading-tight"
					iconClassName="w-5.25 duration-180 relative h-3 overflow-hidden text-white group-hover:w-10.25 group-hover:text-dark-grey"
					iconName="solid-arrow-right"
				/>
			</div>

			{tags?.length > 0 && (
				<div className="absolute flex flex-row flex-wrap gap-2.5 w-fit z-4 top-0 left-0 pt-7.5 px-5 md:pt-10 md:px-10">
					{tags.map((tag, idx) => (
						<Badge
							key={idx}
							text={tag}
							variant="opaque-blue"
							textClassName="font-body font-bold leading-3 text-xs uppercase"
						/>
					))}
				</div>
			)}
		</a>
	);
}

export default SuggestionCard;
