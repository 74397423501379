import PropTypes from 'prop-types';
import { Fragment, useCallback, useMemo, useState } from 'react';

import {
	FlightCard,
	FlightOptions,
	FlightMobileListItem,
} from '@components/flights';
import { useBookingStore } from '@/store';
import { useFlightSelection } from '@/context';
import { Text, RadioGroup } from '@/components/common';
import { cn, currencyFormatter, CABIN_OPTIONS } from '@/lib/utils';
import {
	useUrlParams,
	useBreakpoint,
	useRemoveQueries,
	useFareUpdate,
	useValueCallback,
} from '@/hooks';

/**
 * @typedef {Object} FlightListItemProps
 * @property {Object} flight
 * @property {string} className
 * @property {string} [selectedCabin]
 * @property {"outbound" | "inbound"} instance
 * @property {bool} isLastFlight
 * @property {(value: string)=>void} handleCabinChange
 * */

/**
 * @name FlightListItem
 * @description Renders the flight list item component
 * @param {FlightListItemProps} props
 * @returns { React.JSX.Element}
 * @example
 * <FlightListItem />
 */

function FlightListItem({
	flight,
	className,
	instance,
	flightIndex,
	isLastFlight,
}) {
	const { params } = useUrlParams();
	const isSmallDevice = useBreakpoint('lg');
	const { removeQueries } = useRemoveQueries();

	const { setBookingState } = useBookingStore();
	const state = useBookingStore((store) => store.flights);

	const [selectedCabin, setSelectedCabin] = useState(() => {
		if (state?.selectedCabin?.[instance]) {
			return state?.selectedCabin?.[instance];
		}
		return '';
	});

	useValueCallback((value) => {
		if (value) {
			setSelectedCabin(value);
		}
	}, state?.selected?.selectedCabin?.[instance]);

	function handleCabinChange(value) {
		setSelectedCabin(value);
	}
	const selectedCabinFare =
		state?.selected?.selectedFare?.[instance]?.fare ?? '';

	const selectedFareCabin = useMemo(() => {
		const selectedFCabin = selectedCabinFare
			? `${
					selectedCabinFare?.split('-')[1]?.toLowerCase().includes('eco')
						? 'eco'
						: 'club'
			  }-${selectedCabinFare?.split('-')[0]}`
			: null;

		if (selectedFCabin && isSmallDevice) {
			handleCabinChange(selectedFCabin);
		}

		return selectedFCabin;
	}, [selectedCabinFare, isSmallDevice]);

	const deviceBaseSelectedCabin = useMemo(() => {
		return isSmallDevice ? selectedFareCabin : selectedCabin;
	}, [selectedCabinFare, selectedCabin, isSmallDevice]);

	const people = useMemo(() => {
		if (!params.default) return null;
		let { who } = params.default;

		const formattedPeople = Object.keys(who ?? {}).reduce((result, key) => {
			const value = key === 'adults' ? 1 : 0;
			result[key] = who[key] ? parseInt(who[key].split(':')[0]) : value;
			return result;
		}, {});
		return formattedPeople;
	}, [params?.default]);

	// get the flight details based on the selected cabinOption
	const selectedOptions = useMemo(() => {
		if (!deviceBaseSelectedCabin) return [];

		const { pricingMatrix } = flight;
		if (!pricingMatrix) return [];
		const cabinSelections = pricingMatrix.filter((item) =>
			item?.cabin?.code
				.toLowerCase()
				.includes(deviceBaseSelectedCabin.split('-')[0])
		);

		return cabinSelections;
	}, [selectedCabin, selectedCabinFare, flight, isSmallDevice]);

	const formatSelected = (flight, value) => {
		// get the cabin
		let selectedFCabin = selectedCabin;
		let cabinSelections = flight?.pricingMatrix ?? [];

		if (isSmallDevice) {
			selectedFCabin = value
				? `${
						value?.split('-')[1]?.toLowerCase().includes('eco') ? 'eco' : 'club'
				  }-${value?.split('-')[0]}`
				: null;

			const { pricingMatrix } = flight;
			if (!pricingMatrix) return [];

			cabinSelections = pricingMatrix.filter((item) =>
				item?.cabin?.code.toLowerCase().includes(selectedFCabin.split('-')[0])
			);
		}

		const chosenCabinOption = cabinSelections?.find(
			(option) => option?.cabin?.code === value?.split('-')[1]
		);

		// if no cabin option is found we don't need to proceed
		if (!chosenCabinOption) return null;

		const selectedDetails = {
			departure: flight?.departure,
			departureName: flight?.departureName,
			departureTime: flight?.departureTime,
			departureTimezone: flight?.departureTimezone,
			arrival: flight?.arrival,
			arrivalName: flight?.arrivalName,
			arrivalTime: flight?.arrivalTime,
			arrivalTimezone: flight?.arrivalTimezone,
			inventory: chosenCabinOption?.inventory,
			sequenceCode: flight?.sequenceCode,
			system: chosenCabinOption?.system,
			flightNumber: flight?.flightNumber,
			carrierCode: flight?.carrier?.code,
			bookingClass: chosenCabinOption?.bookingClass,
			cabin: chosenCabinOption?.cabin?.code,
			booking: chosenCabinOption?.booking,
			sectors: flight?.sectors?.map((sector) => ({
				departure: sector?.departure,
				departureTime: sector?.departureTime,
				arrival: sector?.arrival,
				arrivalTime: sector?.arrivalTime,
				flightNumber: sector?.flightNumber,
				carrierCode: sector?.carrier?.code,
				aircraft: sector?.aircraft,
			})),
		};

		return selectedDetails;
	};

	//get other instance flights
	const otherInstance = useMemo(() => {
		return instance === 'outbound' ? 'inbound' : 'outbound';
	}, [instance]);

	const { results: otherInstanceFlights } = useFlightSelection(otherInstance);

	// update the other instance's flight if search is not one way
	const otherFlightUpdate = useCallback(
		(currState) => {
			// new to update the other instance's flight if no isOneWay
			if (currState?.isOneWay) return currState;

			// get the other instance's selected fare
			const otherInstanceSelectedFare =
				state?.selected?.selectedFare?.[otherInstance]?.fare;

			// escape if no selected fare
			if (!otherInstanceSelectedFare) return currState;

			const otherInstanceFlightKey = otherInstanceSelectedFare?.split('-')[0];

			// find the corresponding flight
			const otherInstanceFlight = otherInstanceFlights?.find(
				(flght) => flght?.key === otherInstanceFlightKey
			);

			// if no other instance flight, return the current state (TODO: update state to remove the other instance selected fare)
			if (!otherInstanceFlight) return currState;

			const otherInstanceSelectedDetails = formatSelected(
				otherInstanceFlight,
				otherInstanceSelectedFare
			);

			const newState = {
				...currState,
				selected: {
					...currState?.selected,
					updateFares: false,
					items: currState?.selected?.items?.map((item) => {
						if (!item?.[otherInstance]) {
							item[otherInstance] = otherInstanceSelectedDetails;
						}
						return item;
					}),
					[otherInstance]: otherInstanceSelectedDetails,
				},
			};

			// return new state
			return newState;
		},

		[instance, otherInstanceFlights, otherInstance]
	);

	// handle selection of the cabin
	const handleChange = useCallback(
		(value, flight, isPreSelecting = false) => {
			if (!value) return;

			const existingFare =
				state?.selected?.selectedFare?.[instance]?.fare ?? null;
			const isCabinChanged = existingFare ? existingFare !== value : false;

			const dupState = { ...state };

			let newState = {
				...dupState,
				selected: {
					...dupState?.selected,
					selectedCabin: {
						...dupState?.selected?.selectedCabin,
						[instance]: selectedCabin,
					},
					selectedFare: {
						...dupState?.selected?.selectedFare,
						[instance]: {
							fare: value,
							date: flight?.departureTime,
						},
					},
					items: [
						{
							...(dupState?.selected?.items?.[0] ?? {}),
							type: 'flight',
							[instance]: formatSelected(flight, value),
						},
					],
					[instance]: formatSelected(flight, value),
					people: dupState?.selected?.people ?? people,
				},
				// clear optionPlus, baggage, selectedSeats since the fare / flight changed
				questions: undefined,
				preview: undefined,
				optionPlusOptions: undefined,
				baggage: undefined,
				selectedSeats: undefined,
			};

			// clear answered questions if the fare has changed
			const hasQuestions =
				dupState?.bookingDetails?.questions?.length > 0 ?? false;

			if (hasQuestions && isCabinChanged) {
				newState = {
					...newState,
					bookingDetails: {
						...dupState?.bookingDetails,
						questions: [],
					},
				};
			}

			// set the shouldBuildParams to true if the flight is one way and the instance is outbound
			if (dupState?.isOneWay && instance === 'outbound' && !isPreSelecting) {
				newState = {
					...newState,
					shouldBuildParams: true,
				};
			}

			// set the shouldBuildParams to true if the flight is not one way and the instance is inbound but not when changing the fare
			if (
				!dupState?.isOneWay &&
				instance === 'inbound' &&
				dupState?.selected?.selectedFare?.outbound &&
				!isPreSelecting
			) {
				newState = {
					...newState,
					shouldBuildParams: true,
				};
			}

			if (
				!dupState?.isOneWay &&
				instance === 'outbound' &&
				dupState?.selected?.selectedFare?.inbound &&
				!isPreSelecting
			) {
				newState = {
					...newState,
					shouldBuildParams: true,
				};
			}

			// if is pre-selecting the fare - meaning there is a selected fare
			if (isPreSelecting) {
				newState = {
					...newState,
					shouldBuildParams: true,
					selected: {
						...newState?.selected,
						updateFares: false,
					},
				};

				// update the other instance's flight if not isOneWay
				if (!newState?.isOneWay) {
					newState = otherFlightUpdate(newState);

					// set build params to false if the current instance is not selected
					if (!newState?.selected?.[otherInstance]) {
						newState = {
							...newState,
							shouldBuildParams: false,
						};
					}
				}
			}

			if (isCabinChanged) {
				// newState = { ...newState, shouldBuildParams: true };
				removeQueries(['option-plus', 'baggage', 'seating', 'booking-preview']);
			}

			setBookingState('flights', newState, 'SELECT_FARE');
		},
		[people, removeQueries, flight, instance, otherInstanceFlights, state]
	);

	// get the minimum price for each cabin from the cabin fares
	const fromPrice = useMemo(() => {
		// cabins are eco and club
		if (!flight) return null;
		const cabins = CABIN_OPTIONS.map((cabin) => cabin?.value);

		const { pricingMatrix } = flight;
		if (!pricingMatrix) return null;

		// get all the prices for each cabin
		const prices = cabins.reduce((result, cabin) => {
			const selections = pricingMatrix
				.filter((item) => {
					return item?.cabin?.code.toLowerCase().includes(cabin);
				})
				.map((item) => item?.pricing?.adult);

			if (selections.length === 0) return result;

			// find the minimum price for each cabin
			const minPrice = selections.reduce((min, price) => {
				return Math.min(min, parseFloat(price));
			}, Infinity);

			result[cabin] = minPrice;
			return result;
		}, {});

		return prices;
	}, [flight]);

	// check if the cabin is selected
	const isSelected = useMemo(() => {
		if (!deviceBaseSelectedCabin) return false;

		const selected = CABIN_OPTIONS.find((cabin) => {
			const selectedCab = deviceBaseSelectedCabin.split('-')[0];
			return selectedCab === cabin?.value;
		});
		return deviceBaseSelectedCabin === `${selected?.value}-${flightIndex}`;
	}, [selectedCabin, selectedCabinFare, flightIndex, isSmallDevice]);

	// handle fare update
	useFareUpdate({
		instance,
		selectedCabin,
		onFareChange: handleChange,
		flight: isSelected ? flight : null, // only update the fare if the flight is selected
		fareAvailable: isSelected && selectedOptions?.length > 0 ? true : false,
	});

	return (
		<div className={cn('md:even:bg-black/2', className)}>
			<div
				data-selected={isSelected}
				className="group grid grid-cols-2 w-full lg:data-[selected=true]:border-b-4 lg:data-[selected=true]:border-core-blue lg:grid-cols-4"
			>
				<FlightCard
					flight={flight}
					className="col-span-2 bg-transparent md:col-span-2 lg:p-4"
					overviewClassName="p-4 lg:p-0"
					detailsWrapperClassName="bg-black/2 border-x-0 lg:border border-lighter-grey mt-0 lg:mt-4"
					hideDetailsDivide={false}
				/>
				{CABIN_OPTIONS.map((cabin) => (
					<div
						key={cabin?.id}
						className={cn(
							'col-span-1 flex gap-2 items-center justify-center bg-black/3 lg:border-0 lg:bg-transparent lg:even:bg-black/3',
							{
								'border-b-2 border-core-blue': !isLastFlight,
							},
							{
								'group-data-[selected=true]:bg-core-blue group-data-[selected=true]:text-white':
									deviceBaseSelectedCabin &&
									deviceBaseSelectedCabin === `${cabin?.value}-${flightIndex}`,
							}
						)}
					>
						{isSmallDevice ? (
							<FlightMobileListItem
								cabin={cabin}
								instance={instance}
								fromPrice={fromPrice}
								flight={flight}
								flightIndex={flightIndex}
								selectedCabin={selectedCabin}
								handleChange={handleChange}
								selectedOptions={selectedOptions}
								handleCabinChange={handleCabinChange}
							/>
						) : (
							<Fragment>
								{!fromPrice?.[cabin?.value] ? (
									<Text>-</Text>
								) : (
									<RadioGroup
										hideLabel
										value={selectedCabin}
										onChange={handleCabinChange}
										itemClassName="group-data-[selected=true]:bg-white group-data-[selected=true]:text-core-blue"
										className="flex flex-col items-center w-full gap-2"
										fieldWrapperClassName="w-full flex-col gap-2"
										options={[
											{
												label: cabin?.label,
												value: `${cabin?.value}-${flightIndex}`,
											},
										]}
										renderOptionChildren={() => (
											<div className="flex flex-col items-center gap-1">
												<Text as="span">From</Text>
												<Text as="span" className="text-xl font-bold">
													{fromPrice
														? currencyFormatter({
																amount: fromPrice[cabin?.value],
														  })
														: '-'}
												</Text>
											</div>
										)}
									/>
								)}
							</Fragment>
						)}
					</div>
				))}
			</div>

			{selectedCabin && selectedCabin?.split('-')[1] === flight?.key ? (
				<FlightOptions
					flight={flight}
					instance={instance}
					onSelect={handleChange}
					selectedOptions={selectedOptions}
					value={state?.selected?.selectedFare?.[instance]?.fare ?? null}
				/>
			) : null}
		</div>
	);
}

FlightListItem.propTypes = {
	flight: PropTypes.object,
	className: PropTypes.string,
	flightIndex: PropTypes.string,
	selectedCabin: PropTypes.string,
	handleCabinChange: PropTypes.func,
	isLastFlight: PropTypes.bool,
	instance: PropTypes.oneOf(['outbound', 'inbound']),
};

FlightListItem.defaultProps = {
	flight: null,
	className: null,
	flightIndex: null,
	selectedCabin: null,
	isLastFlight: false,
	instance: 'outbound',
	handleCabinChange: () => {},
};

export default FlightListItem;
