import { useEffect } from 'react';

import { useBookingStore } from '@/store';
import { useParams, useLocation } from 'react-router-dom';
import PassengerDetailsTemplate from '@components/templates/passenger-details';

function PassengerDetails() {
	const params = useParams();
	const { pathname } = useLocation();
	const { setBookingState, submitBooking } = useBookingStore();

	const category = params?.category
		? params?.category
		: pathname && pathname.split('/')[2];

	const state = useBookingStore((store) => store[category]);

	const handleContinue = ({ passengerDetails, bookingDetails }) => {
		const newState = {
			bookingDetails,
			passengerDetails,
		};
		setBookingState(category, newState, 'SET_PASSENGER_DETAILS');

		// submit booking
		submitBooking(category);
	};

	// run only once on mount
	useEffect(() => {
		// reset terms and conditions & read information
		const checkedConditions =
			state?.passengerDetails?.terms_conditions &&
			state?.passengerDetails?.read_information;

		if (checkedConditions) {
			const newState = {
				passengerDetails: {
					...state.passengerDetails,
					terms_conditions: false,
					read_information: false,
				},
				paymentDetails: undefined,
			};
			setBookingState(category, newState, 'RESET_STATE');
		}
	}, [category]);

	return (
		<PassengerDetailsTemplate
			state={state}
			category={category}
			handleContinue={handleContinue}
		/>
	);
}
export default PassengerDetails;
