export const openSearchTab = (clickFunc) => {
    const searchCarHireTabLinkEls = document.querySelectorAll('.tab-car-hire');
    for (const searchCarHireTabLinkElem of searchCarHireTabLinkEls) {
        const carHireTabEl = document.querySelector('[data-tab-id="tab-car-hire"]');
        searchCarHireTabLinkElem.addEventListener('click', function (eve) {
            eve.preventDefault();
            eve.stopPropagation();

            if (carHireTabEl) {
                clickFunc('car-hire');
            }
        });
    }

    const searchHotelsTabLinkEls = document.querySelectorAll('.tab-hotels');
    for (const searchHotelsTabLinkElem of searchHotelsTabLinkEls) {
        const hotelsTabEl = document.querySelector('[data-tab-id="tab-hotels"]');
        searchHotelsTabLinkElem.addEventListener('click', function (eve) {
            eve.preventDefault();
            eve.stopPropagation();

            if (hotelsTabEl) {
                clickFunc('hotels');
            }
        });
    }

    const searchFlightsTabLinkEls = document.querySelectorAll('.tab-flights');
    for (const searchFlightsTabLinkElem of searchFlightsTabLinkEls) {
        const flightsTabEl = document.querySelector('[data-tab-id="tab-flights"]');
        searchFlightsTabLinkElem.addEventListener('click', function (eve) {
            eve.preventDefault();
            eve.stopPropagation();

            if (flightsTabEl) {
                clickFunc('flights');
            }
        });
    }
}
