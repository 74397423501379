import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { Button, Heading, Text } from '@components/common';

function ErrorHandler({
	error,
	isRequestError,
	className,
	resetErrorBoundary,
	hideSearchAgain,
	description,
}) {
	const isDev = import.meta.env.DEV;
	return (
		<section
			className={cn(
				'@container w-full flex flex-col bg-white items-center justify-center gap-5 min-h-screen-md p-4',
				isRequestError && 'fixed inset-0',
				className
			)}
		>
			<div className="flex flex-col items-center justify-center w-full gap-4">
				<Heading className="text-center text-6xl @lg:text-8xl font-display">
					Houston, we have a problem!
				</Heading>

				{description ? (
					<Text
						as="p"
						className="max-w-xl text-lg text-center text-dark-grey/60"
					>
						{description}
					</Text>
				) : (
					<Text
						as="p"
						className="max-w-xl text-lg text-center text-dark-grey/60"
					>
						Apologies, we have encountered an error with your request. Please{' '}
						<a
							href="mailto:enquiries@canadianaffair.com?subject=Website%20Error%20Query"
							target="_top"
							className="underline underline-offset-2"
						>
							email us
						</a>{' '}
						for assistance.
					</Text>
				)}

				{isDev && (
					<Text as="pre" className="text-red-800">
						{error?.message && error.message}
						{error ? JSON.stringify(error, null, 3) : null}
					</Text>
				)}

				<div className="flex flex-col items-center gap-3 @lg:flex-row">
					{hideSearchAgain && (
						<Button
							hideIcon
							tagName="a"
							href="/about-us/contact-us"
							target="_blank"
							rel="noopener noreferrer"
							label="Get in touch"
							variant="core-blue"
							className="w-fit"
						/>
					)}

					<Button
						hideIcon
						tagName="a"
						target="_top"
						href="/"
						className="cursor-pointer"
						label="Return to homepage"
						variant="supporting-yellow"
					/>
				</div>
			</div>
		</section>
	);
}

ErrorHandler.propTypes = {
	error: PropTypes.object,
	isRequestError: PropTypes.bool,
	className: PropTypes.string,
	resetErrorBoundary: PropTypes.func,
	renderActions: PropTypes.func,
	hideSearchAgain: PropTypes.bool,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

ErrorHandler.defaultProps = {
	error: null,
	isRequestError: false,
	hideSearchAgain: false,
	className: '',
	resetErrorBoundary: () => {},
};

export default ErrorHandler;
