// HOC to portalize a component to the given portalId

import { createPortal } from 'react-dom';

/**
 * Portaled component - takes a component and a portalId and returns a component
 * or null if the component is in production and the portalId doesn't exist
 * */

export const withPortal = (Component, portalId) => {
	return (props) => {
		const container = document.getElementById(portalId);
		const isProd = import.meta.env.PROD;

		if (!container) {
			return isProd ? null : <Component {...props} />;
		}

		return createPortal(
			<Component {...props} portalData={container?.dataset || {}} />,
			container
		);
	};
};
