import { useMemo } from 'react';
import useUrlParams from './useUrlParams';

/**
 * @name useSortBar Hook
 * @description A hook to retrieve sort bar params stored in the query string
 * @param {string} paramsKey
 * @param {Array.<{key: string, value: string}>} sortOptions
 * @param {Array.<{key: string, value: string}>} pageOptions
 * @param {function} onParsed
 * @returns {{sort: (undefined|{key: string, value: string}), perPage: (undefined|{key: string, value: string})}}
 * @example
 * const { sort, value } = useSortBar('key', [], [], () => {});
 */
function useSortBar(paramsKey, sortOptions = [], pageOptions = [], onParsed) {
	const { params } = useUrlParams(paramsKey);

	const { sort, perPage } = useMemo(() => {
		if (!paramsKey) return {};
		if (!params?.default) return {};

		const sort =
			sortOptions.find((opt) => opt.value == params[paramsKey]?.sort) ||
			sortOptions[0];
		const perPage =
			pageOptions.find((opt) => opt.value == params[paramsKey]?.perPage) ||
			pageOptions[0];

		if (typeof onParsed === 'function') onParsed();

		return {
			sort: sort,
			perPage: perPage,
		};
	}, [
		paramsKey,
		params?.default,
		params[paramsKey]?.sort,
		params[paramsKey]?.perPage,
	]);

	return {
		sort,
		perPage,
	};
}
export default useSortBar;
