import { DatePlaceHolder, Text } from '@components/common';
import { set } from 'date-fns';
import { useBreakpoint } from '@/hooks';

function MotorCarQueryDetails({ params, QueryItem }) {
	const isXxs = useBreakpoint('xxs');

	if (isXxs) {
		return (
			<div className="flex flex-wrap items-center w-full h-full px-2 gap-x-2 sm:gap-x-0">
				<Text className="text-xs text-core-light-blue">
					{params?.pickup ? params.pickup.split(':')[1] : 'Select Pickup'}
				</Text>
				<QueryItem
					label="When"
					value={() => (
						<DatePlaceHolder
							selected={{
								from: params.pickupTime
									? set(new Date(params?.startDate), {
											hours: params.pickupTime.split(':')[0],
											minutes: params.pickupTime.split(':')[1],
									  })
									: new Date(params?.startDate),
							}}
							className="text-core-light-blue"
							textClassName="text-xs"
							hideToTime={true}
							hideTime={!params.pickupTime && !params.dropoffTime}
						/>
					)}
				/>
				<Text className="text-xs text-core-light-blue">
					{params?.dropoff ? params.dropoff.split(':')[1] : 'Select Dropoff'}
				</Text>
				<QueryItem
					label="When"
					value={() => (
						<DatePlaceHolder
							selected={{
								to: params.dropoffTime
									? set(new Date(params?.endDate), {
											hours: params.dropoffTime.split(':')[0],
											minutes: params.dropoffTime.split(':')[1],
									  })
									: new Date(params?.endDate),
							}}
							className="text-core-light-blue"
							textClassName="text-xs"
							hideFromTime={true}
							hideTime={!params.pickupTime && !params.dropoffTime}
						/>
					)}
				/>
			</div>
		);
	}

	return (
		<div className="flex flex-wrap items-center w-full h-full px-2 gap-x-2 sm:gap-x-0">
			<QueryItem
				label="Pick up"
				className="items-start h-fit w-fit grow"
				value={params?.pickup ? params.pickup.split(':')[1] : 'Select Pickup'}
			/>
			<QueryItem
				label="Drop off"
				className="items-start h-fit w-fit grow"
				value={
					params?.dropoff ? params.dropoff.split(':')[1] : 'Select Dropoff'
				}
			/>
			<QueryItem
				label="When"
				className="grow"
				value={() => (
					<DatePlaceHolder
						selected={{
							from: params.pickupTime
								? set(new Date(params?.startDate), {
										hours: params.pickupTime.split(':')[0],
										minutes: params.pickupTime.split(':')[1],
								  })
								: new Date(params?.startDate),
							to: params.dropoffTime
								? set(new Date(params?.endDate), {
										hours: params.dropoffTime.split(':')[0],
										minutes: params.dropoffTime.split(':')[1],
								  })
								: new Date(params?.endDate),
						}}
						className="text-core-light-blue"
						hideTime={!params.pickupTime && !params.dropoffTime}
					/>
				)}
			/>
		</div>
	);
}

export default MotorCarQueryDetails;
