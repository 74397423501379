import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CarIcon } from '@/site/assets/icons/car-icon.svg';
import { ReactComponent as Search } from '@/site/assets/icons/search-icon.svg';
import { ReactComponent as X } from '@/site/assets/icons/x-icon.svg';
import { ReactComponent as Hotel } from '@/site/assets/icons/hotel-icon.svg';
import { ReactComponent as Motorhome } from '@/site/assets/icons/motorhome-icon.svg';
import { ReactComponent as Plane } from '@/site/assets/icons/plane-icon.svg';
import { ReactComponent as FlightFill } from '@/site/assets/icons/flight-fill-icon.svg';
import { ReactComponent as Holiday } from '@/site/assets/icons/holiday-icon.svg';
import { ReactComponent as ChevronDown } from '@/site/assets/icons/chevron-down.svg';
import { ReactComponent as ArrowRight } from '@/site/assets/icons/right-arrow.svg';
import { ReactComponent as SolidArrowRight } from '@/site/assets/icons/solid-right-arrow.svg';
import { ReactComponent as Calendar } from '@/site/assets/icons/calendar-icon.svg';
import { ReactComponent as Luggage } from '@/site/assets/icons/luggage-icon.svg';
import { ReactComponent as SmallLuggage } from '@/site/assets/icons/small-luggage-icon.svg';
import { ReactComponent as Person } from '@/site/assets/icons/person-icon.svg';
import { ReactComponent as MapleLeaf } from '@/site/assets/icons/maple-leaf-icon.svg';
import { ReactComponent as Check } from '@/site/assets/icons/check-icon.svg';
import { ReactComponent as Moon } from '@/site/assets/icons/moon-icon.svg';
import { ReactComponent as Accomodation } from '@/site/assets/icons/accommodation-icon.svg';
import { ReactComponent as Share } from '@/site/assets/icons/share-icon.svg';
import { ReactComponent as Internet } from '@/site/assets/icons/internet-icon.svg';
import { ReactComponent as Star } from '@/site/assets/icons/star-icon.svg';
import { ReactComponent as LightStar } from '@/site/assets/images/light-star.svg';
import { ReactComponent as Filter } from '@/site/assets/icons/filter-icon.svg';
import { ReactComponent as Home } from '@/site/assets/images/home.svg';
import { ReactComponent as Question } from '@/site/assets/icons/question-icon.svg';
import { ReactComponent as QuestionWithoutOutline } from '@/site/assets/icons/question-without-outline-icon.svg';
import { ReactComponent as Location } from '@/site/assets/icons/location-icon.svg';
import { ReactComponent as Page } from '@/site/assets/icons/page-icon.svg';
import { ReactComponent as LiteSearch } from '@/site/assets/icons/lite-search-icon.svg';
import { ReactComponent as Users } from '@/site/assets/icons/users-icon.svg';
import { ReactComponent as Heart } from '@/site/assets/icons/heart-icon.svg';
import { ReactComponent as SolidHeart } from '@/site/assets/icons/solid-heart-icon.svg';
import { ReactComponent as Twitter } from '@/site/assets/icons/twitter-icon.svg';
import { ReactComponent as Facebook } from '@/site/assets/icons/facebook-icon.svg';
import { ReactComponent as Instagram } from '@/site/assets/icons/instagram-icon.svg';
import { ReactComponent as Baggage } from '@/site/assets/icons/baggage-icon.svg';
import { ReactComponent as BaggageStrikethrough } from '@/site/assets/icons/baggage-strikethrough-icon.svg';
import { ReactComponent as Seat } from '@/site/assets/icons/seat-icon.svg';
import { ReactComponent as Cancellation } from '@/site/assets/icons/cancellation-icon.svg';
import { ReactComponent as Changes } from '@/site/assets/icons/changes-icon.svg';
import { ReactComponent as AirTransat } from '@/site/assets/icons/airtransat-icon.svg';
import { ReactComponent as Phone } from '@/site/assets/icons/phone-icon.svg';
import { ReactComponent as Bags } from '@/site/assets/icons/bags-icon.svg';
import { ReactComponent as FoodBasket } from '@/site/assets/icons/food-basket-icon.svg';
import { ReactComponent as Pass } from '@/site/assets/icons/pass-icon.svg';
import { ReactComponent as Pool } from '@/site/assets/icons/pool-icon.svg';
import { ReactComponent as Winter } from '@/site/assets/icons/winter-icon.svg';
import { ReactComponent as FoodPlate } from '@/site/assets/icons/food-plate-icon.svg';
import { ReactComponent as Wifi } from '@/site/assets/icons/wifi-icon.svg';
import { ReactComponent as Rail } from '@/site/assets/icons/rail-icon.svg';
import { ReactComponent as Toilet } from '@/site/assets/icons/toilet-icon.svg';
import { ReactComponent as LegRoom } from '@/site/assets/icons/leg-room-icon.svg';
import { ReactComponent as DefaultSeat } from '@/site/assets/icons/default-seat-icon.svg';
import { ReactComponent as UnavailableSeat } from '@/site/assets/icons/unavailable-icon.svg';
import { ReactComponent as SelectionSeat } from '@/site/assets/icons/selection-icon.svg';
import { ReactComponent as WindowSeat } from '@/site/assets/icons/window-icon.svg';
import { ReactComponent as Seat2X } from '@/site/assets/icons/2x-icon.svg';
import { ReactComponent as FrontCabinSeat } from '@/site/assets/icons/front-cabin-seat-icon.svg';
import { ReactComponent as AtolSecure } from '@/site/assets/icons/atol-secure-icon.svg';
import { ReactComponent as Lock } from '@/site/assets/icons/lock-icon.svg';
import { ReactComponent as Tick } from '@/site/assets/icons/tick.svg';
import { ReactComponent as User } from '@/site/assets/icons/user-icon.svg';
import { ReactComponent as Info } from '@/site/assets/images/info.svg';
import { ReactComponent as DarkInfo } from '@/site/assets/images/dark-info.svg';
import { ReactComponent as Clock } from '@/site/assets/images/clock.svg';
import { ReactComponent as LogoIcon } from '@/site/assets/icons/logo-icon.svg';
import { ReactComponent as TwitterX } from '@/site/assets/icons/twitter-x-icon.svg';

// map markers
import { ReactComponent as Avis } from '@/site/assets/icons/avis-icon.svg';
import { ReactComponent as DefaultFillMarker } from '@/site/assets/icons/location-fill-icon.svg';
import { ReactComponent as DefaultMarker } from '@/site/assets/icons/location-icon.svg';
import { ReactComponent as Enterprise } from '@/site/assets/icons/enterprise-icon.svg';
import { ReactComponent as HotelMarker } from '@/site/assets/icons/hotel-map-marker-icon.svg';

import { cn } from '@/lib/utils';

/**
 * @typedef {"car" | "carhire" | "car-right" | "motorhome" | "motorhome-hire" | "motorhome-right" | "search"| "lite-search"| "x" | "close"| "cancel" | "hotels" | "holidays" | "plane" | "flights" | "flight-fill" | "chevron-down" | "chevron-up" | "arrow-right" | "solid-arrow-right" | "arrow-left" | "wifi" | "pool" | "winter" | "pass" | "seat" | "air-transat" | "baggage" | "baggage-strikethrough" | "luggage" | "calendar" | "small-luggage" | "person" |"maple-leaf" | "star" | "light-star" | "internet" | "moon" | "users" | "question" | "filter" | "home" | "changes" | "heart" | "solid-heart" | "twitter" | "twitter-x" | "facebook" |"instagram" | "location" | "share" | "page" | "cancellation" | "phone" | "bags" | "food-basket" | "food-plate" | "accommodation" | "hotel" | "rail" | "rail-right" | "window-seat" | "default-seat" | "legroom-seat" | "toilets" | "toilet" | "unavailable-seat" | "selection-seat" | "2x-seat" | "front" | "atol-secure" | "lock" | "tick" | "user" | "info" | "dark-info" | "avis-marker" | "enterprise-marker" | "default-marker" | "default-fill-marker" | "hotel-marker"} iconNames
 */

/**
 * @typedef {Object} IconProps
 * @property {iconNames} name
 * @property {string} className
 */

/**
 * @name Icon
 * @description Renders icons
 * @param {IconProps} props
 * @returns {React.JSX.Element}
 * @example
 * <Icon name="car" className="w-5 h-5" />
 * */

function Icon({ name, className }) {
	switch (name) {
		case 'logo':
			return <LogoIcon className={className} />;
		case 'car':
		case 'carhire':
		case 'car-hire':
			return <CarIcon className={className} />;
		case 'car-right':
			return <CarIcon className={cn('-scale-x-100', className)} />;
		case 'search':
			return <Search className={className} />;
		case 'lite-search':
			return <LiteSearch className={className} />;
		case 'x':
		case 'close':
		case 'cancel':
			return <X className={className} />;
		case 'hotel':
		case 'hotels':
			return <Hotel className={className} />;
		case 'motorhome':
		case 'motorhome-hire':
			return <Motorhome className={className} />;
		case 'motorhome-right':
			return <Motorhome className={cn('-scale-x-100', className)} />;
		case 'plane':
		case 'flights':
			return <Plane className={className} />;
		case 'flight-fill':
			return <FlightFill className={cn(className, 'fill-current')} />;
		case 'holiday':
		case 'holidays':
			return <Holiday className={className} />;

		// chevron icons
		case 'chevron-down':
			return <ChevronDown className={cn('opacity-75', className)} />;
		case 'chevron-up':
			return <ChevronDown className={cn('rotate-180 opacity-75', className)} />;
		case 'chevron-right':
		case 'exit_r':
			return <ChevronDown className={cn('-rotate-90 opacity-75', className)} />;
		case 'chevron-left':
		case 'exit_l':
			return <ChevronDown className={cn('rotate-90 opacity-75', className)} />;

		// arrow icons
		case 'arrow-right':
			return <ArrowRight className={className} />;
		case 'solid-arrow-right':
			return <SolidArrowRight className={className} />;
		case 'arrow-left':
			return <ArrowRight className={cn('rotate-180', className)} />;
		case 'calendar':
			return <Calendar className={className} />;
		case 'small-luggage':
			return <SmallLuggage className={className} />;
		case 'luggage':
			return <Luggage className={className} />;
		case 'person':
			return <Person className={className} />;
		case 'maple-leaf':
			return <MapleLeaf className={cn('text-core-red', className)} />;
		case 'check':
			return <Check className={className} />;
		case 'moon':
			return <Moon className={className} />;
		case 'accommodation':
			return <Accomodation className={className} />;
		case 'share':
			return <Share className={className} />;
		case 'internet':
			return <Internet className={className} />;
		case 'star':
			return <Star className={className} />;
		case 'light-star':
			return <LightStar className={className} />;
		case 'filter':
			return <Filter className={className} />;
		case 'home':
			return <Home className={className} />;
		case 'location':
			return <Location className={className} />;
		case 'page':
			return <Page className={className} />;
		case 'users':
			return <Users className={className} />;
		case 'heart':
			return <Heart className={className} />;
		case 'solid-heart':
			return <SolidHeart className={className} />;
		case 'twitter':
			return <Twitter className={className} />;
		case 'twitter-x':
			return <TwitterX className={className} />;
		case 'facebook':
			return <Facebook className={className} />;
		case 'instagram':
			return <Instagram className={className} />;
		case 'air-transat':
			return <AirTransat className={className} />;
		case 'baggage':
			return <Baggage className={className} />;
		case 'baggage-strikethrough':
			return <BaggageStrikethrough className={className} />;
		case 'seat':
			return <Seat className={className} />;
		case 'cancellation':
			return <Cancellation className={className} />;
		case 'changes':
			return <Changes className={className} />;
		case 'phone':
			return <Phone className={className} />;
		case 'bags':
			return <Bags className={className} />;
		case 'food-basket':
			return <FoodBasket className={className} />;
		case 'pass':
			return <Pass className={className} />;
		case 'pool':
			return <Pool className={className} />;
		case 'winter':
			return <Winter className={className} />;
		case 'food-plate':
			return <FoodPlate className={className} />;
		case 'wifi':
			return <Wifi className={className} />;
		case 'rail':
			return <Rail className={className} />;
		case 'rail-right':
			return <Rail className={cn('-scale-x-100', className)} />;
		case 'atol-secure':
			return <AtolSecure className={className} />;
		case 'lock':
			return <Lock className={className} />;
		case 'tick':
			return <Tick className={className} />;
		case 'user':
			return <User className={className} />;
		case 'info':
			return <Info className={className} />;
		case 'dark-info':
			return <DarkInfo className={className} />;
		case 'clock':
			return <Clock className={className} />;

		// Seat icons
		case 'window':
		case 'seat_window':
		case 'window-seat':
			return <WindowSeat className={className} />;
		case 'default-seat':
		case 'seat_available':
			return <DefaultSeat className={className} />;
		case 'extralegroom':
		case 'seat_xleg':
		case 'legroom-seat':
			return <LegRoom className={className} />;
		case 'toilets':
		case 'toilet':
		case 'lavatory':
		case 'icon_lavatory_sml':
			return <Toilet className={className} />;
		case 'unavailable-seat':
		case 'seat_unavailable':
		case 'seat_reserved':
			return <UnavailableSeat className={className} />;
		case 'selection-seat':
		case 'seat_selected':
			return <SelectionSeat className={className} />;
		case '2x-seat':
		case 'sPlus':
		case 'seat_2x2':
			return <Seat2X className={className} />;
		case 'front':
			return <FrontCabinSeat className={className} />;
		case 'seat_exit':
			return <ChevronDown className={cn('rotate-90', className)} />;

		// Map markers
		case 'avis-marker':
			return <Avis className={className} />;
		case 'enterprise-marker':
			return <Enterprise className={className} />;
		case 'default-fill-marker':
			return <DefaultFillMarker className={className} />;
		case 'hotel-marker':
			return <HotelMarker className={className} />;
		case 'default-marker':
			return <DefaultMarker className={className} />;

		case 'question-without-outline':
			return <QuestionWithoutOutline className={className} />;
		// default icon
		case 'question':
		default:
			return <Question className={className} />;
	}
}

Icon.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
};

Icon.defaultProps = {
	className: '',
	name: 'question',
};

export default Icon;
