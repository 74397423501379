import {
	useMemo,
	useState,
	useContext,
	useCallback,
	createContext,
} from 'react';

import { useBookingStore } from '@/store';

const SeatSelectionContext = createContext({
	passengers: [],
	outboundSectors: [],
	inboundSectors: [],
	templates: null,
	selectionAvailable: false,
	getTemplate: () => null,
	getAvailability: () => true,
	selectedFlight: null,
	setSelectedFlight: () => {},
});

export function SeatSelectionProvider({ children, seatData }) {
	const [error, setError] = useState(null);
	const state = useBookingStore((store) => store.flights);
	const [selectedFlight, setSelectedFlight] = useState(null);

	// seat assign
	const { type, aircraft } = useMemo(() => {
		if (!selectedFlight)
			return {
				flightNumber: '',
				type: '',
				aircraft: '',
			};
		const splitFlight = selectedFlight?.split('-');
		return {
			flightNumber: splitFlight[0],
			type: splitFlight[1],
			aircraft: splitFlight[2],
		};
	}, [selectedFlight]);

	// sectors
	const outboundSectors = seatData?.sectors?.outbound ?? [];
	const inboundSectors = seatData?.sectors?.inbound ?? [];

	// passengers
	const passengers = state?.bookingDetails?.pax ?? [];

	// selectionAvailable
	const selectionAvailable = useMemo(() => {
		if (!passengers.length) return false;
		if (!seatData?.templates) return false;

		const template = seatData.templates[aircraft];

		if (!outboundSectors.length || !inboundSectors.length || !template)
			return false;

		return true;
	}, [
		aircraft,
		passengers,
		inboundSectors,
		outboundSectors,
		seatData?.templates,
	]);

	// function to get the template for the flight
	const getTemplate = (template) => {
		if (!template || !seatData?.templates) return null;
		return seatData?.templates[template] ?? null;
	};

	// get the availability of the flight
	const getAvailability = useCallback(
		(flight) => {
			const template = getTemplate(flight?.template);
			// check if the template is available
			if (!template) return false;
			return true;
		},
		[seatData?.templates]
	);

	return (
		<SeatSelectionContext.Provider
			value={{
				// functions
				getTemplate,
				getAvailability,
				setSelectedFlight,
				setError,

				// values
				error,
				passengers,
				selectedFlight,
				outboundSectors,
				inboundSectors,
				selectionAvailable,
				templates: seatData?.templates,
			}}
		>
			{children}
		</SeatSelectionContext.Provider>
	);
}

/**
 * @typedef {Object} returnValues
 * @property {Array} outboundSectors - The outbound flight selectors
 * @property {Array} inboundSectors - The inbound flight selectors
 * @property {Object} templates - The seat selection templates
 * @property {(flight: string)=>boolean} getAvailability - A function to check if seat selection is available
 * @property {Array} passengers - The passengers
 * @property {Object} selectedFlight - The selected flight
 * @property {(flight: string)=> void} setSelectedFlight - A function to set the selected flight
 * @property {boolean} selectionAvailable - A boolean to check if seat selection is available
 * @property {(flight: string)=>Object} getTemplate - A function to get the template for the flight
 * @property {(param: string)=>void} setError - A function to set the error
 * @property {string} error - The error message
 */

/**
 * @name useSeatSelection
 * @description A hook to consume the SeatSelectionContext
 * @returns {returnValues} - The SeatSelectionContext values
 * @example
 * import { useSeatSelection } from '@/context';
 * ...
 * const { outbound, inbound, getAvailability } = useSeatSelection();
 * ...
 */

export const useSeatSelection = () => {
	const context = useContext(SeatSelectionContext);
	if (context === undefined) {
		throw new Error(
			'useSeatSelection must be used within a SeatSelectionProvider'
		);
	}
	return context;
};
