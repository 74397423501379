import PropTypes from 'prop-types';
import { useEffect, useMemo, useState, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
	FlightListItem,
	FlightCalendar,
	FlightFareDetails,
} from '@components/flights';
import { useBookingStore } from '@/store';
import { Heading, LoopWrapper, Text, Button } from '@/components/common';
import { isValid } from 'date-fns';

/**
 * @typedef {Object} FlightListProps
 * @property {Array} results
 * @property {Object} extra
 * @property {string | Date} date
 * @property {"outbound" | "inbound"} instance
 * @property {(param: string | Date)=>void} handleDateChange
 */

/**
 * @name FlightList
 * @description Renders the flight list component
 * @param {FlightListProps} props
 * @returns { React.JSX.Element}
 * @example
 * <FlightList />
 */

function FlightList({
	date,
	results,
	extra,
	dayPrices,
	title = 'Outbound',
	handleDateChange,
	instance = 'outbound',
}) {
	const containerRef = useRef(null);
	const queryClient = useQueryClient();
	const { setBookingState } = useBookingStore();
	const state = useBookingStore((store) => store.flights);
	const [selectedDate, setSelectedDate] = useState(() => {
		if (date) {
			return new Date(date);
		}
		return new Date();
	});
	const [visibleItemCount, setVisibleItemCount] = useState(3);

	const selectedCabin = useMemo(() => {
		if (state?.selected?.selectedCabin?.[instance]) {
			return state?.selected?.selectedCabin?.[instance];
		}
		return null;
	}, [state]);

	useEffect(() => {
		if (!date) return;

		const newDate = new Date(date);
		if (isValid(newDate)) setSelectedDate(newDate);
	}, [date]);

	useEffect(() => {
		if (!selectedCabin) return;
		const selectedFlightKey = selectedCabin?.split('-')[1];
		const itemIndex = sortedResults.findIndex(
			(item) => item.key === selectedFlightKey
		);

		if (itemIndex > 3) {
			setVisibleItemCount(sortedResults.length);
		}
	}, [selectedCabin]);

	const handleChange = (date) => {
		setSelectedDate(date);

		// clear states when date changes
		const newState = {
			...state,
			selected: {
				...state?.selected,
				items: [
					{
						...(state?.selected?.items?.[0] ?? {}),
						type: 'flight',
						[instance]: {},
					},
				],
				[instance]: undefined,
				isDateChange: true,
				selectedCabin: {
					...state?.selected?.selectedCabin,
					[instance]: '',
				},
				selectedFare: {
					...state?.selected?.selectedFare,
					[instance]: {},
				},
			},
			questions: [],
			preview: undefined,
			optionPlusOptions: undefined,
			baggage: undefined,
			selectedSeats: undefined,
		};

		// clear queries when date changes
		['baggage', 'seats', 'optionPlus'].forEach((key) => {
			queryClient.removeQueries({ queryKey: [key] });
		});

		if (state?.bookingDetails) {
			newState.bookingDetails = {
				...state?.bookingDetails,
				questions: [],
			};
		}

		setBookingState('flights', newState, 'CHANGE_DATE');

		if (handleDateChange && typeof handleDateChange === 'function') {
			handleDateChange(date);
		}
	};

	const sortedResults = useMemo(() => {
		if (!results?.length) return [];

		// sort flights by shortest duration first
		return results
			.reduce(
				(allFlights, res) =>
					res?.flights?.length ? [...allFlights, ...res?.flights] : allFlights,
				[]
			)
			.sort((a, b) => a?.durationInSeconds - b?.durationInSeconds);
	}, [results]);

	const handleLoadMore = () => {
		setVisibleItemCount(sortedResults.length);
	};

	const scrollToContainer = () => containerRef.current.scrollIntoView();

	return (
		<div ref={containerRef}>
			<div className="flex flex-col-reverse items-start w-full gap-4.5 lg:gap-2 mb-4 px-6 sm:p-0 lg:flex-row lg:items-center lg:justify-between">
				<Heading as="h2" className="text-lg font-bold font-body lg:text-4xl">
					{title}
				</Heading>
				{results?.length > 0 ? (
					<FlightFareDetails results={results} extra={extra} />
				) : null}
			</div>

			<FlightCalendar
				prices={dayPrices}
				activeDay={selectedDate}
				onChange={handleChange}
				captionClassName="px-6 sm:px-0"
				scrollToContainer={scrollToContainer}
			/>

			<div className="w-full bg-white border border-lighter-grey">
				{sortedResults?.length > 0 && (
					<div className="hidden grid-cols-4 border-b lg:grid">
						<div className="flex items-center justify-start col-span-2 px-4 py-2.5">
							<Text
								as="span"
								className="leading-snug tracking-tighter text-dark-grey text-opacity-65"
							>
								Select a flight and fare
							</Text>
						</div>
						<div className="flex items-center justify-center col-span-1 gap-2 bg-lighter-grey">
							<Text as="span">Eco</Text>
						</div>
						<div className="flex items-center justify-center col-span-1 gap-2 bg-light-grey">
							<Text as="span">Club</Text>
						</div>
					</div>
				)}
				<div className="flex flex-col w-full">
					<LoopWrapper
						list={sortedResults?.slice(0, visibleItemCount)}
						itemKey="key"
						renderFallback={() => (
							<div className="flex items-center justify-center p-10">
								<Text as="p" className="text-center">
									No flights are available on this date
								</Text>
							</div>
						)}
					>
						{(flight, flightIdx) => (
							<FlightListItem
								flight={flight}
								instance={instance}
								flights={sortedResults}
								flightIndex={flight?.key}
								isLastFlight={flightIdx === sortedResults?.length - 1}
							/>
						)}
					</LoopWrapper>
				</div>
			</div>

			{visibleItemCount !== sortedResults.length &&
				sortedResults?.length > 3 && (
					<Button
						hideIcon
						type="button"
						label="See more flights"
						className="mx-auto my-6 sm:mb-0"
						labelClassName="text-base font-bold"
						onClick={handleLoadMore}
						variant="supporting-yellow"
					/>
				)}
		</div>
	);
}

FlightList.propTypes = {
	params: PropTypes.object,
	results: PropTypes.array,
	extra: PropTypes.object,
};

FlightList.defaultProps = {
	params: {},
	results: [],
	extra: {},
};

export default FlightList;
