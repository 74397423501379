import { forwardRef, useState, useRef, useImperativeHandle } from 'react';
import ReactSelect, { components } from 'react-select';

import { cn } from '@/lib/utils';
import { useFetch } from '@/hooks';
import { Icon } from '@/components/common';

const classNames = (isValid) => ({
	container: (state) =>
		cn('w-full react-select-container', {
			'!pointer-events-auto !cursor-not-allowed': state.isDisabled,
		}),
	control: (state) => {
		return cn(
			'flex h-10.5 lg:h-13.25 w-full items-center justify-between gap-0 sm:gap-2.5 self-stretch rounded border border-border-color bg-white text-dark-grey px-3 focus-within:border-border-color',
			{
				'!outline-gray-400 ring-0': state.isFocused,
				'border-b-0 rounded-b-none shadow-none': state.selectProps.menuIsOpen,
				'border border-border-color rounded':
					state?.selectProps?.menuPlacement === 'auto' &&
					state.selectProps.menuIsOpen,
				'text-core-red border-core-red focus-within:border-core-red': !isValid,
				'text-lightest-grey border-lightest-grey/60 pointer-events-none':
					state.isDisabled,
			}
		);
	},
	valueContainer: () => cn('w-full border-0 focus:outline-0'),
	placeholder: () =>
		cn('text-lightest-grey leading-tight text-base truncate', {
			'text-core-red': !isValid,
		}),
	input: () =>
		cn(
			'w-full focus:outline-0 focus:ring-0 bg-transparent border-0 border-transparent focus:border-transparent'
		),
	menu: (state) => {
		return cn(
			'm-0 rounded-t-none border-t-none border border-border-color border-t-transparent rounded-b z-50 overflow-hidden hide-scrollbar',
			{
				' rounded border border-border-color shadow-sm':
					state.selectProps.menuPlacement === 'auto' &&
					state.selectProps.menuIsOpen,
			}
		);
	},
	menuList: () => cn('px-2 py-2 bg-white overflow-y-scroll z-60'),
	singleValue: () => cn('text-base text-black'),
	option: (state) => {
		return cn(
			'hover:bg-light-grey cursor-pointer text-dark-grey p-3 border-t border-lighter-grey first:border-t-0 last:border-b-0',
			{ 'bg-gray-100': state.isSelected }
		);
	},
	indicatorSeparator: () => cn('hidden'),
});

/**
 * @typedef {Object} option
 * @property {string} value
 * @property {string} label
 */

/**
 * @typedef {Object} SelectProps
 * @property {Array<option>} options
 * @property {string} className
 * @property {boolean} isAsync
 * @property {boolean} isValid
 * @property {string} endpoint
 * @property {string} queryKey
 * @property {()=> void} onChange
 * @property {boolean} allowDeselect
 * @property {()=> void} onDeselect
 * @property {option} value
 * */

/**
 * @name Select
 * @description A custom select component
 * @param {SelectProps & React.ReactHTMLElement<HTMLInputElement>} props
 * @param {React.Ref<HTMLInputElement>} ref
 * @returns {ReactSelect}
 * */

function Select(
	{
		options,
		className,
		isAsync,
		isValid,
		endpoint,
		queryKey,
		onChange,
		allowDeselect = false,
		onDeselect,
		value,
		disabled,
		portalled = false,
		menuPlacement = 'bottom',
		filterAsyncOptions,
		...props
	},
	ref
) {
	const [hasInput, setHasInput] = useState(false);
	const selectRef = useRef(null);
	const menuObserver = useRef({});
	const [selectHeight, setSelectHeight] = useState(300);

	let data = options;
	let loading = false;

	useImperativeHandle(ref, () => selectRef.current);

	const onMenuOpen = () => {
		const observeOnscreen = (entries = []) => {
			const { boundingClientRect, intersectionRect } = entries[0];
			const isOffscreen = boundingClientRect.height > intersectionRect.height;
			if (isOffscreen) {
				setSelectHeight(intersectionRect.height);
			}
		};

		setTimeout(() => {
			const menuList = selectRef.current?.menuListRef;
			menuObserver.current = new IntersectionObserver(observeOnscreen);
			menuObserver.current.observe(menuList);
		}, 1);
	};

	const onMenuClose = () => {
		setSelectHeight(300);
		menuObserver.current.disconnect();
	};

	if (isAsync) {
		const { data: res, isLoading } = useFetch({
			key: queryKey,
			endpoint,
			config: {
				enabled: isAsync && !!endpoint,
				retyOnWindowFocus: false,
			},
			format: (res) => {
				return Object.entries(res?.data || {}).map(([key, value]) => ({
					value: key,
					label: value,
				}));
			},
		});

		data =
			res?.length && typeof filterAsyncOptions === 'function'
				? filterAsyncOptions(res)
				: res || [];
		loading = isLoading;
	}

	return (
		<ReactSelect
			unstyled
			ref={selectRef}
			options={data}
			className={cn('w-full', className)}
			classNames={classNames(isValid)}
			menuPlacement={menuPlacement}
			onInputChange={(inputValue) => {
				setHasInput(inputValue ? true : false);
			}}
			onChange={(newValue) => {
				// reset field value if allowing deselection
				if (allowDeselect && !hasInput && newValue?.value === value?.value) {
					onChange(null);

					if (typeof onDeselect === 'function') onDeselect();
					return;
				}

				onChange(newValue);
			}}
			defaultValue={value || null}
			value={value || null}
			isLoading={loading}
			isDisabled={disabled}
			components={{
				DropdownIndicator,
				Input,
			}}
			menuPortalTarget={portalled ? document.body : null}
			styles={{ menuPortal: (base) => ({ ...base, zIndex: 90 }) }}
			onMenuOpen={onMenuOpen}
			onMenuClose={onMenuClose}
			maxMenuHeight={selectHeight}
			{...props}
		/>
	);
}

const Input = (props) => {
	return (
		<components.Input
			{...props}
			className={cn('bg-transparent focus:ring-0', props.selectProps.className)}
		/>
	);
};

function DropdownIndicator(props) {
	return (
		<components.DropdownIndicator {...props}>
			<Icon
				name="chevron-down"
				className={cn(
					'transition-all appearance-none',
					props.selectProps.menuIsOpen && 'rotate-180'
				)}
			/>
		</components.DropdownIndicator>
	);
}

export default forwardRef(Select);
