import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import { Button } from '@/components/common';
import { cn, currencyFormatter } from '@/lib/utils';
import { BlockLayout, RailSummary } from '@components/holidays';

/**
 * @typedef {Object} RailBlockProps
 * @property {string} className
 * @property {string} title
 * @property {import("@components/holidays/molecules/rail-summary").railObject} rail
 * */

/**
 * @name RailBlock
 * @description Renders a travel plan block with the rail details
 * @param {RailBlockProps} props
 * @returns {JSX.Element | null}
 * @example
 * <RailBlock
 *   Rail={{
 *    from: 'Calgary Airport',
 *    to: 'Calgary Downtown',
 *    date: '2023-09-01T09:50:00+00:00',
 *    details: 'Lorem ipsum dolor sit amet'
 * }}
 * />
 * */

function RailBlock({ rail, className, title, renderStep }) {
	return (
		<ErrorBoundary FallbackComponent={<h2>Something went wrong</h2>}>
			<BlockLayout
				iconName="rail"
				date={rail.date}
				title={title}
				className={className}
				renderStep={renderStep}
			>
				<RailSummary
					rail={rail}
					renderColumns={({ detailsToggle, detailsOpen, onToggle, isOpen }) => (
						<div className="hidden col-span-2 md:flex flex-col justify-end w-full lg:items-end">
							<Button
								hideIcon
								className={cn('w-fit', {
									'underline underline-offset-4': isOpen,
								})}
								variant={isOpen ? 'unstyled' : 'core-blue'}
								label={
									isOpen
										? 'Cancel'
										: `Upgrade from ${currencyFormatter({
											amount: 613,
											params: { hideDecimal: true },
										})}`
								}
								onClick={() => {
									if (detailsOpen) {
										detailsToggle();
									}
									onToggle();
								}}
								disableAnimation
							/>
						</div>
					)}
				/>
			</BlockLayout>
		</ErrorBoundary>
	);
}

RailBlock.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	rail: PropTypes.shape({
		from: PropTypes.string,
		to: PropTypes.string,
		date: PropTypes.string,
		details: PropTypes.string,
	}),
};

RailBlock.defaultProps = {
	className: '',
	title: 'Rail',
	rail: {
		from: 'Calgary Airport',
		to: 'Calgary Downtown',
		date: '2024-06-14T18:20:00+00:00',
		details: 'Lorem ipsum dolor sit amet',
	},
};

export default RailBlock;
