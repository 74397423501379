import { useDisclosure } from '@/hooks';
import {
	Heading,
	Text,
	Icon,
	Button,
	Badge,
	MediaCarousel,
	RteStyledContent,
	Route,
} from '@/components/common';
import { cn, formatCarouselSlides, formatLocalDate } from '@/lib/utils';
import { format, isValid } from 'date-fns';
import pluralize from 'pluralize';
import { useMemo } from 'react';

function SummaryCard({ hotel, hideChange, className, justForShow }) {
	const { onToggle, isOpen } = useDisclosure(false);

	const formattedStartDate = hotel?.startDate ? formatLocalDate(hotel.startDate, 'dd MMMM yyyy') : null;
	const formattedEndDate = hotel?.endDate ? formatLocalDate(hotel.endDate, 'dd MMMM yyyy') : null;

	const slides = useMemo(
		() => formatCarouselSlides(hotel?.gallery),
		[hotel?.gallery]
	);

	return (
		<section className={cn('lg:p-5 w-full bg-white', className)}>
			<div className="flex flex-col-reverse gap-5 lg:flex-row">
				<div className="flex-1 p-5 lg:p-0 lg:mr-8">
					<Heading as="h2" className="font-body font-bold text-3xl mb-2">
						{hotel?.name}
					</Heading>
					{isOpen ? (
						// TODO: create a separate hotel details component
						<div className="py-3">
							{hotel?.facilities?.length > 0 && (
								<div>
									<Heading as="h4" className="font-body font-bold text-xl">
										Facilities
									</Heading>
									<div className="grid grid-cols-2 lg:grid-cols-3">
										{hotel.facilities.map((facility) => (
											<div key={facility?.id} className="flex items-center gap-2 my-2">
												<img
													src={facility?.image}
													alt={`${facility?.title} icon`}
													className="w-6 h-6"
												/>
												<Text as="span" >{facility?.title}</Text>
											</div>
										))}
									</div>
								</div>
							)}
							{hotel?.introtext && (
								<div className="my-4">
									<Heading as="h4" className="font-body font-bold text-xl">
										About the hotel
									</Heading>
									<Text className="text-base font-body text-dark-grey/75 leading-snug tracking-normal font-normal">
										{hotel.introtext}
									</Text>
								</div>
							)}
							{hotel?.nearby && (
								<div>
									<Heading as="h4" className="font-body font-bold text-xl">
										What's nearby
									</Heading>
									<RteStyledContent
										variant='normal'
										dangerouslySetInnerHTML={{ __html: hotel.nearby }}
									/>
								</div>
							)}
						</div>
					) : null}
					{(hotel?.facilities?.length > 0 || hotel?.introtext || hotel?.nearby) && (
						<Button variant="unstyled" onClick={onToggle} className="my-2 gap-1 hover:opacity-75">
							<Text as="span" className="leading-tight text-sm">Info</Text>
							<Icon
								className="w-3 h-3"
								name={isOpen ? 'chevron-up' : 'chevron-down'}
							/>
						</Button>
					)}
					<div>
						{/* {justForShow ? null : (
							<Badge text="Recommended" variant="supporting-yellow" />
						)} */}
						{(formattedStartDate || formattedEndDate) ? (
							<Route
								className="my-3"
								iconName="hotel"
								renderFrom={() => (
									<Text as="span" className="flex flex-col">
										<Text as="span">Check in</Text>
										<Text as="span" className="font-bold">
											{formattedStartDate ? formattedStartDate : '-'}
										</Text>
									</Text>
								)}
								renderTo={() => (
									<Text as="span" className="flex flex-col">
										<Text as="span">Check out</Text>
										<Text as="span" className="font-bold">
											{formattedEndDate ? formattedEndDate : '-'}
										</Text>
									</Text>
								)}
							/>
						) : null}

						{hotel?.rooms?.length > 0 && (
							<div className="flex items-start gap-1">
								<Text as="span">{pluralize('Room', hotel.rooms.length)}:</Text>

								<Text as="span" className="font-bold">
									{hotel.rooms.map(
										(room) => room?.name + (room?.boardBasis ? ` - ${room?.boardBasisDisplayName || room?.boardBasis}` : '')
									).filter((name) => name).join(', ')}
								</Text>

								{hideChange ? null : (
									<Button
										variant='unstyled'
										className="inline text-base font-bold hover:opacity-75"
										onClick={() => console.log('change room')}
									>
										<Text as="span" className="font-bold">
											(
											<Text as="span" className="underline-offset-4 underline font-bold">change</Text>
											)
										</Text>
									</Button>
								)}
							</div>
						)}
					</div>
				</div>
				{slides?.length > 0 && (
					<div className="flex flex-col items-end justify-start gap-2 h-72 md:h-[35vh] lg:h-[32.5vh] w-full lg:w-1/3">
						<div
							className="w-full col-span-1 h-full max-h-72 md:max-h-[35vh] lg:max-h-full"
						>
							<MediaCarousel
								slides={slides}
								showSlideTitles
								theme="dark"
								className="h-full"
							/>
						</div>
					</div>
				)}
			</div>
		</section>
	);
}
export default SummaryCard;
