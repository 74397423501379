import React from 'react';
import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { withPortal } from '@/HOCs/withPortal';
import { Text, Button } from '@components/common';

const travelAgentLinks = [
	{
		title: 'Travel Agent Home',
		href: '/home',
	},
	{
		title: 'My Quotes',
		href: '/quotes',
	},
	{
		title: 'My Bookings',
		href: '/bookings',
	},
];

const myCafLinks = [
	{
		title: 'My Canadian Affair',
		href: '/home',
	},
	{
		title: 'My bookings',
		href: '/quotes',
	},
	{
		title: 'Help',
		href: '/bookings',
	},
];

/**
 * @typedef {Object} AuthHeaderProps
 * @property {string} className
 * @property {"mycaf" | "travel-agent"} instance
 */

/**
 * @name AuthHeader
 * @description Renders the header for the travel agent portal
 * @param {AuthHeaderProps} props
 * @returns {JSX.Element}
 * @example
 * <TravelAgentHeader />
 * */

function AuthHeader({ className, instance }) {
	// TODO: Add logic to determine if user is logged in && also get the user's name
	const user = null;

	const links = instance === 'travel-agent' ? travelAgentLinks : myCafLinks;

	if (!user) return null;
	return (
		<div
			className={cn(
				'h-10 w-full bg-core-blue text-white',
				{ 'bg-core-red': instance === 'travel-agent' },
				className
			)}
		>
			<div className="flex h-full container-fluid mx-auto items-center justify-between">
				<ul className="flex items-center gap-8 shrink grow basis-0">
					{links.map((link) => (
						<li key={link?.title} className="inline-block">
							<Text as="a" href={link.href} className="text-white font-normal">
								{link.title}
							</Text>
						</li>
					))}
				</ul>

				<div className="flex items-center justify-end gap-6 shrink grow basis-0">
					<Text as="span">Logged in as {user?.name}</Text>
					<Button
						label="Logout"
						iconName="user"
						disableAnimation
						variant="unstyled"
						iconClassName="w-5 h-5"
						onClick={() => console.log('logout')}
						labelClassName="text-white font-normal"
					/>
				</div>
			</div>
		</div>
	);
}

AuthHeader.propTypes = {
	className: PropTypes.string,
};

AuthHeader.defaultProps = {
	className: '',
};

export default withPortal(AuthHeader, 'booking-Auth-header');
