import { withPortal } from '@/HOCs/withPortal';
import { BookingWidget } from '@/components/common';

const containerId = 'booking-hotels-booking-widget';

function HotelsBookingWidget() {
	return (
		<BookingWidget
			options={[
				// { label: 'Hotels & Flights', value: 'hotels&flights', icon: 'flights' },
				{ label: 'Hotel Only', value: 'hotels', icon: 'hotel' },
			]}
			containerId={containerId}
		/>
	);
}
export default withPortal(HotelsBookingWidget, containerId);
