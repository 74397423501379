import PropTypes from 'prop-types';
import { Text } from '@/components/common';
import { cn } from '@/lib/utils';

function BookingDetailCard({ title, content }) {
    return (
        <div className={cn(
            'flex-col flex items-start justify-start py-2 sm:py-4 border-b border-lighter-grey w-full',
            !(typeof content === 'object' && content?.length > 1) && 'md:flex-row md:items-center md:justify-between md:gap-2',
        )}>
            {/* Title */}
            <Text as="span" className="md:text-lg font-bold tracking-tighter md:tracking-tighter leading-loose-snug md:leading-loose-snug">
                {title}
            </Text>

            {/* Content */}
            {typeof content === 'object' && content?.length > 1 ? (
                <ul className="leading-tight w-fit font-body md:text-lg">
                    {content.map((text, idx) => (
                        <li key={idx}>
                            <Text as="span" className="tracking-tighter md:tracking-tighter leading-loose-snug md:leading-loose-snug">
                                {text}
                            </Text>
                        </li>
                    ))}
                </ul>
            ) : (
                <Text as="span" className="md:text-lg tracking-tighter md:tracking-tighter leading-loose-snug md:leading-loose-snug">
                    {typeof content === 'string' ? content : content?.toString()}
                </Text>
            )}
        </div>
    );
}

BookingDetailCard.propTypes = {
    title: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.number
    ]),
}

export default BookingDetailCard;
