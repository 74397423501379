import { Heading } from '@/components/common';
import { cn, randomString } from '@/lib/utils';
import { EnhanceItem } from '@components/mycaf';

const enhancements = [
	{
		id: randomString('enh', 6),
		title: 'Add a hotel',
		btnLabel: 'Search Hotels',
		href: '#',
		image:
			'https://assets.architecturaldigest.in/photos/60084dd6cce5700439e12bf7/16:9/w_2560%2Cc_limit/modern-living-room-decor-1366x768.jpg',
		price: 59,
		description:
			'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione consequuntur magnam!',
	},
	{
		id: randomString('enh', 6),
		title: 'Add Car Hire',
		btnLabel: 'Search Car Hire',
		href: '#',
		image:
			'https://assets.architecturaldigest.in/photos/60084dd6cce5700439e12bf7/16:9/w_2560%2Cc_limit/modern-living-room-decor-1366x768.jpg',
		price: 22,
		description:
			'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione consequuntur magnam!',
	},
	{
		id: randomString('enh', 6),
		title: 'Add Motorhome Hire',
		btnLabel: 'Explore',
		href: '#',
		image:
			'https://assets.architecturaldigest.in/photos/60084dd6cce5700439e12bf7/16:9/w_2560%2Cc_limit/modern-living-room-decor-1366x768.jpg',
		price: 63,
		description:
			'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione consequuntur magnam!',
	},
];
function EnhanceList({ className }) {
	return (
		<section
			className={cn(
				'py-10 flex flex-col gap-5 items-center justify-center',
				className
			)}
		>
			<Heading as="h1" className="text-bold font-body text-8xl text-center">
				Enhance your trip
			</Heading>
			<hr className="w-10 mx-auto border border-supporting-coral" />
			<div className="container mx-auto px-6 max-w-screen-2xl grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
				{enhancements.map((enhancement) => (
					<EnhanceItem key={enhancement?.id} enhancement={enhancement} />
				))}
			</div>
		</section>
	);
}
export default EnhanceList;
