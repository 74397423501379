import React from 'react';
import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { Icon, Text } from '@components/common';

/**
 * @name DirectionalArrow
 * @description Renders a directional arrow that grows to the fillable area
 * @returns {React.JSX.Element}
 * @example
 * <DirectionalArrow className="mt-4" />
 */
function DirectionalArrow({ className }) {
	return (
		<Text
			as="span"
			className={cn(
				'inline-flex shrink grow items-center text-current',
				className
			)}
		>
			<hr className="flex h-px  bg-current border-current shrink grow" />
			<Icon name="arrow-right" className="fill-current" />
		</Text>
	);
}

DirectionalArrow.propTypes = {
	className: PropTypes.string,
};

export default DirectionalArrow;
