import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SaveAndShare, Select, Text } from '@/components/common';
import { useUrlParams } from '@/hooks';
import { cn } from '@/lib/utils';

function SortBar({
	paramsKey,
	sortOptions,
	hideSortField,
	pageOptions,
	hidePerPageField,
	hideSaveAndShare,
	summary,
	defaultSort,
	defaultPerPage,
	disabled,
}) {
	const [currentSort, setCurrentSort] = useState(defaultSort || sortOptions[0]);
	const [currentPerPage, setCurrentPerPage] = useState(
		defaultPerPage || pageOptions[0]
	);
	const { params, updateParams } = useUrlParams(paramsKey);

	// update query params when sort/per page changes
	useEffect(() => {
		if (!paramsKey) return;
		if (!params.default) return;

		const defaultSort = sortOptions.length > 0 ? sortOptions[0].value : null;
		const defaultPerPage = pageOptions.length > 0 ? pageOptions[0].value : 10;

		const existingParams = params[paramsKey] || {};
		const newParams = {
			sort: currentSort?.value || defaultSort,
			perPage: currentPerPage?.value || defaultPerPage,
		};

		updateParams({ ...existingParams, ...newParams });
	}, [paramsKey, currentSort?.value, currentPerPage?.value]);

	return (
		<div
			className={cn(
				'h-auto flex items-center px-7 md:px-0',
				hideSaveAndShare
					? 'justify-center lg:justify-between'
					: 'justify-between'
			)}
		>
			<div>{summary && <Text>{summary}</Text>}</div>
			{!(hideSaveAndShare && hideSortField && hidePerPageField) && (
				<div className="inline-flex items-center justify-between gap-8">
					{!hideSaveAndShare && <SaveAndShare />}
					{!(hideSortField && hidePerPageField) && (
						<div className="items-center justify-between hidden gap-3 xl:flex">
							{!hideSortField && sortOptions?.length > 0 && (
								<div className="flex items-center gap-2 shrink grow w-fit">
									<Text className="w-auto flex-2">Sort by</Text>
									<Select
										options={sortOptions}
										placeholder="Select"
										className="flex-1 w-auto"
										onChange={(option) =>
											setCurrentSort(option?.value ? option : currentSort)
										}
										value={currentSort}
										isValid
										disabled={disabled}
									/>
								</div>
							)}
							{!hidePerPageField && pageOptions?.length > 0 && (
								<div className="flex items-center gap-2 shrink grow">
									<Text className="w-fit flex-2">Results per page</Text>
									<Select
										options={pageOptions}
										className="flex-1 w-auto"
										placeholder="Select"
										value={currentPerPage}
										onChange={(option) =>
											setCurrentPerPage(option?.value ? option : currentPerPage)
										}
										isValid
										disabled={disabled}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
}

SortBar.propTypes = {
	sortOptions: PropTypes.array,
	hideSortField: PropTypes.bool,
	pageOptions: PropTypes.array,
	hidePerPageField: PropTypes.bool,
	hideSaveAndShare: PropTypes.bool,
	summary: PropTypes.string,
	disabled: PropTypes.bool,
};

SortBar.defaultProps = {
	sortOptions: [],
	hideSortField: false,
	pageOptions: [],
	hidePerPageField: false,
	hideSaveAndShare: false,
	disabled: false,
};

export default SortBar;
