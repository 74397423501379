/**
 * @typedef {Object} DateOfBirth
 * @property {number} day
 * @property {number} month
 * @property {number} year
 */

/**
 * @name calculateAge
 * @description Calculate the age of a person based on their date of birth
 * @category Utils
 * @param {DateOfBirth} dob 
 * @returns  {number} age
 * @example
 * import { calculateAge } from '@/lib/utils';
 * const age = calculateAge({ day: 1, month: 1, year: 1990 }); // 32
 */

export function calculateAge(dob) {
    let dobObj = dob;

    if (typeof dob === 'string') {
        const date = new Date(dob);
        dobObj = {
            day: date.getDate(),
            month: date.getMonth() + 1, // month is zero-based
            year: date.getFullYear()
        }

        console.log(date, dobObj)
    }
    const { day, month, year } = dobObj;
    const dobDate = new Date(year, month - 1, day);
    const age = new Date().getFullYear() - dobDate.getFullYear();
    if (
        new Date().getMonth() < dobDate.getMonth() ||
        (new Date().getMonth() === dobDate.getMonth() &&
            new Date().getDate() < dobDate.getDate())
    ) {
        return age - 1; // if the current date is before the birthday, subtract 1 from the age
    }
    return age;
}
