import { useFormContext, useFormState } from 'react-hook-form';

import { cn } from '@/lib/utils';
import { FormField, Heading } from '@components/common';

function PreferredContactForm({ className }) {
	const { control } = useFormContext();
	const { errors } = useFormState({ control });
	return (
		<div className={cn('w-full', className)}>
			<div>
				<Heading as="h3" className="my-4 text-xl font-bold font-body">
					Preferred Contact Method
				</Heading>
				<div className="flex flex-col gap-3">
					<FormField
						name="marketing.email"
						label="Email"
						as="toggle-group"
						control={control}
						options={[
							{
								label: 'No thanks',
								value: 'no',
							},
							{
								label: 'Yes please',
								value: 'yes',
							},
						]}
						onChange={(field, value) => {
							if (!value || value === '') return;
							field.onChange(value);
						}}
						errors={errors}
					/>
					<FormField
						name="marketing.phone"
						label="Phone"
						as="toggle-group"
						control={control}
						options={[
							{
								label: 'No thanks',
								value: 'no',
							},
							{
								label: 'Yes please',
								value: 'yes',
							},
						]}
						onChange={(field, value) => {
							if (!value || value === '') return;
							field.onChange(value);
						}}
						errors={errors}
					/>
				</div>
			</div>
		</div>
	);
}

export default PreferredContactForm;
