/**
 * Reusable helper functions for building carousel data.
 */

export const formatCarouselSlides = (gallery) => {
    if (!gallery?.length) return [];

    return gallery
        .map((item) => {
            if (!(item?.image || item?.video)) return;

            const slide = { alt: item.title };
            if (item.image) slide.src = item.image;
            if (item.video) slide.video = item.video;

            return slide;
        })
        .filter((slide) => slide);
};
