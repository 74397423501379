import { useRef, useEffect } from 'react';
import { useDimensions, useBreakpoint } from '@/hooks';

/**
 * @name useCalculateMargin
 * @description Calculates the margin bottom of the container to ensure the footer is always at the bottom of the page
 * @param {"sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl"} breakpoint
 * @returns {{ observe: React.MutableRefObject<null>, containerRef: React.MutableRefObject<null> }}
 * @example
 * const { observe, containerRef } = useCalculateMargin();
 */

function useCalculateMargin(breakpoint = 'md') {
	const containerRef = useRef(null);
	const isBreakpoint = useBreakpoint(breakpoint);
	const { observe, height } = useDimensions();

	useEffect(() => {
		if (isBreakpoint && containerRef.current && observe) {
			const calculatedMarginBottom = height - containerRef.current.offsetHeight;
			containerRef.current.style.marginBottom = `${calculatedMarginBottom}px`;
		}
	}, [isBreakpoint, height]);

	return { observe, containerRef };
}
export default useCalculateMargin;
