import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
	Tag,
	Text,
	Heading,
	buttonVariants,
	MediaCarousel,
} from '@components/common';
import { cn, currencyFormatter } from '@/lib/utils';

/**
 * @typedef {Object} FavouritesCardProps
 * @property {string} className
 * @property {Object} favourite
 */

/**
 * @name FavouritesCard
 * @description Renders a favourites card
 * @param {FavouritesCardProps} props
 * @returns {React.JSX.Element}
 * @example
 * <FavouritesCard favourite={favourite} />
 */

function FavouritesCard({ favourite = null, className }) {
	return (
		<div
			className={cn(
				'w-full overflow-hidden rounded-md h-fit shadow-md bg-white',
				className
			)}
		>
			<div className="relative w-full h-[30vh]">
				<MediaCarousel className="w-full h-full" />
				<Tag
					label="Tag"
					variant="blurred"
					className="absolute top-4 left-4 z-10 rounded-md px-3 py-1"
				/>
			</div>
			<div className="flex flex-col gap-2 p-8">
				<Heading className="text-3xl font-bold text-core-blue">
					Title Goes here
				</Heading>
				<Heading as="h3" className="text-lg font-bold text-core-blue font-body">
					Smaller title goes here
				</Heading>
				<Text className="text-lightest-grey">
					Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illo,
					voluptatem. Fugit quo, amet dolore minima animi expedita inventore
					fuga temporibus.
				</Text>
				<Text as="small" className="font-bold text-sm">
					3 Nights From
				</Text>
				<Text as="span" className="text-2xl font-bold text-core-blue">
					{currencyFormatter({ amount: 2295, params: { hideDecimal: true } })}{' '}
					<small className="text-xs">pp</small>
				</Text>

				<Link className={cn(buttonVariants())} to="#favourite">
					View & Book
				</Link>
			</div>
		</div>
	);
}

FavouritesCard.propTypes = {
	className: PropTypes.string,
	favourite: PropTypes.object || null,
};

export default FavouritesCard;
