import React, { useRef, useMemo, useState, useEffect } from 'react';
import qs from 'qs';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import {
	Icon,
	Text,
	Button,
	Heading,
	Dialog,
	buttonVariants,
} from '@components/common';
import { HolidayEnquiryForm } from '@/components/holidays';
import { cn, currencyFormatter, navigate } from '@/lib/utils';
import { useFetch, useUrlParams, useBreakpoint } from '@/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EnquirySchema } from '@/validationSchemas';

/**
 * @typedef {Object} option
 * @property {string} label
 * @property {"hotels" | "hotels&flights" | "motorhome" | "itinerary"} value
 * @property {import("@components/common/atoms/icon").iconNames} icon
 */

/**
 * @typedef {Object}  EnquiryWidgetProps
 * @property {Object} enquiryDataset
 **/

/**
 * @name EnquiryWidget
 * @description A enquiry widget that allows users to book hotels, motorhomes, flights, and itineraries.
 * @param {EnquiryWidgetProps} props
 * @returns {React.JSX.Element}
 * @example
 * <EnquiryWidget options={[]} containerId="booking-hotels-booking-widget" />
 * @todo TODO: Add rest validation schemas
 **/

function EnquiryWidget({ containerId }) {
	const getQuoteRef = useRef(null);
	const isSm = useBreakpoint('sm');
	const isLg = useBreakpoint('lg');

	const [enquiryDataset, setEnquiryDataset] = useState({});
	const [submitData, setSubmitData] = useState(null);
	const [holidayEnquiryFormSubmitUrl, setHolidayEnquiryFormSubmitUrl] =
		useState('');
	const { params } = useUrlParams();

	// get the code from the portal container
	const code = useMemo(() => {
		const container = document.getElementById(containerId);
		if (container) {
			setEnquiryDataset(container.dataset);
		}
		return 'BAN001';
	}, [containerId]);

	const urlParams = useMemo(() => {
		if (params?.default) {
			return {
				code: code,
				...params.default,
			};
		}
		return {
			code: code,
		};
	}, [code, params?.default]);

	// set defaultValues for the form
	const defaultValues = useMemo(() => {
		const from = urlParams?.from ? urlParams.from.split(':') : [];
		const when = urlParams?.when ? urlParams.when : '';
		const adults = urlParams?.adults ? urlParams.adults.split(':') : [];
		const children = urlParams?.children ? urlParams.children.split(':') : [];
		const infants = urlParams?.infants ? urlParams.infants.split(':') : [];
		const firstName = urlParams?.firstName ? urlParams.firstName : '';
		const surname = urlParams?.surname ? urlParams.surname : '';
		const phone = urlParams?.phone ? urlParams.phone : '';
		const email = urlParams?.email ? urlParams.email : '';
		const flexibleTravel = urlParams?.flexibleTravel
			? urlParams.flexibleTravel.split(':')
			: '';
		const preferredContactMethod = urlParams?.preferredContactMethod
			? urlParams.preferredContactMethod.split(':')
			: [];
		const contactDaysTimes = urlParams?.contactDaysTimes
			? urlParams.contactDaysTimes
			: '';

		return {
			from:
				from.length > 0
					? {
							value: from[0] ? from[0] : '',
							label: from[1] ? from[1] : '',
					  }
					: null,
			when: when,
			rooms: formatRooms(adults, children, infants),
			firstName: firstName,
			surname: surname,
			phone: phone,
			email: email,
			flexibleTravel:
				flexibleTravel.length > 0
					? {
							value: flexibleTravel[0] ? flexibleTravel[0] : '',
							label: flexibleTravel[1] ? flexibleTravel[1] : '',
					  }
					: null,
			preferredContactMethod:
				preferredContactMethod.length > 0
					? {
							value: preferredContactMethod[0] ? preferredContactMethod[0] : '',
							label: preferredContactMethod[1] ? preferredContactMethod[1] : '',
					  }
					: null,
			contactDaysTimes: contactDaysTimes,
		};
	}, [urlParams, code]);

	const {
		watch,
		reset,
		resetField,
		control,
		setValue,
		setError,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(EnquirySchema),
	});

	useEffect(() => {
		reset(defaultValues, { keepDirtyValues: true, keepDirty: true });
	}, [defaultValues, reset]);

	const { data, error, isLoading } = useFetch({
		key: 'request-a-quote',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			SameSite: 'Secure',
		},
		customEndpoint: '/ajax/request-a-quote',
		method: 'POST',
		useBody: true,
		params: submitData,
		config: {
			enabled: !!submitData,
		},
		onData: (data) => {
			if (data?.success) {
				if (getQuoteRef.current) {
					getQuoteRef.current.onClose();
				}
				navigate('/thank-you-quote-holiday');
			}
		},
	});

	const onSubmit = (values) => {
		const {
			from,
			when,
			rooms,
			firstName,
			surname,
			phone,
			email,
			flexibleTravel,
			preferredContactMethod,
			contactDaysTimes,
		} = values;

		const formattedRooms = {
			adults: 0,
			children: 0,
			infants: 0,
		};

		if (rooms?.length) {
			Object.keys(rooms[0]).map((type) => {
				const roomInfo = rooms[0][type];
				if (!roomInfo) return;

				formattedRooms[type] = roomInfo.value;
			});
		}

		const submitParams = {
			hotelCode: code,
			from: `${from.value}:${from.label}`,
			when,
			people: JSON.stringify(formattedRooms),
			firstName,
			surname,
			phone,
			email,
			flexibleTravel,
			preferredContactMethod,
			holidayEnquiryFormSubmitUrl: window.location.href,
			...(preferredContactMethod === 'phone' && {
				contactDaysTimes: contactDaysTimes,
			}),
		};

		setSubmitData(submitParams);
		setHolidayEnquiryFormSubmitUrl(window.location.href);
		// setHolidayEnquiryFormSubmitUrl(qs.stringify(submitParams));
	};

	const getQuoteModalComponent = () => (
		<Dialog
			ref={getQuoteRef}
			as="modal"
			hideCloseBtn
			position="center"
			overlayClassName="sm:py-7.5 md:p-15"
			contentClassName="p-0 h-full overflow-y-auto"
			size={isSm ? 'screen' : 'xl'}
			renderTrigger={({ DialogTrigger, onOpen }) => (
				<DialogTrigger asChild>
					<Button
						label="Get a quote"
						onClick={onOpen}
						variant={isLg ? 'core-blue' : 'supporting-yellow'}
						className={cn(
							'justify-between py-4 lg:w-full',
							isLg && '!text-supporting-yellow py-3.25 shrink-0'
						)}
						labelClassName={cn(
							'text-lg font-body font-bold tracking-tighter',
							isLg && 'leading-less-snug'
						)}
					/>
				</DialogTrigger>
			)}
		>
			{({ CloseButton, onClose }) => (
				<div className="relative">
					<span className="flex items-center justify-end w-full h-12">
						<CloseButton
							onClick={onClose}
							className="relative top-0 right-0 w-12.5 h-full text-white bg-dark-grey"
							variant="square"
						/>
					</span>
					<div className="px-5 py-7.5 sm:px-10">
						<Heading
							as="h2"
							className="text-5xl font-bold tracking-tight font-body sm:font-display text-core-blue sm:text-8xl lg:text-10xl leading-extra-tight mb-2.5 w-full sm:text-center"
						>
							Get a quote
						</Heading>
						<Text
							as="p"
							className="mb-4.5 sm:mb-8 lg:mb-14 xl:mb-18 w-full sm:text-center"
						>
							Please complete the details below and one of our team will come
							back to you shortly.
						</Text>
						<form onSubmit={handleSubmit(onSubmit)} className="w-full">
							<input
								type="hidden"
								name="holidayEnquiryFormSubmitUrl"
								value={holidayEnquiryFormSubmitUrl}
							/>
							<HolidayEnquiryForm
								watch={watch}
								errors={errors}
								control={control}
								params={urlParams}
								setError={setError}
								setValue={setValue}
								enquiryDataset={enquiryDataset}
								disabledButton={isLoading}
							/>
						</form>
					</div>
				</div>
			)}
		</Dialog>
	);

	if (isLg) {
		return (
			<div className="fixed bottom-0 left-0 right-0 flex-row items-center justify-between flex lg:hidden bg-supporting-yellow/90 py-4 px-6.25 z-10">
				<div className="flex flex-col">
					<p className="flex flex-row items-center gap-1 sm:gap-2 grow">
						<Text
							as="span"
							className="block text-xs font-bold sm:text-sm md:text-base !leading-extra-tight tracking-extra-tight font-body text-black/50"
						>
							From
						</Text>
						<Text
							as="span"
							className="block text-base font-bold text-black sm:text-xl md:text-2xl font-body !leading-extra-tight tracking-extra-tight"
						>
							{currencyFormatter({
								amount: enquiryDataset?.price ?? null,
							})}
						</Text>
						<Text
							as="span"
							className="block text-xs font-bold sm:text-sm md:text-base !leading-extra-tight tracking-extra-tight font-body text-black/50"
						>
							per person
						</Text>
					</p>
					{enquiryDataset?.priceStatement && (
						<Text
							as="p"
							variant="body"
							className="mt-1 text-xs text-black md:text-sm font-body leading-loose-snug tracking-less-tigh"
						>
							{enquiryDataset?.priceStatement}
						</Text>
					)}
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="flex flex-col w-full gap-6 px-5 py-8 md:p-12">
				<Heading
					as="h2"
					className="w-full text-5xl font-medium tracking-tighter text-center font-display md:text-6xl lg:text-6.75xl leading-extra-snug"
				>
					Holiday Overview
				</Heading>

				<div className="flex flex-row items-center justify-center w-full gap-2">
					<Text
						as="span"
						className="font-bold leading-extra-tight tracking-extra-tight text-black/50"
					>
						From
					</Text>
					<Heading
						as="h3"
						className="text-5xl font-body leading-extra-tight tracking-extra-tight"
					>
						{currencyFormatter({
							amount: enquiryDataset?.price ?? null,
						})}
					</Heading>
					<Text
						as="span"
						className="font-bold leading-extra-tight tracking-extra-tight text-black/50"
					>
						per person
					</Text>
				</div>

				<div className="flex flex-row items-center justify-center gap-4">
					<Text className="flex items-center gap-2">
						<Icon name="moon" className="w-3.75 h-4.25" />
						<Text
							as="span"
							className="font-bold leading-snug tracking-tighter text-dark-grey"
						>
							{enquiryDataset?.nights || 1}{' '}
							{pluralize('night', enquiryDataset?.nights || 1)}
						</Text>
					</Text>
					<Text className="flex items-center gap-2">
						<Icon name="flights" className="w-4.5 h-4.25" />
						<Text
							as="span"
							className="font-bold leading-snug tracking-tighter text-dark-grey"
						>
							{enquiryDataset?.flights}
						</Text>
					</Text>
				</div>

				{enquiryDataset?.priceStatement && (
					<Text
						as="span"
						className="w-full font-normal leading-snug tracking-tighter text-center text-dark-grey"
					>
						{enquiryDataset?.priceStatement}
					</Text>
				)}

				<div className="flex flex-col w-full gap-2">
					<a
						href="tel:02034246305"
						className={cn(
							buttonVariants({
								variant: 'core-blue',
								className: 'w-full py-4 flex items-center justify-between',
							})
						)}
						variant="core-blue"
					>
						<Text as="span" className="flex items-center gap-2">
							<Icon name="phone" className="w-5 h-5.5" />
							<Text
								as="span"
								className="text-lg font-bold tracking-tighter font-body"
							>
								Call to book
							</Text>
						</Text>
						<Text
							as="span"
							className="text-lg font-bold tracking-tighter font-body"
						>
							0203 424 6305
						</Text>
					</a>

					{/* <Button
						type="button"
						label="Search & Book Online"
						className="justify-between w-full py-4"
						variant="core-blue"
						labelClassName="text-lg font-body font-bold tracking-tighter"
					/> */}
				</div>
			</div>
		</>
	);
}

const formatRooms = (adults, children, infants) => {
	return [
		{
			adults:
				adults.length > 0
					? {
							label: adults[0],
							value: parseInt(adults[1]),
					  }
					: null,
			children:
				children.length > 0
					? {
							label: children[0],
							value: parseInt(children[1]),
					  }
					: null,
			infants:
				infants.length > 0
					? {
							label: infants[0],
							value: parseInt(infants[1]),
					  }
					: null,
		},
	];
};

EnquiryWidget.propTypes = {
	enquiryDataset: PropTypes.object,
};

export default EnquiryWidget;
