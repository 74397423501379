import { ErrorBoundary } from 'react-error-boundary';

import { cn } from '@/lib/utils';
import { ErrorHandler, NotFound } from '@components/common';

function PaymentsIframe({ className, link, name }) {
	if (!link)
		return (
			<div className="h-48 flex items-center justify-center">
				<NotFound
					title="No payment link found"
					message="The payments link was not found, please try again"
				/>
			</div>
		);
	return (
		<ErrorBoundary FallbackComponent={ErrorHandler}>
			<iframe
				src={link}
				name={name}
				id="payments-iframe"
				className={cn('payments-iframe w-full [&>html]:h-20', className)}
			/>
		</ErrorBoundary>
	);
}
export default PaymentsIframe;
