// useBookingStore Store
import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';

import {
	resetState,
	setBookingState,
	resetNestedState,
	mergeFlightsParams,
} from './utils';
import {
	hotelsBooking,
	carhireBooking,
	flightsBooking,
	motorhomeHireBooking,
} from './actions/booking';
import { buildParams } from './actions/buildParams';
import { requestParams } from './actions/requestParams';

/**
 * @typedef {"hotels" | "flights" | "motorhome-hire" | "holidays" | "car-hire"} category
 */

/**
 * @typedef {Object} customError
 * @property {string} message
 * @property {string} [code]
 * @property {"default"|"custom"} [type]
 */

/**
 * @typedef {Object} defaultState
 * @property {boolean} isLoading
 * @property {boolean} previewLoading
 * @property {customError | null} error
 * @property {boolean} [shouldBuildParams]
 * @property {boolean} [bookingDisabled]
 * */

/**
 * @typedef {Object} initialState
 * @property {boolean} initialized
 * @property {defaultState | null} hotels
 * @property {defaultState | null} holidays
 * @property {defaultState | null} flights
 * @property {defaultState | null} motorhome-hire
 * @property {defaultState | null} carhire
 */

/**
 * @type {initialState}
 **/

const initialState = {
	initialized: false,
	hotels: {
		error: null,
		isLoading: false,
		previewLoading: false,
		shouldBuildParams: false,
	},
	holidays: {
		error: null,
		isLoading: false,
		previewLoading: false,
	},
	flights: {
		error: null,
		isLoading: false,
		previewLoading: false,
		shouldBuildParams: false,
		bookingDisabled: false,
		showOptionPlusSeatError: false,
	},
	'motorhome-hire': {
		error: null,
		isLoading: false,
		previewLoading: false,
	},
	'car-hire': {
		error: null,
		isLoading: false,
		previewLoading: false,
		shouldBuildParams: false,
	},
};

/**
 * @typedef {Object} storeMethods
 * @property {(key: category, value: any, label: string) => void} setBookingState
 * @property {(category: category) => void} buildParams
 * @property {() => void} mergeFlightsParams
 * @property {(key: import('./actions/requestParams').requestParamsKey, category: category) => void} requestParams
 * @property {(key: category) => void} submitBooking
 * @property {(key: category) => void} submitQuote
 * @property {(key: category) => void} resetState
 * @property {(category: category, keys: Array<string>) => void} resetNestedState
 * */

/**
 * @type {import('zustand').StateCreator<initialState & storeMethods>}
 * */
const store = (set, get) => ({
	...initialState,

	setBookingState: (key, value, label) =>
		setBookingState(key, value, set, label),
	buildParams: (category) => buildParams(get()[category], category),
	mergeFlightsParams: () => mergeFlightsParams(get),
	requestParams: (key, category) => {
		const state = get()[category];
		if (!state || !key) return null;

		return requestParams(key, state);
	},

	submitBooking: async (key) => {
		switch (key) {
			case 'car-hire':
				await carhireBooking(get);
				break;
			case 'motorhome-hire':
				await motorhomeHireBooking(get);
				break;
			case 'flights':
				await flightsBooking(get);
				break;
			case 'hotels':
				await hotelsBooking(get);
				break;
			default:
				break;
		}
	},

	submitQuote: async (key) => {
		switch (key) {
			case 'car-hire':
				await carhireBooking(get, true);
				break;
			case 'motorhome-hire':
				await motorhomeHireBooking(get, true);
				break;
			case 'flights':
				await flightsBooking(get, true);
				break;
			case 'hotels':
				await hotelsBooking(get, true);
				break;
			default:
				console.error(`Cannot create quote for category '${key}'.`);
				break;
		}
	},

	resetState: (key) => resetState(key, set, initialState),
	resetNestedState: (category, keys) => resetNestedState(category, keys, get),
});

// Persisting the store in session storage
const persistConfig = {
	name: 'booking-store',
	storage: createJSONStorage(() => sessionStorage),
};

const persistedStore = devtools(persist(store, persistConfig));

const useBookingStore = create(persistedStore);

export default useBookingStore;
