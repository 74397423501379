import React, { useEffect, useState } from 'react';
import { Text, Checkbox, Icon } from '@components/common';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';

function CheckBoxGroup({
	options,
	onChange,
	values,
	disabled,
	className,
	inputClassName,
	iconClassName,
	renderOptionChildren,
}) {
	const [selected, setSelected] = useState(() => {
		if (values && values.length > 0) {
			return values;
		}

		return [];
	});

	useEffect(() => {
		// handle external value reset
		if (values?.length !== selected?.length) setSelected(values || []);
	}, [values]);

	const handleChange = (option) => {
		let newValues = [...selected, option.value];
		if (selected.includes(option.value)) {
			newValues = selected.filter((value) => value !== option.value);
			setSelected(newValues);
			onChange(newValues);
			return;
		}

		setSelected(newValues);
		onChange(newValues);
	};

	return (
		<div className={cn('flex flex-col gap-2', className)}>
			{options.map((option) => {
				const checkboxEl = (
					<Checkbox
						id={option.value}
						name={option.value}
						onCheckedChange={() => handleChange(option)}
						checked={selected.includes(option.value)}
						disabled={disabled}
						className={inputClassName}
						iconClassName={iconClassName}
					/>
				);

				return renderOptionChildren ? (
					renderOptionChildren({ option, checkboxEl })
				) : (
					<div key={option.value} className="flex items-center justify-between">
						<div className="flex items-center gap-2">
							{option.icon && <Icon name={option.icon} className="w-4 h-4" />}
							{option.iconImage && (
								<img
									src={option.iconImage}
									alt={`${option.value} icon`}
									className="w-4 h-4"
									style={{
										filter:
											'brightness(0) saturate(100%) invert(19%) sepia(88%) saturate(2331%) hue-rotate(344deg) brightness(91%) contrast(85%)', // core-red
									}}
								/>
							)}
							<Text
								as="label"
								htmlFor={option.value}
								className={cn(
									'text-base text-lightest-grey font-normal leading-tight ',
									disabled && 'cursor-not-allowed opacity-75'
								)}
							>
								{option.label}
							</Text>
						</div>

						{checkboxEl}
					</div>
				);
			})}
		</div>
	);
}

CheckBoxGroup.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		})
	),
	onChange: PropTypes.func,
	value: PropTypes.arrayOf(PropTypes.string), // value is an array of string values of the selected options
	disabled: PropTypes.bool,
	className: PropTypes.string,
	inputClassName: PropTypes.string,
	iconClassName: PropTypes.string,
	renderOptionChildren: PropTypes.func,
};

CheckBoxGroup.defaultProps = {
	options: [],
	onChange: () => {},
	value: [],
	disabled: false,
};

export default CheckBoxGroup;
