import { useMemo, useState } from 'react';
import {
	PageHeader,
	ToggleButtonGroup,
	FilterWidget,
	PageWrapper,
	SearchWidget,
} from '@/components/common';
import { withPortal } from '@/HOCs/withPortal';
import { useUrlParams, useFetch } from '@/hooks';
import { HolidayList, HolidayMap } from '@/components/holidays';

function HolidaySearch({ portalData }) {
	const [view, setView] = useState('list');
	const { params } = useUrlParams('holiday-filters');

	const holidayParams = useMemo(() => {
		// get the required params from params object
		if (params?.default) {
			const {
				location,
				startDate,
				endDate,
				rooms,
				adults,
				children,
				infants,
				roomCount,
			} = params.default;
			const newParams = {
				location: location.split(':')[0],
				startDate,
				endDate,
				rooms: [
					{
						...rooms[0],
						adults: parseInt(rooms[0].adults.split(':')[0]) || 0,
						children: parseInt(rooms[0].children.split(':')[0]) || 0,
						infants: parseInt(rooms[0].infants.split(':')[0]) || 0,
					},
				],
				roomCount: parseInt(roomCount.split(':')[0]) || 0,
				adults: parseInt(adults.split(':')[0]) || 0,
				children: parseInt(children.split(':')[0]) || 0,
				infants: parseInt(infants.split(':')[0]) || 0,
			};
			return newParams;
		}
	}, [params]);

	const { data, isLoading, error } = useFetch({
		key: 'hotels-search',
		params: holidayParams,
		config: {
			enabled: !!JSON.stringify(holidayParams),
			retryOnWindowFocus: false,
		},
	});
	return (
		<>
			<SearchWidget
				category="holidays"
				className={portalData?.withSiteHeader ? 'fixed z-50 w-full top-15 md:top-25 drop-shadow-3xl' : ''}
			/>
			<PageWrapper loading={isLoading} error={error}>
				<div className="min-h-screen pb-10 mx-auto min-w-[375px] w-full bg-light-grey lg:bg-white">
					<PageHeader title="Holiday Search Results">
						<ToggleButtonGroup
							instance="pages"
							className="w-full md:w-auto md:ml-auto"
							itemClassName="uppercase font-bold text-sm leading-snug"
							value={view}
							options={[
								{ label: 'List View', value: 'list' },
								{ label: 'Map View', value: 'map' },
							]}
							onChange={(value) => {
								if (!value || value === view) return;
								setView(value);
							}}
						/>
					</PageHeader>

					<div className="grid grid-cols-1 gap-4 mx-auto max-w-screen-2xl md:px-14 lg:gap-12 lg:grid-cols-4 2xl:px-0">
						<FilterWidget
							widgetLabel="Filter Holidays By"
							paramsKey="holiday_filters"
							filters={[
								{ type: 'star_rating', name: 'star' },
								{ type: 'location', name: 'location' },
								{ type: 'duration', name: 'duration' },
								{ type: 'dates', name: 'dates' },
								{ type: 'budget', min: 400, max: 10000, name: 'budget' },
							]}
						/>

						<div className="w-full col-span-1 mx-auto lg:col-span-3">
							{/* {isFetching && <p>Loading...</p>} */}
							{view === 'list' ? <HolidayList list={[]} /> : <HolidayMap />}
						</div>
					</div>
				</div>
			</PageWrapper>
		</>
	);
}

// portaled to booking-holiday-search-page
export default withPortal(HolidaySearch, 'booking-holiday-search-page');
