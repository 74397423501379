import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { cn } from '@/lib/utils';
import { Icon } from '@components/common';

/**
 * @typedef {import('@radix-ui/react-checkbox').CheckboxProps} CheckboxProps
 **/

/**
 * @name Checkbox
 * @description A checkbox component
 * @param {CheckboxProps} props
 * @returns {React.JSX.Element}
 * @example
 * <Checkbox />
 */

function Checkbox({ className, iconClassName, ...props }, ref) {
	return (
		<CheckboxPrimitive.Root
			ref={ref}
			className={cn(
				'peer h-5 w-5 shrink-0 rounded border border-border-color focus-visible:outline-none focus-visible:ring-2  disabled:cursor-not-allowed disabled:opacity-50 p-0.5',
				className
			)}
			onCheckedChange={props.onChange}
			checked={props.value}
			{...props}
		>
			<CheckboxPrimitive.Indicator
				className={cn('flex items-center justify-center text-current')}
			>
				<Icon name="check" className={cn('h-full w-full', iconClassName)} />
			</CheckboxPrimitive.Indicator>
		</CheckboxPrimitive.Root>
	);
}

export default React.forwardRef(Checkbox);
