import { useEffect } from 'react';

import {
	mergeArrayOfObjects,
	FLIGHT_SPECIAL_ASSISTANCE_OPTIONS,
} from '@/lib/utils';
import { useBookingStore } from '@/store';
import { useRemoveQueries } from '@/hooks';
import PassengerDetailsTemplate from '@components/templates/passenger-details';

function FlightsPassengers() {
	const { removeQueries } = useRemoveQueries();
	const { setBookingState } = useBookingStore();
	const state = useBookingStore((store) => store.flights);

	// reset loading state
	useEffect(() => {
		if (state?.isLoading) {
			setBookingState('flights', { isLoading: false }, 'RESET_ISLOADING');
		}
	}, [state?.isLoading]);

	// run only once on mount
	useEffect(() => {
		// reset terms and conditions & read information
		const checkedConditions =
			state?.passengerDetails?.terms_conditions &&
			state?.passengerDetails?.read_information;

		if (checkedConditions) {
			setBookingState('flights', {
				passengerDetails: {
					...state.passengerDetails,
					terms_conditions: false,
					read_information: false,
				},
			});
		}
	}, []); // don't add any dependencies even if it warns

	const handleContinue = ({ passengerDetails, bookingDetails }) => {
		let hasChangedAge = false;
		if (state?.bookingDetails?.pax) {
			// determine if age has changed
			const anyPaxChangedAge = !bookingDetails?.pax?.every((pax) => {
				const prevPax = state.bookingDetails.pax?.find(
					(oldPax) => oldPax?.ref === pax?.ref
				);
				return JSON.stringify(prevPax?.dob) === JSON.stringify(pax?.dob);
			});
			hasChangedAge =
				state?.bookingDetails?.pax?.length !== bookingDetails?.pax?.length ||
				anyPaxChangedAge;
		} else {
			// age has changed if we didn't previously have pax info
			hasChangedAge = true;
		}

		// NOTE: we merge all parameters on passenger detail page continue (errata is filtered out on all other merges)
		const newState = {
			bookingDetails,
			passengerDetails,
			shouldBuildParams: true,
			usePeople: false,
			selected: {
				...state.selected,
				items: mergeArrayOfObjects({
					// merge the selected items with the additional params
					toMerge: state?.preview?.additionalParams,
					toBeMergedInto: state?.selected?.items,
				}),
			},

			baggage: !hasChangedAge ? state?.baggage : undefined,
			optionPlusOptions: !hasChangedAge ? state?.optionPlusOptions : undefined,
			selectedSeats: !hasChangedAge ? state?.selectedSeats : undefined,
		};

		// remove pax info from stored preview as this will need to come from the new preview request
		if (hasChangedAge && state?.preview?.pax) {
			newState.preview = {
				...state.preview,
				pax: undefined,
			}
		}

		setBookingState('flights', newState, 'PASSANGER_WITH_MERGE');

		// reset baggage search results
		if (hasChangedAge) {
			removeQueries(['baggage', 'option-plus', 'seating']);
		}
	};

	return (
		<PassengerDetailsTemplate
			state={state}
			category="flights"
			handleContinue={handleContinue}
			assistanceOptions={FLIGHT_SPECIAL_ASSISTANCE_OPTIONS}
		/>
	);
}
export default FlightsPassengers;
