import {
	BookingConfirmationPage,
	BookingDetailsPanel,
	Button,
	Heading,
	Text,
	TravelPlanWidget,
} from '@/components/common';
import { currencyFormatter, formatLocalDate } from '@/lib/utils';
import { useMemo } from 'react';
import { useMetaDescription } from '@/hooks';
import { useLocation, useParams } from 'react-router-dom';

function BookingFooter({ booking, bookingTypeName }) {
	const balanceDueDate = useMemo(() => {
		if (!booking?.overview?.balanceDueDate) return null;

		return formatLocalDate(booking.overview.balanceDueDate, 'dd/MM/yyyy');
	}, [booking?.overview?.balanceDueDate]);

	const categoryIcon = useMemo(() => {
		let icon = 'flight-fill'; // flights & holidays icon
		if (bookingTypeName) {
			switch (true) {
				case bookingTypeName.toLowerCase().includes('car'):
					icon = 'car-hire';
					break;
				case bookingTypeName.toLowerCase().includes('motorhome'):
					icon = 'motorhome';
					break;
				case bookingTypeName.toLowerCase().includes('hotel'):
					icon = 'hotels';
					break;
			}
		}

		return icon;
	}, [bookingTypeName]);

	return (
		<TravelPlanWidget
			hideMobileDialog
			hidePanelOnMobile={false}
			hideFooter
			title={`My ${bookingTypeName || 'Travel Plan'}`}
			category={categoryIcon}
			className="block shadow-none"
			contentClassName="px-0 py-0 xl:px-0 xl:py-0 border-0 md:border"
			panelClassName="block"
			panelTitleContainerClassName="hidden md:flex"
		>
			<div className="flex flex-col gap-4 p-5 lg:p-8">
				<Heading
					as="h2"
					className="text-3xl font-bold leading-6 md:hidden font-body"
				>
					Booking Summary
				</Heading>
				<Text className="flex items-center justify-between w-full px-4 py-3 mb-3 rounded-lg bg-light-grey">
					<Text as="span">Booking Ref:</Text>
					<Text as="span">{booking?.id}</Text>
				</Text>
				<hr className="hidden md:block border-light-grey" />
				<div className="flex flex-col gap-4">
					<Text className="flex items-center justify-between w-full">
						<Text as="span" className="text-xl font-bold">
							Total
						</Text>
						<Text as="span" className="text-xl font-bold">
							{currencyFormatter({
								amount: booking?.overview?.total,
							})}
						</Text>
					</Text>
					<Text className="flex items-center justify-between w-full">
						<Text as="span" className="text-xl font-bold">
							Amount Paid
						</Text>
						<Text as="span" className="text-xl font-bold">
							{currencyFormatter({
								amount: booking?.overview?.paid,
							})}
						</Text>
					</Text>
					<Text className="flex items-center justify-between w-full">
						<Text as="span" className="text-xl font-bold">
							Balance Due
						</Text>
						<Text as="span" className="text-xl font-bold">
							{currencyFormatter({
								amount: booking?.overview?.balanceDue,
							})}
						</Text>
					</Text>
					{booking?.overview?.localTotal > 0 && (
						<Text className="flex items-center justify-between w-full">
							<Text as="span">Payable On Collection</Text>
							<Text as="span">
								{currencyFormatter({
									amount: booking.overview.localTotal,
									currency: 'CAD',
								})}
							</Text>
						</Text>
					)}
				</div>
			</div>
			<div className="flex flex-col w-full gap-4 p-5 bg-supporting-yellow/10 md:px-10 md:py-8">
				<Button
					hideIcon
					tagName="a"
					href="/mycaf"
					label="Manage my booking"
					variant="core-blue"
					className="w-fit"
				/>
				{booking?.overview?.balanceDue > 0 && balanceDueDate && (
					<Text className="font-bold">
						Outstanding balance due by {balanceDueDate}
					</Text>
				)}
				<Text className="flex flex-col">
					<Text as="span" className="font-bold">
						Before your{' '}
						<span className="lowercase">{bookingTypeName || 'holiday'}</span>.
					</Text>
					<a
						href="/travel-centre/faq"
						target="_blank"
						rel="noopener noreferrer"
						className="underline underline-offset-4 hover:opacity-75"
					>
						Explore our FAQ checklist.
					</a>
				</Text>
			</div>
		</TravelPlanWidget>
	);
}

function BookingComplete() {
	const { pathname } = useLocation();
	const params = useParams();
	const category = params?.category
		? params.category
		: pathname && pathname.split('/')[2];

	let metaKeyword = '';
	switch (category) {
		case 'flights':
			metaKeyword = 'Flights';
			break;

		case 'hotels':
			metaKeyword = 'Hotels';
			break;
		case 'car-hire':
			metaKeyword = 'Car Hire';
			break;
	}

	useMetaDescription(['Booking Confirmation', metaKeyword, 'Canadian Affair']);

	return (
		<BookingConfirmationPage
			title="Booking Complete!"
			summaryTitle="Your Booking Summary"
			loadingText="Fetching your booking details."
			// noticeText="You will receive an email with your booking details."
			DetailsComponent={BookingDetailsPanel}
			footerClassName="mt-5"
			FooterComponent={BookingFooter}
		/>
	);
}

export default BookingComplete;
