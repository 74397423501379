import { Skeleton } from '@/components/common';

function MapListCardSkeleton() {
	return (
		<div className="w-full h-[100px] bg-white flex-col justify-start items-start inline-flex">
			<div className="self-stretch p-5 justify-between items-center gap-4 inline-flex">
				<div className="justify-start items-center gap-4 flex">
					<Skeleton className="w-[50px] h-[50px]" />

					<div className="flex-col justify-start items-start gap-1 inline-flex">
						<Skeleton className="w-[200px] h-4 rounded-full" />
						<Skeleton className="w-[100px] h-3 rounded-full" />
					</div>
				</div>
				<div className="justify-start items-center gap-8 flex">
					<Skeleton className="w-[100px] h-4 rounded-full" />
					<Skeleton className="w-[100px] h-4 rounded-full" />

					<div className="justify-start items-center gap-4 flex">
						<Skeleton className="w-[150px] h-12 rounded-full" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default MapListCardSkeleton;
