import { useEffect, useMemo } from 'react';

import { useBookingStore } from '@/store';
import { useRemoveQueries } from '@/hooks';

/**
 * @typedef {Object} FareUpdateProps
 * @property {Object} flight - The flight object
 * @property {boolean} fareAvailable - The fare available
 * @property {"outbound"|"inbound"} instance - The instance
 * @property {Function} onFareChange - The fare change callback
 * */

/**
 * @name useFareUpdate
 * @param {FareUpdateProps} params
 * @returns {null}
 * @example
 * import { useFareUpdate } from '@/hooks';
 *
 * const fareUpdateProps = {
 * 	flight: flight,
 * 	fareAvailable: fareAvailable,
 * 	instance: 'outbound',
 * 	onFareChange: onFareChange,
 * };
 *
 * useFareUpdate(fareUpdateProps);
 */

function useFareUpdate({ flight, fareAvailable, instance, onFareChange }) {
	const { removeQueries } = useRemoveQueries();
	const { setBookingState } = useBookingStore();
	const state = useBookingStore((store) => store.flights);

	const selectedFare = state?.selected?.selectedFare?.[instance] ?? null;

	// check if the selected fare has changed
	const flightHasChanged = useMemo(() => {
		if (!selectedFare || !flight) return false;

		const selectedKey = selectedFare?.fare?.split('-')[0];
		return selectedKey !== flight?.key;
	}, [flight, selectedFare]);

	useEffect(() => {
		if (!flight || !selectedFare?.fare) return;

		if (!state?.selected?.updateFares) return;

		// if options not available or flight has changed reset the state
		if (!fareAvailable || flightHasChanged) {
			const newItems = state?.selected?.items?.map((item) => {
				return {
					...item,
					[instance]: undefined,
				};
			});
			const newState = {
				shouldBuildParams: false,
				selected: {
					...state.selected,
					updateFares: false,
					selectedCabin: {
						...state.selected.selectedCabin,
						[instance]: undefined,
					},
					selectedFare: {
						...state.selected.selectedFare,
						[instance]: undefined,
					},
					[instance]: undefined,
					items: newItems,
				},
				baggage: undefined,
				preview: undefined,
				optionPlusOptions: undefined,
				selectedSeats: undefined,
				paymentDetails: undefined,
			};

			// removeQueries
			removeQueries(['booking-preview', 'option-plus', 'baggage', 'seating']);

			setBookingState('flights', newState, 'RESET_SELECTED_FARE');
			return;
		}

		// handle state flight updates here
		onFareChange(selectedFare?.fare, flight, true);
	}, [
		flight,
		selectedFare?.fare,
		flightHasChanged,
		fareAvailable,
		instance,
		state.selected,
		onFareChange,
	]);

	return null;
}

export default useFareUpdate;
