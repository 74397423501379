import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
	Text,
	Button,
	FormField,
	Heading,
	ToggleButtonGroup,
} from '@/components/common';
import {
	cn,
	Required,
	ONE_TO_NINE,
	ZERO_TO_NINE,
	CHELSEA_HOTEL_ROOMS,
	HOLIDAY_ENQUIRY_DEPARTING,
	HOLIDAY_FLEXIBLE_TRAVEL_OPTIONS,
} from '@/lib/utils';

function EnquiryForm({ errors, control, setValue, disabledButton }) {
	const [rooms, setRooms] = useState(1);
	const [preferredContactMethod, setPreferredContactMethod] = useState('email');

	const validateTotalPeople = useCallback((value, formValues) => {
		// only allow up to 9 people per booking
		const adults = formValues.adults?.value
			? parseInt(formValues.adults.value)
			: 0;
		const children = formValues.children?.value
			? parseInt(formValues.children.value)
			: 0;
		const infants = formValues.infants?.value
			? parseInt(formValues.infants.value)
			: 0;

		// show custom message to prevent 3 fields with the same message
		const isValid = adults + children + infants <= 9;
		setShowMaxPeopleError(!isValid);

		return isValid;
	});

	const roomsList = Array.from({ length: rooms });

	useEffect(() => {
		setValue('preferredContactMethod', preferredContactMethod);
	}, [preferredContactMethod]);

	const togglePreferredContactMethod = (value) => {
		const valueIsPhone = value === 'phone';
		setPreferredContactMethod(valueIsPhone ? 'phone' : 'email');
	};

	return (
		<div className="flex flex-col">
			<div className="flex flex-col gap-7.5">
				<FormField
					name="from"
					as="select"
					label="Which departure airport would you like to fly from?"
					showRequired
					errors={errors}
					control={control}
					placeholder="Departing from"
					wrapperClassName="h-full w-full"
					options={HOLIDAY_ENQUIRY_DEPARTING}
					blurInputOnSelect={true}
				/>

				<FormField
					as="input"
					name="when"
					label="When would you like to go?"
					placeholder="When"
					errors={errors}
					control={control}
					showRequired
				/>

				<FormField
					name="flexibleTravel"
					as="radio-group"
					label="Are you flexible when you travel?"
					options={HOLIDAY_FLEXIBLE_TRAVEL_OPTIONS}
					showRequired
					errors={errors}
					control={control}
					className="items-start gap-2.5 md:grid-cols-1 lg:flex lg:items-center"
					itemLabelClassName="md:items-start"
					fieldWrapperClassName="items-center w-auto"
				/>

				{roomsList.map((_, idx) => (
					<div
						key={`booking-form-holidays-${idx}`}
						className="flex flex-col gap-4"
					>
						<div className="grid w-full grid-cols-2 gap-3 md:grid-cols-3">
							<FormField
								name={`rooms.${idx.toString()}.adults`}
								label="Who will be travelling"
								as="select"
								options={ONE_TO_NINE}
								control={control}
								errors={errors}
								validation={[Required, validateTotalPeople]}
								wrapperClassName="w-full lg:min-w-[124px]"
								placeholder="Adults"
								footer="Aged 15 +"
								blurInputOnSelect={true}
							/>

							<FormField
								name={`rooms.${idx.toString()}.children`}
								label="&nbsp;"
								as="select"
								options={ZERO_TO_NINE}
								control={control}
								errors={errors}
								validation={[validateTotalPeople]}
								wrapperClassName="w-full lg:min-w-[124px]"
								placeholder="Children"
								footer="Ages 3 - 15"
								blurInputOnSelect={true}
							/>

							<FormField
								name={`rooms.${idx.toString()}.infants`}
								label="&nbsp;"
								as="select"
								options={ZERO_TO_NINE}
								control={control}
								errors={errors}
								validation={[validateTotalPeople]}
								wrapperClassName="w-full col-span-2 lg:min-w-[124px] md:col-span-1"
								placeholder="Infants"
								footer="Ages 0 - 3"
								blurInputOnSelect={true}
							/>
						</div>
					</div>
				))}
			</div>

			<div className="border-t border-t-dark-grey/20 mt-7.5 pt-7.5 flex flex-col gap-7.5">
				<Heading as="h3" className="text-2xl font-bold font-body">
					Your Details
				</Heading>

				<FormField
					as="input"
					name="firstName"
					label="First name"
					placeholder="First name"
					autoComplete="given-name"
					errors={errors}
					control={control}
					showRequired
				/>

				<FormField
					as="input"
					name="surname"
					label="Surname"
					placeholder="Surname"
					autoComplete="surname"
					errors={errors}
					control={control}
					showRequired
				/>

				<FormField
					as="input"
					name="phone"
					label="Phone number"
					placeholder="Phone number"
					autoComplete="tel"
					errors={errors}
					control={control}
					showRequired
				/>

				<FormField
					as="input"
					name="email"
					type="email"
					label="Email address"
					placeholder="Email address"
					autoComplete="email"
					errors={errors}
					control={control}
					showRequired
				/>

				<div className="flex flex-col w-full gap-2">
					<Text
						as="label"
						htmlFor="preferredContactMethod"
						className="text-base leading-tight text-light-black"
					>
						Preferred contact method
					</Text>
					<ToggleButtonGroup
						instance="formField"
						name="preferredContactMethod"
						onChange={togglePreferredContactMethod}
						className="inline-flex self-start overflow-hidden border rounded border-border-color"
						itemClassName="w-full lg:w-auto first:rounded-none last:rounded-none bg-light-grey data-[state=on]:bg-black border-none hover:data-[state=on]:bg-black/90"
						itemLabelClassName="font-semibold leading-snug tracking-tighter"
						value={preferredContactMethod}
						options={[
							{
								label: 'Phone',
								value: 'phone',
							},
							{
								label: 'Email',
								value: 'email',
							},
						]}
						errors={errors}
						control={control}
					/>
				</div>

				{preferredContactMethod === 'phone' && (
					<>
						<FormField
							as="input"
							name="contactDaysTimes"
							label="What day(s) and time(s) would you like us to call you?"
							placeholder="Day(s) and time(s)"
							errors={errors}
							control={control}
							showRequired
						/>

						<Text
							as="span"
							className="w-full font-normal leading-snug tracking-tighter text-left text-dark-grey"
						>
							Please note our{' '}
							<a
								href="/about-us/contact-us"
								target="_blank"
								rel="noopener noreferrer"
								className="font-bold underline underline-offset-4"
							>
								opening times
							</a>
							.
						</Text>
					</>
				)}

				{/* {enquiryDataset?.priceStatement && (
					<Text
						as="span"
						className="w-full font-normal leading-snug tracking-tighter text-left text-dark-grey"
					>
						{enquiryDataset?.priceStatement}
					</Text>
				)} */}

				<Button
					type="submit"
					label="Submit Quote Enquiry"
					className="self-start justify-between w-full py-2.5 md:w-1/2"
					variant="supporting-yellow"
					labelClassName="text-lg font-body font-bold tracking-tighter"
					disabled={disabledButton}
				/>
			</div>
		</div>
	);
}

EnquiryForm.propTypes = {
	params: PropTypes.shape({
		location: PropTypes.string,
		from: PropTypes.string,
		adults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		infants: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
};

EnquiryForm.defaultProps = {
	params: {},
	hideClearBtn: false,
};

export default EnquiryForm;
