import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import * as Primitives from '@radix-ui/react-dialog';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { useDisclosure } from '@/hooks';
import CloseButton from './CloseButton';
import DialogTrigger from './DialogTrigger';

/**
 * @typedef {"modal"|"drawer"} as
 */

/**
 * @typedef {"sm"|"md"|"lg"|"xl"|"2xl"|"screen"} size
 */

/**
 * @typedef {"left"|"right"|"top"|"bottom"|"center"} position
 */

const dialogVariants = cva(
	'z-50 grid w-full gap-4 bg-white p-6 relative max-h-screen overflow-y-auto',
	{
		variants: {
			as: {
				modal: '',
				drawer: 'shadow-lg',
			},
			size: {
				sm: 'w-full md:max-w-md',
				md: 'max-w-lg',
				lg: 'max-w-2xl',
				xl: 'max-w-3xl',
				'2xl': 'max-w-4xl',
				'4xl': 'max-w-6xl',
				screen: 'w-screen max-h-screen',
			},
			position: {
				left: 'h-full left-0 data-[state=open]:animate-slide-in-bottom data-[state=closed]:animate-slide-out-bottom lg:data-[state=open]:animate-slide-in-left lg:data-[state=closed]:animate-slide-out-left',
				right:
					'h-full transform right-0 data-[state=open]:animate-slide-in-right data-[state=closed]:animate-slide-out-right',
				top: 'w-full h-auto self-start data-[state=open]:animate-slide-in-top data-[state=closed]:animate-slide-out-top',
				bottom:
					'w-full h-auto self-end data-[state=open]:animate-slide-in-bottom data-[state=closed]:animate-slide-out-bottom',
				center:
					'self-center justify-self-center data-[state=open]:animate-in data-[state=closed]:animate-out duration-200',
			},
		},
		defaultVariants: {
			as: 'drawer',
			size: 'md',
			position: 'center',
		},
	}
);

/**
 * @typedef {Object} DialogProps
 * @property {as} as
 * @property {size} size
 * @property {position} position
 * @property {string} title
 * @property {string} className
 * @property {string} overlayClassName
 * @property {string} contentClassName
 * @property {React.ReactNode} children
 * @property {boolean} hideCloseBtn
 * @property {boolean} disableClose
 * @property {(params: { onOpen: ()=>void, onToggle: ()=>void, isOpen: boolean, DialogTrigger: typeof DialogTrigger  }) => React.JSX.Element} renderTrigger
 * @property {string} description
 * @property {(params:{CloseButton: typeof CloseButton, onClose: ()=>void })=> React.JSX.Element | React.ReactNode} children
 * */

/**
 * @typedef {Object} refProps
 * @property {()=>void} onClose
 * @property {()=>void} onOpen
 */

/**
 * @name Dialog
 * @description Renders a dialog that can be either a modal or a drawer
 * @param {DialogProps} props
 * @param {React.Ref<refProps>} ref
 * @returns {React.JSX.Element | null}
 * @example
 * <Dialog
 * 		as="modal"
 * 		size="md"
 * 		position="center"
 * 		title="Modal Title"
 * 		description="Modal Description"
 * 		renderTrigger={({ DialogTrigger, onOpen }) => (
 * 			<DialogTrigger onClick={onOpen}>
 * 				Open Modal
 * 			</DialogTrigger>
 * 		)}
 * 		>
 * 		<div>
 * 			lorem ipsum dolor sit amet, consectetur adipiscing elit.
 * 		</div>
 * </Dialog>
 * */

const Dialog = forwardRef(
	(
		{
			as,
			size,
			title,
			position,
			children,
			hideCloseBtn,
			disableClose,
			description,
			renderTrigger,
			overlayClassName,
			contentClassName,
		},
		ref
	) => {
		const { onClose, onOpen, isOpen, onToggle } = useDisclosure(false);

		useImperativeHandle(
			ref,
			() => {
				return {
					onClose: onClose,
					onOpen: onOpen,
				};
			},
			[onClose, onOpen]
		);

		return (
			<Primitives.Root
				ref={ref}
				open={isOpen}
				onOpenChange={(active) => {
					if (!active && !disableClose) onClose();
				}}
			>
				{renderTrigger({ onOpen, isOpen, onToggle, DialogTrigger })}
				<Primitives.Portal>
					<Primitives.Overlay
						className={cn(
							'fixed inset-0 flex z-[9999] items-center justify-center bg-black/80 data-[state=open]:animate-fade-in data-[state=closed]:animate-fade-out',
							position === 'left' && 'justify-start',
							position === 'right' && 'justify-end',
							overlayClassName
						)}
					>
						<Primitives.Content
							className={cn(
								dialogVariants({
									as,
									size,
									position,
									className: contentClassName,
								})
							)}
						>
							{title ||
								(description && (
									<div className="flex flex-col gap-2">
										{title && (
											<Primitives.Title asChild>{title}</Primitives.Title>
										)}
										{description && (
											<Primitives.Description asChild>
												{description}
											</Primitives.Description>
										)}
									</div>
								))}
							{!hideCloseBtn && <CloseButton disabled={disableClose} />}
							{typeof children === 'function'
								? children({ CloseButton, onClose })
								: children}
						</Primitives.Content>
					</Primitives.Overlay>
				</Primitives.Portal>
			</Primitives.Root>
		);
	}
);

Dialog.propTypes = {
	as: PropTypes.oneOf(['modal', 'drawer']),
	size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl', '4xl', 'screen']),
	position: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'center']),
	hideCloseBtn: PropTypes.bool,
	disableClose: PropTypes.bool,
	renderTrigger: PropTypes.func,
	overlayClassName: PropTypes.string,
	contentClassName: PropTypes.string,
};

Dialog.defaultProps = {
	as: 'modal',
	size: 'md',
	position: 'center',
	hideCloseBtn: false,
	disableClose: false,
	renderTrigger: () => null,
	overlayClassName: '',
	contentClassName: '',
};

export default Dialog;
