import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { HotelBookingSummary } from '@/components/hotels';
import { FlightBookingSummary } from '@/components/flights';
import { MotorHomeBookingSummary } from '@/components/motorhomes';
import { CarBookingSummary } from '@/components/carhire';
import { MissingBookingStepSummary } from '@/components/common';


/**
 * @name BookingSummaryPanel Component to display a summary of booking items.
 * @returns {React.JSX.Element} BookingSummaryPanel
 */
function BookingSummaryPanel({ items }) {
    return (
        <div className="flex flex-col gap-7 lg:gap-4">
            {items.map((item, idx) => {
                // NOTE: holidays & multistep holidays are broken down into individual items
                let Component;
                switch (item?.type) {
                    case 'carhire':
                        Component = CarBookingSummary;
                        break;
                    // case 'motorhomehire':
                    //     Component = MotorHomeBookingSummary;
                    //     break;
                    case 'flight':
                        Component = FlightBookingSummary;
                        break;
                    case 'accommodation':
                        Component = HotelBookingSummary;
                        break;
                    // case 'rail':
                    // Component = RailBookingSummary // TODO: add component
                    //     break;
                    default:
                        Component = MissingBookingStepSummary;
                        break;
                }

                return (
                    <div key={`${item?.type}-${idx}`} className='flex flex-col gap-4'>
                        <Component
                            item={item}
                            className="px-5 md:px-0"
                            contentClassName="border-0 md:border"
                        />

                        {/* Divider between blocks */}
                        {idx < items.length - 1 && (
                            <hr className="hidden lg:block border-lighter-grey" />
                        )}
                    </div>
                );
            })}
        </div>
    );
}

BookingSummaryPanel.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
}

export default BookingSummaryPanel
