import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import {
	BookingSummaryPanel,
	Heading,
	Icon,
	Navbar,
	PageWrapper,
	RteStyledContent,
	Text,
} from '@/components/common';
import { cn } from '@/lib/utils';
import { useFetch, useUrlParams } from '@/hooks';
import { useBookingStore } from '@/store';
import { useNavigate } from 'react-router-dom';

/**
 * @name BookingConfirmationPage Generic confirmation page component with booking retrieval functionality.
 * @returns {React.JSX.Element} BookingConfirmationPage
 */
function BookingConfirmationPage({
	title,
	summaryTitle,
	loadingText,
	noticeText,
	retrieveQuotes,
	hideTickOnDesktop,
	hideTravelInfo,
	footerClassName,
	DetailsComponent,
	FooterComponent,
}) {
	const navigate = useNavigate();
	const { resetState } = useBookingStore();
	const { params, parsedInitialParams } = useUrlParams();

	useEffect(() => {
		// reset booking state when landing on the confirmation page
		resetState();
	}, []);

	const searchParams = useMemo(() => {
		return {
			type: retrieveQuotes ? 'QUOTE' : 'BOOKING',
		};
	}, [retrieveQuotes]);

	const { data, error, isLoading } = useFetch({
		key: 'retrieve-booking',
		formatEndpoint: (defaultEndpoint) =>
			`${defaultEndpoint}/${params?.default?.id}`,
		params: searchParams,
		config: {
			enabled: typeof params?.default?.id === 'string' && !!searchParams,
			retryOnWindowFocus: false,
			retry: false,
		},
	});
	const triggerTagManager = () => {
		if (data && data.marketing) {
			let action = console.log;
			if (window.dataLayer && window.dataLayer.push) {
				action = window.dataLayer.push;
			}

			action({ ecommerce: null }); // Clear the previous ecommerce object
			action(data.marketing);
		}
	};

	const bookingTypeName = useMemo(() => {
		// console.log('test', data);
		triggerTagManager();

		if (!data?.breakdown?.length || data?.breakdown?.length > 1)
			return 'Holiday';

		// if exactly one vehicle/hotel, show the type of booking
		let name = '';
		const itemType = data.breakdown[0]?.type;
		switch (itemType) {
			case 'carhire':
				name = 'Car Hire';
				break;
			case 'motorhomehire':
				name = 'Motorhome Hire';
				break;
			case 'accommodation':
				name = 'Hotel Stay';
				break;
		}

		return name;
	}, [data?.breakdown]);

	const errata = useMemo(() => {
		if (!data?.breakdown?.length) return [];

		return data.breakdown
			.filter((item) => item?.errata?.length >= 1)
			.reduce((errs, item) => [...errs, ...item.errata], []);
	}, [data?.breakdown]);

	// redirect to homepage if landing on page without a booking id
	useEffect(() => {
		// wait until params have parsed
		if (!parsedInitialParams) return;

		if (typeof params?.default?.id !== 'string') {
			navigate('/');
		}
	}, [params, parsedInitialParams]);

	return (
		<>
			<Navbar />
			<PageWrapper
				error={error}
				className="min-h-screen md:min-h-[calc(100vh-4.5rem)] bg-light-grey relative"
				loading={isLoading}
				loadingText={loadingText}
				loaderClassName="h-[calc(100vh-4.5rem)]"
				hideSearchAgainOnError
			>
				<section className="flex flex-col w-full max-w-4xl pt-6 mx-auto md:pt-12 lg:px-0">
					<div className="flex flex-col gap-5 md:gap-8">
						<div className="flex flex-col gap-4 px-5 md:items-center md:justify-center lg:px-0">
							<Text
								as="span"
								className={cn(
									'w-7.5 md:w-10 h-7.5 md:h-10 p-2 rounded-full bg-check-green',
									hideTickOnDesktop && 'md:hidden'
								)}
							>
								<Icon name="check" className="w-full h-full text-white" />
							</Text>
							<Heading className="text-4xl md:text-6xl lg:text-10xl">
								{title}
							</Heading>
						</div>
						<div className="flex flex-col gap-2 px-5 md:gap-4 lg:px-0">
							{typeof DetailsComponent === 'function' && (
								<div>
									<DetailsComponent booking={data} />
								</div>
							)}
							{noticeText && (
								<Text className="md:text-lg">Note: {noticeText}</Text>
							)}
						</div>

						<div className="flex flex-col gap-4 md:gap-8">
							{summaryTitle && (
								<Heading
									as="h2"
									className="px-5 text-4xl font-bold md:text-5xl lg:px-0"
								>
									{summaryTitle}
								</Heading>
							)}

							{data?.breakdown?.length > 0 && (
								<div className="md:px-5 lg:px-0">
									<BookingSummaryPanel items={data?.breakdown || []} />
								</div>
							)}
						</div>

						{/* Travel info - hide for car/motorhome hire */}
						{!hideTravelInfo &&
							!bookingTypeName.toLowerCase().includes('hire') && (
								<div className="flex flex-col gap-4 px-5 lg:px-0">
									<Heading
										as="h2"
										className="text-3xl font-bold leading-6 font-body"
									>
										Important Information
									</Heading>
									<div className="border-b border-lighter-grey">
										<Heading
											as="h3"
											className="text-base font-bold uppercase w-fit font-body"
										>
											Travel Documents
										</Heading>
										<Text className="pt-2 pb-4 text-dark-grey/65">
											{bookingTypeName.toLowerCase() === 'accommodation'
												? 'You MUST present at check-in your Canadian Affair voucher. No other form of printed documentation will be accepted.'
												: 'You MUST present at check-in your Canadian Affair Flight E-Ticket. No other form of printed documentation will be accepted.'}
										</Text>
									</div>
									<div
										className={cn(
											'border-b border-lighter-grey',
											bookingTypeName.toLowerCase() === 'accommodation' &&
												!errata?.length &&
												'border-none'
										)}
									>
										<Heading
											as="h3"
											className="text-base font-bold uppercase w-fit font-body"
										>
											Visas
										</Heading>
										<Text
											className={cn(
												'pt-2 pb-4 text-dark-grey/65',
												!errata?.length &&
													bookingTypeName.toLowerCase() === 'accommodation' &&
													'pb-0'
											)}
										>
											It is YOUR responsibility to ensure that you have a valid
											passport and relevant visa(s) for your trip. British
											CITIZENS, British OVERSEAS CITIZENS who are re-admissible
											to the UK, and British DEPENDANT TERRITORIES CITIZENS,
											with the unrestricted right of permanent abode in the UK,
											will be exempt from requiring a visa and can enter Canada
											with an Electronic Travel Authorisation (ETA). It is
											mandatory for all visa exempt foreign nationals to have a
											pre-approved Electronic Travel Authorisation (ETA) to
											enter Canada by air. Applications must be made online at:{' '}
											<a
												href="https://www.canada.ca/en/immigration-refugees-citizenship/services/visit-canada/eta.html?utm_source=slash-eta_ave&utm_medium=short-url-en&utm_campaign=eta"
												title="Canada Electronic travel authorization"
												target="_blank"
												rel="noopener noreferrer"
												className="underline underline-offset-4 hover:opacity-75"
											>
												www.Canada.ca/eta
											</a>{' '}
											Please note: If you are a British Subject where you are
											subject to control under the immigration Act of 1971 you
											will still require a visa to enter Canada (subject to
											change).
										</Text>
									</div>
									{bookingTypeName.toLowerCase() !== 'accommodation' && (
										<div
											className={cn(
												errata?.length > 0 && 'border-b border-lighter-grey'
											)}
										>
											<Heading
												as="h3"
												className="text-base font-bold uppercase w-fit font-body"
											>
												Checking in
											</Heading>
											<Text className="pt-2 text-dark-grey/65">
												We recommend all passengers check-in 24 hours before
												departure via the Air Transat website or app. If you are
												unable to check-in online you can do so at the airport
												up to 4 hours prior to departure.
											</Text>
											<Text
												variant="bold"
												className={cn(
													'pt-2 pb-4 text-dark-grey',
													!errata?.length && 'pb-0'
												)}
											>
												Check-in closes strictly 1 hour before departure there
												are no exceptions.
											</Text>
										</div>
									)}
									{/* <div
										className={
											errata?.length > 0 ? 'border-b border-lighter-grey' : ''
										}
									>
										<Heading
											as="h3"
											className="text-base font-bold uppercase w-fit font-body"
										>
											Reconfirmation Of Flights
										</Heading>
										<Text
											className={cn(
												'pt-2 pb-4 text-dark-grey/65',
												!errata?.length ? 'pb-0' : ''
											)}
										>
											Should you wish to check on the day of departure that the
											flight is running on time, please call the airport locally
											on the numbers below, or visit the airport web sites. If
											you are staying longer than 3 weeks, it is vital to check
											your departure time.
										</Text>
									</div> */}
									{errata?.length > 0 && (
										<div>
											<Heading
												as="h3"
												className="text-base font-bold uppercase w-fit font-body"
											>
												Other Information
											</Heading>
											<div className="flex flex-col gap-4 pt-2 text-dark-grey/65">
												{errata.map((err, idx) => (
													<RteStyledContent
														key={idx}
														variant="atcom-rte"
														dangerouslySetInnerHTML={{
															__html: err.safeHtml,
														}}
													/>
												))}
											</div>
										</div>
									)}
								</div>
							)}
					</div>

					{typeof FooterComponent === 'function' && (
						<div className={cn('md:px-5 lg:px-0 md:mt-8', footerClassName)}>
							<FooterComponent
								booking={data}
								bookingTypeName={bookingTypeName}
							/>
						</div>
					)}
				</section>
			</PageWrapper>
		</>
	);
}

BookingConfirmationPage.propTypes = {
	title: PropTypes.string,
	summaryTitle: PropTypes.string,
	loadingText: PropTypes.string,
	noticeText: PropTypes.string,
	retrieveQuotes: PropTypes.bool,
	hideTickOnDesktop: PropTypes.bool,
	hideTravelInfo: PropTypes.bool,
	footerClassName: PropTypes.string,
	DetailsComponent: PropTypes.func,
};

export default BookingConfirmationPage;
