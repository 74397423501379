import React from 'react';
import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { useDisclosure } from '@/hooks';
import { Icon, Text, Heading, Button, Route } from '@/components/common';

const features = [
	'Unlimited Mileage',
	'Loss damage waiver',
	'Up to $1million 3rd party liability coverage',
	'Zero excess',
	'All applicable taxes',
	'All airport surcharges - all airport pick ups are on site',
];

/**
 * @typedef {Object} MotorHomeSummaryProps
 * @property {Object | null} motorhome
 * @property {string} className
 * @property {boolean} justForShow
 */

/**
 * @name MotorHomeSummary
 * @description A summary of the motorhome details
 * @param {MotorHomeSummaryProps} props
 * @returns {React.JSX.Element}
 * @example
 * <MotorHomeSummary motorhome={motorhome} />
 */

function MotorHomeSummary({ motorhome, className, justForShow }) {
	const { onToggle, isOpen } = useDisclosure(false);

	return (
		<div
			className={cn(
				'bg-white border border-lighter-grey w-full p-5 grid grid-cols-1 lg:grid-cols-3 gap-10',
				className
			)}
		>
			<div className="col-span-1 lg:col-span-2">
				<Heading
					as="h3"
					className="text-core-blue font-bold font-body text-2xl"
				>
					C25 Standard Motorhome
				</Heading>
				<Button variant="unstyled" onClick={onToggle} className="my-2 gap-1 hover:opacity-75">
					<Text as="span" className="leading-tight text-sm">Info</Text>
					<Icon
						className="w-3 h-3"
						name={isOpen ? 'chevron-up' : 'chevron-down'}
					/>
				</Button>
				{isOpen && (
					<div className="mt-5 p-3">
						<ul>
							{features.map((feature) => (
								<li key={feature} className="list-none">
									<Text variant="muted" className="flex gap-2 items-center">
										<Icon name="maple-leaf" />
										<Text as="span">{feature}</Text>
									</Text>
								</li>
							))}
						</ul>
					</div>
				)}
				<Route
					className="my-3"
					iconName="motorhome-right"
					renderFrom={() => (
						<Text className="flex flex-col">
							<Text as="span">Pick up</Text>
							<Text as="span" className="font-bold">
								20 September 2023
							</Text>
						</Text>
					)}
					renderTo={() => (
						<Text className="flex flex-col">
							<Text as="span">Drop off</Text>
							<Text as="span" className="font-bold">
								27 September 2023
							</Text>
						</Text>
					)}
				/>
				<div>
					<Text>Summary: 5 People</Text>
					<Text>Bed Summary: Cab Over, Dinette & Rear bed</Text>
				</div>

				<div className=" col-span-1 w-36 h-36 lg:hidden flex items-start">
					<img
						className="self-stretch h-full w-full object-contain"
						src="https://www.carthago.com/fileadmin/_processed_/6/2/csm_2022_Carthago_c-tourer_T143LE-Fiat_vV-dyn-BFS_fc087a5008.png"
					/>
				</div>
			</div>
			<div className="hidden col-span-1 w-full lg:block">
				<img
					className="self-stretch h-full w-full object-contain"
					src="https://www.carthago.com/fileadmin/_processed_/6/2/csm_2022_Carthago_c-tourer_T143LE-Fiat_vV-dyn-BFS_fc087a5008.png"
				/>
			</div>
		</div>
	);
}

MotorHomeSummary.propTypes = {
	motorhome: PropTypes.object,
	className: PropTypes.string,
	justForShow: PropTypes.bool,
};

MotorHomeSummary.defaultProps = {
	className: '',
	justForShow: false,
	motorhome: null,
};

export default MotorHomeSummary;
