import PropTypes from 'prop-types';
import HotelCard from '../../molecules/card';
import { SearchResultsList } from '@/components/common';

function HotelList({ pages }) {
	return (
		<SearchResultsList
			pages={pages}
			ResultCardComponent={HotelCard}
			className="md:grid-cols-2 lg:grid-cols-1 lg:gap-5"
		/>
	);
}

HotelList.propTypes = {
	pages: PropTypes.array.isRequired,
};

export default HotelList;
