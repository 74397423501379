import invoke from '@/lib/invoke';


/**
 * @typedef {import('../useBookingStore').category} category
 */

/**
 * @name makeBooking
 * @description Makes a booking for the given category
 * @param {(key: category, value: any, label: string) => void} setBookingState
 * @param {unknown} params
 * @param {category} category
 * @returns {void}
 */

export const makeBooking = async (setBookingState, params, category) => {
    // if none of the params are available, return
    if (!params || !category || !setBookingState) return;

    setBookingState(category, { isLoading: true, error: null }, 'MAKE_BOOKING/SET_LOADING_TRUE');

    // reset loading state on refresh
    const handleBeforeUnload = () => {
        setBookingState(category, { isLoading: false });
    }
    window.addEventListener("beforeunload", handleBeforeUnload);


    // add category url segment to the booking
    params.category = category;

    const { res, error } = await invoke({
        method: 'POST',
        endpoint: 'booking',
        data: params,
    });

    // request finished - remove listener
    window.removeEventListener("beforeunload", handleBeforeUnload);

    if (error || !res?.breakdown) {
        // ignore network axios errors (only thrown in Safari)
        if (error?.originalError?.code === 'ERR_NETWORK') return;

        // ignore abort axios errors (only thrown in Firefox)
        if (error?.originalError?.code === 'ECONNABORTED') return;

        const defaultError = {
            message: res?.original?.error || 'Please try again later.',
        };

        setBookingState(category, {
            error: error ? error : defaultError,
            isLoading: false,
        }, 'MAKE_BOOKING/SET_ERROR');
        return;
    }

    // extract preview data from booking request
    const {
        questions,
        bookingId,
        sessionId,
        viewKey,
        guid,
        paymentLink,
        ...preview
    } = res;

    setBookingState(category, {
        isLoading: false,
        preview: { ...preview },
        error: null,
        paymentDetails: res
    }, 'MAKE_BOOKING/SET_SUCCESS');
};
