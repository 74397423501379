import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';

/**
 * @typedef {"light"  | "dark" | "normal" | "core-blue" | "atcom-rte"} variants


/**
 * @typedef {Object} TextProps
 * @property {variants} variant
 * @property {string} className
 * @property {Object} dangerouslySetInnerHTML
 * */

/**
 * @name RteStyledContent
 * @description Renders raw html as styled rte editor content.
 * @param {TextProps & React.BaseHTMLAttributes} props
 * @returns {React.JSX.Element | null}
 * @example
 * <RteStyledContent variant="dark" dangerouslySetInnerHTML={{ __html: "<div>html content</div>" }} />
 */

function RteStyledContent({ className, variant, ...props }) {
    return (
        <div className={cn("gallery tabs-block", className)}>
            <div
                className={`text-rte ${variant}`}
                {...props}
            ></div>
        </div>
    );
}

RteStyledContent.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['light', 'dark', 'normal', 'core-blue', 'atcom-rte']),
};

export default RteStyledContent;
