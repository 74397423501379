import PropTypes from 'prop-types';

import { cn, formatLocalDate, formatLocalTime } from '@/lib/utils';
import { Text, Icon } from '@/components/common';

/**
 * @typedef {Object} FlightCardDetailsProps
 * @property {number} index
 * @property {Object} flight
 * @property {Object} parentFlight
 * @property {number} flights
 * @property {string} className
 * @property {string} timeClassName
 * @property {string} nameWrapperClassName
 * @property {string} airportCodeClassName
 * @property {string} airportClassName
 * @property {string} layoverClassName
 * @property {string} daysForwardClassName
 * @property {bool} hidePlaneIcon
 * @property {()=>React.ReactNode} renderExtras
 */

/**
 * @name FlightCardDetails
 * @description Renders the flight card details component
 * @param {FlightCardDetailsProps} props
 * @returns { React.JSX.Element}
 * @example
 * <FlightCardDetails flight={flight} index={index} flights={flights?.length} />
 */

function FlightCardDetails({
	flight,
	parentFlight,
	isFinalSector,
	index,
	renderExtras,
	flights = 1,
	className,
	timeClassName,
	flightNumberClassName,
	nameWrapperClassName,
	airportCodeClassName,
	airportClassName,
	layoverClassName,
	daysForwardClassName,
	hidePlaneIcon,
}) {
	const departureTime = formatLocalTime(flight?.departureTime);
	const arrivalTime = formatLocalTime(flight?.arrivalTime);

	return (
		<div className={cn('flex flex-col gap-3', className)}>
			<div className={cn('w-full h-4 justify-between items-start gap-2.5 inline-flex', nameWrapperClassName)}>
				<div className="justify-start items-center gap-1 flex">
					{!hidePlaneIcon && (
						<Icon name="flight-fill" className="w-2.5 lg:w-3 text-dark-grey" />
					)}
					<Text className={cn("text-center text-neutral-800 text-xs font-medium leading-none", flightNumberClassName)}>
						{`${flight?.carrier?.code}${flight?.flightNumber}`}
					</Text>
				</div>
				<Text className={cn("text-center text-neutral-800 text-xs font-medium leading-none", flightNumberClassName)}>
					Flight {index} of {flights}
				</Text>
			</div>
			<div className="w-full h-3.5 justify-between items-center gap-2.5 inline-flex">
				<Text className={cn("text-center text-dark-grey text-xs font-medium leading-none", timeClassName)}>
					{departureTime ? departureTime : 'N/A'}
				</Text>
				<Text className={cn("text-center text-dark-grey text-xs font-medium leading-none", timeClassName)}>
					{flight?.duration}
				</Text>
				<Text className={cn("text-center text-dark-grey text-xs font-medium leading-none", timeClassName)}>
					{arrivalTime ? arrivalTime : 'N/A'}
				</Text>
			</div>
			<div className="w-full h-auto justify-start items-center gap-2.5 flex">
				<Text className={cn("text-center text-dark-grey text-xs font-medium leading-none", airportCodeClassName)}>
					{flight?.departure}
				</Text>
				<div className="grow shrink basis-0 h-px opacity-50 justify-start items-center gap-1 flex">
					<div className="grow shrink basis-0 h-px border border-neutral-800 border-opacity-60 border-dashed" />
					<Icon name="flight-fill" className="w-3 h-3" />
					<div className="grow shrink basis-0 h-px border border-neutral-800 border-opacity-60 border-dashed" />
				</div>
				<Text className={cn("text-center text-dark-grey text-xs font-medium leading-none", airportCodeClassName)}>
					{flight?.arrival}
				</Text>
			</div>
			<div className="w-full h-auto justify-between items-start gap-1 flex">
				<Text
					as="span"
					className={cn('text-dark-grey text-opacity-60 text-xs font-medium leading-3', airportClassName)}
				>
					{flight?.departureName ?? 'N/A'}
				</Text>
				<Text
					as="span"
					className={cn('text-dark-grey text-opacity-60 text-xs font-medium leading-3 text-end', airportClassName)}
				>
					{flight?.arrivalName ?? 'N/A'}
				</Text>
			</div>
			{typeof renderExtras === 'function' && (
				<div className="w-full">
					{renderExtras({ flight, parentFlight })}
				</div>
			)}
			{
				flight?.layoverDuration ? (
					<Text className={cn('flex flex-col w-full text-xs leading-normal', layoverClassName)}>
						<span>
							<span className="font-bold text-opacity-100">
								{flight?.layoverDuration} layover
							</span>
							<span className="text-dark-grey text-opacity-65">{' '}at</span>
						</span>
						<span className="text-dark-grey text-opacity-65 -mt-1">{flight?.arrivalName}</span>
					</Text>
				) : null
			}

			{isFinalSector && parentFlight?.daysForward > 0 && (
				<Text className={cn('text-xs font-bold tracking-less-tight leading-4', daysForwardClassName)}>
					{parentFlight?.daysForward === 1 ? 'Next-day arrival' : `Arriving ${parentFlight?.daysForward} days after departure`}
					{parentFlight?.arrivalTime ? ` (${formatLocalDate(flight?.arrivalTime, 'EEEE MMM d')})` : null}
				</Text>
			)}
		</div>
	);
}

FlightCardDetails.propTypes = {
	flight: PropTypes.object,
	parentFlight: PropTypes.object,
	index: PropTypes.number,
	className: PropTypes.string,
	timeClassName: PropTypes.string,
	nameWrapperClassName: PropTypes.string,
	airportCodeClassName: PropTypes.string,
	airportClassName: PropTypes.string,
	layoverClassName: PropTypes.string,
	daysForwardClassName: PropTypes.string,
	renderExtras: PropTypes.func,
	flights: PropTypes.number,
	hidePlaneIcon: PropTypes.bool,
	isFinalSector: PropTypes.bool,
};

FlightCardDetails.defaultProps = {
	flight: {},
	parentFlight: {},
	index: 1,
	className: '',
	flights: 1,
	hidePlaneIcon: true,
	isFinalSector: false,
};

export default FlightCardDetails;
