import qs from 'qs';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useRemoveQueries, useUrlParams } from '@/hooks';
import { useBookingStore } from '@/store';
import { Text } from '@/components/common';
import { CarList, CarMapList } from '@/components/carhire';

function SearchList({ list, carTypeFilter, fetching }) {
	let navigate = useNavigate();
	const { params } = useUrlParams();
	const { setBookingState } = useBookingStore();
	const { removeQueries } = useRemoveQueries();
	const state = useBookingStore((state) => state['car-hire']);

	if (import.meta.env.PROD) {
		navigate = (url) => (window.location.href = url);
	}

	const { results, extra } = useMemo(() => {
		if (!list) return { results: [], extra: {} };
		return { results: list.data, extra: list.extra };
	}, [list]);

	const handleCarSelect = ({ car, pickup, dropoff }) => {
		// check if car is available
		if (!car) return;

		const selected = {
			type: 'carhire',
			startDate: params.default.startDate,
			endDate: params.default.endDate,
			pickupLocation: pickup?.location,
			dropoffLocation: dropoff?.location,
			// location: 'CA-COUNTRY', // TODO: get location from somewhere ..
			code: car?.car_code,
			typeCode: car?.type_code,
			subTypeCode: car?.sub_type_code,
			carItem: car?.item,
			pickupDepot: pickup?.code,
			pickupTime: params.default.pickupTime,
			dropoffDepot: dropoff?.code,
			dropoffTime: params.default.dropoffTime,
		};

		// check if there is a car in the state already and check if the car code is the same as the selected car
		const isSameSelectedCar =
			state?.selected &&
			JSON.stringify(state?.selected) === JSON.stringify(selected);

		let urlString = qs.stringify(selected);

		if (!isSameSelectedCar) {
			// if new selected options, reset the selected, extras, and question state
			let newState = {
				...state,
				selected: undefined,
				carExtras: undefined,
				preview: undefined,
				questions: undefined,
				paymentDetails: undefined,
				error: null,
			};

			if (state?.bookingDetails) {
				newState = {
					...newState,
					bookingDetails: {
						...state.bookingDetails,
						questions: undefined,
					},
				};
			}

			setBookingState('car-hire', newState, 'RESET_SELECTED');

			// remove queries
			removeQueries(['carhire-extra', 'booking-preview']);
		}

		navigate(`/booking/car-hire/extras?${urlString}`, { replace: true });
	};

	return (
		<div className="w-full h-full my-4 md:px-6">
			{extra?.showOnMap === 'pickup' || extra?.showOnMap === 'dropoff' ? (
				<CarMapList
					results={results}
					fetching={fetching}
					carTypeFilter={carTypeFilter}
					handleCarSelect={handleCarSelect}
					showOnMap={extra.showOnMap}
					isOneWay={extra?.isOneWay}
					defaultCenter={extra?.mapCenter}
				/>
			) : (
				<div className="mx-auto max-w-4xl md:px-5 w-full flex flex-col md:gap-5">
					<Text className="text-xl w-full my-2 font-bold lg:text-3xl px-5 md:px-0">
						Select a car type
					</Text>
					{results &&
						results.length > 0 &&
						results.map((item, idx) => (
							<CarList
								fetching={fetching}
								cars={item?.cars || []}
								pickup={item?.pickup || {}}
								dropoff={item?.dropoff || {}}
								handleCarSelect={handleCarSelect}
								key={`${item?.pickup?.code}_${item?.dropoff?.code}_${idx}`}
							/>
						))}
				</div>
			)}
		</div>
	);
}

SearchList.propTypes = {
	list: PropTypes.object.isRequired,
	carTypeFilter: PropTypes.array,
};

SearchList.defaultProps = {
	list: {},
	carTypeFilter: [],
};

export default SearchList;
