import { format, isValid } from "date-fns";

/**
 * @typedef {Object} dateRangeFormatterParams
 * @property {string} startDate - Start date in range
 * @property {string} endDate - End date in range
 * @property {string} [dateFormat=dd/MM/yyyy] - Date format
 */

/**
 * @module Date
 * @desc Date range formatting utilities
 * @category Utils
 * @param {dateRangeFormatterParams} params
 * @returns { string } Formatted date range
 * @example
 * import { dateRangeFormatter } from '@/lib/utils';
 * dateRangeFormatter({ start: '2024-03-14', end: '2024-04-14', dateFormat: 'dd/MM/yyyy' }); // 14/03/2024 - 14/04/2024
 */
export const dateRangeFormatter = ({ start, end, dateFormat = 'dd/MM/yyyy' }) => {
    const formattedStart = formatLocalDate(start, dateFormat);
    if (!formattedStart) return null;

    const formattedEnd = formatLocalDate(end, dateFormat);
    if (!formattedEnd) return formattedStart;

    // only show range if formatted results aren't the same
    return formattedStart !== formattedEnd ?
        `${formattedStart} - ${formattedEnd}` :
        formattedStart;
};

/**
 * @typedef {Object} dobFormatterParams
 * @property {string} day
 * @property {string} month
 * @property {string} year
 */

/**
 * @module Date
 * @desc Format separate day/month/year values to Y-m-d format.
 * @category Utils
 * @param {dobFormatterParams} params
 * @returns { string } Formatted dob
 * @example
 * import { formatYmd } from '@/lib/utils';
 * formatYmd({ day: '14', month: '3', dateFormat: '2002' }); // 2002-03-14
 */
export const formatYmd = ({ day, month, year }) => {
    const formatD = typeof day !== 'undefined' ? day.toString().padStart(2, '0') : '00';
    const formatM = typeof month !== 'undefined' ? month.toString().padStart(2, '0') : '00';
    const formatY = typeof year !== 'undefined' ? year.toString().padStart(2, '0') : '0000';

    return `${formatY}-${formatM}-${formatD}`;
}

/**
 * @typedef {Object} localDateFormatterProps
 * @property {string} dateStr
 * @property {string} dateFnsFormat
 */

/**
 * @module Date
 * @desc Format a local date, ignoring it's timezone (date-only component).
 * @category Utils
 * @param {localDateFormatterProps} params
 * @returns { null|string } Formatted date
 * @example
 * import { formatLocalDate } from '@/lib/utils';
 * formatLocalDate('2024-03-19T16:50:19-09:00', 'dd/MM/yyyy'); // 19/03/2024
 */
export const formatLocalDate = (dateStr, dateFnsFormat) => {

    if (!(dateStr && typeof dateStr === 'string')) return null;

    // extract date part of iso string
    const [datePart, timePart] = dateStr.split('T');
    if (typeof datePart !== 'string') return null;

    const [year, month, day] = datePart.split('-');
    if (!(year && month && day)) return null;
    // parse date as midnight in the current timezone. e.g. for UTC 2024-06-12 will be parsed as 2024-06-12T00:00:00.000Z
    const parsed = new Date(`${year}-${month}-${day}`);
    if (!isValid(parsed)) return null;

    // remove timezone offset from the parsed date
    const offset = new Date(parsed.valueOf() + parsed.getTimezoneOffset() * 60 * 1000);
    if (!isValid(parsed)) return null;

    // format the offset date using date-fns
    return format(offset, dateFnsFormat || 'yyyy-MM-dd');
}

/**
 * @module Date
 * @desc Format a local date to 24-hour time from Atcom by splitting it's components.
 * @category Utils
 * @param {string} dateStr
 * @returns { null|string } Formatted date
 * @example
 * import { formatLocalDate } from '@/lib/utils';
* formatLocalTime('2024-06-14T09:50:00+00:00', 'yyyy-MM-dd'); // 09:50
 */
export const formatLocalTime = (dateStr) => {
    if (!(dateStr && typeof dateStr === 'string')) return null;

    const [date, time] = dateStr.split('T');
    if (!time) return null;

    const [hours, minutes] = time.split(':');
    if (!(hours && minutes)) return null;

    // ignore invalid formatting
    if (hours?.length > 2 || minutes?.length > 2) return null;

    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
}
