import PropTypes from 'prop-types';
import ReactLoqate from 'react-loqate';
import { Controller } from 'react-hook-form';
import React, { useCallback, useEffect } from 'react';
import { ErrorMessage } from '@hookform/error-message';

import { Required } from '@/lib/utils';
import { useDisclosure } from '@/hooks';
import { LOQATE_API_KEY } from '@/lib/constants';
import { FormField, Button, Text, Input } from '@components/common';

function AddressForm({
	watch,
	control,
	errors,
	setValue,
	nameObj = 'address',
}) {
	const { isOpen, onOpen, onToggle } = useDisclosure(() => {
		if (watch(`${nameObj}.postcode`)) return true;
		return false;
	});

	// if there are errors, open the form
	const onErrors = useCallback(() => {
		if (!errors[nameObj]) return;
		onOpen();
	}, [errors]);

	useEffect(() => {
		onErrors();
	}, [onErrors]);

	const inputPostcode = watch(`${nameObj}.postcode`);

	const handlePostcodeSelect = (data) => {
		const setValParams = {
			shouldDirty: true,
			shouldValidate: true,
		};

		if (!data || data?.Error) {
			// Add details manually if there is an error
			onToggle();
			setValue(`${nameObj}.postcode`, inputPostcode, setValParams);

			return;
		}

		setValue(`${nameObj}.address1`, data?.Line1, setValParams);
		setValue(`${nameObj}.address2`, data?.Line2, setValParams);
		setValue(`${nameObj}.town`, data?.City, setValParams);
		setValue(`${nameObj}.county`, data?.Province, setValParams);
		setValue(`${nameObj}.postcode`, data?.PostalCode, setValParams);

		onToggle();
	};

	return (
		<div>
			{isOpen ? null : (
				<div className="flex items-center justify-between mb-2">
					<Text as="label">Address</Text>
					<Text variant="muted">required</Text>
				</div>
			)}
			{isOpen ? (
				<div className="flex flex-col items-start w-full gap-3 ">
					<FormField
						as="input"
						showRequired
						name={`${nameObj}.address1`}
						control={control}
						validation={[Required]}
						placeholder="Address 1"
						wrapperClassName="flex-1"
						label={isOpen ? 'Address 1' : null}
						errors={errors}
					/>
					<FormField
						as="input"
						name={`${nameObj}.address2`}
						control={control}
						placeholder="Address 2"
						wrapperClassName="flex-1"
						label={isOpen ? 'Address 2' : null}
						errors={errors}
					/>

					<FormField
						as="input"
						showRequired
						name={`${nameObj}.town`}
						control={control}
						placeholder="Town"
						label={isOpen ? 'Town' : null}
						wrapperClassName="flex-1"
						errors={errors}
					/>
					<FormField
						as="input"
						name={`${nameObj}.county`}
						control={control}
						placeholder="County"
						label={isOpen ? 'County' : null}
						wrapperClassName="flex-1"
						errors={errors}
					/>
					<FormField
						as="input"
						control={control}
						showRequired={isOpen}
						placeholder="Postcode"
						wrapperClassName="flex-1"
						name={`${nameObj}.postcode`}
						label={isOpen ? 'Postcode' : null}
						errors={errors}
					/>

					<Button
						hideIcon
						type="button"
						variant="link"
						disableAnimation
						onClick={onToggle}
						className="p-0 m-0 border-0"
						label="Find address"
					/>
				</div>
			) : (
				<div className="flex flex-col items-start w-full gap-2 ">
					<div className="flex w-full gap-3">
						<Controller
							control={control}
							name={`${nameObj}.postcode`}
							render={({
								field: { ref, value, ...rest },
								fieldState: { error },
							}) => (
								<div className="flex flex-col w-full gap-1">
									<ReactLoqate
										locale="en-GB"
										apiKey={LOQATE_API_KEY}
										value={value}
										components={{
											Input: (props) => (
												<Input
													ref={ref}
													name={`${nameObj}.postcode`}
													placeholder="Postcode"
													{...props}
												/>
											),
										}}
										classes={{
											list: 'bg-gray-50 w-full p-2 border border-border-color rounded bg-white max-h-96 overflow-y-auto mt-2',
											listItem: 'p-2 cursor-pointer hover:bg-gray-50 rounded',
										}}
										placeholder="Enter your address"
										className="flex-1 uppercase"
										onSelect={handlePostcodeSelect}
										debounce={500}
										{...rest}
										selected={value}
									/>
									{error?.message ? (
										<Text as="span" variant="error">
											&uarr; {error?.message}
										</Text>
									) : null}
								</div>
							)}
						/>
					</div>
					<ErrorMessage
						errors={errors}
						render={({ messages }) => {
							console.log(messages);
							return (
								<Text as="span" variant="error">
									&uarr; {messages?.required?.message}
								</Text>
							);
						}}
					/>
					<Button
						hideIcon
						type="button"
						variant="link"
						disableAnimation
						onClick={onToggle}
						className="p-0 m-0 border-0"
						label="Enter address manually"
					/>
				</div>
			)}
		</div>
	);
}

AddressForm.propTypes = {
	control: PropTypes.object.isRequired,
	watch: PropTypes.func.isRequired,
};

export default AddressForm;
