import { useNavigate, useLocation } from 'react-router-dom';
import { getPreviousStep, navigate as goTo } from '@/lib/utils';
import { useBookingStore } from '@/store';
import useSearchSave from './useSearchSave';

/**
 * @typedef Returns
 * @property {()=> void} handleBack
 * @property {import('@/lib/steps').step} previousStep
 */

/**
 * @name useBackNavigate
 * @description custom hook to handle back navigation
 * @param {import('@/lib/steps').step[]} steps
 * @param {"flights" | "hotels" | "carhire" | "motorhome"} [category]
 * @returns {Returns} Returns
 * @example
 * const { handleBack, previousStep } = useBackNavigate(steps, 'flights');
 */

function useBackNavigate(steps = [], category) {
	const { pathname } = useLocation();
	const sessionSteps = JSON.parse(sessionStorage.getItem('breadcrumbs'));
	const state = useBookingStore((store) => store[category]);

	const { getSearch } = useSearchSave();
	const savedSearch = getSearch(category);

	// use session steps if available else use steps
	let processSteps = sessionSteps ? sessionSteps : steps;

	const previousStep = getPreviousStep(processSteps, pathname);
	const isSearch = previousStep?.to.includes('/search');

	const navigate = useNavigate();

	const handleBack = () => {
		// check if navigation step includes /search
		if (!previousStep) {
			goTo('/');
			return;
		}

		// check if search string starts with ? and remove it
		let url = previousStep?.to;

		// handle back navigation for hotel page
		if (category === 'hotels' && previousStep?.to.includes('/search')) {
			// check if the current previous page is the search page is
			const hotelPage = state?.selected?.pageUrl ?? '';

			//get the selected hotel url from the state

			if (hotelPage) {
				goTo(hotelPage);
			} else {
				if (savedSearch) {
					const url = `${previousStep?.to}?${savedSearch}`;
					goTo(url);
				}
			}

			return;
		}

		if (previousStep?.search) {
			url = previousStep?.search.startsWith('?')
				? `${previousStep?.to}${previousStep?.search}`
				: `${previousStep?.to}?${previousStep?.search}`;
		}

		if (isSearch) {
			goTo(url, { from: pathname });
		} else {
			navigate(url, { state: { from: pathname } });
		}
	};
	return { handleBack, previousStep };
}

export default useBackNavigate;
