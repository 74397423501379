import * as yup from 'yup';

export const getPassengerSchema = (assistanceNeeded) => {
    return yup.object().shape({
        passengers: yup.array().of(
            yup.object().shape({
                title: yup
                    .object()
                    .shape({
                        label: yup.string(),
                        value: yup.string(),
                    }).default(undefined).required('Title is required'),

                firstName: yup
                    .string()
                    .min(2, 'Name should be at least two characters')
                    .max(50, 'Name should be less than 50 characters')
                    .required('First name is required'),
                middleName: yup.string(),

                lastName: yup
                    .string()
                    .min(2, 'Name should be at least two characters')
                    .max(50, 'Name should be less than 50 characters')
                    .required('Last name is required'),

                gender: yup
                    .object()
                    .shape({
                        label: yup.string(),
                        value: yup.string(),
                    })
                    .required('Gender is required'),
                dob: yup.object().shape({
                    day: yup
                        .number('Day should be a number')
                        .typeError('Day should be a number')
                        .min(1, 'Day should be at least 1')
                        .max(31, 'Day should be less than 31')
                        .required('Day is required')
                    ,
                    month: yup
                        .number('Month should be a number')
                        .typeError('Month should be a number')
                        .min(1, 'Month should be at least 1')
                        .max(12, 'Month should be less than 12')
                        .required('Month is required'),

                    year: yup
                        .number('Year should be a number')
                        .typeError('Year should be a number')
                        .min(1900, 'Year should be at least 1900')
                        .max(
                            new Date().getFullYear(),
                            'Year should be less than current year'
                        )
                        .required('Year is required'),
                }),
                assistance: assistanceNeeded ? yup
                    .object()
                    .shape({
                        label: yup.string(),
                        value: yup.string(),
                    }).nullable() : null,
            })
        ).min(1, 'At least one passenger is required').required('At least one passenger is required'),
        contact: yup.object().shape({
            phone: yup
                .string()
                .matches(/^[0-9]{10,11}$/, 'Please enter a valid UK telephone number')
                .required('Telephone number is required'),
            email: yup
                .string()
                .email('Please enter a valid email address')
                .required('Email address is required'),
            address: yup.object().shape({
                address1: yup.string().required('Address line 1 is required'),
                address2: yup.string(),
                town: yup.string().required('Town is required'),
                county: yup.string(),
                postcode: yup.string().required('Postcode is required'),
            }),
        }),
        marketing: yup.object().shape({
            email: yup.string().required('Email is required'),
            phone: yup.string().required('Phone is required'),
        }),

        terms_conditions: yup
            .boolean()
            .required('Check the box to confirm you have read the terms and conditions')
            .oneOf(
                [true],
                'Check the box to confirm you have read the terms and conditions'
            ),
        read_information: yup
            .boolean()
            .required('Check the box to confirm')
            .oneOf([true], 'Check the box to confirm'),
    });
};
