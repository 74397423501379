import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { Button, Text } from '@/components/common';
import pluralize from 'pluralize';

function MapListCard({
	noOfCars = 0,
	pickup,
	dropoff,
	logo,
	unlimitedMileage,
	onSelect,
	isSelected,
	showOnMap,
}) {
	const displayDepot = showOnMap === 'pickup' ? pickup : dropoff;
	const otherDepot = showOnMap === 'pickup' ? dropoff : pickup;

	const formatDepotName = (depot) =>
		[depot?.name, depot?.location_name].filter((name) => name).join(', ');

	return (
		<div
			className={cn(
				'w-full h-auto bg-white flex justify-start items-start py-5 gap-5'
			)}
		>
			<div className="w-[50px] h-[50px] border border-lighter-grey justify-center items-center flex">
				{logo && <img className="object-contain w-full h-full" src={logo} />}
			</div>
			<div className="grid self-stretch w-full grid-cols-1 gap-6 md:grid-cols-2 lg:gap-12">
				<div className="flex items-start col-span-1 gap-2">
					<div className="inline-flex flex-col items-start justify-start">
						<Text variant="bold" className="align-left grow shrink">
							{displayDepot.name}
						</Text>
						{displayDepot?.location_name && (
							<Text variant="muted">{displayDepot.location_name}</Text>
						)}
						{displayDepot?.code !== otherDepot?.code && (
							<Text variant="muted" className="mt-2">
								{showOnMap === 'pickup' ? 'Drop off' : 'Pick up'}
								{otherDepot?.name
									? `: ${formatDepotName(otherDepot)}`
									: ' at different a depot.'}
							</Text>
						)}
					</div>
				</div>
				<div className="flex flex-col items-start justify-end w-full col-span-1 gap-4 md:items-end xl:flex-row xl:justify-between xl:items-center">
					<div className="flex items-start gap-1 md:flex-col">
						{/* {unlimitedMileage && <Text variant="muted">Unlimited Mileage</Text>} */}
						<Text variant="muted">
							{noOfCars} car {pluralize('type', noOfCars)}
						</Text>
					</div>

					<div className="flex items-center justify-start gap-4 ">
						<Button
							hideIcon
							variant="outline"
							onClick={onSelect}
							labelClassName="truncate"
							className="border-core-blue"
							label={isSelected ? 'Cancel' : 'Select Car'}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

MapListCard.propTypes = {
	noOfCars: PropTypes.number.isRequired,
	showOnMap: PropTypes.oneOf(['pickup', 'dropoff']).isRequired,
	pickup: PropTypes.object.isRequired,
	dropoff: PropTypes.object.isRequired,
	logo: PropTypes.string,
	unlimitedMileage: PropTypes.bool.isRequired,
	onSelectCar: PropTypes.func,
};

MapListCard.defaultProps = {
	noOfCars: 3,
	showOnMap: 'pickup',
	pickup: {},
	dropoff: {},
	logo: null,
	unlimitedMileage: false,
	onSelectCar: () => {},
};

export default MapListCard;
