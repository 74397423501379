import * as React from 'react';
import PropTypes from 'prop-types';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { cn } from '@/lib/utils';

function SliderThumb({ className }) {
	return (
		<SliderPrimitive.Thumb
			className={cn(
				'block h-6 w-6 rounded-full bg-dark-grey transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 data-[disabled]:pointer-events-none',
				className
			)}
		/>
	);
}

const Slider = React.forwardRef(({ type, className, ...props }, ref) => {
	return (
		<SliderPrimitive.Root
			ref={ref}
			className={cn(
				'relative flex w-full touch-none select-none items-center data-[disabled]:opacity-75 data-[disabled]:cursor-not-allowed',
				className
			)}
			{...props}
		>
			<SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-supporting-coral/20">
				<SliderPrimitive.Range className="absolute h-full bg-supporting-coral" />
			</SliderPrimitive.Track>
			{type === 'range' ? (
				<>
					<SliderThumb />
					<SliderThumb />
				</>
			) : (
				<SliderThumb />
			)}
		</SliderPrimitive.Root>
	);
});

Slider.propTypes = {
	type: PropTypes.oneOf(['range', 'single']),
	className: PropTypes.string,
};

Slider.defaultProps = {
	type: 'single',
	className: '',
};

export default Slider;
