import cookie from 'js-cookie';
import { addDays } from 'date-fns';

/**
 * Object containing keys for different categories.
 * @typedef {Object} CategoryKeys
 * @property {string} flights - Key for flights category.
 * @property {string} hotels - Key for hotels category.
 * @property {string} carhire - Key for car hire category.
 * @property {string} holidays - Key for holidays category.
 * @property {string} motorhome - Key for motorhome hire category.
 */

/**
 * Keys for different categories.
 * @type {CategoryKeys}
 */
const categoryKeys = {
	flights: '__flight_search',
	hotels: '__hotel_search',
	carhire: '__car_search',
	holidays: '__holiday_search',
	motorhome: '__motorhome_search',
};


/**
 * @typedef {"flights" | "hotels" | "carhire" | "holidays" | "motorhome"} Category

/**
 * @name useSearchSave
 * @description Custom hook to save and retrieve search data from cookies.
 * @param {number} [ttl=14] - Time to live for the cookie in days. Default is 14 days.
 * @returns {{ getSearch: (category: Category ) => string, saveSearch: (content: string, category: Category) => void }} - Object containing functions to save and retrieve search data.
 * @example
 * import { useSearchSave } from '@/hooks';
 * const { saveSearch, getSearch } = useSearchSave(14);
 */
function useSearchSave(ttl = 14) {
	const expires = addDays(new Date(), ttl);
	const saveSearch = (content, category) => {
		const searchKey = categoryKeys[category] ?? '__search';
		const existingSearch = cookie.get(searchKey);

		if (existingSearch) {
			// if the content is the same as the existing search, don't save it again
			if (existingSearch === content) return;

			// if its different, remove the existing search
			cookie.remove(searchKey);
		}

		cookie.set(searchKey, content, { expires });
	};

	const getSearch = (category) => {
		const searchKey = categoryKeys[category] ?? '__search';
		return cookie.get(searchKey);
	};

	return {
		getSearch,
		saveSearch,
	};
}
export default useSearchSave;
