import { ErrorBoundary } from 'react-error-boundary';
import { motion, AnimatePresence } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';

import {
	Tabs,
	Text,
	Icon,
	TabsList,
	TabsContent,
	TabsTrigger,
	QueryWidget,
	MotorCarSearchForm,
	MotorCarQueryDetails,
} from '@/components/common';
import { cn } from '@/lib/utils';
import { openSearchTab } from '@/lib/external/openSearchTab';
import { useUrlParams } from '@/hooks';
import { HolidaySearchForm } from '@/components/holidays';
import { FlightQueryDetails, FlightSearchForm } from '@/components/flights';
import { HotelQueryDetails, HotelSearchForm } from '@/components/hotels';

const tabItems = [
	// {
	// 	value: 'holidays',
	// 	icon: 'holiday',
	// 	label: 'Holidays',
	// },
	{
		value: 'flights',
		icon: 'plane',
		label: 'Flights',
	},
	{
		value: 'hotels',
		icon: 'hotel',
		label: 'Hotels',
	},
	{
		value: 'car-hire',
		icon: 'car',
		label: 'Cars',
	},
	// {
	// 	value: 'motorhome-hire',
	// 	icon: 'motorhome',
	// 	label: 'Motorhome',
	// },
];

/**
 * @typedef {Object} SearchWidgetProps
 * @property {boolean} isHome - True if the widget is used in the home page
 * @property {boolean} isHeader - True if the widget is used in the header of a page
 * @property {"flights" | "holidays" | "hotels" | "motorhome-hire" | "car-hire"} [category] - The category of the search widget
 */

/**
 * @name SearchWidget
 * @description A component that shows the search widget. This component is portalized for the header and home page.
 * @param {SearchWidgetProps} props
 * @returns {React.JSX.Element}
 * @example
 * <SearchWidget isHome /> // for home page
 * <SearchWidget /> // for header
 */

function SearchWidget({ isHome, isHeader, category, className }) {
	const [view, setView] = useState('search');
	const { params, defaultParamSize } = useUrlParams();
	const [activeTab, setActiveTab] = useState(() => {
		if (isHome) return 'flights';

		if (category) return category;

		if (defaultParamSize > 0) {
			return params.default.category;
		}
		return 'search';
	});

	const forms = {
		holidays: HolidaySearchForm,
		flights: FlightSearchForm,
		hotels: HotelSearchForm,
		'car-hire': MotorCarSearchForm,
		'motorhome-hire': MotorCarSearchForm,
	};

	const defaultCategory = useMemo(() => {
		if (isHome) return 'holidays';
		if (category) return category;
		if (params?.default?.category) return params.default.category;
	}, [category, params.default?.category]);

	const isOnResultPage = useMemo(() => {
		if (!params?.default?.category) return false;

		// assume we're on a results page if a valid 'category' param is in the qs
		return Object.keys(forms).includes(params.default.category);
	}, [params?.default?.category]);

	const clearActiveTab = () => {
		if (isHome) return;
		if (isOnResultPage && defaultParamSize > 0 && view === 'search') {
			setView('query');
		}
		setActiveTab('search');
	};

	useEffect(() => {
		if (!isHome && isOnResultPage && defaultParamSize > 0) {
			setView('query');
		}
	}, [defaultParamSize, isHome, params?.default?.category]);

	const SearchForm = useMemo(() => {
		if (!forms[activeTab]) return null;
		return forms[activeTab];
	}, [activeTab]);

	const ParamWidget = useMemo(() => {
		if (!defaultCategory) return () => null;
		const widgets = {
			holidays: HotelQueryDetails,
			flights: FlightQueryDetails,
			hotels: HotelQueryDetails,
			'car-hire': MotorCarQueryDetails,
			'motorhome-hire': MotorCarQueryDetails,
		};
		if (!widgets[defaultCategory]) return () => null;
		return widgets[defaultCategory];
	}, [defaultCategory]);

	const onTabClick = (tabValue) => {
		if (activeTab === tabValue) {
			clearActiveTab();
			return;
		}

		setActiveTab(tabValue);
	};

	useEffect(() => {
		openSearchTab(onTabClick);
	}, []);

	return (
		<div
			className={cn(
				'inline-flex h-auto w-full items-start justify-start z-50 sticky top-0 left-0',
				className
			)}
		>
			<AnimatePresence initial={false} mode="wait">
				{view === 'search' ? (
					// search view
					<motion.div
						className="w-full"
						initial={{ y: -20, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: -20, opacity: 0 }}
					>
						<Tabs value={activeTab} className="w-full h-full bg-white">
							<TabsList
								className={cn(
									'm-0 h-auto w-full p-0',
									isHome || isHeader ? 'lg:h-14' : 'lg:h-18'
								)}
							>
								<TabsTrigger
									disabled
									value="search"
									className={cn(
										'hidden lg:flex h-full w-[142px] items-center justify-start rounded-none border-none bg-core-blue px-16 disabled:opacity-100',
										isHome || isHeader ? 'py-2' : 'py-3'
									)}
								>
									<div className="relative h-3.5 w-3.5">
										<Icon name="search" className="text-white" />
									</div>
								</TabsTrigger>
								{tabItems.map((item) => (
									<TabsTrigger
										key={item.value}
										value={item.value}
										onClick={() => {
											if (activeTab === item.value) {
												clearActiveTab();
												return;
											}

											setActiveTab(item.value);
										}}
										className={cn(
											'group flex flex-col lg:flex-row w-full h-full shrink grow basis-0 items-center justify-center lg:gap-5 rounded-none border-b-2 border-b-dark-grey border-opacity-10 bg-white p-2 sm:px-4 xl:px-8 2xl:px-16 text-light-black data-[state=active]:text-white lg:data-[state=active]:text-light-black transition-all appearance-none data-[state=active]:bg-core-red lg:data-[state=active]:bg-transparent lg:data-[state=active]:border-b-2 lg:data-[state=active]:border-b-core-red data-[state=active]:shadow-none',
											{ 'lg:py-3': !isHome && !isHeader }
										)}
										data-tab-id={`tab-${item.value}`}
									>
										<Icon name={item.icon} className="h-8" />
										<Text
											variant="bold"
											className={cn(
												'text-3xs sm:text-xxs lg:text-base tracking-extra-tight font-body uppercase lg:normal-case opacity-75',
												activeTab === item.value
													? 'text-white lg:text-light-black opacity-100'
													: ''
											)}
										>
											{item.label}
										</Text>
										<Icon
											name="chevron-down"
											className="hidden group-data-[state=active]:rotate-180 transition-all appearance-none lg:flex"
										/>
									</TabsTrigger>
								))}
							</TabsList>

							<AnimatePresence initial={false} mode="wait">
								{SearchForm ? (
									<motion.div
										initial={{ height: 0 }}
										animate={{ height: 'auto' }}
										exit={{ height: 0 }}
										className="w-full bg-white"
									>
										<motion.div
											className="w-full"
											initial={{ y: -20, opacity: 0 }}
											animate={{ y: 0, opacity: 1 }}
											exit={{ y: -20, opacity: 0 }}
											transition={{ delay: 0.1 }}
										>
											<TabsContent className="h-full p-0 m-0" value={activeTab}>
												<div className="mx-auto h-fit w-full p-5 md:px-7.5 lg:px-14 md:py-8">
													<ErrorBoundary fallback={<p>Something went wrong</p>}>
														<SearchForm
															hideClearBtn={isHome}
															clear={clearActiveTab}
															motorhome={activeTab === 'motorhome-hire'}
															params={
																defaultParamSize > 0 ? params.default : null
															}
														/>
													</ErrorBoundary>
												</div>
											</TabsContent>
										</motion.div>
									</motion.div>
								) : null}
							</AnimatePresence>
						</Tabs>
					</motion.div>
				) : (
					<motion.div
						className="w-full"
						initial={{ y: -20, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: -20, opacity: 0 }}
					>
						<QueryWidget
							category={defaultCategory}
							changeCategory={() => {
								setView('search');
								setActiveTab(defaultCategory);
							}}
						>
							<ErrorBoundary
								fallback={
									<div className="w-full h-full">Something went wrong</div>
								}
							>
								<ParamWidget
									params={params.default}
									QueryItem={QueryWidget.Item}
								/>
							</ErrorBoundary>
						</QueryWidget>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}

export default SearchWidget;
