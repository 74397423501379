import { cn } from '@/lib/utils';
import React from 'react';
import PropTypes from 'prop-types';

const Input = React.forwardRef(
	(
		{
			className,
			fieldWrapperClassName,
			type,
			isValid,
			prefix,
			suffix,
			disabled,
			...props
		},
		ref
	) => {
		return (
			<div className={cn('relative p-0 m-0', fieldWrapperClassName)}>
				{prefix && (
					<div
						className={cn(
							'absolute top-0 left-0 w-fit px-2.5 text-lg text-lightest-grey h-full flex items-center justify-center',
							disabled && 'opacity-75'
						)}
					>
						{prefix}
					</div>
				)}
				<input
					ref={ref}
					type={type}
					className={cn(
						'w-full h-10.5 lg:h-13.25 p-3 bg-white rounded border border-border-color focus-within:outline-0 focus-within:ring-0 focus:outline-transparent focus:border-border-color disabled:cursor-not-allowed disabled:text-lightest-grey disabled:border-lightest-grey/60',
						!isValid
							? 'text-core-red border-core-red focus:border-core-red'
							: '',
						prefix ? 'pl-8' : 'pl-3',
						suffix ? 'pr-8' : 'pr-3',
						className
					)}
					disabled={disabled}
					{...props}
				/>
				{suffix && (
					<div className="absolute top-0 right-1 w-fit px-2.5 text-lg text-lightest-grey h-full flex items-center justify-center">
						{suffix}
					</div>
				)}
			</div>
		);
	}
);

Input.propTypes = {
	className: PropTypes.string,
	fieldWrapperClassName: PropTypes.string,
	type: PropTypes.string,
	isValid: PropTypes.bool,
	prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	disabled: PropTypes.bool,
};

Input.defaultProps = {
	type: 'text',
	isValid: true,
	prefix: null,
	disabled: false,
};

Input.displayName = 'Input';

export default Input;
