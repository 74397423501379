import { useState } from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import {
	Text,
	Select,
	Accordion,
	AccordionItem,
	AccordionContent,
	AccordionTrigger,
} from '@components/common';
import Button from '@/components/common/atoms/button';

function DropDownFilter({
	name,
	title,
	value,
	className,
	inputOptions,
	onFilterChange,
	renderSelectedValues,
	disabled,
}) {
	const [activeValue, setActiveValue] = useState('');
	const handleChange = (selected) => {
		const selectedValues = {
			name,
			value: { ...value, [selected.name]: selected.value },
		};
		onFilterChange(selectedValues);
	};

	return (
		<div className={cn('w-full', className)}>
			<Accordion value={activeValue} onValueChange={setActiveValue} collapsible>
				<AccordionItem value={name} className="group py-2">
					<AccordionTrigger asChild>
						<Text variant="bold" className="p-0 m-0 tracking-normal">
							{title}
						</Text>
					</AccordionTrigger>
					<div>
						{value && Object.keys(value).length > 0 ? (
							<Text
								variant="muted"
								className="text-sm flex gap-1 group-data-[state=open]:hidden group-data-[state=closed]:flex transition-all ease-in-out"
							>
								{renderSelectedValues(value)}
								<span
									role="button"
									aria-label="change"
									onClick={() => setActiveValue(name)}
									className="underline cursor-pointer underline-offset-4"
								>
									(change)
								</span>
							</Text>
						) : (
							<Text
								as="span"
								variant="muted"
								className="text-sm flex gap-1 group-data-[state=open]:hidden"
							>
								Any
								<span
									role="button"
									aria-label="change"
									onClick={() => setActiveValue(name)}
									className="underline cursor-pointer underline-offset-4"
								>
									(change)
								</span>
							</Text>
						)}
					</div>
					<AccordionContent
						value={name}
						className="group-data-[state=open]:p-2 flex flex-col gap-8 z-0 overflow-visible"
						childClassName="pb-0"
					>
						<div
							className={cn(
								'h-auto grid grid-cols-1 gap-4 lg:grid-cols-2',
								inputOptions && inputOptions.length === 1 && 'lg:grid-cols-1'
							)}
						>
							{inputOptions.map((input) => {
								const selected = input?.options.find((option) => {
									const selectedValue = value
										? value[input?.name]?.value?.toString()
										: null;
									if (!selectedValue) return false;

									return (
										value &&
										input?.name &&
										typeof input?.value !== 'undefined' &&
										option.value.toString() === selectedValue
									);
								});

								return (
									<div
										key={input.name}
										className="flex flex-col gap-1 items-start"
									>
										{input.label && (
											<Text variant="muted" className="text-sm">
												{input.label}
											</Text>
										)}
										<Select
											key={selected?.value}
											className="cols-span-1"
											name={input.name}
											value={selected}
											options={input.options}
											onChange={(selected) =>
												handleChange({
													name: input.name,
													value: selected,
												})
											}
											isValid
											disabled={disabled}
										/>
										{!input?.hideReset && (
											<div
												className={
													disabled ? 'cursor-not-allowed opacity-75' : null
												}
											>
												<Button
													type="button"
													variant="unstyled"
													className="underline underline-offset-3"
													onClick={() =>
														handleChange({
															name: input.name,
															value: null, // clear the value
														})
													}
													disabled={disabled}
												>
													Any
												</Button>
											</div>
										)}
									</div>
								);
							})}
						</div>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</div>
	);
}

DropDownFilter.propTypes = {
	name: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.arrayOf(PropTypes.string),
	]),
	className: PropTypes.string,
	inputOptions: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			options: PropTypes.arrayOf(
				PropTypes.shape({
					label: PropTypes.string.isRequired,
					value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
						.isRequired,
				})
			).isRequired,
		})
	),
	disabled: PropTypes.bool,
};

DropDownFilter.defaultProps = {
	disabled: false,
};

export default DropDownFilter;
