import { createElement } from 'react';
import PropTypes from 'prop-types';
import { cva } from 'class-variance-authority';
import { cn } from '@/lib/utils';

const stackVariants = cva('flex', {
	variants: {
		orientation: {
			default:
				'flex-col justify-between md:flex-row md:items-center gap-5 md:justify-between',
			vertical: 'flex-col gap-5 items-start justify-between',
		},
	},
	defualtVariants: {
		orientation: 'vertical',
	},
});

function Stack({ as, orientation = 'vertical', className, ...props }) {
	return createElement(as, {
		className: cn(stackVariants({ orientation, className })),
		...props,
	});
}

Stack.propTypes = {
	as: PropTypes.oneOf(['div', 'p', 'span', 'section']).isRequired,
	orientation: PropTypes.oneOf(['vertical', 'default']),
	className: PropTypes.string,
};

Stack.defaultProps = {
	as: 'div',
	orientation: 'default',
	className: '',
};

export default Stack;
