import { Icon, Map, Text, MapMarker, Dialog } from '@/components/common';
import HotelCard from '@/components/hotels/molecules/card';

const hotels = [
	{
		lat: 45.5019,
		lng: -73.5674,
	},
	{
		lat: 46.2538,
		lng: -72.3232,
	},
	{
		lat: 44.65,
		lng: -63.64,
	},
	{
		lat: 45.2538,
		lng: -72.3232,
	},
	{
		lat: 45.38,
		lng: -75.69,
	},
	{
		lat: 43.75,
		lng: -79.3,
	},
];

function SearchMap() {
	return (
		<div className="h-[375px] md:h-[500px] lg:h-[800px] w-full z-0 relative">
			<Map
				instance="hotels"
				defaultCenter={[45.5019, -73.5674]}
				zoomLevel={7}
				className="z-[0]"
			>
				{hotels.map((hotel, index) => (
					<Dialog
						size="xl"
						key={index}
						as="drawer"
						hideCloseBtn
						position="right"
						renderTrigger={({ onOpen }) => (
							<MapMarker
								position={[hotel.lat, hotel.lng]}
								onClick={() => onOpen()}
							/>
						)}
					>
						{({ CloseButton }) => (
							<div className="relative">
								<CloseButton className="top-0 right-0 border-0 rounded-none text-white bg-dark-grey hover:bg-darker-grey" />
								<HotelCard variant="popup" result={hotel} />
							</div>
						)}
					</Dialog>
				))}
			</Map>
			<div className="w-fit px-4 rounded-full shadow-md bg-white flex items-center gap-1 absolute bottom-4 left-4 z-[100]">
				<Icon name="hotel-marker" className="w-5" />
				<Text className="text-sm">Hotel</Text>
			</div>
		</div>
	);
}
export default SearchMap;
