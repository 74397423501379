import React from 'react';
import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';

/**
 * @typedef {Object} ColumnProps
 * @property {string} className
 * @property {boolean} widthScreen
 **/

/**
 * @name Column
 * @description A reusable column component.
 * @param {ColumnProps & React.HTMLAttributes<HTMLDivElement>} props
 * @returns {React.JSX.Element}
 * @example
 * <Column className="bg-white">
 **/
function Column({ className, widthScreen, children, ...props }) {
	return (
		<div
			className={cn(
				'max-w-screen-4xl mx-auto h-full flex items-start gap-10',
				className,
				{
					'px-0 w-full !max-w-none': widthScreen,
				}
			)}
			{...props}
		>
			{children}
		</div>
	);
}

//grid grid-cols-1 gap-5 py-10 px-7 md:px-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:px-0

Column.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	widthScreen: PropTypes.bool,
};

Column.defaultProps = {
	className: '',
	widthScreen: false,
};

export default Column;
