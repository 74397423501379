import React from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '@/lib/utils';
import PropTypes from 'prop-types';
import { Icon, Text } from '@components/common';

/**
 * @typedef {"supporting-yellow" | "supporting-blue" | "core-blue" | "hollow-expand" | "button-field-footer" | "link" | "unstyled" |"outline"} buttonVariants
 **/

export const buttonVariants = cva(
	'button-fill-standard group transition-all duration-200 ease-in-out flex items-center justify-center gap-2.5 disabled:pointer-events-none disabled:opacity-75 disabled:transform-none',
	{
		variants: {
			variant: {
				'supporting-yellow': 'supporting-yellow',
				'supporting-blue': 'supporting-lightblue',
				'core-blue': 'core-blue',
				black:
					'bg-black text-white border-black hover:text-black hover:bg-white hover:border-black duration-0',
				'hollow-expand': 'button-hollow-expand',
				'hollow-arrow':
					'button-hollow-arrow no-underline hover:border-white group-hover:bg-white group-hover:text-dark-grey duration-0',
				'button-field-footer':
					'border-none text-sm font-normal text-left p-0 m-0',
				link: 'button-link',
				unstyled: 'border-none text-sm font-normal text-left p-0 m-0',
				outline: 'border-2 border-core-blue text-core-blue',
				'outline-mobile':
					'bg-transparent lg:bg-core-blue border-2 lg:border-3 border-core-blue text-core-blue lg:text-white',
			},
		},
		defaultVariants: {
			variant: 'core-blue',
		},
	}
);

/**
 * @typedef {object} ButtonProps
 * @property {buttonVariants} variant
 * @property {boolean} hideIcon
 * @property {import("@components/common/atoms/icon").iconNames} iconName
 * @property {string} label
 * @property {string} labelClassName
 * @property {string} iconClassName
 * @property {boolean} disableAnimation
 * @property {React.HTMLProps<HTMLButtonElement | HTMLAnchorElement>} buttonProps - Any additional props to pass to the button
 * */

/**
 * @name Button
 * @description Renders a button with an optional icon and label
 * @param {ButtonProps} props
 * @param {React.ForwardedRef<HTMLButtonElement>} ref
 * @returns {JSX.Element | null}
 * @example
 * <Button
 *   variant="core-blue"
 *   label="Continue"
 *   iconName="arrow-right"
 * />
 */

function Button(
	{
		className,
		tagName = 'button',
		variant = 'core-blue',
		children,
		hideIcon = false,
		label,
		labelClassName,
		iconName = 'arrow-right',
		disableAnimation = false,
		iconClassName,
		disabled = false,
		...props
	},
	ref
) {
	const btnProps = {
		ref: ref,
		className: cn(buttonVariants({ className, variant })),
		disabled: disabled,
		...props,
	};

	const btnChildren = children ? (
		children
	) : (
		<>
			{label && (
				<Text
					as="span"
					className={cn(
						'font-semibold',
						!disableAnimation &&
							'group-hover:font-bold transition-all duration-200',
						labelClassName
					)}
				>
					{label}
				</Text>
			)}
			{!hideIcon &&
				(iconName === 'arrow-right' ? (
					<span
						className={cn(
							'relative h-3 w-5.25 overflow-hidden',
							!disableAnimation &&
								'group-hover:w-10.25 duration-180 transition-all ease-out'
						)}
					>
						<Icon
							name={iconName}
							className={cn('absolute right-0', iconClassName)}
						/>
					</span>
				) : (
					<Icon
						name={iconName}
						className={cn(
							'w-6 h-full',
							!disableAnimation &&
								'transition-all group-hover:w-10 duration-200',
							iconClassName
						)}
					/>
				))}
		</>
	);

	return React.createElement(tagName, btnProps, btnChildren);
}

export default React.forwardRef(Button);
