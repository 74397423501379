/**
 * @typedef {Object} currencyFormatterParams
 * @property {number} amount - Amount to format
 * @property {"GBP" | "USD" | "CAD"} [currency=GBP] - Currency code
 * @property {"narrowSymbol" | "symbol" | "code" | "name"} [currencyDisplay=narrowSymbol] - Currency display type
 * @property {string} [locale=en-GB] - Locale
 * @property {{
 *      hideDecimal: boolean,
 * }} params
 */

/**
 * @module Currency
 * @desc Currency formatting utilities
 * @category Utils
 * @param {currencyFormatterParams} params
 * @returns { string } Formatted currency string
 * @example
 * import { currencyFormatter } from '@/lib/utils';
 * currencyFormatter({ amount: 1000 }); // £1,000
 */
export const currencyFormatter = ({ amount, currency = "GBP", currencyDisplay = "narrowSymbol", locale = 'en-GB', params }) => {
    if (amount === null || amount === undefined) return null;

    const formatOptions = { ...params }

    // If the amount has no decimal places, we don't want to show any
    const hasNoDecimal = amount % 1 === 0;

    if (params?.hideDecimal || hasNoDecimal) {
        formatOptions.minimumFractionDigits = 0;
        formatOptions.maximumFractionDigits = 0;

        delete formatOptions.hideDecimal;
    }

    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency,
        currencyDisplay,
        ...formatOptions,
    }).format(amount);
};
