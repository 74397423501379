import React, { useMemo } from 'react';

import { cn, currencyFormatter } from '@/lib/utils';
import { Tooltip, Text, Button, Icon, Badge } from '@/components/common';

/**
 * @typedef {{
 * 	seatNumber?: string;
 * 	type: string;
 * 	iconOverride?: string;
 * 	tooltip?: boolean;
 * 	text?: string;
 *  span?: number;
 *  cell: number;
 *  category?: string;
 *  positionOffset?: string;
 * }} Cell
 * */

/**
 * @typedef {object} SeatCellProps
 * @property {Cell} cell
 * @property {(params:{cell:Cell}) => void} onChange
 * @property {Cell} value
 * @property {string} className
 * */

/**
 * @name SeatCell
 * @description Renders a seat cell
 * @param {SeatCellProps} props
 * @returns {JSX.Element}
 * @example
 * <SeatCell cell={{}} onChange={() => {}} value={{}} />
 * */

function SeatCell({
	cell,
	cells,
	hView,
	onChange,
	selectedSeats,
	className,
	availability,
}) {
	const cellType = cell?.type.toLowerCase();
	const isAisle = cellType.toLowerCase() === 'aisle';
	const isLavatory = cellType.toLowerCase() === 'lavatory';
	const isExit = cellType.toLowerCase() === 'exit';
	const isText = cellType === 'text';
	const isEndOfClub = cell?.text?.toLowerCase().includes('end of club');
	const isGalley = cellType === 'galley';
	const isSeat = cellType === 'seat';

	// find a way to check if the seat is the middle seat

	const selected = useMemo(() => {
		return selectedSeats.find((item) => item.seat === cell?.seatNumber);
	}, [selectedSeats, cell?.seatNumber]);

	// remove space at the beginning of a string
	const seatIcon = cell?.iconOverride?.replace(/\s/g, '');

	// unavailable if not available, and it isn't already selected by the user
	const unavailable =
		!availability?.status ||
		(availability?.status?.toUpperCase() !== 'AVAILABLE' && !selected);

	const renderIcon = () => {
		if (isSeat && unavailable) {
			return <Icon name="unavailable-seat" className="w-4 h-4" />;
		}

		if (!isAisle && !isText && !isExit && !isGalley && !selected) {
			return (
				<Icon name={seatIcon ? seatIcon : 'default-seat'} className="w-4 h-4" />
			);
		}
	};

	const renderExitIcon = () => {
		if (!isExit) return null;
		return (
			<Text
				as="span"
				className={cn('flex items-center gap-1', {
					'flex-row-reverse': seatIcon === 'exit_r',
				})}
			>
				<Icon name={seatIcon} />
				<Text as="small">Exit</Text>
			</Text>
		);
	};

	const renderPrice = () => {
		if (availability?.price === 'undefined' || availability?.price === null)
			return null;
		return (
			<Text as="small" className="mt-1">
				{currencyFormatter({
					amount: availability?.price,
				})}
			</Text>
		);
	};

	const renderRestrictions = () => {
		if (!(cell?.restrictions || cell?.shortrestrictions)) return null;

		const restrictionText = cell?.shortrestrictions || cell?.restrictions;
		if (typeof restrictionText !== 'string') return null;
		if (restrictionText?.toLowerCase() === 'none') return null;

		return (
			<div className="flex flex-col gap-2 my-2">
				<Text>{restrictionText}</Text>
			</div>
		);
	};

	// don't show cockpit
	if (cellType === 'cockpit') return null;

	const handleSelect = (seat) => {
		// get next seat and previous seat
		const newSeat = {
			...seat,
			price: availability?.price || 0,
			isWindowSeat: seat?.feature?.toLowerCase().includes('window'),
		};

		onChange({ seat: newSeat, cells });
	};

	return (
		<Button
			id={cell?.seatNumber}
			variant="unstyled"
			data-seat-type={cellType}
			disabled={
				isLavatory || isExit || isText || isGalley || isAisle || unavailable
			}
			onClick={() => handleSelect(cell)}
			className={cn(
				'group rounded-none disabled:opacity-90 disabled:cursor-not-allowed w-fit h-auto flex-1',
				className,
				{ 'w-16 py-4': hView && isAisle },
				{
					' text-dark-grey/30': isExit,
				},
				{
					'grow-[2]': cell?.span === 2,
				},
				{
					'grow-[3]': cell?.span === 3,
				},
				{
					'grow-[4]': cell?.span === 4,
				},

				{
					'border-none text-gray-400 font-bold w-full': isText,
				},
				{
					'flex-1 w-full py-0 text-center': isText && isEndOfClub,
					'w-full h-full': isEndOfClub && isText && hView,
				},

				{
					'group-even/row:even:bg-light-grey group-odd/row:odd:bg-light-grey hover:group-odd/row:odd:bg-light-grey hover:group-even/row:even:bg-light-grey min-h-[60px] w-10 ':
						isSeat,
				},
				{ 'min-h-[50px] w-auto': hView && isSeat }
			)}
		>
			<Text as="span">
				<Tooltip
					sideOffset={0}
					position="center"
					hideTooltip={!cell?.tooltip}
					renderContent={() => (
						<div className="w-full">
							<Text className="w-full flex gap-2 items-center justify-between">
								<Text as="span" variant="bold">
									Seat
								</Text>
								<Text as="span">{cell?.seatNumber}</Text>
							</Text>
							<Text className="w-full flex gap-2 items-center justify-between my-2">
								<Text as="span" variant="bold">
									Cost
								</Text>
								<Text as="span">
									{availability?.price === 0
										? 'INCLUDED'
										: currencyFormatter({
												amount: availability?.price,
										  })}
								</Text>
							</Text>
							{availability && availability?.status ? (
								<Badge
									variant={
										availability?.status?.toLowerCase() === 'available'
											? 'success'
											: 'light-grey'
									}
									text={availability?.status}
								/>
							) : null}
							{renderRestrictions()}
						</div>
					)}
					className="bg-white max-w-[160px] p-5 col-span-full"
				>
					<span
						className={cn(
							'p-2 flex flex-col w-full h-full items-center justify-center text-sm font-bold',
							{
								'text-xs font-normal': isAisle || isText,
							},
							{
								'flex-row gap-2 p-0': hView,
							}
						)}
					>
						{renderIcon()}
						{isSeat && selected && (
							<Icon name="selection-seat" className="w-4 h-4" />
						)}
						{renderExitIcon()}

						{renderPrice()}

						{isText && cell?.text}
						{isAisle && cell?.text && cell?.text}
					</span>
				</Tooltip>
			</Text>
		</Button>
	);
}


export default SeatCell;
