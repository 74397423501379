import invoke from "./invoke";

// get locations
export const getLocations = async (endpoint) => {
    const { res, error } = await invoke('GET', endpoint);

    if (error) {
        throw new Error(error);
    }

    return Object.keys(res.data).map((key) => ({
        label: res.data[key],
        value: key,
    }));
}


// fetch car-rental results
export const getCarHireResults = async (queryString) => {
    const { res, error } = await invoke('GET', `car-rental/search?${queryString}`);

    if (error) {
        throw new Error(error);
    }

    return res;
}


// fetch car hire extras
export const getCarHireExtras = async (queryString) => {
    const { res, error } = await invoke('GET', `car-rental/extras?${queryString}`);

    if (error) {
        throw new Error(error);
    }

    return res.data;
}

// hotel search
export const getHotelSearchResults = async (queryString) => {
    const { res, error } = await invoke('GET', `hotel/search?${queryString}`);

    if (error) {
        throw new Error(error);
    }

    return res.data;
};

// site search
export const searchSite = async (term) => {
    if (term.length < 3) return;

    const urlString = new URLSearchParams({ term }).toString();

    const { res, error } = await invoke('GET', `site/search?${urlString}`);

    if (error) {
        throw new Error(error);
    }

    return res.data;

};

/**
 * @typedef {"hotel-search" | "carhire-extra" | "carhire-search" | "motorhome-search" | "flight-search" | "holiday-search" |"site-search" | "car-hire-locations" | "hotel-locations" | "hotel-prices" | "hotel-rooms" | "hotel-preview" | "hotel-booking" | "booking" | "booking-preview" | "baggage" | "option-plus" | "seating" | "mycaf-bookings"} endpointKey
 */

/**
 * @typedef {Object} Endpoint
 * @property {endpointKey} key - The key of the endpoint
 * @property {string} endpoint - The endpoint url path,
 */

/**
 * @name ENDPOINTS
 * @type {Array<Endpoint>}
 * @description - The list of endpoints
 * @example
 * import { ENDPOINTS } from '@/lib/api';
 **/

export const ENDPOINTS = [
    {
        key: 'hotel-search',
        endpoint: 'hotel/search',
    },
    {
        key: 'carhire-extra',
        endpoint: 'car-rental/extras',
    },
    {
        key: 'carhire-search',
        endpoint: 'car-rental/search',
    },
    {
        key: 'motorhome-search',
        endpoint: 'motorhome/search',
    },
    {
        key: 'flight-search',
        endpoint: 'flight/search',
    },
    {
        key: 'holiday-search',
        endpoint: 'holiday/search',
    },
    {
        key: 'find-my-holiday-search',
        endpoint: 'holiday/find-my-holiday',
    },
    {
        key: 'marketing-search',
        endpoint: 'marketing/search',
    },
    {
        key: 'flight-special-offer-search',
        endpoint: 'flight/offers',
    },
    {
        key: 'site-search',
        endpoint: 'site/search',
    },
    {
        key: 'car-hire-locations',
        endpoint: 'car-rental/locations',
    },
    {
        key: 'hotel-locations',
        endpoint: 'hotel/locations',
    },
    {
        key: 'hotel-prices',
        endpoint: 'hotel/prices',
    },
    {
        key: 'hotel-rooms',
        endpoint: 'hotel/rooms',
    },
    {
        key: 'hotel-preview',
        endpoint: 'hotel/preview',
    },
    {
        key: 'hotel-booking',
        endpoint: 'hotel/booking',
    },
    {
        key: "booking",
        endpoint: "booking"
    },
    {
        key: "booking-preview",
        endpoint: "booking/preview"
    },
    {
        key: "booking-confirm",
        endpoint: "booking/confirm"
    },
    {
        key: "retrieve-booking",
        endpoint: "booking" // add a booking id param in the invoke function
    },
    {
        key: 'option-plus',
        endpoint: 'flight/option-plus'

    },
    {
        key: "baggage",
        endpoint: "flight/baggage"
    },
    {
        key: "seating",
        endpoint: "flight/seating"
    },
    {
        key: "mycaf-bookings",
        endpoint: "mycaf/bookings"
    },
];
