import { useEffect, useMemo } from 'react';

import { useFetch } from '@/hooks';
import { useBookingStore } from '@/store';

// flights preview hook
function useFlightPreview(isDisabled) {
	const state = useBookingStore((store) => store.flights);
	const { setBookingState, buildParams } = useBookingStore();

	const previewParams = useMemo(() => {
		if (isDisabled) return null;
		if (!state?.shouldBuildParams) return null;
		return buildParams('flights');
	}, [state?.shouldBuildParams, isDisabled]);

	const { isLoading, error } = useFetch({
		key: 'booking-preview',
		method: 'POST',
		useBody: true,
		params: previewParams,
		config: {
			enabled: !!previewParams,
		},
		onData: (data) => {
			if (!data) return;
			if (data?.original?.error) {
				const state = {
					previewLoading: false,
					shouldBuildParams: false,
					error: { message: data?.original?.error },
				};
				setBookingState('flights', state, 'FLIGHTS_ONDATA_ERROR');
				return;
			}

			const { questions, ...rest } = data;

			const newState = {
				previewLoading: false,
				shouldBuildParams: false,
				preview: { ...rest },
				questions: questions?.length ? questions : state?.questions,
				error: null,
			};

			setBookingState('flights', newState, 'SET_PREVIEW');
		},
	});

	useEffect(() => {
		if (!previewParams) return;
		if (isLoading) {
			const state = { previewLoading: true };
			setBookingState('flights', state, 'FLIGHTS_PREVIEW_LOADING');
		}
		if (error) {
			const state = { error, previewLoading: false };
			setBookingState('flights', state, 'FLIGHTS_PREVIEW_ERROR');
		}
	}, [isLoading, error, previewParams]);

	return null;
}

// carhire preview hook
function useCarhirePreview(isDisabled) {
	const { setBookingState, buildParams } = useBookingStore();
	const state = useBookingStore((state) => state['car-hire']);

	const previewParams = useMemo(() => {
		if (isDisabled) return null;
		if (!state?.shouldBuildParams) return null;
		return buildParams('car-hire');
	}, [state?.shouldBuildParams, isDisabled]);

	const { isLoading, error } = useFetch({
		key: 'booking-preview',
		method: 'POST',
		useBody: true,
		params: previewParams,
		config: {
			enabled: !!previewParams,
			keepPreviousData: true,
		},
		onData: (data) => {
			if (data?.original?.error) {
				const newState = {
					previewLoading: false,
					shouldBuildParams: false,
					error: { message: data?.original?.error },
				};
				setBookingState('car-hire', newState, 'CARHIRE_ONDATA_ERROR');
				return;
			}

			const newState = {
				...state,
				previewLoading: false,
				shouldBuildParams: false,
				preview: data,
				questions: data?.questions?.length ? data.questions : state?.questions,
				error: null,
			};
			setBookingState('car-hire', newState, 'SET_CARHIRE_PREVIEW');
		},
	});

	useEffect(() => {
		if (!previewParams) return;
		if (isLoading) {
			const state = { previewLoading: true };
			setBookingState('car-hire', state, 'CARHIRE_PREVIEW_LOADING');
		}
		if (error) {
			const state = { error, previewLoading: false };
			setBookingState('car-hire', state, 'CARHIRE_PREVIEW_ERROR');
		}
	}, [isLoading, error, previewParams]);

	return null;
}

function useHotelPreview(isDisabled) {
	const { setBookingState, buildParams } = useBookingStore();
	const state = useBookingStore((state) => state.hotels);

	const previewParams = useMemo(() => {
		if (isDisabled) return null;
		if (!state?.shouldBuildParams) return null;

		return buildParams('hotels');
	}, [state?.shouldBuildParams, isDisabled]);

	const { isLoading, error } = useFetch({
		key: 'booking-preview',
		method: 'POST',
		useBody: true,
		params: previewParams,
		config: {
			enabled: !!previewParams,
		},
		onData: (data) => {
			if (data?.original?.error) {
				const newState = {
					previewLoading: false,
					shouldBuildParams: false,
					error: { message: data?.original?.error },
				};
				setBookingState('hotels', newState, 'HOTELS_ONDATA_ERROR');
				return;
			}

			const newState = {
				...state,
				previewLoading: false,
				shouldBuildParams: false,
				preview: data,
				questions: data?.questions?.length ? data.questions : state?.questions,
				error: null,
			};
			setBookingState('hotels', newState, 'SET_HOTELS_PREVIEW');
		},
	});

	useEffect(() => {
		if (!previewParams) return;
		if (isLoading) {
			const state = { previewLoading: true };
			setBookingState('hotels', state, 'HOTELS_PREVIEW_LOADING');
		}
		if (error) {
			const state = { error, previewLoading: false };
			setBookingState('hotels', state, 'HOTELS_PREVIEW_ERROR');
		}
	}, [isLoading, error, previewParams]);

	return null;
}

/**
 * @typedef {"flights" | "hotels" | "carhire"|"motorhome-hire" | "holidays"} category
 */

/**
 * @name usePreviewFetch
 * @description A hook to fetch the preview for the category - resolves refetch on re-render
 * @param {category} category
 * @param {bool} isDisabled
 * @returns {(params: category)=> void | null}
 * @example
 * usePreviewFetch('flights');
 */
function usePreviewFetch(category, isDisabled = false) {
	switch (category) {
		case 'flights':
			return useFlightPreview(isDisabled);
		case 'car-hire':
			return useCarhirePreview(isDisabled);
		case 'hotels':
			return useHotelPreview(isDisabled);
		default:
			return null;
	}
}

export default usePreviewFetch;
