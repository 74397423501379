import PropTypes from 'prop-types';

import { useDisclosure } from '@/hooks';
import { BlockLayout } from '@components/holidays';
import { cn, currencyFormatter } from '@/lib/utils';
import { Badge, Button, Icon } from '@/components/common';
import {
	FareModal,
	FlightCard,
	FlightBlockListItem,
} from '@/components/flights';

/**
 * @typedef {Object} FlightBlockProps
 * @property {Object} flight
 * @property {string} title
 * @property {string} className
 * @property {() => React.ReactNode} renderStep
 * */

/**
 * @name FlightBlock
 * @description Renders a travel plan block with the flight details
 * @param {FlightBlockProps} props
 * @returns {JSX.Element | null}
 * */
function FlightBlock({ flight, title, renderStep }) {
	const { isOpen, onToggle } = useDisclosure(false);
	const isOutbound = title.toLowerCase().includes('outbound') ?? false;

	return (
		<BlockLayout
			title={title}
			date={isOutbound ? flight?.departureTime : flight?.arrivalTime}
			renderStep={renderStep}
		>
			<div className="flex flex-col p-5 gap-3 lg:items-end lg:gap-0">
				<span className="align-left w-24 h-24 p-3 border border-lighter-grey flex items-center justify-center lg:w-20 lg:h-auto lg:border-0 lg:p-0">
					<Icon className="w-full h-full" name="air-transat" />
				</span>
				<div className="grid grid-cols-1 gap-5 w-full lg:grid-cols-5">
					<div className="col-span-3 w-full">
						<Badge
							text="Best Price"
							className="text-core-blue bg-supporting-yellow uppercase mb-3"
						/>
						<FlightCard
							hideFlightLogo
							flight={flight}
							detailsClassName="bg-light-grey"
						/>
					</div>
					<div className="col-span-2 flex flex-col items-start justify-end gap-2 lg:items-end w-full">
						<FareModal
							onSelect={(value) => console.log(value)}
							renderTrigger={({ DialogTrigger, onOpen }) => (
								<DialogTrigger asChild>
									<Button
										hideIcon
										variant="unstyled"
										label={`Upgrade fare from ${currencyFormatter({
											amount: 120,
											params: {
												hideDecimal: true,
											},
										})}`}
										onClick={onOpen}
										className="underline underline-offset-4 font-bold w-auto text-core-light-blue"
									/>
								</DialogTrigger>
							)}
						/>
						<Button
							label={
								isOpen
									? 'Cancel'
									: `Change Flights from + ${currencyFormatter({
										amount: 98,
										params: {
											hideDecimals: true,
										},
									})}`
							}
							variant={isOpen ? 'outline' : 'core-blue'}
							hideIcon
							className={cn('w-max ', {
								'bg-lightish-grey text-core-blue p-3 px-10 border-none hover:border-2 hover:border-lightish-grey':
									isOpen,
							})}
							onClick={onToggle}
						/>
					</div>
				</div>
			</div>

			{isOpen && (
				<div className="p-3 border-t-4 border-core-blue bg-light-grey lg:p-5">
					<div className="w-full h-auto bg-white border border-lighter-grey">
						{Array.from({ length: 3 }).map((_, i) => (
							<FlightBlockListItem key={i} flight={flight} />
						))}
					</div>
				</div>
			)}
		</BlockLayout>
	);
}

FlightBlock.propTypes = {
	flight: PropTypes.object,
	title: PropTypes.string,
	className: PropTypes.string,
	renderStep: PropTypes.func,
};

FlightBlock.defaultProps = {
	flight: {},
	className: '',
	renderStep: () => null,
	title: 'Outbound Flight',
};

export default FlightBlock;
