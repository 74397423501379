/**
 * @typedef {"option-plus" | "seat-selection"} requestParamsKey
 */

/**
 * @name requestParams
 * @param {requestParamsKey} key 
 * @param {any} state 
 * @returns {unknown} params
 */

export function requestParams(key, state) {
    if (!state || !key) return null;

    switch (key) {
        case 'option-plus':
            const selectedItems = state?.selected?.items;
            const pax = state?.bookingDetails?.pax;
            const people = state?.selected?.people;

            if (!selectedItems || !selectedItems.length) return null;

            return {
                items: selectedItems,
                pax: pax ? pax : null,
                people: pax ? [] : [people],
            }

        case 'seat-selection':
            const items = state?.selected.items ?? [];
            if (!items?.length) return null;

            const params = items.reduce((acc, item) => {
                const outbound = item?.outbound;
                const inbound = item?.inbound;

                if (outbound) {
                    acc.outbound = {
                        ...acc.outbound,
                        ...outbound,
                    };
                }

                if (inbound) {
                    acc.inbound = {
                        ...acc.inbound,
                        ...inbound,
                    };
                }

                return acc;
            }, {});

            return params;

        default:
            return null
    }

}
