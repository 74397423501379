import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { addYears, format, isDate, isEqual, set } from 'date-fns';

import { cn } from '@/lib/utils';
import { Icon, Button, Calendar, Dialog, Text } from '@components/common';

function DatePicker({
	mode = 'single',
	onChange,
	selected,
	name,
	label,
	wrapperClassName,
	...calendarProps
}) {
	const [selectedDate, setSelectedDate] = useState(null);

	useEffect(() => {
		if (selectedDate) return;
		if (!isDate(selected)) return;

		setSelectedDate(selected);
	}, [selected]);

	const handleRangeChange = (date) => {
		setSelectedDate(date);
		typeof onChange === 'function' && onChange(date);
	};

	return (
		<div className={cn('flex flex-col gap-2 w-full', wrapperClassName)}>
			<div className="flex items-center justify-between">
				<Text
					as="label"
					htmlFor={name}
					className="text-base leading-tight text-light-black"
				>
					{label}
				</Text>
			</div>
			<Dialog
				as="modal"
				size="lg"
				hideCloseBtn
				contentClassName="w-fit p-8 bg-white"
				renderTrigger={({ DialogTrigger, onOpen }) => (
					<DialogTrigger asChild>
						<Button
							onClick={onOpen}
							variant="outline"
							className={cn(
								'flex h-10.25 lg:h-13.25 w-full items-center justify-between gap-2 rounded border border-border-color bg-white px-3 text-base font-normal tracking-normal',
								!selected && 'text-muted-foreground'
							)}
						>
							{selected ? (
								format(selected, 'dd/MM/yyyy')
							) : (
								<span>Pick a date</span>
							)}
							<Icon name="calendar" />
						</Button>
					</DialogTrigger>
				)}
			>
				{({ CloseButton, onClose }) => (
					<div className="w-full">
						<CloseButton
							variant="square"
							className="absolute top-0 right-0 text-white bg-core-blue"
						/>

						<Calendar
							mode={mode}
							selected={selectedDate}
							onSelect={handleRangeChange}
							defaultMonth={selectedDate ? selectedDate : new Date()}
							initialFocus
							{...calendarProps}
						/>

						<Button
							variant="supporting-yellow"
							onClick={onClose}
							className="text-base font-bold"
						>
							Confirm
						</Button>
					</div>
				)}
			</Dialog>
		</div>
	);
}

export default DatePicker;
