import { cn } from '@/lib/utils';
import PropTypes from 'prop-types';
import { FlightDestinationSummary } from '@/components/flights';

function SmallFlightCard({
	flight,
	renderTitle,
	renderSummary,
	children,
	className,
	...props
}) {
	return (
		<div
			className={cn(
				'group/sfc h-auto grid grid-cols-1 gap-2 bg-white border border-lighter-grey p-5 w-full tracking-normal aria-disabled:opacity-50 aria-disabled:cursor-not-allowed aria-disabled:pointer-events-none lg:gap-5 xl:grid-cols-5 lg:items-center ',
				className
			)}
			{...props}
		>
			<div className="col-span-1 xl:col-span-3">
				<div className="font-bold capitalize flex items-center gap-3">
					{renderTitle({ flight })}
				</div>

				{typeof renderSummary === 'function' ? (
					renderSummary()
				) : (
					<FlightDestinationSummary flight={flight} />
				)}
			</div>
			<div className="group/sfc-disabled:opacity-50 mt-2 md:mt-0 col-span-1 flex xl:justify-end xl:col-span-2">
				{typeof children === 'function' ? children({ flight }) : children}
			</div>
		</div>
	);
}

SmallFlightCard.propTypes = {
	flight: PropTypes.object,
	renderTitle: PropTypes.func.isRequired,
	renderSummary: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
	className: PropTypes.string,
};

SmallFlightCard.defaultProps = {
	className: '',
};

export default SmallFlightCard;
