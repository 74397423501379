import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import useDimensions from 'react-cool-dimensions';
import {
	Badge,
	Button,
	Heading,
	Text,
	Image,
	RteStyledContent,
} from '@/components/common';
import { FavouriteToggle } from '@/components/mycaf';
import { cn, currencyFormatter, formatCarouselSlides } from '@/lib/utils';

function ResultCard({ result }) {
	const [showWhatsIncluded, setShowWhatsIncluded] = useState(false);
	const slides = useMemo(
		() => formatCarouselSlides(result?.gallery),
		[result?.gallery?.length]
	);
	const { observe, height, width } = useDimensions({
		debounce: 100,
	});

	return (
		<div className="w-full h-auto group perspective-2500">
			<div
				className={cn(
					'flex flex-col relative h-full w-full transition-transform duration-500 transform-style-3d',
					showWhatsIncluded && 'rotate-y-180'
				)}
			>
				{/* Front of card */}
				<div
					className={cn(
						'backface-hidden flex flex-col justify-between transition-opacity duration-400 opacity-100 h-full bg-white rounded-lg shadow-md',
						showWhatsIncluded && 'opacity-0'
					)}
				>
					{/* Front card content */}
					<div className="grid grid-cols-1">
						{slides?.length > 0 && (
							<div className="w-full col-span-1 h-72">
								<div
									ref={observe}
									className="relative h-full min-w-0 overflow-hidden rounded-t-lg shrink-0 grow-0 basis-full"
								>
									<Image
										height={height || 500}
										src={slides[0].src}
										alt={slides[0].alt}
										width={width || 500}
										className="object-cover w-full h-full"
									/>
								</div>
							</div>
						)}
						<div
							className={cn(
								'w-full grid-cols-1 bg-white flex flex-col gap-2 justify-center items-start px-7.5 pt-6 ',
								!slides?.length ? ' pt-18' : ''
							)}
						>
							{result?.pagetitle && (
								<Heading
									as="h3"
									className="self-stretch text-4xl font-bold text-core-blue"
								>
									{result.pagetitle}
								</Heading>
							)}

							{/* <Text className="text-lg font-semibold leading-tight text-dark-grey">
								Additional title goes here
							</Text> */}

							{result?.introtext && (
								<Text className="text-base font-normal leading-5 tracking-tighter opacity-75 text-dark-grey font-body">
									{result.introtext}
								</Text>
							)}

							{result?.nights && result?.price && (
								<div className="flex flex-col">
									<Text className="text-xs font-bold leading-tight uppercase text-dark-grey opacity-80">
										{result.nights} Nights From
									</Text>
									<div className="flex items-center gap-1">
										<Text variant="bold" className="text-2xl">
											{currencyFormatter({ amount: result.price })}
										</Text>

										<Text
											as="small"
											variant="bold"
											className="text-base leading-none"
										>
											pp
										</Text>
									</div>
								</div>
							)}
						</div>
					</div>

					<div className="absolute top-0 flex justify-between w-full pt-5 pb-0 pl-5 pr-4 z-4">
						<div className="flex flex-row flex-wrap gap-2.5 w-fit z-4 inset-x-5 md:left-10">
							{result?.types
								? result?.types.map((tag, i) => (
										<Badge
											key={i}
											text={tag}
											variant="opaque-blue"
											textClassName="font-body font-bold leading-3 text-xs uppercase leading-extra-snug"
										/>
								  ))
								: null}
						</div>

						{/* {result?.id && (
							<FavouriteToggle
								pageId={result.id}
								className="items-start pt-1 pl-4 pr-1"
								iconClassName={slides?.length ? 'text-white' : 'text-core-blue'}
							/>
						)} */}
					</div>

					{/* Front card footer */}
					<div className="w-full flex flex-col-reverse lg:flex-row gap-8 lg:gap-0 items-start justify-start lg:justify-between lg:items-center p-7.5 pt-2">
						{result?.url && (
							<Button
								label="View Holiday"
								variant="black"
								tagName="a"
								href={result.url}
								className="px-6 py-3 text-center w-fit"
								labelClassName="text-base"
								hideIcon
							/>
						)}

						{(result?.introtext || result?.whatsIncluded) && (
							<Button
								variant="unstyled"
								label="What’s included?"
								className={cn(
									'flex flex-row-reverse lg:flex-row items-center lg:text-right gap-2 w-fit'
								)}
								labelClassName={cn(
									'underline opacity-60 text-core-blue text-sm font-bold tracking-wide uppercase lg:normal-case'
								)}
								iconName="dark-info"
								iconClassName="w-5"
								disableAnimation
								onClick={() => setShowWhatsIncluded(true)}
							/>
						)}
					</div>
				</div>

				{/* Back of card */}
				<div
					className={cn(
						'backface-hidden absolute top-0 right-0 bottom-0 left-0 h-full -rotate-y-180 flex flex-col gap-2 p-7.5 transition-opacity duration-400 opacity-0 bg-white rounded-lg shadow-md overflow-y-auto',
						showWhatsIncluded && 'opacity-100'
					)}
				>
					{/* Back card content */}
					{result?.introtext && (
						<>
							<Heading
								as="h3"
								className="self-stretch text-3xl font-bold text-core-blue"
							>
								Overview
							</Heading>

							<Text className="text-base font-normal leading-snug tracking-tighter opacity-75 text-dark-grey font-body">
								{result.introtext}
							</Text>
						</>
					)}

					{result?.whatsIncluded && (
						<>
							<Heading
								as="h3"
								className="self-stretch text-3xl font-bold text-core-blue"
							>
								What’s included?
							</Heading>
							<RteStyledContent
								dangerouslySetInnerHTML={{ __html: result.whatsIncluded }}
							/>
						</>
					)}

					{/* Back card footer */}
					<div className="flex flex-row items-center justify-between w-full gap-8 pt-2 mt-auto lg:gap-0">
						{result?.url && (
							<Button
								label="Find out more"
								variant="black"
								tagName="a"
								href={result.url}
								className="px-6 py-3 text-center w-fit"
								labelClassName="text-base"
								hideIcon
							/>
						)}

						<Button
							variant="unstyled"
							label="Close"
							className={cn(
								'flex flex-row text-right items-center gap-2 w-fit'
							)}
							labelClassName={cn(
								'underline opacity-60 text-core-blue text-sm font-bold tracking-wide'
							)}
							hideIcon
							disableAnimation
							onClick={() => setShowWhatsIncluded(false)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

ResultCard.propTypes = {
	result: PropTypes.object.isRequired,
};

export default ResultCard;
