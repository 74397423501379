import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import {
	Text,
	TagGroup,
	Accordion,
	AccordionItem,
	CheckboxGroup,
	AccordionContent,
	AccordionTrigger,
} from '@components/common';
import { cn, sortFacetOptions } from '@/lib/utils';

/**
 * @typedef {Object} ListFilterProps
 * @property {string} name
 * @property {string} title
 * @property {string[]} values
 * @property {Object[]} options
 * @property {string} className
 * @property {(params: { name: string, value: string[] }) => void} onFilterChange
 **/

/**
 * @name ListFilter Component
 * @description This component is used to create a list filter
 * @param {ListFilterProps} props
 * @returns {React.JSX.Element}
 * @example
 * <ListFilter
 *   name="dates"
 *   title="Dates Filter"
 *   options={[]}
 *   values={[]}
 *   onFilterChange={(val)=> console.log(val)}
 * />
 **/

function ListFilter({
	name,
	title,
	values,
	options,
	className,
	onFilterChange,
	disabled,
}) {
	const [activeValue, setActiveValue] = useState('');
	const handleChange = (selected) => {
		onFilterChange({ name, value: selected });
	};

	const handleCancel = (option) => {
		let newValues = [];

		if (values && values.length > 0) {
			newValues = values.filter((value) => value !== option.value);
		}

		onFilterChange({ name, value: newValues });
	};

	const selectedOptions = useMemo(() => {
		if (values && values.length > 0) {
			return values
				.sort((a, b) => sortFacetOptions(name, a, b))
				.map((val) => ({
					label: val,
					value: val,
				}));
		}
		return [];
	}, [values]);

	return (
		<div className={cn('w-full', className)}>
			<Accordion value={activeValue} onValueChange={setActiveValue} collapsible>
				<AccordionItem value={name} className="group py-2">
					<AccordionTrigger asChild>
						<Text as="span" variant="bold" className="p-0 m-0 tracking-normal">
							{title}
						</Text>
					</AccordionTrigger>
					<div>
						{selectedOptions?.length > 0 ? (
							<TagGroup
								className="mt-2"
								onCancel={handleCancel}
								options={selectedOptions}
								disabled={disabled}
							/>
						) : (
							<Text
								as="span"
								variant="muted"
								className="text-sm flex gap-1 group-data-[state=open]:hidden group-data-[state=closed]:flex transition-all ease-in-out"
							>
								Any
								<span
									role="button"
									aria-label="change"
									onClick={() => setActiveValue(name)}
									className="underline cursor-pointer underline-offset-4"
								>
									(change)
								</span>
							</Text>
						)}
					</div>
					<AccordionContent
						value={name}
						className="py-4 px-4"
						childClassName="pb-0"
					>
						<CheckboxGroup
							key={values && values.length}
							options={options}
							values={values}
							onChange={handleChange}
							disabled={disabled}
						/>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</div>
	);
}

ListFilter.propTypes = {
	name: PropTypes.string, // name is the name of the accordion - enables accordion to be controlled
	title: PropTypes.string, // title in the trigger section of the accordion
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		})
	),
	onFilterChange: PropTypes.func,
	values: PropTypes.arrayOf(PropTypes.string),
	disabled: PropTypes.bool,
};

ListFilter.defaultProps = {
	name: 'accordion-name',
	title: 'Change title',
	options: [],
	values: [],
	onFilterChange: () => {},
	disabled: false,
};

export default ListFilter;
