import { useEffect } from 'react';
import { SearchResultsPage } from '@/components/common';
import { SearchResultsList } from '@/components/marketing-material';
import { withPortal } from '@/HOCs/withPortal';
import { buildFacetFilters } from '@/lib/utils';

const sortOptions = [
	{
		label: 'Most recently added',
		value: 'createdon:asc',
	},
	{
		label: 'Least recently added',
		value: 'createdon:desc',
	},
];

const pageOptions = [
	{
		label: '10',
		value: '10',
	},
];

/**
 * @name MarketingMaterialsSearch Page
 * @description Page to display marketing material search results
 * @returns {React.JSX.Element} MarketingMaterialsSearch
 */
function MarketingMaterialsSearch() {
	return (
		<SearchResultsPage
			paramsKey="marketing_filters"
			queryKey="marketing-search"
			sortOptions={sortOptions}
			pageOptions={pageOptions}
			hidePerPageField
			hideSaveAndShare
			filterLabel="Filter Assets By"
			className="lg:pt-12"
			buildFilters={(distribution, icons, stats) => {
				const filterOrder = [
					'videoLengths',
					'categories',
					'assetTypes',
					'locations',
					'orientations',
					'products',
					'suppliers',
				];

				return buildFacetFilters({
					distribution: distribution,
					icons: icons,
					customSortOrder: filterOrder,
				});
			}}
			ListResultsComponent={SearchResultsList}
		/>
	);
}

// portaled to marketing-material-search-page
export default withPortal(
	MarketingMaterialsSearch,
	'marketing-material-search-page'
);
