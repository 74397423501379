import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { Text, Icon } from '@/components/common';

/**
 * @typedef {Object} BookingCardProps
 * @property {string} label
 * @property {string} className
 * @property {string} bookingRef
 * @property {React.ReactNode} children
 * @property {"flights" | "hotels" | "holidays" | "motorhome-hire" | "car-hire"} category
 */

/**
 * @name BookingCard
 * @description Renders a booking card
 * @param {BookingCardProps} props
 * @returns {React.JSX.Element}
 * @example
 * <BookingCard label="Flights" category="flights" bookingRef="123456">
 */

function BookingCard({ category, className, label, bookingRef, children }) {
	return (
		<div className={cn('flex flex-col gap-0 bg-white min-h-[15vh]', className)}>
			<div className="w-full h-16 bg-core-blue p-4 flex items-center justify-between  text-white">
				<Text className="flex items-center gap-4">
					<Icon name={category === 'flights' ? 'flight-fill' : category} />
					<Text as="span" className="text-white font-bold">
						{label}
					</Text>
				</Text>
				<Text as="span" className="flex items-center gap-4 text-sm">
					Booking reference: {bookingRef}
				</Text>
			</div>
			<div className="p-5 flex flex-col gap-4">{children}</div>
		</div>
	);
}

BookingCard.propTypes = {
	label: PropTypes.string.isRequired,
	className: PropTypes.string,
	bookingRef: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	category: PropTypes.oneOf([
		'flights',
		'hotels',
		'holidays',
		'motorhome-hire',
		'car-hire',
	]).isRequired,
};

export default BookingCard;
