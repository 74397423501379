import { Text } from '@/components/common';
import { HotelSummaryCard } from '@components/hotels';
import { cn, dateRangeFormatter } from '@/lib/utils';

function BookingSummary({ item, className }) {
	return (
		<div>
			<Text className={cn('flex gap-2 items-center justify-between w-full mb-2', className)}>
				<Text as="span" className="font-bold text-2xl leading-tight capitalize">
					Hotel
				</Text>
				{(item?.startDate || item?.endDate) && (
					<Text as="span" className="text-right text-lg tracking-tighter">
						{dateRangeFormatter({
							start: item?.startDate,
							end: item?.endDate,
						})}
					</Text>
				)}
			</Text>
			<HotelSummaryCard hotel={item} hideChange className="border border-lighter-grey" />
		</div>
	);
}
export default BookingSummary;
