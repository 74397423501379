import PropTypes from 'prop-types';

import { Heading, Text } from '@/components/common';
import { SuggestionCard } from '@/components/find-my-holiday';

function SuggestionsList({ extra }) {
	return (
		<div className="flex flex-col gap-4 md:gap-5 px-5">
			<Text
				variant="black"
				className="md:hidden text-dark-grey text-base text-capitalize font-black uppercase leading-tight tracking-tight opacity-30"
			>
				Trending
			</Text>
			<Heading as="h2" className="flex md:gap-2 flex-col">
				<span className="italic font-display font-semibold text-xl md:text-3.5xl leading-extra-tight tracking-wide text-opacity-60 text-dark-grey">
					Need inspiration?
				</span>
				<span className="font-body font-bold text-3xl md:text-4xl text-core-blue">
					Discover our <br className="md:hidden" />
					trending holiday collections
				</span>
			</Heading>

			{extra?.suggestions?.length > 0 && (
				<div className="w-full grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-4 xl:gap-5">
					{extra.suggestions.map((suggestion, idx) => (
						<SuggestionCard key={idx} {...suggestion} />
					))}
				</div>
			)}
		</div>
	);
}

SuggestionsList.propTypes = {
	extra: PropTypes.object,
};

export default SuggestionsList;
