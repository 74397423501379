import { cn } from '@/lib/utils';

/**
 * @typedef {Object} SkeletonProps
 * @property {string} className
 * @property {React.ReactNode} children
 */

/**
 * @name Skeleton Component
 * @description This component is used to create a skeleton loading effect
 * @param {SkeletonProps} props
 * @returns {React.JSX.Element}
 * @example
 * <Skeleton />
 * */

function Skeleton({ className, ...props }) {
	return (
		<div
			className={cn('animate-pulse rounded-none bg-lighter-grey', className)}
			{...props}
		/>
	);
}

export default Skeleton;
