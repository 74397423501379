import { withPortal } from '@/HOCs/withPortal';
import { BookingWidget } from '@/components/common';

const containerId = 'booking-itinerary-booking-widget';

function ItineraryBookingWidget() {
	return (
		<BookingWidget
			hideTrigger
			containerId={containerId}
			options={[{ label: 'Itinerary', value: 'itinerary', icon: 'itinerary' }]}
		/>
	);
}
export default withPortal(ItineraryBookingWidget, containerId);
