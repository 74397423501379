import React, { Fragment, useMemo } from 'react';
import { isDate, differenceInMonths } from 'date-fns';

import { useBreakpoint } from '@/hooks';
import DateRangeTime from './DateRangeTime';
import { Heading, Calendar, Button } from '@components/common';

function DateRangeContent({
	value,
	hideTime,
	disabledDays,
	selectedTime,
	numberOfMonths = 2,
	handleTimeChange,
	handleRangeChange,
	isMaxMonths,
	loadMoreMonths,
	useCurrentTime,
	...calendarProps
}) {
	const isSmallDevice = useBreakpoint('md');
	const monthsToDisplay = useMemo(() => {
		if (!isSmallDevice) return numberOfMonths;
		if (!value?.from || !isDate(value?.from)) return numberOfMonths;

		const today = new Date();
		// calculate the number of months between today and the selected date using date-fns
		const months = differenceInMonths(value.from, today);

		if (months < 2) return numberOfMonths;
		return months + numberOfMonths;
	}, [isSmallDevice, numberOfMonths, value?.from]);

	const defaultMonth = useMemo(() => {
		if (isSmallDevice || !isDate(value?.from)) return new Date();
		return value.from;
	}, [isSmallDevice, value?.from]);

	return (
		<Fragment>
			<Heading as="h3" className="px-3 mb-4 text-sans md:text-left lg:px-0">
				Select your dates and times
			</Heading>
			<div className="flex flex-col-reverse gap-2 overflow-hidden md:overflow-visible md:flex-col grow">
				<div className="overflow-y-auto grow">
					<Calendar
						mode="range"
						initialFocus
						selected={value}
						showOutsideDays={false}
						disabled={disabledDays}
						onSelect={handleRangeChange}
						numberOfMonths={monthsToDisplay}
						month={isSmallDevice ? defaultMonth : undefined}
						defaultMonth={isSmallDevice ? undefined : defaultMonth}
						{...calendarProps}
					/>
					{isSmallDevice &&
						(isMaxMonths ? null : (
							<Button
								variant="outline"
								onClick={loadMoreMonths}
								className="mx-auto mt-4 w-fit"
							>
								Load more months
							</Button>
						))}
				</div>
				{hideTime ? null : (
					<div className="inline-flex h-fit w-full flex-col items-center md:items-start justify-center px-3 md:justify-start gap-2.5 pb-7.5 md:pl-7.5 md:pr-10 lg:px-0">
						<DateRangeTime
							hideTime={hideTime}
							selectedTime={selectedTime}
							handleTimeChange={handleTimeChange}
							useCurrentTime={useCurrentTime}
						/>
					</div>
				)}
			</div>
		</Fragment>
	);
}

export default DateRangeContent;
