import { useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import { cn, currencyFormatter, formatLocalDate } from '@/lib/utils';
import { useDisclosure } from '@/hooks';
import { Icon, Badge, Route, Text, Button, Dialog } from '@components/common';
import { RailUpgrade } from '@components/holidays';

/**
 * @typedef {{
 *  from: string;
 *  to: string;
 *  date: string;
 *  details: string;
 * }} railObject
 */

/**
 * @typedef {Object} RailSummaryProps
 * @property {string} className
 * @property {railObject} rail
 * @property {boolean} justForShow
 * @property {(params: { rail: railObject; isOpen: boolean; onToggle: () => void; detailsOpen: boolean; detailsToggle: () => void; }) => JSX.Element} renderColumns
 *
 */

/**
 * @name RailSummary
 * @description Renders a summary detail of the rail travel
 * @param {RailSummaryProps} props
 * @returns {JSX.Element | null}
 * @example
 * <RailSummary
 *  rail={{
 *   from: 'Calgary Airport',
 *   to: 'Calgary Downtown',
 *   date: '2024-06-15T18:20:00+00:00',
 *   details: 'Lorem ipsum dolor sit amet'
 *  }}
 * />
 */

function RailSummary({ rail, className, renderColumns, justForShow }) {
	const [selectedUpgrade, setSelectedUpgrade] = useState(null);

	const { onToggle, isOpen } = useDisclosure(false);
	const { onToggle: detailsToggle, isOpen: detailsOpen } = useDisclosure(false);

	return (
		<ErrorBoundary FallbackComponent={<h2>Something went wrong</h2>}>
			<div
				className={cn(
					'grid grid-cols-1 p-5 gap-5 lg:grid-cols-5',
					{
						'lg:grid-cols-1': !renderColumns || justForShow,
					},
					className
				)}
			>
				<div className="col-span-3 flex flex-col items-start gap-1">
					<Icon name="air-transat" className="w-20 h-20" />

					{justForShow ? null : (
						<Badge
							text="Recommended"
							className="uppercase mb-4"
							variant="supporting-yellow"
						/>
					)}
					<Route
						iconName="rail-right"
						renderFrom={() => (
							<Text className="flex flex-col">
								<Text as="span" className="font-bold">
									West Lake Louise
								</Text>
								<Text as="time" className="text-sm">
									{formatLocalDate('2024-06-14T18:20:00+00:00', 'dd MMMM')}
								</Text>
							</Text>
						)}
						renderTo={() => (
							<Text className="flex flex-col">
								<Text as="span" className="font-bold">
									Vancouver
								</Text>
								<Text as="time" className="text-sm">
									{formatLocalDate('2024-06-15T18:20:00+00:00', 'dd MMMM')}
								</Text>
							</Text>
						)}
					/>
					<div className="w-full">
						<div className="flex flex-col items-start justify-between lg:flex-row lg:items-center">
							<Text className="flex items-center gap-1">
								<Text>
									Service: <strong>Silver Leaf Double </strong>
								</Text>
								<Button
									hideIcon
									variant="unstyled"
									label="(upgrade)"
									onClick={() => {
										if (detailsOpen) {
											detailsToggle();
										}
										onToggle();
									}}
								/>
							</Text>
							<Button
								hideIcon
								variant="unstyled"
								onClick={() => {
									if (isOpen) {
										onToggle();
									}
									detailsToggle();
								}}
								label="Journey Info"
								className="flex items-center gap-1"
							>
								<Text className="leading-tight text-sm">Journey Info</Text>
								<Icon
									name={detailsOpen ? 'chevron-up' : 'chevron-down'}
									className="w-3 h-3"
								/>
							</Button>
						</div>
					</div>
				</div>
				{renderColumns({
					rail,
					isOpen,
					onToggle,
					detailsOpen,
					detailsToggle,
					selectedUpgrade,
				})}
			</div>
			{detailsOpen ? (
				<div
					className={cn(
						'p-5 w-full md:border-t-3 bg-light-grey md:border-core-blue',
						{ 'bg-white': justForShow }
					)}
				>
					{/* Add rail journey details here */}
					<Text className="font-bold">Rail Journey Details</Text>
					<div className="text-dark-grey/80 mt-2">
						<Text>First passage to the West Lake Louise to Vancouver</Text>
						<Text>
							Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora,
							nemo quos! Illum perferendis quae id eos, dolorum quam aperiam
							possimus vitae? Laboriosam fugit minus voluptas odio voluptates
							nihil ullam fuga quam cum at architecto, aut hic ut expedita modi
							odit accusantium officiis commodi in nulla pariatur eaque? Odio,
							delectus earum! Quisquam, voluptatum.
						</Text>
					</div>
				</div>
			) : null}

			<div className="px-5 pb-5 md:hidden">
				<Dialog
					as="modal"
					hideCloseBtn
					size="screen"
					position="center"
					contentClassName="py-2 px-0"
					renderTrigger={({ DialogTrigger, onOpen }) => (
						<DialogTrigger asChild>
							<Button
								label={`Upgrade from ${currencyFormatter({
									amount: 613,
									params: { hideDecimal: true },
								})}`}
								variant="core-blue"
								hideIcon
								className="w-fit"
								onClick={() => {
									if (detailsOpen) {
										detailsToggle();
									}
									onOpen();
								}}
							/>
						</DialogTrigger>
					)}
				>
					{({ CloseButton, onClose }) => (
						<div className="relative">
							<div className="flex items-center justify-between px-5 mb-5">
								<Text className="font-bold text-3xl">Select Fare</Text>

								<CloseButton
									variant="unstyled"
									className="relative top-0 right-0"
								/>
							</div>
							<RailUpgrade
								isMobile
								selected={{}}
								onSelect={() => console.log('select something')}
							/>
						</div>
					)}
				</Dialog>
			</div>

			{isOpen ? (
				// rail upgrade options - TODO: Make it standalone component use the setSelectedUpgrade to set the selected upgrade
				<div className="p-5 border-t-3 w-full border-core-blue">
					<RailUpgrade />
				</div>
			) : null}
		</ErrorBoundary>
	);
}

RailSummary.propTypes = {
	className: PropTypes.string,
	rail: PropTypes.shape({
		from: PropTypes.string,
		to: PropTypes.string,
		date: PropTypes.string,
		details: PropTypes.string,
	}),
	renderColumns: PropTypes.func,
	justForShow: PropTypes.bool,
};

RailSummary.defaultProps = {
	className: '',
	rail: {
		from: '',
		to: '',
		date: '',
		details: '',
	},
	renderColumns: () => null,
	justForShow: false,
};

export default RailSummary;
