import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Icon } from '@/components/common';
import { cn } from '@/lib/utils';

/**
 * @name FavouriteToggle
 * @description Renders a button for adding/removing a favourite
 * @returns {React.JSX.Element}
 * @example
 * <FavouriteToggle pageId="323" className="w-fill" iconClassName="text-white">
 */
function FavouriteToggle({ pageId, className, iconClassName }) {
	const [favourited, setFavourited] = useState(false);

	const handleToggle = () => {
		// TODO: implement
		console.log(`TODO: ${favourited ? 'Remove' : 'Save'} favourite: ${pageId}`);
		setFavourited(!favourited);
	};

	return (
		<Button variant="unstyled" className={className} onClick={handleToggle}>
			<Icon
				name={favourited ? 'solid-heart' : 'heart'}
				className={cn(iconClassName, favourited && 'fill-white')}
			/>
		</Button>
	);
}

FavouriteToggle.propTypes = {
	pageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	className: PropTypes.string,
	iconClassName: PropTypes.string,
};

export default FavouriteToggle;
