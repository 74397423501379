import React from 'react';
import PropTypes from 'prop-types';
import { format, isSameDay } from 'date-fns';

import { Button, Text } from '@/components/common';
import { cn, currencyFormatter } from '@/lib/utils';

/**
 * @typedef {Object} MonthItemProps
 * @property {Date} currentDay
 * @property {Date} day
 * @property {boolean} disabled
 * @property {(params: Date)=>React.ReactNode} renderPrice
 * @property {Object.<string, number>} prices
 * @property {React.HTMLProps<HTMLButtonElement>} buttonProps
 * */

/**
 * @name MonthItem
 * @description A component that renders a single day in the calendar
 * @param {MonthItemProps} props
 * @returns {React.JSX.Element}
 * @example
 * <MonthItem
 * 	 day={day}
 * 	 currentDay={currentDay}
 * 	 renderPrice={renderPrice}
 * />
 * */

function MonthItem({
	day,
	prices,
	onClick,
	renderPrice,
	currentDay,
	disabled,
	...props
}) {
	const isActive = isSameDay(currentDay, day);

	const key = format(day, 'yyyy-MM-dd');
	const price = prices ? prices[key] : null;

	return (
		<Button
			hideIcon
			variant="unstyled"
			className={cn(
				'relative z-10 bg-white flex flex-col h-auto justify-center items-center gap-2 grow shrink basis-0 py-4 text-dark-grey cursor-pointer rounded-2xl md:rounded-t-2xl md:bg-transparent',
				{
					'!bg-slate-800 rounded-2xl text-white w-full md:rounded-b-none':
						isActive,
				}
			)}
			onClick={() => {
				if (onClick && typeof onClick === 'function') {
					onClick(day);
				}
			}}
			disabled={disabled}
			{...props}
		>
			<Text as="span" className="text-sm uppercase font-bold">
				{format(day, 'EE')}
			</Text>
			<Text as="span" className="text-3xl md:text-5xl font-bold my-1">
				{format(day, 'dd')}
			</Text>
			<Text
				as="span"
				className="text-opacity-50 text-sm font-black uppercase leading-none tracking-tight"
			>
				{format(day, 'MMM')}
			</Text>
			<Text
				as="span"
				className={cn(
					'text-lg font-bold leading-snug p-0 m-0',
					isActive && 'text-supporting-yellow'
				)}
			>
				<Text as="span" className="text-xl font-bold">
					{typeof renderPrice === 'function'
						? renderPrice(day, disabled)
						: price
							? currencyFormatter({ amount: price })
							: null}
				</Text>
			</Text>
		</Button>
	);
}

MonthItem.propTypes = {
	renderPrice: PropTypes.func,
	currentDay: PropTypes.instanceOf(Date).isRequired,
	day: PropTypes.instanceOf(Date).isRequired,
	prices: PropTypes.objectOf(PropTypes.number),
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
};

MonthItem.defaultProps = {
	renderPrice: null,
	prices: null,
	onClick: null,
};

export default MonthItem;
