import { useState } from 'react';
import PropTypes from 'prop-types';
import {
	Badge,
	Button,
	DirectionalArrow,
	Heading,
	Icon,
	Text,
	Accordion,
	AccordionItem,
	AccordionTrigger,
	AccordionContent,
	SearchFormButton,
} from '@/components/common';
import WhoFormContent from '@/components/common/organisms/who-form/WhoContent';
import { useForm, FormProvider } from 'react-hook-form';
import { cn, navigate, currencyFormatter, WHO_OPTIONS, formatLocalDate } from '@/lib/utils';
import qs from 'qs';

function ResultCard({ result }) {
	const [openSearchBook, setOpenSearchBook] = useState('');

	const defaultValues = {
		who: WHO_OPTIONS.reduce((acc, option) => {
			const value = option.value === 'adults' ? 1 : 0;
			acc[option.value] = { value, label: value.toString() };
			return acc;
		}, {}),
	};

	const methods = useForm({
		mode: 'onChange',
		defaultValues,
	});

	const {
		handleSubmit,
		watch,
		control,
		formState: { errors },
	} = methods;

	const onSubmit = async (data) => {
		const whoData = data?.[`${result.id}-whocontent`];

		const formattedWho = Object.keys(whoData).reduce((acc, type) => {
			acc[type] = whoData[type]?.value
				? `${whoData[type].value}:${whoData[type].label}`
				: '';
			return acc;
		}, {});

		const searchQs = qs.stringify(
			{
				category: 'flights',
				who: formattedWho,
				startDate: result?.departureTime
					? formatLocalDate(result?.departureTime, 'yyyy-MM-dd')
					: null,
				endDate: result?.arrivalTime
					? formatLocalDate(result?.arrivalTime, 'yyyy-MM-dd')
					: null,
				outboundDepart: result?.departure,
				outboundArrive: result?.arrival,
				inboundDepart: result?.arrival,
				inboundArrive: result?.departure,
			},
			{
				addQueryPrefix: true,
				encode: true,
			}
		);

		navigate(`/search/flights${searchQs}`);
	};

	return (
		<div className="relative flex flex-col justify-between w-full h-auto gap-2 bg-white rounded-lg shadow">
			{/* Card content */}
			<div
				className={cn(
					'flex flex-col gap-4 p-5 md:p-7.5 pb-2 md:pb-2',
					openSearchBook === 'search-book' && 'pb-3 md:pb-5.5'
				)}
			>
				{(result?.arrivalName || result?.stops || result?.duration) && (
					<div className="flex flex-col items-start w-full gap-2 sm:flex-row sm:justify-between sm:gap-3 md:flex-col md:items-start md:gap-2 xl:flex-row xl:items-center xl:justify-between xl:gap-3">
						{result?.arrivalName && (
							<div className="flex flex-row items-center gap-0.5">
								<Icon name="location" />
								<Heading
									as="h3"
									className="self-stretch text-core-blue text-2.5xl leading-extra-tight tracking-tight font-body font-bold uppercase mt-1"
								>
									{result.arrivalName}
								</Heading>
							</div>
						)}
						{(result?.stops || result?.duration) && (
							<div className="flex flex-row flex-wrap items-end justify-end gap-1">
								{result?.stops && (
									<Badge
										text={result.stops}
										variant="red"
										icon="plane"
										textClassName="font-body text-xs font-bold uppercase leading-snug"
										iconClassName="text-white w-4 h-4"
									/>
								)}
								{result?.duration && (
									<Badge
										text={`${result.duration} hrs`}
										variant="core-blue"
										icon="clock"
										textClassName="font-body text-xs font-bold uppercase leading-snug"
										iconClassName="text-white w-4 h-4"
									/>
								)}
							</div>
						)}
					</div>
				)}

				{(result?.departureName || result?.arrivalName) && (
					<div className="flex flex-col items-start gap-4 sm:flex-row sm:gap-5.25 grow lg:flex-col lg:gap-4 xl:flex-row xl:gap-5.25">
						{result?.departureName && (
							<div className="flex flex-col flex-1 gap-1">
								<Heading
									as="h4"
									className="text-xs font-bold uppercase text-dark-grey/30 font-body leading-less-snug tracking-less-tight"
								>
									Departure
								</Heading>
								<Text className="font-medium text-black leading-loose-snug tracking-less-tight whitespace-nowrap">
									{result.departureName}
								</Text>
								{result?.departureTime ? (
									<>
										<Text className="text-2.5xl font-bold leading-extra-tight tracking-tight text-dark-grey whitespace-nowrap">
											{formatLocalDate(result?.departureTime, 'dd MMM')}
										</Text>
										<Text className="font-bold tracking-tight leading-extra-tight text-core-light-blue">
											{formatLocalDate(result?.departureTime, 'yyyy')}
										</Text>
									</>
								) : null}
							</div>
						)}

						<DirectionalArrow className="hidden sm:flex lg:hidden xl:flex mt-5.75" />

						{result?.arrivalName && (
							<div className="flex flex-col flex-1 gap-1">
								<Heading
									as="h4"
									className="text-xs font-bold uppercase text-dark-grey/30 font-body leading-less-snug tracking-less-tight"
								>
									Return
								</Heading>
								<Text className="font-medium text-black leading-loose-snug tracking-less-tight whitespace-nowrap">
									{result.arrivalName}
								</Text>
								{result?.arrivalTime ? (
									<>
										<Text className="text-2.5xl font-bold leading-extra-tight tracking-tight text-dark-grey whitespace-nowrap">
											{formatLocalDate(result?.arrivalTime, 'dd MMM')}
										</Text>
										<Text className="font-bold tracking-tight leading-extra-tight text-core-light-blue">
											{formatLocalDate(result?.arrivalTime, 'yyyy')}
										</Text>
									</>
								) : null}
							</div>
						)}
					</div>
				)}

				{result?.price && (
					<div className="flex flex-col gap-1">
						<Text className="text-xs font-bold uppercase text-dark-grey/75 leading-loose-snug tracking-less-tight">
							From
						</Text>

						<Text className="text-2.5xl md:text-3.5xl lg:text-4xl font-bold leading-extra-tight tracking-tight text-dark-grey">
							{currencyFormatter({
								amount: result.price,
								params: {
									hideDecimal: result.price === Math.floor(result.price),
								},
							})}
							<sub className="bottom-0 text-base sm:text-2.5xl md:text-3.5xl lg:text-4xl font-bold tracking-tight text-dark-grey font-body !leading-extra-tight">
								{' '}
								pp
							</sub>
						</Text>
					</div>
				)}
			</div>

			{/* Card footer */}
			<Accordion
				type="single"
				collapsible
				className="flex flex-col w-full gap-4"
				value={openSearchBook}
				onValueChange={(value) => {
					setOpenSearchBook(value);
				}}
			>
				<AccordionItem className="w-full pb-0 border-none" value="search-book">
					{openSearchBook !== 'search-book' && (
						<AccordionTrigger
							hideChevron
							asChild
							className="relative px-5 md:px-7.5 pb-5 md:pb-7.5 flex justify-between items-center transition-all duration-200 ease-in-out cursor-pointer"
						>
							<Button
								label="Search & Book"
								variant="black"
								className="w-fit gap-2.5 py-4 px-8"
								labelClassName="text-lg leading-snug tracking-tighter"
								iconName="chevron-down"
								iconClassName="opacity-100 group-hover:text-black w-3.5"
								disableAnimation
							/>

							{result?.carrierLogo && (
								<div className="absolute right-5 bottom-22.5 sm:bottom-5 md:right-7.5 md:bottom-25 lg:bottom-26 xl:bottom-7.5 h-15">
									<img
										alt={`${result.carrierName} logo`}
										src={result.carrierLogo}
										className="block object-contain h-full"
									/>
								</div>
							)}
						</AccordionTrigger>
					)}

					<AccordionContent
						value="search-book"
						className="overflow-visible"
						childClassName="p-0"
					>
						<FormProvider {...methods}>
							<form
								onSubmit={handleSubmit(onSubmit)}
								className="@container flex flex-col gap-2 rounded-b-lg bg-light-grey"
							>
								<WhoFormContent
									name={`${result.id}-whocontent`}
									options={WHO_OPTIONS}
									control={control}
									showFormFieldFooter={false}
									className="md:px-7.5 pb-0 gap-2"
									formFieldsContainerClassName="grid grid-cols-1 @sm:grid-cols-1 @lg:grid-cols-2 gap-2.5 w-full lg:gap-5 "
									wrapperClassName="flex grid-cols-none"
									combinedLabel={
										<Text className="font-medium text-light-black leading-loose-snug tracking-less-tight">
											Please select the number of passengers.
										</Text>
									}
								/>
								<div className="flex flex-row items-center justify-between px-5 md:px-7.5 pb-5">
									<SearchFormButton
										onCancel={() => {
											setOpenSearchBook('');
										}}
										label="Book now"
										hideCancel={false}
										cancelTextOnly={true}
										className="flex-row justify-between mt-0 lg:mt-0 md:w-full"
										submitButtonClassName="w-auto md:w-auto"
										handleSubmit={handleSubmit(onSubmit)}
										hasYouth={watch(`${result.id}-whocontent.youth`)?.value > 0}
										hasErrors={Object.keys(errors).length > 0}
									/>
								</div>
							</form>
						</FormProvider>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</div>
	);
}

ResultCard.propTypes = {
	result: PropTypes.object.isRequired,
};

export default ResultCard;
