import {
	isDate,
	format,
	addDays,
	isValid,
	isBefore,
	differenceInDays,
	startOfDay,
} from 'date-fns';
import qs from 'qs';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { ErrorBoundary } from 'react-error-boundary';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import {
	Icon,
	Tabs,
	Text,
	Alert,
	Button,
	Heading,
	Dialog,
	Skeleton,
	TabsList,
	TabsContent,
	TabsTrigger,
	buttonVariants,
	DatePlaceHolder,
} from '@components/common';
import {
	HolidayBookingForm,
	ItineraryBookingForm,
} from '@/components/holidays';
import { useBookingStore } from '@/store';
import { useFetch, useUrlParams, useBreakpoint } from '@/hooks';
import {
	FLIGHT_HOTEL_DURATIONS,
	HOTEL_ONLY_MAX_DATE,
	cn,
	navigate,
	currencyFormatter,
} from '@/lib/utils';
import { HotelBookingForm } from '@/components/hotels';
import { MotorhomeBookingForm } from '@/components/motorhomes';
import { hotelBookingSchema } from '@/validationSchemas';
import { EnquiryWidget } from '@/components/common';

/**
 * @typedef {Object} option
 * @property {string} label
 * @property {"hotels" | "hotels&flights" | "motorhome" | "itinerary"} value
 * @property {import("@components/common/atoms/icon").iconNames} icon
 */

/**
 * @typedef {Object} BookingWidgetProps
 * @property {option[]} options
 * @property {boolean} hideTrigger
 * @property {string} containerId
 **/

/**
 * @name BookingWidget
 * @description A booking widget that allows users to book hotels, motorhomes, flights, and itineraries.
 * @param {BookingWidgetProps} props
 * @returns {React.JSX.Element}
 * @example
 * <BookingWidget options={[]} containerId="booking-hotels-booking-widget" />
 * @todo TODO: Add rest validation schemas
 **/

function BookingWidget({ options, hideTrigger, containerId }) {
	// const navigate = useNavigate();
	const bookingModalRef = useRef(null);
	const isLg = useBreakpoint('lg');
	const [customError, setCustomError] = useState(null);
	const [enquiryDataset, setEnquiryDataset] = useState({});

	const { params, updateParams } = useUrlParams();
	const { setBookingState, resetState } = useBookingStore();
	const [activeTab, setActiveTab] = useState(options[0]?.value);

	const bookingState = useBookingStore((store) =>
		store ? store[activeTab] : null
	);

	// set the active tab
	useEffect(() => {
		if (!params?.default?.tab) return;

		// check the param matches a valid tab option
		const tabOption = options.find(
			(option) => option.value === params.default.tab
		);
		if (!tabOption) return;

		setActiveTab(tabOption.value);
	}, [params?.default?.tab]);

	// get the code from the portal container
	const code = useMemo(() => {
		const container = document.getElementById(containerId);
		const defaultCode = 'BAN009'; // DEBUGGABLE CODE// BAN009 - External // TOR073 - Internal
		if (container) {
			setEnquiryDataset(container.dataset);
			switch (activeTab) {
				case 'hotels':
				case 'hotels&flights':
					return container.getAttribute('data-hotel');
				case 'motorhomes':
					return container.getAttribute('data-motorhome');
				case 'itinerary':
					return container.getAttribute('data-itinerary');
				default:
					return defaultCode;
			}
		}
		return defaultCode;
	}, [containerId, activeTab]);

	// get url params
	const urlParams = useMemo(() => {
		if (params?.default) {
			return {
				code: code,
				...params.default,
			};
		}
		return {
			code: code,
		};
	}, [code, params?.default]);

	// set defaultValues for the form
	const defaultValues = useMemo(() => {
		const from = urlParams?.from ? urlParams.from.split(':') : [];
		const adults = urlParams?.adults ? urlParams.adults.split(':') : [];
		const children = urlParams?.children ? urlParams.children.split(':') : [];
		const infants = urlParams?.infants ? urlParams.infants.split(':') : [];
		const startDate = urlParams?.startDate
			? new Date(urlParams.startDate)
			: null;
		const endDate = urlParams?.endDate ? new Date(urlParams.endDate) : null;

		const isValidEndDate =
			isDate(startDate) &&
			isDate(endDate) &&
			!isBefore(startOfDay(endDate), startOfDay(startDate));

		switch (activeTab) {
			case 'hotels&flights':
				let duration =
					isDate(startDate) && isDate(endDate)
						? differenceInDays(endDate, startDate)
						: -1;

				// only allow 7/14 night durations
				const isValidFlightEnd = isValidEndDate && [7, 14].includes(duration);

				let flightEndDate = endDate;
				if (!isValidFlightEnd) {
					flightEndDate = undefined;

					// set default end date (7 days from start date)
					if (isDate(startDate)) {
						flightEndDate = addDays(startDate, 7);
						duration = 7;
					}
				}

				const durationOption = FLIGHT_HOTEL_DURATIONS.find(
					(option) => option.value === duration
				);

				return {
					from:
						from.length > 0
							? {
									value: from[0] ? from[0] : '',
									label: from[1] ? from[1] : '',
							  }
							: null,

					when: {
						from: isDate(startDate) ? startDate : undefined,
						to: flightEndDate,
					},
					duration: durationOption ? durationOption : undefined,
					rooms: formatRooms(adults, children, infants),
				};
			case 'hotels':
				// only pre-fill if at least 3 days from today & before max date
				const isValidHotelFromDate =
					isDate(startDate) &&
					differenceInDays(startOfDay(startDate), startOfDay(new Date())) >=
						3 &&
					differenceInDays(
						startOfDay(startDate),
						startOfDay(new Date(HOTEL_ONLY_MAX_DATE))
					) <= 0;

				// only pre-fill if after the from date & before max date
				const isValidHotelToDate =
					isValidHotelFromDate &&
					isValidEndDate &&
					differenceInDays(
						startOfDay(endDate),
						startOfDay(new Date(HOTEL_ONLY_MAX_DATE))
					) <= 0;

				const roomdefaultParams = urlParams?.rooms?.length
					? urlParams.rooms
					: [{ adults: 2, children: 0, infants: 0 }];

				const rooms = roomdefaultParams.map((room) => {
					const acc = { room: null };

					Object.keys(room).map((type) => {
						const value = room[type];
						acc[type] = {
							value,
							label: value.toString(),
						};
					}, {});

					return acc;
				});

				return {
					when: {
						from: isValidHotelFromDate ? startDate : undefined,
						to: isValidHotelToDate ? endDate : undefined,
					},
					rooms: rooms, //formatRooms(adults, children, infants),
				};
			case 'motorhome':
				return {
					when: {
						from: isDate(startDate) ? startDate : undefined,
						to: isValidEndDate ? endDate : undefined,
					},
					adults:
						{
							value: adults[0],
							label: adults[1],
						} ?? null,
					children:
						{
							value: children[0],
							label: children[1],
						} ?? null,
					infants:
						{
							value: infants[0],
							label: infants[1],
						} ?? null,
				};
			case 'itinerary':
				return {
					departure:
						{
							value: from[0],
							label: from[1],
						} ?? null,

					when: {
						from: isDate(startDate) ? startDate : undefined,
						to: isValidEndDate ? endDate : undefined,
					},
					adults:
						{
							value: adults[0],
							label: adults[1],
						} ?? null,
					children:
						{
							value: children[0],
							label: children[1],
						} ?? null,
					infants:
						{
							value: infants[0],
							label: infants[1],
						} ?? null,
				};
			default:
				return {
					from: null,
					when: {
						from: undefined,
						to: undefined,
					},
					adults: null,
					children: null,
					infants: null,
					rooms: formatRooms(adults, children, infants),
				};
		}
	}, [urlParams, activeTab, code]);

	const validationSchema = {
		hotels: hotelBookingSchema,
	}[activeTab];

	const {
		watch,
		reset,
		resetField,
		control,
		setValue,
		setError,
		handleSubmit,
		clearErrors,
		formState: { errors },
	} = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		reset(defaultValues, { keepDirtyValues: true, keepDirty: true });
	}, [defaultValues, reset]);

	useEffect(() => {
		const subscription = watch((state, { name }) => {
			// update just the dates
			if (name === 'when') {
				const when = state[name];
				const startDate = isValid(when?.from)
					? format(when.from, 'yyyy-MM-dd')
					: null;

				const endDate = isValid(when.to) ? format(when.to, 'yyyy-MM-dd') : null;

				if (!startDate || !endDate) return;

				updateParams({
					startDate,
					endDate,
				});
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, updateParams]);

	const Form = useMemo(() => {
		const forms = {
			'hotels&flights': HolidayBookingForm,
			hotels: HotelBookingForm,
			motorhome: MotorhomeBookingForm,
			itinerary: ItineraryBookingForm,
		};
		if (!forms[activeTab]) {
			return () => (
				<div className="w-full p-5 text-center capitalize rounded-lg bg-red-50">
					{activeTab} form not found
				</div>
			);
		}
		return forms[activeTab];
	}, [activeTab]);

	const { when, rooms } = watch();

	const selectedNights =
		isDate(when?.from) && isDate(when?.to)
			? differenceInDays(when.to, when.from)
			: null;

	const type = useMemo(() => {
		const types = {
			'hotels&flights': 'accommodation',
			hotels: 'accommodation',
			motorhome: 'motorhome-hire',
			itinerary: 'itinerary',
		};

		return types[activeTab];
	}, [activeTab]);

	const previewParams = () => {
		const validStartDate = isValid(when?.from)
			? format(when?.from, 'yyyy-MM-dd')
			: null;
		const validEndDate = isValid(when?.to)
			? format(when?.to, 'yyyy-MM-dd')
			: null;

		if (!(rooms && rooms[0]?.room)) return null;
		if (!validStartDate || !validEndDate) {
			return null;
		}

		let hotelState = 'INTERNAL';
		const roomParams = rooms.reduce((totalRooms, room) => {
			if (!room?.room?.value) return totalRooms;
			if (!room?.adults?.value) return totalRooms;

			// default to 2 adults on first room
			const defaultAdults = totalRooms.length === 0 ? 2 : 0;

			hotelState = room?.room?.type === 'external' ? 'EXTERNAL' : 'INTERNAL';
			totalRooms.push({
				roomCode: room.room.value,
				adults: room?.adults?.value
					? parseInt(room.adults.value)
					: defaultAdults,
				children: room?.children?.value ? parseInt(room.children.value) : 0,
				infants: room?.infants?.value ? parseInt(room.infants.value) : 0,
			});
			return totalRooms;
		}, []);

		const params = {
			code,
			type,
			state: hotelState, // TODO: where does this come from?
			endDate: validEndDate,
			startDate: validStartDate,
			rooms: roomParams,
		};

		return params;
	};

	// fetch the price preview
	const { data, isFetching, error } = useFetch({
		key: 'booking-preview',
		method: 'POST',
		useBody: true,
		params: {
			items: [previewParams()],
		},
		config: {
			enabled: !!previewParams(),
		},
		format: (res) => {
			return res?.overview;
		},
	});

	const onError = useCallback(() => {
		if (!error) return;
		if (error?.message.includes('booking-preview')) {
			setCustomError({
				...error,
				message: 'Please check your selections and try again.',
			});
		} else {
			setCustomError(error);
		}

		setTimeout(() => {
			setCustomError(null);
		}, 4000);
	}, [error]);

	useEffect(() => {
		onError();
	}, [onError]);

	const nextPage = useMemo(() => {
		const pages = {
			'hotels&flights': '/booking/holidays/travel-plan',
			hotels: '/booking/hotels/passenger-details',
			motorhome: '/booking/motorhome-hire/extras',
			itinerary: '/booking/holidays/travel-plan',
		};

		if (!pages[activeTab]) return '/';
		return pages[activeTab];
	}, [activeTab]);

	const stateNames = {
		'hotels&flights': 'holidays',
		hotels: 'hotels',
		motorhome: 'motorhome-hire',
		itinerary: 'holidays', //TODO: to be confirmed
	};

	const onSubmit = (values) => {
		// calculate the total number of people
		const totalPeople = values.rooms.reduce((acc, room) => {
			const { adults, children, infants } = room;
			if (adults?.value) acc += adults.value;
			if (children?.value) acc += children.value;
			if (infants?.value) acc += infants.value;
			return acc;
		}, 0);

		// reset the booking state
		resetState(stateNames[activeTab]);

		const prevParams = previewParams();

		// set the booking state for the selected tab
		let newState = {
			shouldBuildParams: true,
			selected: {
				...prevParams,
				pageUrl: window.location.pathname + window.location.search,
			},
			passengerDetails: bookingState?.passengerDetails
				? { ...bookingState.passengerDetails }
				: undefined,
			preview: undefined,
			questions: undefined,
			paymentDetails: undefined,
			error: null,
		};

		if (bookingState?.bookingDetails) {
			// remove pax and answered questions
			newState = {
				...newState,
				bookingDetails: {
					...(bookingState.bookingDetails || {}),
					questions: undefined,
					pax: undefined,
				},
			};
		}

		setBookingState(stateNames[activeTab], newState, 'SET_SELECTED_HOTEL');

		const params = {
			itemCode: code,
			people: totalPeople,
			endDate: isValid(values?.when.to)
				? format(values.when.to, 'yyyy-MM-dd')
				: null,
			startDate: isValid(values?.when.from)
				? format(values.when.from, 'yyyy-MM-dd')
				: null,
		};

		const queryString = qs.stringify(params, {
			addQueryPrefix: true,
			encode: true,
		});

		// navigate to booking page for the selected tab
		navigate(nextPage + queryString);
	};

	const handleTabChange = (value) => {
		if (value === activeTab) return;

		switch (value) {
			case 'hotels&flights':
				const newWhen = {
					from: undefined,
					to: undefined,
				};

				// only allow 7/14 night durations
				const difference =
					isDate(when?.from) && isDate(when?.to)
						? differenceInDays(when.to, when.from)
						: -1;
				if ([7, 14].includes(difference)) {
					newWhen.from = when.from;
					newWhen.to = when.to;
				}

				resetField('when', { defaultValue: newWhen });
				break;
			case 'hotels':
				// set duration back to default values if from/to was cleared
				if (!(when?.from && when?.to)) {
					resetField('when', { defaultValue: defaultValues.when });
				}
				break;
		}

		setActiveTab(value);
	};

	const cleanDocTitle = document.title.replace(/\|/g, '-');

	const iconLinks = [
		{
			name: 'Facebook',
			icon: 'facebook',
			href: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&t=${cleanDocTitle}`,
		},
		{
			name: 'Twitter',
			icon: 'twitter-x',
			href: `http://twitter.com/share?text=${cleanDocTitle}&url=${window.location.href}`,
		},
	];

	const bottomShareComponent = () => (
		<div className="flex items-center justify-center py-4 mt-2 text-core-blue/80">
			<div className="flex items-center gap-4">
				<Text
					as="span"
					className="font-bold tracking-tight uppercase leading-extra-tight pt-0.5"
				>
					Share
				</Text>
				<div className="flex items-center gap-2">
					{iconLinks.map((item) => (
						<a
							key={item.name}
							href={item.href}
							rel="noopener noreferrer"
							target="_blank"
						>
							<Icon
								name={item.icon}
								className={cn('w-4.5 h-4.5 hover:text-core-blue', {
									'w-3.5 h-3.5': item.icon === 'twitter-x',
								})}
							/>
						</a>
					))}
				</div>
			</div>
			{/* <Button
				label="Save"
				iconName="heart"
				disableAnimation
				variant="unstyled"
				className="w-auto h-4.5 gap-4 hover:text-core-blue rounded-none"
				iconClassName="w-5 h-4.5"
				labelClassName="font-bold leading-extra-tight tracking-tight pt-0.5 uppercase"
			/> */}
		</div>
	);

	if (Object.hasOwn(enquiryDataset, 'atcom') && !enquiryDataset?.atcom) {
		if (isLg) {
			return <EnquiryWidget containerId={containerId} />;
		}
		return (
			<section className="w-full min-h-20 md:w-[450px]">
				<div className="flex items-center justify-between bg-white drop-shadow-md">
					<EnquiryWidget containerId={containerId} />
				</div>
				{bottomShareComponent()}
			</section>
		);
	}

	const bookingComponent = () => (
		<section className={cn('w-full min-h-20 sm:w-[450px]', isLg && 'mx-auto')}>
			<div
				className={cn(
					'flex items-center justify-between bg-white drop-shadow-md',
					{ 'pb-6': activeTab }
				)}
			>
				<form onSubmit={handleSubmit(onSubmit)} className="w-full">
					<Tabs className="w-full" value={activeTab}>
						<TabsList className="w-full p-0 rounded-none h-14">
							{options.map((option) => (
								<TabsTrigger
									key={option.value}
									value={option.value}
									className={cn(
										'flex gap-2 w-full h-full shadow-none rounded-none text-white bg-core-blue hover:opacity-80',
										{
											'text-white shadow-none bg-core-blue data-[state=active]:text-core-blue data-[state=active]:bg-white data-[state=active]:shadow-none':
												options.length > 1, // swap active states when more than one tab
										},
										{ hidden: hideTrigger }
									)}
									onClick={() => handleTabChange(option.value)}
								>
									<Icon name={option.icon} />
									<Text as="span">{option.label}</Text>
								</TabsTrigger>
							))}
						</TabsList>
						{activeTab && (
							<>
								<div
									className={cn(
										'p-8 flex flex-col gap-5',
										hideTrigger && 'pt-0'
									)}
								>
									<div>
										<Heading as="h3" className="text-5xl font-body">
											Book now.
										</Heading>
										<Text as="p" className="text-core-blue/70">
											Prices per person. Based on two people sharing.
										</Text>
									</div>

									<TabsContent value={activeTab}>
										<AnimatePresence mode="wait" initial={false}>
											{customError ? (
												<motion.div
													initial={{ opacity: 0, height: 0 }}
													animate={{ opacity: 1, height: 'auto' }}
													exit={{ opacity: 0, height: 0 }}
												>
													<Alert variant="destructive" className="mb-4">
														{customError?.message ?? ''}
													</Alert>
												</motion.div>
											) : null}
										</AnimatePresence>
										<ErrorBoundary
											fallback={<span> Something went wrong ...</span>}
										>
											<Form
												watch={watch}
												errors={errors}
												control={control}
												params={urlParams}
												setError={setError}
												setValue={setValue}
												clearErrors={clearErrors}
											/>
										</ErrorBoundary>
									</TabsContent>
								</div>

								<div className="flex flex-col gap-3 px-8">
									{when?.to && (
										<div className="flex flex-col gap-3">
											<DatePlaceHolder
												className="w-full gap-5"
												selected={{
													from: new Date(when?.from),
													to: new Date(when?.to),
												}}
												hideTime
											/>
											{when?.from && (
												<Text className="flex items-center gap-2">
													<Icon name="moon" />
													<Text as="span" className="font-bold">
														{selectedNights || 1}{' '}
														{pluralize('night', selectedNights || 1)}
													</Text>
												</Text>
											)}
										</div>
									)}
									<div className="flex items-center justify-between">
										<Text as="span" className="text-3xl font-bold">
											Total
										</Text>
										{isFetching && !data?.total ? (
											<Skeleton className="w-20 h-8" />
										) : (
											<Text as="span" className="text-3xl font-bold">
												{currencyFormatter({
													amount: data?.total ?? null,
												})}
											</Text>
										)}
									</div>
									<Button
										type="submit"
										label={
											activeTab === 'motorhome' ? 'Book online' : 'Book now'
										}
										disabled={
											isFetching ||
											!!error ||
											Object.keys(errors || {}).length > 0
										}
										className="justify-between w-full py-4"
										variant={
											activeTab === 'itinerary'
												? 'core-blue'
												: 'supporting-yellow'
										}
									/>
									{activeTab === 'itinerary' ? (
										<div className="flex flex-col gap-3">
											<a
												href="tel:0207 616 9184"
												className={cn(
													buttonVariants({
														variant: 'core-blue',
														className:
															'w-full py-4 flex items-center justify-between',
													})
												)}
												variant="core-blue"
											>
												<Text as="span" className="flex items-center gap-2">
													<Icon name="phone" className="w-4 h-4" />
													<Text as="span" className="font-bold">
														Call to book
													</Text>
												</Text>
												<Text as="span" className="font-bold">
													0207 616 9184
												</Text>
											</a>
											<Button
												label="Get a quote"
												onClick={handleSubmit(() => {
													// navigate to quote page
												})}
												className="justify-between w-full py-4"
												variant="supporting-yellow"
											/>
										</div>
									) : null}
								</div>
							</>
						)}
					</Tabs>
				</form>
			</div>
			{bottomShareComponent()}
		</section>
	);

	const bookingModalComponent = () => (
		<Dialog
			ref={bookingModalRef}
			as="modal"
			hideCloseBtn
			position="center"
			contentClassName="p-0 h-full overflow-y-auto"
			size="screen"
			renderTrigger={({ DialogTrigger, onOpen }) => (
				<DialogTrigger asChild>
					<Button
						label="Search & Book"
						onClick={onOpen}
						variant="core-blue"
						className="justify-between lg:w-full !text-supporting-yellow py-3.25 shrink-0"
						labelClassName="text-lg font-body font-bold tracking-tighter leading-less-snug"
					/>
				</DialogTrigger>
			)}
		>
			{({ CloseButton, onClose }) => (
				<div className="relative">
					<span className="flex items-center justify-end w-full h-12">
						<CloseButton
							onClick={onClose}
							className="relative top-0 right-0 w-12.5 h-full text-white bg-dark-grey"
							variant="square"
						/>
					</span>
					{bookingComponent()}
				</div>
			)}
		</Dialog>
	);

	if (isLg) {
		return (
			<div className="fixed bottom-0 left-0 right-0 flex-row items-center justify-between flex lg:hidden bg-supporting-yellow/90 py-4 px-6.25 z-10">
				<div className="flex flex-col">
					<p className="flex flex-row items-center gap-1 sm:gap-2 grow">
						<Text
							as="span"
							className="block text-xs font-bold sm:text-sm md:text-base !leading-extra-tight tracking-extra-tight font-body text-black/50"
						>
							{data?.total ? 'Total' : params?.default?.priceFrom ? 'From' : ''}
						</Text>
						<Text
							as="span"
							className="block text-base font-bold text-black sm:text-xl md:text-2xl font-body !leading-extra-tight tracking-extra-tight"
						>
							{currencyFormatter({
								amount: data?.total
									? data?.total
									: params?.default?.priceFrom ?? null,
							})}
						</Text>
					</p>
				</div>
				{bookingModalComponent()}
			</div>
		);
	}

	return (
		<ErrorBoundary fallback={<span> Something went wrong ...</span>}>
			{bookingComponent()}
		</ErrorBoundary>
	);
}

BookingWidget.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.oneOf([
				'Hotels & Flights',
				'Hotels Only',
				'Motorhome',
				'Itinerary',
			]),
			value: PropTypes.oneOf([
				'hotels&flights',
				'hotels',
				'motorhome',
				'itinerary',
			]),
			icon: PropTypes.oneOf(['flights', 'hotel', 'motorhome', 'itinerary']),
		})
	),
	hideTrigger: PropTypes.bool,
};

BookingWidget.defaultProps = {
	options: [
		{
			label: 'Hotels & Flights',
			value: 'hotels&flights',
			icon: 'flights',
		},
		{
			label: 'Hotels Only',
			value: 'hotels',
			icon: 'hotel',
		},
	],
	hideTrigger: false,
};

const formatRooms = (adults, children, infants) => {
	return [
		{
			room: null,
			adults:
				adults.length > 0
					? {
							label: adults[0],
							value: parseInt(adults[1]),
					  }
					: null,
			children:
				children.length > 0
					? {
							label: children[0],
							value: parseInt(children[1]),
					  }
					: null,
			infants:
				infants.length > 0
					? {
							label: infants[0],
							value: parseInt(infants[1]),
					  }
					: null,
		},
	];
};

export default BookingWidget;
