import PropTypes from 'prop-types';

import { RteStyledContent } from '@/components/common';

function MobileView({ cabins = [], features = [] }) {
	return (
		<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-5">
			{cabins.map((cabin) => (
				<div key={cabin.code} className="w-full h-full border">
					<div className="p-4 text-lg font-bold bg-core-blue text-white">
						{cabin?.displayName || cabin?.name}
					</div>
					<div className="h-full">
						{features.map((feature) => (
							<div key={feature.apiField} className="p-4 even:bg-light-grey/70">
								<div className="text-base font-bold">{feature.label}</div>
								<RteStyledContent
									variant="dark"
									className="flight-info"
									dangerouslySetInnerHTML={{
										__html: cabin[feature.apiField],
									}}
								/>
							</div>
						))}
					</div>
				</div>
			))}
		</div>
	);
}

MobileView.propTypes = {
	cabins: PropTypes.arrayOf(
		PropTypes.shape({
			code: PropTypes.string,
			name: PropTypes.string,
		})
	).isRequired,
	features: PropTypes.arrayOf(
		PropTypes.shape({
			apiField: PropTypes.string,
			label: PropTypes.string,
		})
	).isRequired,
};

export default MobileView;
