import PropTypes from 'prop-types';
import { Button, Heading, Text } from '@components/common';
import { cn } from '@/lib/utils';

function NotFound({ title, message, className, titleClassName, resetFilters }) {
	return (
		<section
			className={cn(
				'flex flex-col items-center justify-center gap-2 w-full',
				className
			)}
		>
			<Heading
				as="h3"
				className={cn(
					'text-center font-body text-3xl font-bold text-core-blue uppercase',
					titleClassName
				)}
			>
				{title}
			</Heading>
			{message && <Text>{message}</Text>}
			{resetFilters && (
				<Button
					hideIcon
					variant="unstyled"
					label="Reset filters"
					onClick={resetFilters}
					className="underline underline-offset-4"
				/>
			)}
		</section>
	);
}

NotFound.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string,
	titleClassName: PropTypes.string,
	resetFilters: PropTypes.func,
};

NotFound.defaultProps = {
	title: 'No results found',
};

export default NotFound;
