import React from 'react';

import { cn } from '@/lib/utils';
import { Button, Select } from '@/components/common';
import { useBreakpoint, useDisclosure } from '@/hooks';
import { useTimeValues } from '../dateRangerPicker/DateRangeTime';

/**
 * @typedef {Object} TimePickerProps
 * @property {string} selected - selected time
 * @property {string} [className] - className
 * @property {()=> void} onChange - onChange
 * @property {string} [label] - label
 */

/**
 * @name TimePicker
 * @description A time picker component
 * @param {TimePickerProps} props
 * @returns {React.JSX.Element} React Component
 */

function TimePicker({
	onChange,
	selected,
	className,
	label,
	onOpen,
	onClose,
	useCurrentTime,
}) {
	const isSmallDevice = useBreakpoint('md');
	const {
		isOpen: inIsOpen,
		onClose: inOnClose,
		onOpen: inOnOpen,
	} = useDisclosure(false);

	const { value } = useTimeValues(selected, useCurrentTime);

	const handleTimeChange = (key, val) => {
		if (!onChange) return;
		onChange({ ...value, [key]: val });
	};

	return (
		<div className={cn('w-full', className)}>
			{!inIsOpen ? (
				<div className="flex flex-col items-center justify-center space-y-4">
					{label ? (
						<label className="text-base font-bold leading-tight text-center text-dark-grey">
							{label}
						</label>
					) : null}

					<Button
						variant="outline"
						onClick={isSmallDevice ? onOpen : inOnOpen}
						className="group w-full rounded-md border border-border-color hover:border-core-blue data-[state=open]:border-core-blue font-normal text-base h-13"
					>
						{value?.hours}
						{(value?.hours || value?.minutes) && ':'}
						{value?.minutes}
					</Button>
				</div>
			) : (
				<>
					{isSmallDevice ? null : (
						<DesktopPicker
							selected={selected}
							onChange={handleTimeChange}
							onClose={isSmallDevice ? onClose : inOnClose}
						/>
					)}
				</>
			)}
		</div>
	);
}

function DesktopPicker({ onClose, onChange, selected }) {
	const { hours, minutes } = useTimeValues(selected);
	return (
		<div className="flex flex-col items-center gap-4 p-4 px-6 border rounded-lg">
			<div className="grid w-full grid-cols-2 gap-4">
				<div>
					<label>Hours</label>
					<Select
						isValid
						id="hours-select"
						options={hours.map((hour) => ({
							label: hour.toString(),
							value: hour.toString(),
						}))}
						menuPlacement="auto"
						value={{ label: selected?.hours, value: selected?.hours }}
						onChange={(val) => onChange('hours', val.value)}
					/>
				</div>
				<div>
					<label>Minutes</label>
					<Select
						isValid
						id="minutes-select"
						options={minutes.map((minute) => ({
							label: minute.toString(),
							value: minute.toString(),
						}))}
						menuPlacement="auto"
						value={{ label: selected?.minutes, value: selected?.minutes }}
						onChange={(val) => onChange('minutes', val.value)}
					/>
				</div>
			</div>
			<Button
				variant="unstyled"
				onClick={onClose}
				className="font-bold tracking-wide underline underline-offset-4"
			>
				Confirm
			</Button>
		</div>
	);
}

export default TimePicker;
