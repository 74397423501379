import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { cn } from '@/lib/utils';
import { isSunday } from 'date-fns';

import { Icon } from '@/components/common';
import { cva } from 'class-variance-authority';

const calendarBtnVariants = cva(
	'inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
	{
		variants: {
			variant: {
				ghost: 'hover:bg-light-grey hover:text-accent-foreground',
			},
			size: {
				default: 'h-10 px-4 py-2',
				sm: 'h-9 rounded-md px-3',
				lg: 'h-11 rounded-md px-8',
				icon: 'h-10 w-10',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
);

function Calendar({
	className,
	classNames,
	hideArrows,
	showOutsideDays = true,
	...props
}) {
	return (
		<DayPicker
			weekStartsOn={1}
			showOutsideDays={showOutsideDays}
			className={cn('p-4 w-full', className)}
			classNames={{
				months:
					'flex flex-col sm:flex-row space-y-4 sm:space-x-12 sm:space-y-0 overflow-x-auto md:overflow-x-hidden',
				month: 'space-y-4 w-full min-w-[300px] lg:min-w-none lg:w-auto',
				caption: 'flex justify-center pt-1 relative items-center',
				caption_label: 'text-base font-medium',
				nav: 'space-x-1 flex items-center',
				nav_button: cn(
					calendarBtnVariants({ variant: 'ghost' }),
					'h-7 w-7 bg-transparent p-0 opacity-60 hover:opacity-100 hover:bg-transparent'
				),
				nav_button_previous: 'absolute left-1',
				nav_button_next: 'absolute right-1',
				table: 'w-full border-collapse space-y-1',
				head_row: 'flex',
				head_cell:
					'text-dark-grey rounded-md w-full font-normal text-sm md:w-full',

				// Calendar days
				row: 'grid grid-cols-7 w-full mt-0 border-b last:border-b-0 border-lighter-grey',
				cell: 'w-full text-center text-sm p-0 relative [&:has([aria-selected])]:bg-light-grey first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
				day: cn(
					calendarBtnVariants({ variant: 'ghost' }),
					'h-12 w-full p-0 font-normal aria-selected:opacity-100 rounded-none border-r border-lighter-grey hover:bg-light-grey md:w-12'
				),
				day_selected:
					'bg-darker-grey text-light-grey hover:bg-darker-grey/90 hover:text-light-grey focus:bg-dark-grey focus:text-light-grey',
				day_today: ' text-outline-blue',
				day_outside: 'text-lightest-grey opacity-50 ',
				day_disabled: 'text-lightest-grey opacity-50',
				day_range_middle:
					'aria-selected:bg-light-grey aria-selected:text-dark-grey',
				day_hidden: 'invisible',
				...classNames,
			}}
			components={{
				IconLeft: hideArrows
					? () => null
					: ({ ...props }) => <Icon name="arrow-left" />,
				IconRight: hideArrows
					? () => null
					: ({ ...props }) => <Icon name="arrow-right" />,
				...props.components,
			}}
			modifiers={{
				sunday: (date) => isSunday(date),
				...props.modifiers,
			}}
			modifiersClassNames={{
				sunday: 'border-r-0',
				...props.modifierClassNames,
			}}
			{...props}
		/>
	);
}
Calendar.displayName = 'Calendar';

export default Calendar;
