import { Text } from '@/components/common';
import { CarSummaryCard } from '@/components/carhire';
import { cn, dateRangeFormatter } from '@/lib/utils';

/**
 * @name BookingSummary
 * @description A summary of the booking details
 * @returns {React.JSX.Element}
 * @example
 * <BookingSummary />
 * */

function BookingSummary({ item, className, contentClassName }) {
    return (
        <div>
            <Text className={cn('flex gap-2 items-center justify-between w-full mb-2', className)}>
                <Text as="span" className="font-bold text-2xl leading-tight capitalize">
                    Car
                </Text>
                {(item?.startDate || item?.endDate) && (
                    <Text as="span" className="text-right text-lg tracking-tighter">
                        {dateRangeFormatter({
                            start: item?.startDate,
                            end: item?.endDate,
                        })}
                    </Text>
                )}
            </Text>
            <CarSummaryCard car={item} className={contentClassName} />
        </div>
    );
}
export default BookingSummary;
