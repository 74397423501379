/**
 * @module objectDotValue
 * @name getObjectValue
 * @description Get the value of an object using a dot notation path
 * @categeory Utils
 * @param {unknown} obj 
 * @param {string} path 
 * @returns {any}
 * @example 
 * import { getObjectValue } from '@/lib/utils';
 * getObjectValue({a: {b: {c: 1}}}, 'a.b.c'); // 1
 */
export function getObjectValue(obj, path) {
    return path.split('.').reduce((acc, curr) => {
        if (!acc) return acc; // return null if acc is null

        // check if acc is an array and curr is a number
        if (curr.includes('[')) {
            const index = curr.match(/\d+/)[0];
            const key = curr.split('[')[0];

            if (!acc[key]) return null; // return null if key does not exist
            return acc[key][index];
        }
        return acc[curr]
    }, obj);
}


/**
 * @module objectDotValue
 * @name setNestedValue
 * @description Set the value of an object using a dot notation path
 * @categeory Utils
 * @param {unknown} obj
 * @param {string} path
 * @param {unknown} value
 * @returns {any}
 * @example
 * import { setObjectValue } from '@/lib/utils';
 * setObjectValue({a: {b: {c: 1}}}, 'a.b.c', 2); // {a: {b: {c: 2}}}
 */

export function setNestedValue(obj, path, value) {
    const keys = path.split('.');

    // remove the last key from the array
    const lastKey = keys.pop();

    // loop through the keys array
    keys.reduce((acc, curr) => {
        // if the key does not exist, create it
        if (!acc[curr]) {
            acc[curr] = {};
        }
        return acc[curr];
        // set the value of the last key
    }, obj)[lastKey] = value;

    return obj;
}
