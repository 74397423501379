import React from 'react';
import PropTypes from 'prop-types';

import {
	Tabs,
	Icon,
	Dialog,
	Button,
	TabsList,
	TabsTrigger,
	TabsContent,
	Heading,
	Text,
} from '@/components/common';
import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks';

/**
 * @typedef {Object} FareModalProps
 * @property {import("@components/common/atoms/dialog").DialogProps["renderTrigger"]} renderTrigger
 * @property {()=>void} onSelect
 * @property {Object} selected
 * */

/**
 * @name FareModal
 * @description Renders a modal with the fare details
 * @param {FareModalProps} props
 * @returns {React.JSX.Element | null}
 * @example
 * <FareModal onSelect={handleSelect} selected={selected} />
 * */

function FareModal({ onSelect, selected, renderTrigger }) {
	const isSm = useBreakpoint('sm');
	return (
		<Dialog
			as="modal"
			hideCloseBtn
			position="center"
			contentClassName="p-0"
			size={isSm ? 'screen' : 'xl'}
			renderTrigger={renderTrigger}
		>
			{({ CloseButton, onClose }) => (
				<div className="relative">
					<span className="w-full h-12 flex items-center justify-end">
						<CloseButton
							onClick={onClose}
							className="top-0 right-0 relative h-full w-12 bg-core-blue text-white"
							variant="square"
						/>
					</span>
					<div className="w-full p-5 flex items-center gap-4">
						<span className="w-24 h-24 p-2 border border-lighter-grey">
							<Icon name="air-transat" className="w-full h-full" />
						</span>
						<Heading as="h2" className="font-bold">
							British Airways
						</Heading>
					</div>
					<div className="border-t border-lighter-grey">
						<Tabs defaultValue="Fare Type 1">
							<div className="bg-lightish-grey h-10 p-0">
								<TabsList>
									{['Fare Type 1', 'Fare Type 2', 'Fare Type 3'].map(
										(option) => (
											<TabsTrigger
												key={option}
												value={option}
												className={cn(
													'flex gap-2 w-fit rounded-none px-5 h-10 text-core-blue border-t border-transparent font-bold uppercase shadow-none bg-transparent data-[state=active]:bg-white data-[state=active]:shadow-none data-[state=active]:mx-0 data-[state=active]:border-t data-[state=active]:border-core-red data-[state=active]:text-core-blue'
												)}
											>
												{option}
											</TabsTrigger>
										)
									)}
								</TabsList>
							</div>

							{['Fare Type 1', 'Fare Type 2', 'Fare Type 3'].map((option) => (
								<TabsContent value={option}>
									<div className="p-10 flex flex-col gap-5">
										<Heading as="h3">Economy</Heading>
										<div>
											<Text className="flex items-center">
												<Icon name="baggage" className="w-5 h-5" />
												<Text as="span" className="ml-2">
													Baggage
												</Text>
											</Text>
											<Text className="font-bold my-2">1x 23kg included</Text>
											<Text>1x Carry-on and</Text>
											<Text>1x Personal item (under the seat)</Text>
										</div>
										<div>
											<Text className="flex items-center">
												<Icon name="seat" className="w-5 h-5" />
												<Text as="span" className="ml-2">
													Seat selection
												</Text>
											</Text>
											<Text className="font-bold my-2">
												Standard seats free of charge
											</Text>
											<Text>Upgraded seats from £47</Text>
										</div>
										<div>
											<Text className="flex items-center">
												<Icon name="changes" className="w-5 h-5" />
												<Text as="span" className="ml-2">
													Changes
												</Text>
											</Text>
											<Text>
												<strong>
													Date and destination change at no charge{' '}
												</strong>{' '}
												up to 24 hours prior to departure. Only fare difference,
												if any, will apply.
											</Text>
										</div>
										<div>
											<Text className="flex items-center">
												<Icon name="cancellation" className="w-5 h-5" />
												<Text as="span" className="ml-2">
													Cancellations
												</Text>
											</Text>
											<Text className="font-bold">Fees of £150</Text>
										</div>
										<Text className="font-bold text-xl">£XX</Text>
										<Button
											label="Select"
											hideIcon
											className="w-fit"
											onClick={() => onSelect(option)}
										/>
									</div>
								</TabsContent>
							))}
						</Tabs>
					</div>
				</div>
			)}
		</Dialog>
	);
}

FareModal.propTypes = {
	renderTrigger: PropTypes.func.isRequired,
	onSelect: PropTypes.func,
	selected: PropTypes.object,
};

FareModal.defaultProps = {
	onSelect: () => {},
	selected: null,
};

export default FareModal;
