import PropTypes from 'prop-types';
import { SearchResultsList } from '@/components/common';
import { SearchResultCard } from '@/components/marketing-material';

function MarketingMaterialsResultsList({ pages }) {
	return (
		<SearchResultsList
			pages={pages}
			ResultCardComponent={SearchResultCard}
			className="md:grid-cols-2 lg:gap-5"
		/>
	);
}

MarketingMaterialsResultsList.propTypes = {
	pages: PropTypes.array,
};

MarketingMaterialsResultsList.defaultProps = {
	pages: [],
};

export default MarketingMaterialsResultsList;
