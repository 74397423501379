import React from 'react';
import PropTypes from 'prop-types';
import { cn, currencyFormatter } from '@/lib/utils';
import {
	Button,
	Icon,
	Text,
	Stack,
	Heading,
	Dialog,
	LoopWrapper,
} from '@/components/common';
import pluralize from 'pluralize';

function RentalInsurance({ className, info }) {
	return (
		<Dialog
			as="modal"
			size="lg"
			hideCloseBtn
			position="center"
			contentClassName="p-0"
			renderTrigger={({ DialogTrigger, onOpen }) => (
				<DialogTrigger
					onClick={onOpen}
					className={cn(
						'underline underline-offset-2 text-left font-normal leading-tight text-neutral-800',
						className
					)}
				>
					<Text as="span">Rental insurance information</Text>
				</DialogTrigger>
			)}
		>
			{({ CloseButton }) => (
				<div className="min-h-48 max-h-[85vh] overflow-y-scroll py-12">
					<CloseButton
						className="absolute top-0 right-0 bg-core-blue text-white w-12 h-12"
						variant="square"
					/>
					<Heading as="h2" className="font-bold leading-tight px-12 py-8">
						Rental insurance information
					</Heading>
					<div className="px-12 h-auto ">
						{info ? (
							<Text className="text-lg text-left">{info}</Text>
						) : (
							<div className="space-y-3">
								<Heading as="h3" className="font-bold leading-tight">
									Terms & Conditions
								</Heading>
								<Text className="text-lg">
									Loss Damage Waiver (LDW) or Collision Damage Waiver (CDW)
									Limited Damage Waiver (LDW) or Collision Damage Waiver (CDW)
									limits the driver&apos;s liability in the event that the
									rental car is damaged, subject to the terms of the rental
									agreement. Rather than the full cost, the driver is generally
									only responsible for the first portion, known as their
									‘excess’. This protection applies to all authorised drivers of
									the rental car.
								</Text>
								<Text className="text-lg">
									Third-Party Liability (TPL) Third-Party Liability (TPL) covers
									the driver&apos;s liability for damage to any property,
									excluding the rental car itself. TPL also covers injuries
									suffered by other people, whether or not they are in the
									rental car, subject to the terms of the rental agreement.
								</Text>
								<Text className="text-lg">
									Theft Protection (TP) Theft Protection (TP) limits the
									driver&apos;s liability for costs incurred by the theft of the
									rental car – or damage incurred by the theft or attempted
									theft – to an ‘excess’ amount, subject to the terms of the
									rental agreement. TP does not cover personal effects, which
									may be covered by the driver&apos;s own travel or household
									policies.
								</Text>
								<Text
									as="a"
									href="/travel-centre/terms-and-conditions/car-hire"
									target="_blank"
									rel="noopener noreferrer"
									className="block mt-8 text-lg font-bold underline hover:opacity-80"
								>
									For more information please read our car hire T&C's
								</Text>
							</div>
						)}
					</div>
				</div>
			)}
		</Dialog>
	);
}

function CarCard({
	car,
	hideProviderLogo,
	selected,
	className,
	onSelect,
	type,
}) {
	const carSummary = [
		car?.type,
		car?.sub_type,
		car?.aircon ? 'Air Conditioning' : null,
	]
		.filter((info) => info)
		.join(', ');

	return (
		<div
			className={cn(
				'@container flex h-auto w-full justify-start gap-2 bg-white p-4 md:p-6 lg:p-12',
				className
			)}
		>
			<div
				className={cn(
					'w-full flex flex-col items-start justify-start md:flex-col',
					type === 'extras' && 'flex-col'
				)}
			>
				<Heading
					as="h2"
					className="text-2xl font-bold leading-relaxed font-body"
				>
					{car?.displayName || car?.name}
				</Heading>
				{type === 'extras' && <Text>{carSummary}</Text>}
				<div className="flex flex-col w-full">
					<div
						className={cn('gap-1 flex-col ', type === 'extras' && 'flex-row')}
					>
						{type !== 'extras' && (
							<div className="flex items-center justify-start gap-1">
								<Text>{carSummary}</Text>
							</div>
						)}

						<div className="flex items-center justify-start gap-x-4 flex-wrap md:justify-start">
							<div className="flex gap-2 items-center">
								{car?.pax && (
									<Text className="flex gap-1 items-center">
										<Icon name="person" />
										{car.pax}
									</Text>
								)}
								{car?.large_baggage && (
									<Text className="flex gap-1 items-center">
										<Icon name="luggage" />
										{car.large_baggage}
									</Text>
								)}
								{car?.small_baggage && (
									<Text className="flex gap-1 items-center">
										<Icon name="small-luggage" />
										{car.small_baggage}
									</Text>
								)}
							</div>
							<RentalInsurance
								className="hidden lg:block"
								info={car?.insurance_info}
							/>
						</div>
					</div>
					{car?.image && (
						<img
							src={car.image}
							className="self-center h-fit w-[200px] object-contain xl:hidden"
						/>
					)}
				</div>
				<ul className="my-2">
					<LoopWrapper list={car?.features || []} itemKey="feature">
						{(feature) => (
							<li key={feature} className="list-none">
								<Text variant="muted" className="flex gap-2 items-start">
									<Icon name="maple-leaf" />
									<Text as="span">{feature}</Text>
								</Text>
							</li>
						)}
					</LoopWrapper>
				</ul>

				<RentalInsurance
					className="lg:hidden my-2"
					info={car?.insurance_info}
				/>
				{!selected && (
					<>
						<Stack
							orientation="vertical"
							className="items-start justify-start gap-3"
						>
							{car?.duration && car?.price && (
								<Text variant="bold" className="text-2xl">
									{car.duration} {pluralize('day', car.duration)} rental{' '}
									{currencyFormatter({
										amount: car.price,
									})}
								</Text>
							)}

							<Button
								aria-label="Car Hire Book Now"
								variant="supporting-yellow"
								className="flex gap-2"
								onClick={onSelect}
								label="Book Now"
							/>
						</Stack>
						{car?.localCharge > 0 && (
							<div className="inline-flex items-start justify-start gap-2.5 pt-3">
								<div>
									<span className="text-base font-bold leading-tight text-slate-800">
										Important:{' '}
									</span>
									<span className="text-base font-normal leading-tight text-slate-800">
										One way fee/local charges {currencyFormatter({
											amount: car.localCharge,
											currency: 'CAD',
											currencyDisplay: 'code',
										})} plus tax (payable locally).
									</span>
								</div>
							</div>
						)}
					</>
				)}
			</div>
			{((!hideProviderLogo && car?.supplier_logo) || car?.image) && (
				<div className="hidden w-[300px] flex-col items-end justify-center gap-[25.89px] xl:inline-flex">
					{!hideProviderLogo && (
						<div className="inline-flex h-[100px] w-[100px] items-center justify-center border border-border-color border-opacity-20 pl-6 pr-[23px]">
							{car?.supplier_logo && (
								<img
									className="h-full w-full object-contain scale-[1.4]"
									src={car.supplier_logo}
									alt={`${car?.supplier} logo`}
								/>
							)}
						</div>
					)}
					{car?.image && (
						<img
							className="hidden h-[200px] w-[300px] object-contain lg:block"
							src={car.image}
							alt={car?.display_name || car?.name}
						/>
					)}
				</div>
			)}
		</div>
	);
}

CarCard.propTypes = {
	car: PropTypes.object,
	hideProviderLogo: PropTypes.bool,
	selected: PropTypes.bool,
	className: PropTypes.string,
	onSelect: PropTypes.func,
};

export default CarCard;
