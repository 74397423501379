import React from 'react';

import { cn } from '@/lib/utils';
import { BreadCrumbs, Heading } from '@components/common';

function PageHeader({
	title,
	children,
	className,
	titleClassName,
	contentClassName,
	renderCustomBreadcrumb,
	hideBreadcrumb,
}) {
	const breadcrumbs = renderCustomBreadcrumb ? (
		renderCustomBreadcrumb()
	) : (
		<BreadCrumbs />
	);
	return title || children ? (
		<div
			className={cn(
				'flex flex-col gap-1 sm:gap-2 md:gap-4 bg-light-grey w-full mx-auto lg:bg-transparent ',
				className
			)}
		>
			{hideBreadcrumb ? null : breadcrumbs}
			<div
				className={cn(
					'flex flex-col gap-4 items-start justify-between md:flex-row md:items-center',
					contentClassName
				)}
			>
				{title && (
					<Heading
						as="h1"
						className={cn('font-bold text-core-blue', titleClassName)}
					>
						{title}
					</Heading>
				)}
				{children}
			</div>
		</div>
	) : null;
}

PageHeader.defaultProps = {
	hideBreadcrumb: false,
};

export default PageHeader;
