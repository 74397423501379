import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { BookingCalendar, DatePlaceHolder, Text } from '@/components/common';
import FormField from '@/components/common/molecules/formfield';
import {
	cn,
	Required,
	ONE_TO_NINE,
	ZERO_TO_NINE,
	HOLIDAY_DESTINATIONS_DEPARTING,
} from '@/lib/utils';
import { useDisclosure } from '@/hooks';

function ItineraryBookingForm({
	control,
	errors,
	watch,
	setValue,
	handleSubmit,
}) {
	const { isOpen, onToggle } = useDisclosure(() => {
		const { from, to } = watch('when');

		if (from && to) {
			return true;
		}

		return false;
	});
	const validateTotalPeople = useCallback((value, formValues) => {
		// only allow up to 9 people per booking
		const adults = formValues.adults?.value
			? parseInt(formValues.adults.value)
			: 0;
		const children = formValues.children?.value
			? parseInt(formValues.children.value)
			: 0;
		const infants = formValues.infants?.value
			? parseInt(formValues.infants.value)
			: 0;

		// show custom message to prevent 3 fields with the same message
		const isValid = adults + children + infants <= 9;
		setShowMaxPeopleError(!isValid);

		return isValid;
	});

	const { from, to } = watch('when');

	return (
		<form onSubmit={handleSubmit}>
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-3 items-center justify-center">
					<Text as="label" className="font-bold text-gray-700">
						Select a departure date
					</Text>
					<Controller
						name="when"
						control={control}
						render={({ field }) => {
							return isOpen ? (
								<DatePlaceHolder
									hideTime
									showIcon
									type="inputdate"
									handleClick={onToggle}
									className="cursor-pointer"
									selected={{ from: new Date(from), to: new Date(to) }}
								/>
							) : (
								<BookingCalendar
									className="w-full"
									{...field}
									onChange={(value) => {
										console.log(value);
										field.onChange(value.range);
										setValue('price', value.totalPrice);
										onToggle();
									}}
								/>
							);
						}}
					/>
				</div>
				<FormField
					name="departure"
					as="select"
					control={control}
					errors={errors}
					validation={[Required]}
					wrapperClassName="h-full w-full"
					labelClassName="whitespace-nowrap"
					placeholder="Select a departing airport"
					options={HOLIDAY_DESTINATIONS_DEPARTING}
				/>

				<div className="w-full grid grid-cols-3 gap-3">
					<FormField
						name="adults"
						label="Adults"
						as="select"
						options={ONE_TO_NINE}
						control={control}
						errors={errors}
						validation={[Required, validateTotalPeople]}
						wrapperClassName="w-full lg:min-w-[124px]"
						placeholder="Adults"
						footer="Aged 15 +"
					/>

					<FormField
						name="children"
						label="Children"
						as="select"
						options={ZERO_TO_NINE}
						control={control}
						errors={errors}
						validation={[validateTotalPeople]}
						wrapperClassName="w-full lg:min-w-[124px]"
						placeholder="Children"
						footer="Ages 3 - 15"
					/>

					<FormField
						name="infants"
						as="select"
						label="Infants"
						options={ZERO_TO_NINE}
						control={control}
						errors={errors}
						validation={[validateTotalPeople]}
						wrapperClassName="w-full lg:min-w-[124px]"
						placeholder="Infants"
						footer="Ages 0 - 3"
					/>
				</div>
			</div>
		</form>
	);
}

ItineraryBookingForm.propTypes = {
	params: PropTypes.shape({
		location: PropTypes.string,
		from: PropTypes.string,
		adults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		infants: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
};

ItineraryBookingForm.defaultProps = {
	params: {},
	hideClearBtn: false,
};

export default ItineraryBookingForm;
