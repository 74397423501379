import { Skeleton } from '@components/common';

function CardSkeleton() {
	return (
		<div className="inline-flex h-auto w-full items-center justify-start gap-10 bg-white p-10 rounded-lg mb-5">
			<div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-3">
				<Skeleton className="w-3/4 h-6 rounded-full" />
				<div className="inline-flex items-center justify-start gap-2">
					<Skeleton className="w-[50px] h-4" />
					<Skeleton className="w-[60px] h-4" />
					<Skeleton className="w-[100px] h-4" />
				</div>

				<div className="flex flex-col items-start justify-start gap-3 py-4">
					<div className="flex flex-col items-start justify-start gap-2">
						<Skeleton className="w-[250px] h-2 rounded-full" />
						<Skeleton className="w-[180px] h-2 rounded-full" />
						<Skeleton className="w-[200px] h-2 rounded-full" />
					</div>
				</div>
				<div className="flex flex-col items-start justify-start gap-3">
					{/* <Skeleton className="w-1/2 h-4 rounded-full" /> */}
					<Skeleton className="w-[150px] h-12 rounded-full" />
				</div>
				<div className="flex flex-col items-start justify-start gap-2.5 pt-3">
					<Skeleton className="w-[200px] h-4 rounded-full" />
					<Skeleton className="w-[100px] h-2 rounded-full" />
				</div>
			</div>
			<div className="inline-flex w-[300px] flex-col items-end justify-center gap-[25.89px]">
				<Skeleton className="w-[100px] h-[100px]" />
				<Skeleton className="w-[300px] h-[200px] rounded" />
			</div>
		</div>
	);
}
export default CardSkeleton;
