import PropTypes from 'prop-types';

import { BlockLayout } from '@components/holidays';
import { HotelSummaryCard } from '@/components/hotels';

/**
 * @typedef {Object} HotelBlockProps
 * @property {Object} flight
 * @property {string} title
 * @property {string} className
 * @property {() => React.ReactNode} renderStep
 */

/**
 * @name HotelBlock
 * @description Renders a travel plan block with the hotel details
 * @param {HotelBlockProps} props
 * @returns
 */
function HotelBlock({ hotel, title, renderStep }) {
	return (
		<BlockLayout title={title} date="2024-06-14T00:00:00+00:00" renderStep={renderStep}>
			<HotelSummaryCard hotel={hotel} />
		</BlockLayout>
	);
}

HotelBlock.propTypes = {
	flight: PropTypes.object,
	title: PropTypes.string,
};

HotelBlock.defaultProps = {
	flight: {},
	title: 'Outbound Flight',
};

export default HotelBlock;
