import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { useCallback, useEffect, useState } from 'react';

import {
	Accordion,
	AccordionTrigger,
	AccordionItem,
	Icon,
	Text,
	Button,
	AccordionContent,
	ErrorHandler,
	Tag,
} from '@components/common';
import FilterList from './FilterList';
import useUrlParams from '@/hooks/useUrlParams';
import pluralize from 'pluralize';
import {
	cn,
	currencyFormatter,
	formatFacetTitle,
	formatActiveDurationLabel,
} from '@/lib/utils';
import { useAppliedFilters } from '@/hooks';

import { SortFilter } from '@components/common/organisms/filters';

const getDefaultState = (filters) => {
	const filterState = filters.map((filter) => {
		if (filter.type === 'duration') {
			return {
				[filter.name]: {},
			};
		}

		if (filter.type === 'budget' || filter.type === 'flightDuration') {
			// set filter to min and max if range is provided
			const min = parseFloat(filter.min);
			const max = parseFloat(filter.max);
			if (!isNaN(min) && !isNaN(max))
				return {
					[filter.name]: filter?.defaultToZero ? [0, 0] : [min, max],
				};

			return {
				[filter.name]: [],
			};
		}

		return { [filter.name]: [] };
	});
	return Object.assign({}, ...filterState);
};

function FilterWidget({
	filters,
	paramsKey,
	sortOptions,
	defaultSort,
	hideSortField,
	widgetLabel,
	disabled,
	filterClassName,
	scrollToContainer,
}) {
	const { params, updateParams, clearParams } = useUrlParams(paramsKey);

	const [filterState, setFilterState] = useState(() =>
		getDefaultState(filters)
	);

	// update filterState when params change
	const updateFilterState = useCallback(() => {
		if (!params[paramsKey]) return;
		const newFilterState = { ...filterState };
		const filterParams = params[paramsKey] || {};

		Object.keys(filterParams).forEach((key) => {
			newFilterState[key] = [];

			if (filterParams[key]) newFilterState[key] = filterParams[key];
		});

		setFilterState(newFilterState);
	}, [params, paramsKey, filters]);

	useEffect(() => {
		updateFilterState();
	}, [updateFilterState]);

	// Update url params when filters change
	const handleFilterChange = ({ name, value }) => {
		const defaultState = getDefaultState(filters);
		const newFilterState = { ...filterState };
		newFilterState[name] = value;

		Object.keys(newFilterState).forEach((key) => {
			// remove empty states
			if (Array.isArray(newFilterState[key])) {
				if (newFilterState[key].length === 0) {
					delete newFilterState[key];
				}
			} else if (typeof newFilterState[key] === 'object') {
				if (Object.keys(newFilterState[key]).length === 0) {
					delete newFilterState[key];
				}
			} else if (newFilterState[key] === '') {
				delete newFilterState[key];
			}

			switch (key) {
				// remove duration filter if a min or max isn't chosen
				case 'duration':
					const min = newFilterState?.duration?.minNights?.value;
					const max = newFilterState?.duration?.maxNights?.value;
					if (!(min || max)) delete newFilterState['duration'];
					break;

				// remove range filter if it's range matches the default range
				case 'budget':
				case 'flightDuration':
					if (defaultState[key] && defaultState[key]?.length) {
						const isDefaultValue =
							newFilterState[key] &&
							defaultState[key][0] == newFilterState[key][0] &&
							newFilterState[key] &&
							defaultState[key][1] == newFilterState[key][1];
						if (isDefaultValue) delete newFilterState[key];
					}
					break;
			}
		});

		scrollToContainer();
		setFilterState(newFilterState);

		// only update qs if default params have been parsed
		if (params.default)
			updateParams({
				...newFilterState,
				sort: params[paramsKey]?.sort,
				perPage: params[paramsKey]?.perPage,
			});
	};

	const handleSortFilterChange = ({ name, value }) => {
		if (!value?.sort?.value) return;

		const existingParams = params[paramsKey] || {};
		updateParams({
			...existingParams,
			sort: value.sort.value,
		});
	};

	const resetFilters = () => {
		clearParams();
		updateParams({
			sort: params[paramsKey]?.sort,
			perPage: params[paramsKey]?.perPage,
		});
		setFilterState(() => getDefaultState(filters));
	};

	const { appliedFilters, totalAppliedFilters } = useAppliedFilters(paramsKey);

	const formatActiveFilterName = (filter) => {
		if (!(filter?.key && filter?.value)) return '';

		let label;
		const activeCount = filter.value?.length || 1;
		switch (filter.key) {
			// show budget range
			case 'budget':
				if (
					typeof filter.value[0] === 'undefined' ||
					typeof filter.value[1] === 'undefined'
				)
					return '';

				label = currencyFormatter({ amount: filter.value[0] });
				if (filter.value[0] === filter.value[1] || !filter.value[1])
					return label;

				return `${label} - ${currencyFormatter({
					amount: filter.value[1],
				})}`;
			case 'flightDuration':
				if (
					typeof filter.value[0] === 'undefined' ||
					typeof filter.value[1] === 'undefined'
				)
					return '';

				label = `${filter.value[0]} ${pluralize('hour', filter.value[0])}`;
				if (filter.value[0] === filter.value[1] || !filter.value[1])
					return label;

				return `${label} - ${filter.value[1]} ${pluralize(
					'hour',
					filter.value[1]
				)}`;

			case 'duration':
				const min = filter?.value?.minNights?.value;
				const max = filter?.value?.maxNights?.value;
				return formatActiveDurationLabel(min, max);
		}

		// show active value name if only one is selected
		if (activeCount === 1) return filter.value[0];

		return `${formatFacetTitle(filter.key)} (${activeCount})`;
	};

	return (
		<ErrorBoundary FallbackComponent={ErrorHandler}>
			<div
				className={cn(
					'w-full bg-white md:bg-transparent',
					filterClassName && filterClassName
				)}
			>
				<Accordion
					type="single"
					collapsible
					className="mx-auto overflow-visible px-7 lg:overflow-y-scroll hide-scrollbar md:w-full md:px-0"
				>
					<AccordionItem
						value="mobile-filters"
						className="w-full pb-0 border-0 lg:hidden"
					>
						<div className="flex items-center justify-between py-5 rounded md:py-0">
							<AccordionTrigger
								hideChevron
								asChild
								className="flex items-center justify-between w-full gap-2 border-0 rounded"
							>
								<Icon name="filter" />
								<Text className="leading-tight tracking-normal">
									Filter {!hideSortField && '& Sort'}
								</Text>
							</AccordionTrigger>
							{totalAppliedFilters > 0 && (
								<div>
									{totalAppliedFilters === 1 ? (
										<Tag
											key={appliedFilters[0]?.key}
											label={formatActiveFilterName(appliedFilters[0])}
											isCancellable={true}
											onCancel={resetFilters}
											disabled={disabled}
										/>
									) : (
										<Text className="underline">
											{totalAppliedFilters}{' '}
											{pluralize('filter', totalAppliedFilters)}
										</Text>
									)}
								</div>
							)}
						</div>
						<AccordionContent
							className="w-full px-4 py-2 overflow-visible"
							value="mobile-filters"
						>
							<FilterList
								filters={filters}
								filterState={filterState}
								handleFilterChange={handleFilterChange}
								disabled={disabled}
							/>
							{!hideSortField && sortOptions?.length > 0 && (
								<SortFilter
									name="sort"
									value={defaultSort || sortOptions[0]}
									onChange={handleSortFilterChange}
									disabled={disabled}
									options={sortOptions}
								/>
							)}
							{totalAppliedFilters > 0 ? (
								<div className={cn(disabled && 'cursor-not-allowed')}>
									<Button
										variant="unstyled"
										onClick={resetFilters}
										className="mt-4 underline underline-offset-3 disabled:cursor-not-allowed"
										disabled={disabled}
									>
										Clear all filters
									</Button>
								</div>
							) : null}
						</AccordionContent>
					</AccordionItem>
				</Accordion>
				<div className="hidden lg:block">
					{widgetLabel && (
						<Text variant="muted" className="font-bold text-center uppercase">
							{widgetLabel}
						</Text>
					)}
					<FilterList
						filters={filters}
						filterState={filterState}
						handleFilterChange={handleFilterChange}
						disabled={disabled}
					/>
					{!hideSortField && sortOptions?.length > 0 && (
						<SortFilter
							name="sort"
							value={defaultSort || sortOptions[0]}
							onChange={handleSortFilterChange}
							disabled={disabled}
							options={sortOptions}
							className="xl:hidden"
						/>
					)}
				</div>

				{totalAppliedFilters > 0 ? (
					<div className={cn(disabled && 'cursor-not-allowed')}>
						<Button
							variant="unstyled"
							onClick={resetFilters}
							className="hidden mt-4 underline underline-offset-3 lg:block"
							disabled={disabled}
						>
							Clear all filters
						</Button>
					</div>
				) : null}
			</div>
		</ErrorBoundary>
	);
}

FilterWidget.propTypes = {
	filters: PropTypes.array.isRequired,
	paramsKey: PropTypes.string,
	sortOptions: PropTypes.array,
	hideSortField: PropTypes.bool,
	widgetLabel: PropTypes.string,
	disabled: PropTypes.bool,
};

FilterWidget.defaultProps = {
	disabled: false,
	sortOptions: [],
	hideSortField: false,
};

export default FilterWidget;
