import {
	isEqual,
	isToday,
	isSunday,
	isSameMonth,
	startOfToday,
} from 'date-fns';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { cva } from 'class-variance-authority';
import { useDayRender } from 'react-day-picker';

import { cn } from '@/lib/utils';
import { Calendar, Text } from '@/components/common';

const calendarBtnVariants = cva(
	'inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
	{
		variants: {
			variant: {
				ghost: 'hover:bg-light-grey hover:text-accent-foreground',
			},
			size: {
				default: 'h-10 px-4 py-2',
				sm: 'h-9 rounded-md px-3',
				lg: 'h-11 rounded-md px-8',
				icon: 'h-10 w-10',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
);

function PriceCalendar({ month, selected, onChange, setMonth, renderPrice, minDate, maxDate }) {
	const today = startOfToday();

	const handleChange = (values) => {
		if (selected?.from && selected?.to) {
			const chosenDate =
				values?.from && isEqual(values.from, selected.from)
					? values?.to
					: values?.from;

			onChange({
				from: chosenDate,
				to: undefined, // deselect the 'to' date
			});
			return;
		}

		onChange(values);
	};

	return (
		<Calendar
			mode="range"
			month={month}
			disableNavigation
			selected={selected}
			onSelect={handleChange}
			onMonthChange={setMonth}
			className="w-full border border-border-color p-0 rounded-lg overflow-hidden"
			components={{
				Caption: () => null,
				Day: ({ date, displayMonth }) => {
					const dayRef = useRef(null);
					const { buttonProps } = useDayRender(date, displayMonth, dayRef);

					const isDayToday = isEqual(date, today);
					const sameMonth = isSameMonth(date, displayMonth);
					const isASunday = isSunday(date);
					const isDaySelected =
						(selected?.from && selected?.to && isEqual(date, selected?.from)) ||
						isEqual(date, selected?.to);

					return (
						<button
							{...buttonProps}
							ref={dayRef}
							type="button"
							onClick={() => {
								buttonProps.onClick();

								if (!sameMonth) setMonth(date);
							}}
							className={cn(
								calendarBtnVariants({
									variant: 'ghost',
									className: cn(
										'w-10 h-10 md:w-14 md:h-14 p-2 border-t border-r border-collapse text-gray-400 border-light-grey flex flex-col justify-center items-center md:gap-1 hover:bg-supporting-yellow/30 rounded-none hover:text-accent-foreground',
										{ 'text-black': sameMonth },
										{ 'text-red-500': isDayToday },
										{ 'border-r-0': isASunday },
										{
											'bg-core-blue text-white':
												isEqual(date, selected?.to) && !isToday(date),
										},
										{
											'bg-supporting-yellow text-core-blue hover:bg-supporting-yellow focus:bg-supporting-yellow focus:text-core-blue':
												isDaySelected,
										}
									),
								})
							)}
						>
							<Text as="span" className="text-xs md:text-sm font-bold">
								{date.getDate()}
							</Text>
							<Text as="p" className="p-0 m-0 text-[10px] md:text-sm">
								{renderPrice({ date, displayMonth })}
							</Text>
						</button>
					);
				},
			}}
			classNames={{
				months: 'w-full',
				month: 'w-full',
				table: 'w-full space-y-1 border-collapse',
				head_row: 'flex py-2 bg-light-grey rounded-t-lg w-full',
				head_cell: 'text-dark-grey rounded-md w-full font-normal text-[0.8rem]',
				row: 'grid grid-cols-7 w-full mt-0 border-b-0 last:border-b-0 border-lighter-grey',
				day_range_middle:
					'aria-selected:bg-light-grey aria-selected:text-dark-grey',
			}}
			disabled={{
				before: minDate,
				after: maxDate,
			}}
		/>
	);
}

PriceCalendar.propTypes = {
	month: PropTypes.instanceOf(Date),
	selected: PropTypes.shape({
		from: PropTypes.instanceOf(Date),
		to: PropTypes.instanceOf(Date),
	}),
	onChange: PropTypes.func,
	renderPrice: PropTypes.func,
	minDate: PropTypes.instanceOf(Date),
	maxDate: PropTypes.instanceOf(Date),
};

PriceCalendar.defaultProps = {
	month: new Date(),
	selected: new Date(),
	onChange: () => {},
	renderPrice: () => {},
	minDate: new Date(),
};

export default PriceCalendar;
