import React from 'react';
import { Icon, Heading, Text } from '@/components/common';

const inclusions = [
	{
		title: 'Seat selection',
		description: 'Enjoy free standard seat selection when you book.',
		icon: 'seat',
	},
	{
		title: 'Priority boarding',
		description: 'Be among the first to board the plane.',
		icon: 'pass',
	},
	{
		title: 'Priority check-in',
		description: 'Check in at a dedicated counter.',
		icon: 'plane',
	},
	{
		title: 'Priority baggage handling',
		description: 'Be among the first to get your luggage at destination.',
		icon: 'baggage',
	},
	{
		title: 'Travel Amenity Kit',
		description:
			'Includes a headset, bar snacks and a comfort kit (blanket, inflatable neck pillow, eyeshade).',
		icon: 'food-basket',
	},
	{
		title: 'Extra baggage allowance',
		description:
			'Enjoy a supplementary checked baggage allowance of up to 23kg per flight segment.',
		icon: 'bags',
	},
];

function OptionPlusInclusions() {
	return (
		<div className="w-full">
			<Text className="mb-5">
				Take advantage of Option Plus to get the most from your flight. Option
				Plus includes ...
			</Text>

			<div className="grid w-full grid-cols-1 gap-5 lg:grid-cols-2">
				{inclusions.map((inclusion) => (
					<div
						key={inclusion.icon}
						className="flex items-start gap-4 text-dark-grey md:items-center"
					>
						<span className="w-10 h-10">
							<Icon name={inclusion.icon} className="w-full h-full p-1" />
						</span>
						<div className="flex flex-col items-start justify-start flex-1 h-auto gap-1">
							<Heading as="h3" className="text-lg font-bold font-body">
								{inclusion.title}
							</Heading>
							<Text className="font-normal tracking-normal">
								{inclusion.description}
							</Text>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
export default OptionPlusInclusions;
