import PropTypes from 'prop-types';
import { addDays, format, isValid } from 'date-fns';
import { Controller } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
	Text,
	Button,
	FormField,
	DatePlaceHolder,
	BookingCalendar,
} from '@/components/common';
import {
	cn,
	Required,
	ONE_TO_NINE,
	ZERO_TO_NINE,
	HOLIDAY_DESTINATIONS_DEPARTING,
	FLIGHT_HOTEL_DURATIONS,
} from '@/lib/utils';
import { useDisclosure, useFetch } from '@/hooks';

function BookingForm({ control, errors, watch, setValue, params }) {
	const [rooms, setRooms] = useState(1);
	const { from, to } = watch('when');
	const { isOpen, onOpen, onClose } = useDisclosure(true);
	const [previousFrom, setPreviousFrom] = useState(null);

	const showCalendar = useCallback(() => {
		if (from && to) {
			onOpen();
			return;
		}

		if (!from && !to && !previousFrom) {
			onOpen();
			return;
		}

		onClose();
	}, [from, to, previousFrom]);

	useEffect(() => {
		showCalendar();
	}, [showCalendar]);

	const validateTotalPeople = useCallback((value, formValues) => {
		// only allow up to 9 people per booking
		const adults = formValues.adults?.value
			? parseInt(formValues.adults.value)
			: 0;
		const children = formValues.children?.value
			? parseInt(formValues.children.value)
			: 0;
		const infants = formValues.infants?.value
			? parseInt(formValues.infants.value)
			: 0;

		// show custom message to prevent 3 fields with the same message
		const isValid = adults + children + infants <= 9;
		setShowMaxPeopleError(!isValid);

		return isValid;
	});

	const roomsList = Array.from({ length: rooms });
	const { adults, children, infants } = watch('rooms.0.persons');
	const duration = watch('duration');

	const roomsParams = useMemo(() => {
		const validStartDate = isValid(from) ? format(from, 'yyyy-MM-dd') : null;
		const validEndDate = isValid(to) ? format(to, 'yyyy-MM-dd') : null;

		if (!params?.code) return null;
		if (!validStartDate || !validEndDate) return null;

		return {
			code: params?.code,
			startDate: validStartDate,
			endDate: validEndDate,
			rooms: [
				adults?.value.toString(),
				children?.value.toString(),
				infants?.value.toString(),
			],
		};
	}, [params, to, from]);

	const { data, isFetching } = useFetch({
		key: 'hotel-rooms',
		params: roomsParams,
		config: {
			enabled: !!roomsParams,
			retry: false,
		},
		format: (res) => {
			const newRes = res.data?.map((room) => {
				return {
					label: room.name,
					value: room.code + (room.boardBasis ? `-${room.boardBasis}` : ''),
				};
			});
			return newRes;
		},
	});

	return (
		<div className="flex flex-col gap-4">
			<FormField
				name="from"
				as="select"
				showRequired
				errors={errors}
				control={control}
				validation={[Required]}
				placeholder="Select"
				label="Departing from"
				wrapperClassName="h-full w-full"
				labelClassName="whitespace-nowrap"
				options={HOLIDAY_DESTINATIONS_DEPARTING}
			/>

			<div className="flex flex-col items-center justify-center gap-3">
				<Text as="label" className="font-bold text-gray-700">
					Select a departure date
				</Text>

				{!isOpen && (
					<FormField
						name="duration"
						as="select"
						showRequired
						errors={errors}
						control={control}
						validation={[Required]}
						placeholder="Select"
						label="For"
						wrapperClassName="h-full w-full"
						labelClassName="whitespace-nowrap"
						options={FLIGHT_HOTEL_DURATIONS}
						value={FLIGHT_HOTEL_DURATIONS[0]}
					/>
				)}

				<Controller
					name="when"
					control={control}
					render={({ field }) => {
						return isOpen ? (
							<DatePlaceHolder
								hideTime
								showIcon
								type="inputdate"
								handleClick={() => {
									// clear the currently selected date
									setPreviousFrom(from);

									field.onChange({
										from: undefined,
										to: undefined,
									});

									setTimeout(() => {
										onClose();
									}, 150);
								}}
								className="cursor-pointer"
								selected={{ from: new Date(from), to: new Date(to) }}
							/>
						) : (
							<BookingCalendar
								className="w-full"
								code={params?.code}
								{...field}
								openToDate={previousFrom}
								onChange={(range) => {
									if (!isValid(range?.from)) {
										onOpen();
										return;
									}

									// set end date 7/14 days after from date
									const daysToAdd =
										parseInt(duration?.value) ||
										FLIGHT_HOTEL_DURATIONS[0]?.value;
									field.onChange({
										from: range.from,
										to: addDays(range.from, daysToAdd),
									});
								}}
								minDate={addDays(new Date(), 3)}
							/>
						);
					}}
				/>
			</div>

			{roomsList.map((_, idx) => (
				<div
					key={`booking-form-holidays-${idx}`}
					className="flex flex-col gap-4"
				>
					<div className="grid w-full grid-cols-2 gap-3 md:grid-cols-3">
						<FormField
							name={`rooms.${idx.toString()}.persons.adults`}
							label={idx === 0 ? 'Who' : `Who room ${idx + 1}?`}
							as="select"
							options={ONE_TO_NINE}
							control={control}
							errors={errors}
							validation={[Required, validateTotalPeople]}
							wrapperClassName="w-full lg:min-w-[124px]"
							placeholder="Adults"
							footer="Aged 15 +"
						/>

						<FormField
							name={`rooms.${idx.toString()}.persons.children`}
							as="select"
							options={ZERO_TO_NINE}
							control={control}
							errors={errors}
							validation={[validateTotalPeople]}
							className="mt-6.25"
							wrapperClassName="w-full lg:min-w-[124px]"
							placeholder="Children"
							footer="Ages 3 - 15"
						/>

						<FormField
							name={`rooms.${idx.toString()}.persons.infants`}
							as="select"
							options={ZERO_TO_NINE}
							control={control}
							errors={errors}
							validation={[validateTotalPeople]}
							className={cn('md:mt-6.25')}
							wrapperClassName="w-full col-span-2 lg:min-w-[124px] md:col-span-1"
							placeholder="Infants"
							footer="Ages 0 - 3"
						/>
					</div>

					<FormField
						as="select"
						options={data}
						errors={errors}
						control={control}
						isLoading={isFetching}
						validation={[Required]}
						placeholder="Select Room..."
						labelClassName="whitespace-nowrap"
						name={`rooms.${idx.toString()}.room`}
						wrapperClassName="h-full w-full col-span-2"
					/>
					{roomsList.length > 1 && (
						<Button
							hideIcon
							type="button"
							disableAnimation
							variant="unstyled"
							label="Remove room"
							className="h-auto underline border rounded-md w-fit border-border-color text-core-blue/70 underline-offset-4"
							labelClassName="text-sm font-normal hover:text-core-blue"
							onClick={() => {
								// get the current value of the rooms field
								const currentRooms = watch('rooms');

								// remove the room at the current index
								currentRooms.splice(idx, 1);

								// update the rooms field
								setValue('rooms', currentRooms);

								if (Array.isArray(currentRooms) && currentRooms.length === 1) {
									currentRooms.splice(idx, 1);
								}

								// remove the last room
								const newRooms = roomsList.filter((_, index) => {
									return index !== idx;
								});

								setRooms(newRooms.length);
							}}
						/>
					)}
				</div>
			))}
			<Button
				type="button"
				variant="unstyled"
				label="Add a room"
				onClick={() => {
					setRooms((prev) => prev + 1);

					// update the rooms field
					const currentRooms = watch('rooms');
					const newRooms = [...currentRooms, { persons: {} }];

					setValue('rooms', newRooms);
				}}
				className="underline underline-offset-2 w-fit"
				hideIcon
			/>
		</div>
	);
}

BookingForm.propTypes = {
	params: PropTypes.shape({
		location: PropTypes.string,
		from: PropTypes.string,
		adults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		infants: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
};

BookingForm.defaultProps = {
	params: {},
	hideClearBtn: false,
};

export default BookingForm;
