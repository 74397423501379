import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { Button, Checkbox, Text } from '@components/common';

/**
 * @typedef {Object} CheckButtonProps
 * @property {boolean} checked
 * @property {string} className
 * @property {function} onChange
 * @property {boolean} isDisabled
 * @property {(params:boolean)=> React.JSX.Element} renderText
 * */

/**
 * @name CheckButton
 * @description Renders a check button
 * @param {CheckButtonProps } props
 * @returns {React.JSX.Element | null}
 * @example
 * <CheckButton
 * 	checked={false}
 * 	isDisabled={false}
 * 	onChange={() => {}}
 * 	renderText={(checked) => (
 * 		<Text as="span" variant="caption">
 * 			{checked ? 'Selected' : 'Not Selected'}
 * 		</Text>
 * 	)}
 * />
 */

function CheckButton({
	onChange,
	className,
	isDisabled,
	renderText,
	checked = false,
}) {
	const handleChange = () => {
		if (isDisabled) return;
		onChange();
	};

	return (
		<Button
			type="button"
			disabled={isDisabled}
			onClick={handleChange}
			data-selected={checked}
			variant={checked ? 'core-blue' : 'outline'}
			className={cn(
				'flex items-center border-1 disabled:opacity-40 disabled:border-lightish-grey disabled:cursor-not-allowed ',
				className
			)}
		>
			<Checkbox
				checked={checked}
				disabled={isDisabled}
				onCheckedChange={handleChange}
				className={cn({
					'bg-white text-core-blue': checked,
				})}
			/>
			<Text as="label" className="cursor-pointer">{renderText(checked)}</Text>
		</Button>
	);
}

CheckButton.propTypes = {
	checked: PropTypes.bool,
	isDisabled: PropTypes.bool,
	onChange: PropTypes.func,
	renderText: PropTypes.func,
	className: PropTypes.string,
};

CheckButton.defaultProps = {
	checked: false,
	isDisabled: false,
	onChange: () => {},
	renderText: () => null,
	className: '',
};

export default CheckButton;
