import { useState, useRef, useEffect, useCallback } from 'react';

function useMapToggle(defaultView = 'default', callback = null) {
	const callbackRef = useRef(callback);
	const [view, setView] = useState(defaultView);

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	const toggle = useCallback(
		(value) => {
			if (!value || value === view) return;
			setView(value);

			if (typeof callbackRef.current === 'function') {
				callbackRef.current(value);
			}
		},
		[view]
	);

	return { view, toggle };
}
export default useMapToggle;
