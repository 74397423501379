/**
 * The maximum date for car hire booking calendars.
 */
export const CAR_HIRE_MAX_DATE = '2026-03-31';

/**
 * The maximum date for hotel-only booking calendars.
 */
export const HOTEL_ONLY_MAX_DATE = '2026-03-31';

/**
 * The maximum date for flight-only booking calendars.
 */
export const FLIGHT_ONLY_MAX_DATE = '2025-10-31';
