import { useMemo } from 'react';
import useUrlParams from './useUrlParams';

/**
 * @name useAppliedFilters Hook
 * @description A hook to retrieve the filters applied to the search
 * @param {string} paramsKey
 * @returns {{activeFilters: ({key: string, value: string}), totalAppliedFilters: (number)}}
 * @example
 * const { activeFilters, totalAppliedFilters } = useAppliedFilters('key', [], [], () => {});
 */
function useAppliedFilters(paramsKey) {
	const { params } = useUrlParams(paramsKey);

	const appliedFilters = useMemo(() => {
		if (!(paramsKey && params)) return [];

		// determine which filters are applied
		const filterValues = params[paramsKey] || {};
		return Object.keys(filterValues)
			.filter((field) => {
				if (field === 'sort' || field === 'perPage') return false;

				const value = filterValues[field];
				switch (field) {
					case 'budget':
					case 'flightDuration':
						return (
							value?.length == 2 &&
							!value.every((val) => val === '') &&
							!value.every((val) => parseInt(val) === 0)
						);
					case 'duration':
						const min = value?.minNights?.value;
						const max = value?.maxNights?.value;
						return min || max;
					default:
						return value?.length > 0;
				}
			})
			.map((filter) => ({
				key: filter,
				value: filterValues[filter],
			}));
	}, [params, paramsKey]);

	return {
		appliedFilters,
		totalAppliedFilters: appliedFilters?.length,
	};
}

export default useAppliedFilters;
