import * as yup from 'yup';
import { WHO_OPTIONS } from '@/lib/utils';

export const holidaySearchSchema = yup.object().shape({
    type: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required(),
            label: yup.string(),
        })
        .required('Required'),
    location: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required(),
            label: yup.string(),
        })
        .required('Required'),
    from: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required(),
            label: yup.string(),
        })
        .required('Required'),
    who: yup
        .object()
        .shape(
            WHO_OPTIONS.reduce((acc, option) => {
                if (option.value === 'adults') {
                    acc[option.value] = yup
                        .object()
                        .shape({
                            value: yup.string().required('At least one adult is required'),
                            label: yup.string(),
                        })
                        .required('At least one adult is required');

                    return acc;
                }
                acc[option.value] = yup.object().nullable().shape({
                    value: yup.string(),
                    label: yup.string(),
                });
                return acc;
            }, {})
        )
        .test({
            name: 'who',
            message: 'Total number of people cannot exceed 9',
            test: function (value) {
                const total = Object.values(value).reduce((acc, curr) => {
                    if (curr?.value) {
                        acc += parseInt(curr.value);
                    }
                    return acc;
                }, 0);
                return total <= 9;
            },
        }),
    month: yup.object().nullable().shape({
        value: yup.string().required(),
        label: yup.string(),
    }),
    duration: yup.object().nullable().shape({
        value: yup.string().required(),
        label: yup.string(),
    }),
    when: yup.object().nullable().shape({
        from: yup.date().required(),
        to: yup.date().required(),
    }),
});
