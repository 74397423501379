import PropTypes from 'prop-types';
import { SearchResultsPage } from '@/components/common';
import { SpecialOfferResultsList } from '@/components/flights';
import { withPortal } from '@/HOCs/withPortal';
import { buildFacetFilters } from '@/lib/utils';

const sortOptions = [
	{
		label: 'Price - Low to High',
		value: 'price:asc',
	},
	{
		label: 'Price - High to Low',
		value: 'price:desc',
	},
	{
		label: 'Departure Date - Closest',
		value: 'departureTimestamp:asc',
	},
	{
		label: 'Departure Date - Furthest',
		value: 'departureTimestamp:desc',
	},
];

const pageOptions = [
	{
		label: '6',
		value: '6',
	},
];

/**
 * @name FlightSpecialOfferSearch Page
 * @description Page to display flight special offer search results
 * @returns {React.JSX.Element} FlightSpecialOfferSearch
 */
function FlightSpecialOfferSearch({ portalData }) {
	return (
		<SearchResultsPage
			paramsKey="flight_offer_filters"
			queryKey="flight-special-offer-search"
			sortOptions={sortOptions}
			pageOptions={pageOptions}
			hidePerPageField
			filterLabel="Filter Flights By"
			className="lg:pt-12.5"
			buildDefaultFilterParams={() => {
				if (!portalData?.location) return {};

				return {
					arrivalAirportCode: [portalData.location],
				};
			}}
			buildFilters={(distribution, icons, stats) => {
				const customFilters = {
					price: {},
					duration: {},
				};

				// represent price as a range filter
				if (stats?.price?.max && stats.price.max > 0)
					customFilters.price = {
						type: 'budget',
						min: 0,
						max: stats?.price?.max,
						name: 'budget',
						title: 'Budget',
						defaultToZero: true, // default to zero when resetting (as we don't know the future max price value)
					};

				// represent duration as a range filter
				if (stats?.duration?.max && stats.duration.max > 0)
					customFilters.duration = {
						type: 'flightDuration',
						min: stats?.duration?.min || 0,
						max: stats?.duration?.max,
						name: 'flightDuration',
						title: 'Journey Duration',
						defaultToZero: true, // default to zero when resetting (as we don't know the future max duration value)
					};

				// exclude arrival name filter on airport-specific offer pages
				const facetsToIgnore = portalData?.location ? ['arrivalName'] : [];

				return buildFacetFilters({
					distribution: distribution,
					icons: icons,
					customFilters: customFilters,
					facetsToIgnore: facetsToIgnore,
				});
			}}
			ListResultsComponent={SpecialOfferResultsList}
		/>
	);
}

FlightSpecialOfferSearch.propTypes = {
	portalData: PropTypes.object,
};

// portaled to flight-special-offer-search-page
export default withPortal(
	FlightSpecialOfferSearch,
	'flight-special-offer-search-page'
);
