import { forwardRef } from 'react';
import { cva } from 'class-variance-authority';
import * as Primitives from '@radix-ui/react-dialog';

import { cn } from '@/lib/utils';
import { Icon } from '@/components/common';

/**
 * @typedef {"rounded"|"square" | "unstyled"} variants
 **/

export const closeButtonVariants = cva(
	'absolute top-3 right-3 z-[99] flex items-center justify-center disabled:pointer-events-none disabled:opacity-75',
	{
		variants: {
			variant: {
				rounded:
					'rounded-full border border-border-color w-10 h-10 hover:bg-gray-100',
				square: 'rounded-none w-10 h-10 hover:opacity-75',
				unstyled: 'rounded-none w-fit h-auto border-0',
			},
		},
		defaultVariants: {
			variant: 'rounded',
		},
	}
);

/**
 * @typedef {Object} CloseButtonProps
 * @property {boolean} asChild
 * @property {boolean} disabled
 * @property {string} className
 * @property {React.ReactNode} children
 * @property {variants} variant
 * */

/**
 * @name CloseButton
 * @description Renders a close button
 * @param {CloseButtonProps} props
 * @param {React.ForwardedRef<HTMLButtonElement>} ref
 * @returns {JSX.Element | null}
 * @example
 * <CloseBtn variant="square" />
 */

function CloseButton(
	{ asChild, disabled, className, variant, children, ...props },
	ref
) {
	return (
		<Primitives.Close
			ref={ref}
			asChild={asChild}
			disabled={disabled}
			className={cn(closeButtonVariants({ variant, className }))}
			{...props}
		>
			{children ? children : <Icon name="close" className="w-full h-full" />}
		</Primitives.Close>
	);
}
export default forwardRef(CloseButton);
