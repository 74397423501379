import { useEffect, useRef } from 'react';

/**
 * @name useValueCallback
 * @description A hook that calls and passes a value to a callback function when the value changes
 * @param {(param: unknown)=> void} callback
 * @param {unknown} value
 * @returns {null}
 * @example
 * import { useValueCallback } from '@/hooks';
 * const [value, setValue] = useState(null);
 * const handleValue = (value) => {
 *         console.log(value);
 * };
 * useValueCallback(handleValue, value);
 */

function useValueCallback(callback, value) {
	const callbackRef = useRef();

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	useEffect(() => {
		if (typeof callbackRef.current === 'function' && value) {
			callbackRef?.current(value);
		}
	}, [value]);
	return null;
}
export default useValueCallback;
