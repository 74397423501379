import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { getNextStep } from '@/lib/utils';
import { useBackNavigate } from '@/hooks';

/**
 * @typedef {import('@/lib/steps').step[]} Steps
 */

/**
 * @name useSkipPage
 * @description A hook to skip the option plus step if it is not available on any flight direction
 * @param {Object} params
 * @param {Steps} params.steps
 * @param {boolean} params.disableSkip
 * @returns {{ skip: (forceSkip?: boolean) => void }}
 */

function useSkipPage({ steps, disableSkip, skipCallback = null }) {
	const callbackRef = useRef();
	const { pathname, state } = useLocation();

	const nextStep = getNextStep(steps, pathname);
	const { handleBack } = useBackNavigate(steps);

	const navigate = useNavigate();

	useEffect(() => {
		callbackRef.current = skipCallback;
	}, [skipCallback]);

	// skip option plus step if it is not available on any flight direction
	const skip = useCallback(
		(forceSkip = false) => {
			if (disableSkip && !forceSkip) return;

			// if coming from the next page go back to the previous page else go to the next page
			if (state?.from && state?.from.includes(nextStep?.to)) {
				window.history.pushState(null, '', state?.from);
				handleBack();
			} else {
				const url = nextStep?.search
					? `${nextStep?.to}?${nextStep?.search}`
					: nextStep?.to;

				// call the callback function if it is a function
				if (typeof callbackRef?.current === 'function') {
					callbackRef.current();
				}
				navigate(url, { state: { from: pathname } });
			}
		},
		[disableSkip, state?.from]
	);

	useEffect(() => {
		skip();
	}, [skip]);

	return { skip };
}

export default useSkipPage;
