import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { Button, Heading, Text } from '@components/common';

function Loader({
	title,
	onCancel,
	hideIcon,
	className,
	cancelLabel,
	loadingSubText,
	// disableAnimation,
}) {
	const imgUrl = '/site/assets/images/animated-maple-leaf.gif';

	return (
		<section
			className={cn(
				'h-full flex flex-col items-center justify-center bg-white gap-5',
				className
			)}
		>
			{!hideIcon && (
				<div className={cn('w-32 h-32 lg:w-48 lg:h-48')}>
					{/* <AnimatedMapleLeaf /> */}
					<img src={imgUrl} alt="animated logo" className="w-full h-full" />
				</div>
			)}
			<div className="flex flex-col items-center justify-center gap-3">
				{title && (
					<Heading as="h2" className="text-2xl font-bold font-body lg:text-3xl">
						{title}
					</Heading>
				)}
				{loadingSubText && <Text as="span">{loadingSubText}</Text>}
				{typeof onCancel === 'function' && (
					<Button
						variant="unstyled"
						onClick={onCancel}
						className="underline text-core-blue/60 underline-offset-4 hover:opacity-75"
					>
						{cancelLabel}
					</Button>
				)}
			</div>
		</section>
	);
}

Loader.propTypes = {
	onCancel: PropTypes.func,
	title: PropTypes.string,
	cancelLabel: PropTypes.string,
	loadingSubText: PropTypes.string,
	loaderClassName: PropTypes.string,
	hideIcon: PropTypes.bool,
	disableAnimation: PropTypes.bool,
	className: PropTypes.string,
};

Loader.defaultProps = {
	title: 'Loading',
	loadingSubText: 'This may take a few seconds...',
	cancelLabel: 'Cancel',
	hideIcon: false,
	disableAnimation: false,
};

export default Loader;
