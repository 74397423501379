import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import { Text, Button, Heading } from '@/components/common';
import { dateRangeFormatter } from "@/lib/utils";

function MissingBookingStepSummary({ item, className, contentClassName }) {
    return (
        <div>
            <Text className={cn(
                'flex gap-2 items-center justify-end w-full mb-4 px-5 md:px-0',
                className
            )}>
                {(item?.startDate || item?.endDate) && (
                    <Text as="span" className="text-right text-lg tracking-tighter">
                        {dateRangeFormatter({
                            start: item?.startDate,
                            end: item?.endDate,
                        })}
                    </Text>
                )}
            </Text>
            <div
                className={cn(
                    'bg-white border border-lighter-grey w-full p-5 gap-10',
                    contentClassName
                )}
            >
                <div className="flex flex-col gap-4">
                    <Heading
                        as="h3"
                        className="text-core-blue font-bold font-body text-2xl"
                    >
                        Something Went Wrong
                    </Heading>
                    <Text>
                        Apologies we couldn't display this item. Please get in touch if you need more information.
                    </Text>
                    <Button
                        hideIcon
                        tagName="a"
                        href="/about-us/contact-us"
                        target="_blank"
                        rel="noopener noreferrer"
                        label="Get in touch"
                        variant="core-blue"
                        className="w-fit"
                    />
                </div>
            </div>
        </div>
    );
}

MissingBookingStepSummary.propTypes = {
    item: PropTypes.object,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
}

export default MissingBookingStepSummary;
