import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { PasswordForm } from '@components/mycaf';

const schema = yup.object().shape({
	newPassword: yup
		.string()
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
			'Password must contain at least 8 characters, one uppercase, one lowercase and one number'
		)
		.required('Password is required'),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

function PasswordTab() {
	const { control, handleSubmit } = useForm({
		defaultValues: {
			newPassword: '',
			confirmPassword: '',
		},
		resolver: yupResolver(schema),
	});

	const loading = false; // get loading from somewhere

	const onSubmit = (details) => {
		console.log(details);
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<PasswordForm control={control} isLoading={loading} />
		</form>
	);
}
export default PasswordTab;
