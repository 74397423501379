import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import {
	Column,
	ErrorHandler,
	PageHeader,
	TravelPlanWidget,
} from '@/components/common';
import { cn } from '@/lib/utils';
import { Recommendations } from '@components/mycaf';

/**
 * @typedef {Object} BookingLayoutProps
 * @property {string} title
 * @property {React.ReactNode} children
 * @property {string} [className]
 * @property {string} [headerClassName]
 * @property {boolean} [hideRecommended]
 * @property {boolean} [hideTravelPlan]
 * @property {boolean} [isLoading]
 * @property {boolean} [widthScreen]
 * @property {()=> React.ReactNode} [renderHeaderChildren]
 */

/**
 * @name BookingLayout
 * @description A layout for booking pages
 * @param {BookingLayoutProps} props
 * @returns {React.JSX.Element}
 * @example
 * <BookingLayout title="Booking Information"><main>Hello Booking</main></BookingLayout>
 */

function BookingLayout({
	title,
	children,
	isLoading, //TODO: add loading state component
	className,
	widthScreen,
	headerClassName,
	hideRecommended,
	hideTravelPlan,
	renderHeaderChildren,
}) {
	return (
		<>
			<Column
				widthScreen={widthScreen}
				className={cn('py-10 pb-20 relative md:px-6', className)}
			>
				<div
					className={cn('w-3/4 h-full space-y-4', {
						'w-full': hideTravelPlan,
					})}
				>
					{title ? (
						<PageHeader
							title={title}
							titleClassName="text-8xl"
							className={headerClassName}
						>
							{typeof renderHeaderChildren === 'function'
								? renderHeaderChildren()
								: null}
						</PageHeader>
					) : null}
					<ErrorBoundary FallbackComponent={ErrorHandler}>
						<div>{children}</div>
					</ErrorBoundary>
				</div>
				{!hideTravelPlan ? (
					<div className="w-1/4 min-h-[40vh] bg-white sticky left-0 top-20 rounded-md overflow-hidden">
						<TravelPlanWidget category="flights" />
					</div>
				) : null}
			</Column>
			{!hideRecommended ? <Recommendations /> : null}
		</>
	);
}

BookingLayout.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	headerClassName: PropTypes.string,
	showRecommended: PropTypes.bool,
	isLoading: PropTypes.bool,
	widthScreen: PropTypes.bool,
	renderHeaderChildren: PropTypes.func,
};

BookingLayout.defaultProps = {
	className: '',
	isLoading: false,
	headerClassName: '',
	showRecommended: true,
	widthScreen: false,
	renderHeaderChildren: null,
};

export default BookingLayout;
