import { useMemo } from 'react';

import {
	Icon,
	Text,
	RadioGroup,
	LoopWrapper,
	RteStyledContent,
} from '@/components/common';
// import { useFareUpdate } from '@/hooks';
import { cn, currencyFormatter } from '@/lib/utils';
import { useBookingStore } from '@/store';

const cabinFeatures = [
	{
		label: 'Baggage',
		icon: 'baggage',
		apiFields: ['hold_baggage_details', 'hand_baggage_details'],
	},
	{
		label: 'Seat selection',
		icon: 'seat',
		apiFields: ['seat_selection_details'],
	},
	{
		label: 'Changes',
		icon: 'changes',
		apiFields: ['changes_details'],
	},
	{
		label: 'Cancellations',
		icon: 'cancellation',
		apiFields: ['cancellation_details'],
	},
];

function FlightOptions({
	value,
	flight,
	onSelect,
	// instance,
	selectedOptions = [],
}) {
	const state = useBookingStore((store) => store.flights);
	// restrict to 9 columns
	const options = useMemo(() => {
		if (!selectedOptions) return [];
		if (selectedOptions?.length === 0) return [];

		if (selectedOptions?.length > 8) return selectedOptions?.slice(0, 8);

		return selectedOptions.map((option) => ({
			...option,
			optionKey: `${flight?.key}-${option?.cabin?.code}`,
		}));
	}, [selectedOptions]);

	const totalCols = options?.length + 1;

	return (
		<div className="flex-col items-center justify-center hidden w-full h-auto gap-3 px-6 py-4 bg-white lg:flex">
			<div
				className={`w-full h-auto gap-4 border-b grid grid-cols-${totalCols} py-2`}
			>
				<div className="flex items-end col-span-1">
					<Text as="span" className="text-dark-grey/70">
						Select a flight and fare
					</Text>
				</div>
				<LoopWrapper list={options} itemKey="optionKey">
					{(option) => (
						<div className="col-span-1">
							<RadioGroup
								hideLabel
								value={value}
								className="flex flex-col items-start w-full gap-2"
								disabled={state?.isLoading || state?.previewLoading}
								fieldWrapperClassName="w-full flex-col items-start gap-2"
								onChange={(value) => {
									if (typeof onSelect !== 'function') return;
									onSelect(value, flight);
								}}
								options={[
									{
										label: option?.cabin?.displayName || option?.cabin?.name,
										value: option?.optionKey,
									},
								]}
								renderOptionChildren={() => (
									<div className="flex flex-col items-start">
										<Text as="span" className="text-2xl font-bold capitalize">
											{option?.cabin?.displayName || option?.cabin?.name}
										</Text>
										<Text as="span" className="text-2xl font-bold">
											{currencyFormatter({
												amount: parseFloat(option?.pricing?.adult),
											})}
										</Text>
									</div>
								)}
							/>
						</div>
					)}
				</LoopWrapper>
			</div>

			{options?.length > 0 ? (
				<div className="flex flex-col w-full gap-2">
					{cabinFeatures.map((feature, idx) => (
						<div
							key={`details-${idx}`}
							className={cn(
								`w-full grid grid-cols-${totalCols} border-b last:border-b-0 gap-4 py-2`,
								idx + 1 !== cabinFeatures?.length ? 'pb-4' : ''
							)}
						>
							<div className="flex items-center justify-start col-span-1 gap-2 break-all">
								<Icon name={feature?.icon} className="w-5 h-5 min-w-min" />
								<Text as="span" className="break-words">
									{feature?.label}
								</Text>
							</div>

							<LoopWrapper list={options}>
								{(option) => (
									<div className="flex flex-col items-start justify-center col-span-1 gap-2">
										<LoopWrapper list={feature?.apiFields}>
											{(field) => (
												<RteStyledContent
													variant="dark"
													className="flight-info"
													dangerouslySetInnerHTML={{
														__html: option.cabin[field],
													}}
												/>
											)}
										</LoopWrapper>
									</div>
								)}
							</LoopWrapper>
						</div>
					))}
				</div>
			) : null}
		</div>
	);
}

export default FlightOptions;
