import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { addYears, format, isDate, isEqual, set } from 'date-fns';
import { Button, Drawer, Dialog } from '@/components/common';
import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks';
import DateRangeTrigger from './DateRangeTrigger';
import DateRangeContent from './DateRangeContent';
import { DrawerClose } from '@components/common/atoms/drawer/CnDrawer';

const DateRangePicker = forwardRef(
	(
		{
			value,
			onChange,
			className,
			isValid,
			hideTime,
			disabledDays,
			useCurrentTime = true,
			setHasSelectedTime,
		},
		ref
	) => {
		const [selectedDate, setSelectedDate] = useState({});
		const [selectedTime, setSelectedTime] = useState(
			useCurrentTime
				? {
						to: {
							hours: format(new Date(), 'HH'),
							minutes: format(new Date(), 'mm'),
						},
						from: {
							hours: format(new Date(), 'HH'),
							minutes: format(new Date(), 'mm'),
						},
				  }
				: undefined
		);

		const handleRangeChange = (date) => {
			// reset the 'to' date when the 'to' & 'from' dates are already selected
			if (value?.from && value?.to) {
				const chosenDate =
					date?.from && isEqual(date.from, value.from) ? date?.to : date?.from;

				setSelectedDate({
					from: chosenDate, // set the new 'from' date to whatever date was just chosen
					to: undefined, // deselect the 'to' date
				});
				return;
			}

			setSelectedDate(date);
		};

		const handleTimeChange = (time, key) => {
			const newTime = { ...selectedTime };

			switch (key) {
				case 'to':
					newTime.to = time;
					break;
				case 'from':
					newTime.from = time;
					break;
			}

			setSelectedTime(newTime);
		};

		useEffect(() => {
			// handle prefill
			if (selectedDate?.to || selectedDate?.from) return;
			if (!(value?.to && value?.from)) return;

			// prefill selected date
			setSelectedDate(value);

			// prefill selected time
			if (!useCurrentTime) return;

			const defaultTime = { ...selectedTime };
			if (isDate(value?.to)) {
				defaultTime.to = {
					hours: format(value.to, 'HH'),
					minutes: format(value.to, 'mm'),
				};
			}

			if (isDate(value?.from)) {
				defaultTime.from = {
					hours: format(value.from, 'HH'),
					minutes: format(value.from, 'mm'),
				};
			}

			setSelectedTime(defaultTime);
		}, [value?.to, value?.from]);

		useEffect(() => {
			// update field value when internal time/date states change
			const newSelected =
				typeof selectedDate === 'object' ? { ...selectedDate } : {};

			if (isDate(selectedDate?.from) && selectedTime?.from?.hours) {
				newSelected.from = set(selectedDate.from, {
					hours: selectedTime.from.hours,
					minutes: selectedTime.from.minutes || '00',
				});
			}

			if (isDate(selectedDate?.to) && selectedTime?.to?.hours) {
				newSelected.to = set(selectedDate.to, {
					hours: selectedTime.to.hours,
					minutes: selectedTime.to.minutes || '00',
				});
			}

			if (selectedTime?.from && selectedTime?.to) {
				setHasSelectedTime && setHasSelectedTime(true);
			}

			onChange(newSelected);
		}, [
			selectedDate?.from,
			selectedDate?.to,
			selectedTime?.from,
			selectedTime?.to,
		]);

		const isSmallDevice = useBreakpoint('md');

		// add a month counter for the mobile calendar
		const [noOfMonths, setNoOfMonths] = useState(6);

		const isMaxMonths = noOfMonths >= 24;
		const loadMoreMonths = () => {
			// set max to 2 years - 24 months
			if (isMaxMonths) return;
			setNoOfMonths(noOfMonths + 4);
		};

		return (
			<div className={cn('grid w-full gap-2', className)} ref={ref}>
				{isSmallDevice ? (
					<Drawer
						dismissable={false}
						contentClassName="p-2"
						renderTrigger={() => (
							<DateRangeTrigger
								value={value}
								isValid={isValid}
								hideTime={hideTime}
								selectedTime={selectedTime}
								useCurrentTime={useCurrentTime}
							/>
						)}
					>
						<div
							className={cn(
								'max-h-[70dvh] h-full flex flex-col',
								hideTime && 'overflow-y-scroll'
							)}
						>
							<DateRangeContent
								value={value}
								hideTime={hideTime}
								numberOfMonths={noOfMonths}
								disabledDays={disabledDays}
								selectedTime={selectedTime}
								handleTimeChange={handleTimeChange}
								handleRangeChange={handleRangeChange}
								fromYear={new Date().getFullYear()}
								toYear={addYears(new Date(), 2).getFullYear()}
								hideArrows
								isMaxMonths={isMaxMonths}
								loadMoreMonths={loadMoreMonths}
								useCurrentTime={useCurrentTime}
							/>
						</div>

						<hr className="my-4 border-lighter-grey" />
						<DrawerClose asChild>
							<Button variant="supporting-yellow" className="w-full">
								Confirm
							</Button>
						</DrawerClose>
					</Drawer>
				) : (
					<Dialog
						as="modal"
						size="2xl"
						hideCloseBtn
						contentClassName="w-fit bg-white p-8"
						renderTrigger={({ DialogTrigger, onOpen }) => (
							<DialogTrigger onClick={onOpen}>
								<DateRangeTrigger
									value={value}
									isValid={isValid}
									hideTime={hideTime}
									selectedTime={selectedTime}
									useCurrentTime={useCurrentTime}
								/>
							</DialogTrigger>
						)}
					>
						{({ CloseButton, onClose }) => (
							<div className="w-full">
								<CloseButton
									variant="square"
									className="absolute top-0 right-0 text-white bg-core-blue"
								/>
								<DateRangeContent
									value={value}
									hideTime={hideTime}
									numberOfMonths={2}
									disabledDays={disabledDays}
									selectedTime={selectedTime}
									handleTimeChange={handleTimeChange}
									handleRangeChange={handleRangeChange}
									useCurrentTime={useCurrentTime}
								/>

								<Button
									variant="supporting-yellow"
									onClick={onClose}
									className="text-base font-bold"
								>
									Confirm
								</Button>
							</div>
						)}
					</Dialog>
				)}
			</div>
		);
	}
);

DateRangePicker.propTypes = {
	value: PropTypes.shape({
		from: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onChange: PropTypes.func,
	className: PropTypes.string,
	isValid: PropTypes.bool,
	hideTime: PropTypes.bool,
	disabledDays: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

DateRangePicker.defaultProps = {
	isValid: false,
	hideTime: false,
	disabledDays: {
		before: new Date(),
	},
};

export default DateRangePicker;
