import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

/**
 * @typedef {Object} ImageProps
 * @property {number} width - The width of the image
 * @property {number} height - The height of the image
 * @property {React.HTMLProps<import("react-lazy-load-image-component").LazyLoadImageProps>} imgProps - The props for the image
 */

/**
 * @name Image
 * @description A lazy loaded component for images
 * @param {ImageProps} props
 * @returns {React.JSX.Element}
 * @example
 * <Image src={image.src} alt={image.alt} />
 */

function Image({ children, width = 500, height = 500, ...imgProps }) {
	return (
		<LazyLoadImage
			width={width}
			effect="blur"
			height={height}
			wrapperClassName="w-full h-full"
			placeholder={
				<div
					className="w-full h-full bg-gray-50 animate-pulse"
					style={{ width, height }}
				/>
			}
			{...imgProps}
		/>
	);
}
export default Image;
