import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text } from '@/components/common';
import useClipboard from 'react-use-clipboard';
import { cn } from '@/lib/utils';

function SaveAndShare({ label, copiedLabel }) {
	const [isCopied, setCopied] = useClipboard(window.location, {
		successDuration: 5000,
	});

	return (
		<button
			type="button"
			className={cn(
				'flex shrink grow items-center gap-2 transition-all transition-300 opacity-100',
				isCopied ? 'text-core-light-blue' : 'hover:opacity-75'
			)}
			onClick={setCopied}
			disabled={isCopied}
		>
			<Icon name={isCopied ? 'tick' : 'share'} />
			<Text as="span">{isCopied ? copiedLabel : label}</Text>
		</button>
	);
}

SaveAndShare.propTypes = {
	label: PropTypes.string,
	copiedLabel: PropTypes.string,
};

SaveAndShare.defaultProps = {
	label: 'Share Results',
	copiedLabel: 'Copied To Clipboard!',
};

export default SaveAndShare;
