import PropTypes from 'prop-types';
import { FlightCard } from '@components/flights';
import { CheckButton, Text } from '@/components/common';
import { formatLocalDate } from '@/lib/utils';

function FlightBlockListItemDetails({
	flightType,
	sectors,
	selectedFlights,
	handleSelectFlight,
}) {
	return (
		<section className="bg-lightish-grey w-full min-h-[20vh] mt-5 py-5 px-5 border-b-5 border-core-blue lg:px-10">
			<div>
				<Text className="my-3">
					Please select your{' '}
					<strong className="underline underline-offset-4">{flightType}</strong>{' '}
					flight to view return flight options
				</Text>
				{sectors.map((flight) => (
					<div
						key={flight?.flightNumber}
						className="flex flex-col w-full gap-2 mb-5 lg:mb-0 last:mb-0"
					>
						<div className="flex items-center gap-2">
							{flight?.departureTime ? (
								<Text className="font-bold">
									{formatLocalDate(flight?.departureTime, 'dd MMM')}
								</Text>
							) : null}
							<Text className="flex items-center gap-1 text-sm">
								<Text as="span" className="text-sm">
									{flight?.carrier?.code}
								</Text>
								<Text as="span" className="text-sm">
									{flight?.flightNumber}
								</Text>
							</Text>
						</div>
						<div className="flex flex-col items-start gap-2 lg:flex-row lg:items-center lg:gap-5">
							<FlightCard
								hideFlightLogo
								flight={flight}
								hideMoreInfoToggle
								className="flex-1"
							/>
							<div className="flex flex-col items-start  gap-3 lg:flex-row lg:items-center lg:gap-5">
								{/* TODO: show fare information */}
								<Text>Fare information goes here</Text>
								<CheckButton
									checked={selectedFlights.outbound?.id === flight.id}
									onChange={() => handleSelectFlight('outbound', flight)}
									renderText={(checked) => (
										<Text>{checked ? 'Selected' : 'Select'}</Text>
									)}
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		</section>
	);
}

FlightBlockListItemDetails.propTypes = {
	flightType: PropTypes.oneOf(['outbound', 'return']).isRequired,
	sectors: PropTypes.array,
};

FlightBlockListItemDetails.defaultProps = {
	sectors: [],
};

export default FlightBlockListItemDetails;
