import PropTypes from 'prop-types';
import { SearchResultsList } from '@/components/common';
import { SearchResultCard } from '@/components/find-my-holiday';

function FindMyHolidayResultsList({ pages }) {
	return (
		<SearchResultsList
			pages={pages}
			ResultCardComponent={SearchResultCard}
			className="md:grid-cols-2 xl:grid-cols-3 xl:gap-5"
		/>
	);
}

FindMyHolidayResultsList.propTypes = {
	pages: PropTypes.array,
};

FindMyHolidayResultsList.defaultProps = {
	pages: [],
};

export default FindMyHolidayResultsList;
