import React from 'react';
import PropTypes from 'prop-types';

import { cn } from '@/lib/utils';
import { Icon, Text, Heading } from '@/components/common';

const features = [
	'Unlimited Mileage',
	'Loss damage waiver',
	'Up to $1million 3rd party liability coverage',
	'Zero excess',
	'All applicable taxes',
	'All airport surcharges - all airport pick ups are on site',
];

/**
 * @typedef {Object} MotorHomeInfoCardProps
 * @property {Object | null} motorhome
 * @property {string} className
 **/

/**
 * @name MotorHomeInfoCard
 * @description A summary of the motorhome details
 * @param {MotorHomeInfoCardProps} props
 * @returns {React.JSX.Element}
 * @example
 * <MotorHomeInfoCard motorhome={motorhome} />
 * */

function MotorHomeInfoCard({ motorhome, className }) {
	return (
		<div
			className={cn(
				'bg-white w-full p-5 grid grid-cols-1 lg:grid-cols-3',
				className
			)}
		>
			<div className="col-span-1 lg:col-span-2">
				<Heading
					as="h3"
					className="text-core-blue font-bold font-body text-2xl"
				>
					C25 Standard Motorhome
				</Heading>

				<div>
					<Text>Summary: 5 People</Text>
					<Text>Bed Summary: Cab Over, Dinette & Rear bed</Text>
				</div>

				<div className=" col-span-1 w-36 h-36 lg:hidden flex items-start">
					<img
						className="self-stretch h-full w-full object-contain"
						src="https://www.carthago.com/fileadmin/_processed_/6/2/csm_2022_Carthago_c-tourer_T143LE-Fiat_vV-dyn-BFS_fc087a5008.png"
					/>
				</div>

				<div className="mt-5">
					<ul>
						{features.map((feature) => (
							<li key={feature} className="list-none">
								<Text variant="muted" className="flex gap-2 items-center">
									<Icon name="maple-leaf" />
									<Text as="span">{feature}</Text>
								</Text>
							</li>
						))}
					</ul>
				</div>
			</div>
			<div className="hidden col-span-1 w-full lg:block">
				<img
					className="self-stretch h-full w-full object-contain"
					src="https://www.carthago.com/fileadmin/_processed_/6/2/csm_2022_Carthago_c-tourer_T143LE-Fiat_vV-dyn-BFS_fc087a5008.png"
				/>
			</div>
		</div>
	);
}

MotorHomeInfoCard.propTypes = {
	motorhome: PropTypes.object.isRequired,
	className: PropTypes.string,
};

MotorHomeInfoCard.defaultProps = {
	className: '',
};

export default MotorHomeInfoCard;
