import { useCallback, useState } from 'react';

/**
 * @typedef {Object} seatAssignReturns
 * @property {Array} assignedSeats
 * @property {(param: Object)=>void} assignSeat
 * @property {(param: string)=>void} unassignSeat - unassign a seat by passing the seat number
 * @property {()=>void} unassignAll - unassign all seats
 */

/**
 * @name useSeatAssign
 * @description custom hook to assign seats to passengers
 * @param {Array} pax
 * @param {Array} selectedSeats
 * @returns {seatAssignReturns}
 */

function useSeatAssign(pax, selectedSeats) {
	const [assignedSeats, setAssignedSeats] = useState(() => selectedSeats || []);

	const assignSeat = useCallback(
		(seat) => {
			if (!pax.length || !seat) return;

			// filter out unassigned pax

			const unassigned = pax.filter((px) => {
				return !assignedSeats.find((seat) => seat?.paxRef === px?.ref);
			});

			const toAssign = unassigned[0]; // assign first unassigned pax

			if (!toAssign) return;

			const { seatNumber, price } = seat;
			const match = seatNumber.match(/(\d+)([A-Z])/);

			if (!match) return;

			const [row, col] = match.slice(1);

			const newSeat = {
				seat: seatNumber,
				row,
				col,
				price,
				paxRef: toAssign.ref,
				isMiddleSeat: seat?.isMiddleSeat,
			};

			setAssignedSeats((prev) => [...prev, newSeat]);
		},
		[pax, assignedSeats]
	);

	const unassignSeat = useCallback((seatNo) => {
		setAssignedSeats((prev) => prev.filter((seat) => seat?.seat !== seatNo));
	}, []);

	const unassignAll = useCallback(() => {
		setAssignedSeats([]);
	}, []);

	return {
		assignedSeats,
		assignSeat,
		unassignSeat,
		unassignAll,
	};
}
export default useSeatAssign;
