import * as React from 'react';
import PropTypes from 'prop-types';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { cn } from '@/lib/utils';
import {
	Text,
	Carousel,
	CarouselContent,
	CarouselNext,
	CarouselPrevious,
	CarouselItem,
} from '@components/common';
import RadioGroupItem from './RadioGroupItem';

/**
 * @typedef {Object} RadioGroupProps
 * @property {string} className
 * @property {string} itemClassName
 * @property {string} wrapperClassName
 * @property {Array<{label: string, value: string, disabled: boolean}>} options
 * @property {Function} renderOptionChildren
 * @property {string} defaultValue
 * @property {boolean} isValid
 * @property {boolean} hideLabel
 * @property {()=>void} onChange
 * @property {boolean} showCarousel
 * */

/**
 * @name RadioGroup
 * @description A radio group component
 * @param {RadioGroupProps} props
 * @returns {React.JSX.Element}
 * @example
 * <RadioGroup />
 * */

const RadioGroup = React.forwardRef((props, ref) => {
	const {
		options,
		className,
		defaultValue,
		itemClassName,
		itemLabelClassName,
		isValid = true,
		fieldWrapperClassName,
		hideLabel = false,
		showCarousel = false,
		onChange = () => {},
		renderOptionChildren = () => null,
		...rest
	} = props;
	return (
		<RadioGroupPrimitive.Root
			ref={ref}
			className={cn(
				'grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3',
				className
			)}
			value={defaultValue}
			onValueChange={onChange}
			{...rest}
		>
			{showCarousel && (
				<Carousel className="w-full p-4" opts={{ loop: true }}>
					<CarouselContent className="h-full">
						{options.map((option) => (
							<CarouselItem
								key={option.value}
								className="md:basis-1/3 lg:basis-1/3"
							>
								<Option
									option={option}
									isValid={isValid}
									hideLabel={hideLabel}
									itemClassName={itemClassName}
									itemLabelClassName={itemLabelClassName}
									wrapperClassName={fieldWrapperClassName}
									renderOptionChildren={renderOptionChildren}
								/>
							</CarouselItem>
						))}
					</CarouselContent>

					<CarouselPrevious
						variant="outline"
						className={cn(
							'left-0 -translate-y-1/2 h-12 w-12 p-3 bg-white border border-gray-400 rounded-full'
						)}
					/>
					<CarouselNext
						variant="outline"
						className={cn(
							'right-0 -translate-y-1/2 h-12 w-12 p-3 bg-white border border-gray-400 rounded-full'
						)}
					/>
				</Carousel>
			)}
			{!showCarousel &&
				options.map((option) => (
					<Option
						option={option}
						isValid={isValid}
						key={option.value}
						hideLabel={hideLabel}
						itemClassName={itemClassName}
						itemLabelClassName={itemLabelClassName}
						wrapperClassName={fieldWrapperClassName}
						renderOptionChildren={renderOptionChildren}
					/>
				))}
		</RadioGroupPrimitive.Root>
	);
});

function Option({
	option,
	itemClassName,
	itemLabelClassName,
	wrapperClassName,
	isValid,
	hideLabel,
	renderOptionChildren,
}) {
	return (
		<div
			key={option.value}
			className={cn('flex items-center gap-4 w-full', wrapperClassName, {
				'text-core-red': !isValid,
			})}
		>
			<RadioGroupItem
				id={option.id || option.value}
				value={option.value}
				className={itemClassName}
				disabled={option.disabled}
			/>

			<Text
				as="label"
				htmlFor={option.id || option.value}
				className={cn(
					'flex flex-col items-start flex-1 gap-2 cursor-pointer grow shrink basis-0 md:items-center',
					itemLabelClassName
				)}
			>
				{option.label && !hideLabel && <Text>{option.label}</Text>}
				{renderOptionChildren(option)}
			</Text>
		</div>
	);
}

RadioGroup.propTypes = {
	className: PropTypes.string,
	itemClassName: PropTypes.string,
	fieldWrapperClassName: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
			disabled: PropTypes.bool,
		})
	),
	renderOptionChildren: PropTypes.func,
	defaultValue: PropTypes.string,
	isValid: PropTypes.bool,
	hideLabel: PropTypes.bool,
	onChange: PropTypes.func,
	showCarousel: PropTypes.bool,
};

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
