import HotelCard from '@components/hotels/molecules/card';
import { Button, SortBar } from '@/components/common';
import { useSortBar } from '@/hooks';

function SearchList({ results }) {
	const list = new Array(5).fill(0);
	const sortOptions = [
		{
			label: 'Most Popular',
			value: 'popular',
		},
	];

	const pageOptions = [
		{
			label: '10',
			value: '10',
		},
		{
			label: '20',
			value: '20',
		},
	];

	const { sort, perPage } = useSortBar(
		'holiday_filters',
		sortOptions,
		pageOptions
	);

	return (
		<div className="flex flex-col gap-6 px-7 md:px-0 ">
			{sort && perPage && (
				<SortBar
					sortOptions={sortOptions}
					pageOptions={pageOptions}
					paramsKey="holiday_filters"
					defaultSort={sort}
					defaultPerPage={perPage}
				/>
			)}
			<div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-1 lg:gap-12">
				{list.map((_, i) => (
					<HotelCard key={i} />
				))}
			</div>

			<div className="flex items-center justify-center">
				<Button
					variant="outline"
					className="border-lightest-grey text-lightest-grey font-semibold hover:bg-core-blue hover:text-white"
				>
					Load More
				</Button>
			</div>
		</div>
	);
}
export default SearchList;
