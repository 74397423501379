import { useBreakpoint } from '@/hooks';
import { DatePlaceHolder } from '@components/common';

function QueryDetails({ params, QueryItem }) {
	const isMd = useBreakpoint('md');
	const location = params?.location
		? params?.location.split(':')[1]
		: 'Select Location';
	const rooms = params?.rooms ? params?.rooms.length : 0;

	let people = 0;

	params?.rooms?.map((room) => {
		people +=
			parseInt(room.adults) + parseInt(room.children) + parseInt(room.infants);
	});

	return (
		<div className="flex flex-wrap items-center justify-between w-full h-full gap-2 px-2 py-2 lg:py-0">
			<QueryItem label="Location" value={location} />
			<QueryItem
				label="Rooms"
				value={
					typeof rooms === 'undefined'
						? 'Select Rooms'
						: `${rooms} ${rooms > 1 ? 'rooms' : 'room'}`
				}
			/>
			<QueryItem
				icon="users"
				value={people}
				className="flex flex-row items-center gap-3 md:hidden"
			/>
			<QueryItem
				label="Dates"
				className="col-span-2 md:col-span-1"
				value={() => (
					<DatePlaceHolder
						hideTime
						selected={{
							from: new Date(params.startDate),
							to: new Date(params.endDate),
						}}
					/>
				)}
			/>
			<QueryItem
				icon="users"
				value={people}
				className="flex-row items-center hidden gap-3 md:flex"
			/>
		</div>
	);
}

export default QueryDetails;
