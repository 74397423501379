import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    Text,
    Dialog,
    Button,
    Heading,
    FormField,
    NullTerniaryWrapper,
    Alert,
} from '@components/common';
import { useForm } from 'react-hook-form';
import { TITLE_OPTIONS } from '@/lib/utils';
import { useBookingStore } from '@/store';


/**
 * @typedef {Object} SaveForLaterProps
 * @property {"hotels" | "holidays" | "motorhome-hire" | "car-hire" | "flights"} category
 **/

/**
 * @name SaveForLater
 * @description A widget that shows the travel plan for specific categories
 * @param {SaveForLaterProps} props
 * @param {React.ForwardedRef<HTMLDivElement>} ref
 * @returns {React.JSX.Element}
 * @example
 * <SaveForLater
 * 	 category="hotels"
 * />
 */

const validationSchema = yup.object().shape({
    title: yup
        .object()
        .shape({
            label: yup.string(),
            value: yup.string(),
        })
        .test(
            'required-select',
            'Title is required',
            (title) => title?.label && title?.value ? true : false
        )
        .required('Title is required'),
    firstName: yup
        .string()
        .required('First name is required')
        .min(2, 'Name should be at least two characters')
        .max(50, 'Name should be less than 50 characters'),
    middleName: yup.string(),
    lastName: yup
        .string()
        .required('Last name is required')
        .min(2, 'Name should be at least two characters')
        .max(50, 'Name should be less than 50 characters'),
    phone: yup
        .string()
        .matches(/^[0-9]{10,11}$/, 'Please enter a valid UK telephone number')
        .required('Telephone number is required'),
    email: yup
        .string()
        .email('Please enter a valid email address')
        .required('Email address is required'),
});

function SaveForLater({ category }) {
    const [submitted, setSubmitted] = useState(false);
    const { setBookingState, submitQuote } = useBookingStore();
    const bookingState = useBookingStore((state) => state[category]);

    // prefill default values from session data
    const defaultValues = useMemo(() => {
        let leadPax = {};
        let phone = '';
        let email = '';

        // pull leading pax from passenger details, if provided
        if (bookingState?.passengerDetails?.passengers?.length) {
            leadPax = bookingState.passengerDetails.passengers[0];
            phone = bookingState.passengerDetails?.contact?.phone;
            email = bookingState.passengerDetails?.contact?.email;
        }

        // pull leading pax from driver details, if provided
        if (bookingState?.driverDetails?.driver) {
            leadPax = bookingState.driverDetails.driver;
            phone = bookingState.driverDetails?.contact?.phone;
            email = bookingState.driverDetails?.contact?.email;
        };

        let title = {};
        if (leadPax?.title) {
            const leadPaxTitle = typeof leadPax.title === 'string' ?
                leadPax.title :
                leadPax?.title?.value;
            const titleOption = TITLE_OPTIONS.find((opt) => opt.value === leadPaxTitle);
            if (titleOption?.value) title = titleOption;
        }

        return {
            title: title,
            firstName: leadPax?.firstName || '',
            middleName: leadPax?.middleName || '',
            lastName: leadPax?.lastName || '',
            phone: phone || '',
            email: email || '',
        };
    }, [bookingState?.passengerDetails, bookingState?.driverDetails]);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues,
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (values) => {
        setSubmitted(true); // show errors if present

        setBookingState(category, {
            quoteReceiver: {
                ...values,
                title: values?.title?.value,
            },
        });

        submitQuote(category);
    };

    return (
        <Dialog
            as="modal"
            size="lg"
            hideCloseBtn
            position="center"
            contentClassName="p-0"
            disableClose={bookingState?.isSubmittingQuote || false}
            renderTrigger={({ DialogTrigger, onOpen }) => (
                <DialogTrigger
                    onClick={onOpen}
                >
                    <Button
                        tagName="span"
                        variant="unstyled"
                        disableAnimation
                        iconName="heart"
                        className="flex-row-reverse hover:opacity-75"
                        label="Save for later"
                        disabled={bookingState?.isLoading || false}
                    />
                </DialogTrigger>
            )}
        >
            {({ CloseButton }) => (
                <div className="min-h-48 max-h-[85vh] overflow-y-auto relative">
                    <CloseButton
                        className="absolute top-0 right-0 bg-core-blue text-white w-12 h-12"
                        variant="square"
                        disabled={bookingState?.isSubmittingQuote || false}
                    />
                    <div className="flex flex-col items-start justify-center gap-4 p-12 px-8 md:px-12">
                        <div className="flex flex-col justify-center items-center mx-auto">
                            <Heading as="h2" className="text-5xl md:text-7xl lg:text-10xl font-body lg:font-display font-bold leading-tight">
                                Save for later
                            </Heading>
                            <Text className="text-base md:text-lg lg:text-2xl text-dark-grey">
                                Get this quote emailed to you.
                            </Text>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col space-y-4  px-0 md:px-0">
                            <NullTerniaryWrapper condition={submitted && !!bookingState?.error} animate>
                                <Alert
                                    className="w-full mb-2"
                                    variant="destructive"
                                    title="Houston, we have a problem!"
                                    subtitle="Apologies, we have encountered an error with your request. Please <a href='mailto:enquiries@canadianaffair.com?subject=Website%20Error%20Query' target='_top' class='underline underline-offset-2'>email us</a> for assistance."
                                    showHtmlSubtitle={true}
                                >
                                    {bookingState?.error?.message}
                                </Alert>
                            </NullTerniaryWrapper>

                            <FormField
                                as="select"
                                name="title"
                                label="Title"
                                control={control}
                                errors={errors}
                                showRequired
                                options={TITLE_OPTIONS}
                            />

                            <FormField
                                as="input"
                                name="firstName"
                                label="First name"
                                autoComplete="given-name"
                                errors={errors}
                                control={control}
                                showRequired
                            />

                            <FormField
                                as="input"
                                name="middleName"
                                label="Middle name"
                                autoComplete="additional-name"
                                errors={errors}
                                control={control}
                            />

                            <FormField
                                as="input"
                                name="lastName"
                                label="Last name"
                                autoComplete="family-name"
                                errors={errors}
                                control={control}
                                showRequired
                            />

                            <FormField
                                as="input"
                                name="email"
                                type="email"
                                label="Email address"
                                placeholder="Email address"
                                autoComplete="email"
                                control={control}
                                errors={errors}
                                showRequired
                            />

                            <FormField
                                as="input"
                                name="phone"
                                label="Telephone"
                                placeholder="Telephone"
                                autoComplete="tel"
                                control={control}
                                errors={errors}
                                showRequired
                            />

                            <Button
                                type="submit"
                                variant="supporting-yellow"
                                className="w-fit"
                                disabled={bookingState?.isSubmittingQuote || false}
                            >
                                {bookingState?.isSubmittingQuote ? 'Saving quote...' : 'Save for later'}
                            </Button>
                        </form>
                    </div>
                </div>
            )}
        </Dialog>
    );
}
SaveForLater.propTypes = {
    category: PropTypes.oneOf([
        "hotels",
        "holidays",
        "motorhome-hire",
        "car-hire",
        "flights",
    ])
}

export default SaveForLater;
