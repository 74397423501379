import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { cn, randomString } from '@/lib/utils';
import { BookingCard } from '@components/mycaf';
import { FlightCard } from '@components/flights';
import { Button, buttonVariants } from '@components/common';

/**
 * @typedef {Object} BookingsListProps
 * @property {boolean} isOverview
 */

/**
 * @name BookingsList
 * @description A list of bookings
 * @param {BookingsListProps} props
 * @returns {React.JSX.Element}
 * @example
 * <BookingsList isOverview />
 */

function BookingsList({ isOverview }) {
	const limit = isOverview ? 3 : 6;
	const bookings = Array.from({ length: limit }).map((item) => ({
		id: item + 1,
		bookingRef: randomString('cafbk-', 10),
	}));

	const { outbound } = useMemo(() => {
		const outbound = flightData.filter((flight) => flight.type === 'outbound');
		const returns = flightData.filter((flight) => flight.type === 'return');
		return { outbound, returns };
	}, [flightData]);
	return (
		<section className="flex flex-col gap-8 mt-2">
			{bookings.map((booking) => (
				<BookingCard
					label="Flights"
					key={booking?.id}
					category="flights"
					bookingRef={booking?.bookingRef}
				>
					<FlightCard
						hideMoreInfoToggle
						flight={outbound[0]}
						detailsClassName="bg-white"
					/>
					<Link
						to={`/my-canadian-affair/bookings/${booking?.bookingRef}`}
						className={cn('w-fit', buttonVariants({ variant: 'outline' }))}
					>
						View Booking
					</Link>
				</BookingCard>
			))}
			<div className="flex items-center justify-center w-full">
				{isOverview ? (
					<Link
						className={cn(
							buttonVariants({
								variant: 'outline',
							})
						)}
						to="/my-canadian-affair/bookings"
					>
						See all bookings
					</Link>
				) : (
					<Button
						hideIcon
						disableAnimation
						label="Load More Bookings"
						onClick={() => console.log('loading more ...')}
					/>
				)}
			</div>
		</section>
	);
}

const flightData = [
	{
		type: 'outbound',
		flightNo: '283',
		departure: 'LGW',
		arrival: 'YVR',
		departureTime: '2023-09-01T09:50:00+00:00',
		arrivalTime: '2023-09-01T19:26:00+00:00',
		duration: '13hrs 1mins',
		stops: '0',
		flightNumber: '283',
		carrier: 'TS',
		aircraft: '',
		cabin: {
			code: 'CLUB',
			name: 'Club ',
		},
		pricing: {
			adult: '1300.63',
			child: '0.00',
			infant: '0.00',
		},
		sectors: [
			{
				departure: 'LGW',
				arrival: 'YYZ',
				departureTime: '2023-09-01T09:50:00+00:00',
				arrivalTime: '2023-09-01T12:45:00+00:00',
				duration: null,
				stops: '',
				flightNumber: '283',
				carrier: 'TS',
				aircraft: '32Q',
				cabin: {
					code: 'CLUB',
					name: 'Club ',
				},
				pricing: {
					adult: '',
					child: '',
					infant: '',
				},
				isSeatResPossible: false,
			},
			{
				departure: 'YYZ',
				arrival: 'YVR',
				departureTime: '2023-09-01T17:20:00+00:00',
				arrivalTime: '2023-09-01T19:26:00+00:00',
				duration: null,
				stops: '',
				flightNumber: '7909',
				carrier: 'TS',
				aircraft: '295',
				cabin: {
					code: 'CLUB',
					name: 'Club ',
				},
				pricing: {
					adult: '',
					child: '',
					infant: '',
				},
				isSeatResPossible: false,
			},
		],
	},

	{
		type: 'return',
		flightNo: '282',
		departure: 'YYZ',
		arrival: 'LGW',
		departureTime: '2023-09-06T19:50:00+00:00',
		arrivalTime: '2023-09-07T07:55:00+00:00',
		duration: '7hrs 5mins',
		stops: '0',
		flightNumber: '282',
		carrier: 'TS',
		aircraft: '',
		cabin: {
			code: 'CLUB',
			name: 'Club ',
		},
		pricing: {
			adult: '1038.72',
			child: '0.00',
			infant: '0.00',
		},
		isSeatResPossible: false,
		sectors: [
			{
				departure: 'YYZ',
				arrival: 'LGW',
				departureTime: '2023-09-06T19:50:00+00:00',
				arrivalTime: '2023-09-07T07:55:00+00:00',
				duration: null,
				stops: '',
				flightNumber: '282',
				carrier: 'TS',
				aircraft: '32Q',
				cabin: {
					code: 'CLUB',
					name: 'Club ',
				},
				pricing: {
					adult: '',
					child: '',
					infant: '',
				},
				isSeatResPossible: false,
			},
		],
	},
];

BookingsList.propTypes = {
	isOverview: PropTypes.bool,
};

export default BookingsList;
