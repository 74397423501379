import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * @module cn
 * @description Merge tailwind classes, removing any conflicting styles.
 * @param {import ('clsx').ClassValue[]} inputs - The classes to merge.
 * @returns string of merged classes.
 * @example
 * import {cn} from '@/lib/utils';
 * <div className={cn('text-red-500', 'text-green-500')}>Hello World</div>
 */

export default function cn(...inputs) {
    return twMerge(clsx(inputs));
}
