import { BookingConfirmationPage, Text, Button } from '@/components/common';
import { currencyFormatter } from '@/lib/utils';

function QuoteSaved() {
	return (
		<BookingConfirmationPage
			retrieveQuotes
			title="Quote Saved"
			loadingText="Fetching your quote details."
			noticeText="You will receive an email with your quote details."
			hideTickOnDesktop
			hideSummaryTitle
			hideTravelInfo
			footerClassName="px-5 lg:px-0 pt-2 pb-4 my-4"
			DetailsComponent={({ booking }) => (
				<Text className="flex items-center justify-between w-full py-4 border-b border-lighter-grey">
					<Text as="span" className="text-lg font-bold">
						Quote reference
					</Text>
					<Text as="span" className="text-lg">
						{booking?.id}
					</Text>
				</Text>
			)}
			FooterComponent={({ booking }) => (
				<div className="flex flex-col gap-2 md:gap-4">
					<Text className="flex items-center justify-between w-full">
						<Text as="span" className="text-2xl font-bold">
							Total
						</Text>
						<Text as="span" className="text-2xl font-bold">
							{currencyFormatter({ amount: booking?.overview?.total })}
						</Text>
					</Text>
					{booking?.overview?.localTotal > 0 && (
						<Text className="flex items-center justify-between w-full">
							<Text as="span">Payable On Collection</Text>
							<Text as="span">
								{currencyFormatter({
									amount: booking.overview.localTotal,
									currency: 'CAD',
								})}
							</Text>
						</Text>
					)}
					<div className="flex flex-col items-center gap-3 @lg:flex-row">
						<Button
							hideIcon
							tagName="a"
							target="_top"
							href="/"
							className="cursor-pointer"
							label="Return to homepage"
							variant="supporting-yellow"
						/>
					</div>
				</div>
			)}
		/>
	);
}

export default QuoteSaved;
