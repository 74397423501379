import { currencyFormatter } from '@/lib/utils';
import { SmallFlightCard } from '@components/flights';
import { Text, CheckButton } from '@/components/common';

/**
 * @typedef {Object} OptionPlusCardProps
 * @property {string} title
 * @property {Object} flight
 * @property {Object} option
 * @property {(param: Object)=> void} onSelect
 * @property {bool} isSelected
 * @property {bool} isAvailable
 * @property {bool} isDisabled
 * @property {string} unavailableMessage
 * @property {()=> React.JSX.Element} renderSummary
 **/

/**
 * @name OptionPlusCard
 * @description Renders an option plus card
 * @param {OptionPlusCardProps} props
 * @returns {React.JSX.Element}
 * @example
 * <OptionPlusCard option={option} type="Outbound" />
 */

function OptionPlusCard({
	title,
	flight,
	option,
	onSelect,
	isDisabled,
	isSelected,
	isAvailable,
	unavailableMessage,
	renderSummary,
	hidePrice = false,
	checkboxText,
}) {
	const amount = option?.pricing?.adult || 0;

	return (
		<fieldset
			disabled={!isAvailable || isDisabled}
			className="group disabled:pointer-events-none disabled:opacity-70"
		>
			<SmallFlightCard
				flight={flight}
				renderTitle={() => (
					<div className="flex flex-col items-start w-auto gap-1 group-disabled:text-dark-grey/60">
						<Text className="font-bold capitalize w-fit">{title}</Text>
					</div>
				)}
				renderSummary={renderSummary}
			>
				<div className="flex items-center justify-between w-full h-auto bg-white">
					<div className="flex w-auto xl:justify-end xl:w-full">
						{!isAvailable ? (
							<Text as="span" className="capitalize">
								{unavailableMessage}
							</Text>
						) : (
							<div className="flex flex-col items-start gap-4 xl:flex-row xl:items-center">
								{!hidePrice && (
									<div className="flex flex-col xl:items-end xl:text-right">
										<Text className="font-bold">Price</Text>
										<Text className="text-sm">
											{`${currencyFormatter({
												amount,
											})} per person`}
										</Text>
									</div>
								)}
								<CheckButton
									checked={isSelected}
									onChange={() => {
										if (!isAvailable) return;
										if (typeof onSelect === 'function') {
											onSelect(option);
										}
									}}
									isDisabled={!isAvailable || isDisabled}
									renderText={(checked) => (
										<Text className="ml-2" as="span">
											{checked ? 'Selected' : checkboxText ?? 'Add to basket'}
										</Text>
									)}
								/>
							</div>
						)}
					</div>
				</div>
			</SmallFlightCard>
		</fieldset>
	);
}

OptionPlusCard.defaultProps = {
	unavailableMessage: 'Not Available',
};

export default OptionPlusCard;
