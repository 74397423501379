import PropTypes from 'prop-types';
import { Loader } from '@components/common';
import { useUrlParams } from '@/hooks';

function SearchCancelledButton({ paramsKey }) {
	const { clearParams } = useUrlParams(paramsKey);

	const restartSearch = (e) => {
		e.preventDefault();

		// reset the filters
		if (paramsKey) clearParams();

		// trigger reload to restart the search
		window.location.reload();
	};

	return (
		<div className="flex justify-center">
			<Loader
				hideIcon
				title="Search Cancelled"
				loadingSubText="This search was cancelled."
				cancelLabel="Resume search"
				className="h-auto bg-transparent"
				onCancel={restartSearch}
			/>
		</div>
	);
}

SearchCancelledButton.propTypes = {
	paramsKey: PropTypes.string,
};

export default SearchCancelledButton;
